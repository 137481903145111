import React from 'react';
import { GenericDataAccess } from '../dataControls/GenericDataAccess';
import { SingleChoiceControl } from './SingleChoiceControl';
import { MultipleChoiceControl } from './MultipleChoiceControl';
import { TextLineControl } from './TextLineControl';
import { TextLineLargeControl } from './TextLineLargeControl';
import { ImageUploadControl } from './ImageUploadControl';
import { Card } from '../components/Containers';
export class GenericInputComponent extends React.Component {

    constructor(props) {
        super(props);

        this.logger = window.BaseLogger;
        this.dataAccess = new GenericDataAccess();

        this.controls = [];

        this.request = {
        };

        this.dataId = this.props.dataId;
        this.state = {
            metaDataItems: [],
            isValid: false
        }

        this.onInit = this.onInit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidMount() {
        var self = this;
        if (this.dataId) {
            this.loadData(this.dataId);
        } else {
            this.retrieveMetaData();
        }

        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    loadData(dataId, onCompleted) {
        var self = this;

        this.dataId = dataId;
        this.dataAccess.getItem(this.props.dataObject, this.dataId, function (result) {
            self.request = result;
            self.retrieveMetaData(onCompleted);
        });
    }


    async retrieveMetaData(onCompleted) {

        var result = await this.dataAccess.getMetaData(this.props.dataObject);
        this.setState({
            metaDataItems: result
        });

        if (onCompleted) {
            onCompleted();
        }
    }

    onInit(cntrl) {
        this.controls.push(cntrl);
        var isValid = this.isValid();
        this.setState({ isValid: isValid });
    }

    onChange(value, id) {
        this.request[id] = value;

        var isValid = this.isValid();
        this.setState({ isValid: isValid });
    }

    onSaveClick() {
        var self = this;

        this.dataAccess.saveItem(this.props.dataObject, this.request, function (result) {
            window.Alerts.showSuccess('Your input has been saved.', 'Saved');
            if (self.props.onCompleted) {
                self.props.onCompleted();
            }
        });
    }

    onCancelClick() {
        if (this.props.onCompleted) {
            this.props.onCompleted();
        }
    }


    isValid() {
        var isValid = true;
        this.controls.map(function (cntrl, index) {
            if (!cntrl.isInputValid()) {
                isValid = false;
            }
        });
        return isValid;
    }

    createTitle(id) {
        var metaData = this.state.metaDataItems[id];
        var display = metaData.display;
        if (display && display.title) {
            return (<h3 className='title'>{display.title}</h3>);
        }
    }

    createDescription(id) {
        var metaData = this.state.metaDataItems[id];
        var display = metaData.display;
        if (display && display.description) {
            return (<p className='description'>{display.description}</p>);
        }
    }

    createInputControl(id) {       
        var control = null;
        var metaData = this.state.metaDataItems[id];

        var display = metaData.display;
        var validation = metaData.validation;
        var datasource = metaData.datasource;
        var help = metaData.help;

        if (display && display.inputType) {
            var value = this.getDefaultValue(id);
            var disabled = this.getPropsValue(id) !== null ? true : false;
            
            switch (display.inputType) {
                case 'SingleChoice':                                       
                    control = (<SingleChoiceControl id={id} key={id} display={display} validation={validation} datasource={datasource} help={help} onInit={this.onInit} onChange={this.onChange} value={value} disabled={disabled} />);
                    break;
                case 'MultipleChoice':
                    control = (<MultipleChoiceControl id={id} key={id} display={display} validation={validation} datasource={datasource} help={help} onInit={this.onInit} onChange={this.onChange} value={value} disabled={disabled} />);
                    break;
                case 'TextLine':
                    control = (<TextLineControl id={id} key={id} display={display} validation={validation} onInit={this.onInit} help={help} onChange={this.onChange} value={value} disabled={disabled} />);
                    break;
                case 'TextLineLarge':
                    control = (<TextLineLargeControl id={id} key={id} display={display} validation={validation} onInit={this.onInit} help={help} onChange={this.onChange} value={value} disabled={disabled} />);
                    break;
                case 'ImageUpload':                    
                    control = (<ImageUploadControl id={id} key={id} display={display} validation={validation} onInit={this.onInit} help={help} onChange={this.onChange} value={value} disabled={disabled} />);
                    break;
                default:
                    control = null;
                    break;
            }
            return control;
        }
    }

    hasInputControl(id) {

    }

    getDefaultValue(id) {
        var result = null;
        
        if (this.dataId && this.dataId > 0) {            
            result = this.request[id];
        }
        else {
            if (this.props && this.props[id]) {                
                result = this.props[id]
            }
        }
        return result;
    }

    getPropsValue(id) {
        if (this.props && this.props[id]) {
            return this.props[id]
        }
        return null;
    }


    render() {
        var self = this;
        return (
            <Card title='Edit' subtitle='Enter the required information and press the save button'>
                <div className="generic-input-component">
                    {Object.keys(self.state.metaDataItems).map(function (controlId, index) {
                        return (
                            <div key={`input_component_${index}` }>
                                {self.createInputControl(controlId) &&
                                    <div className='row'>
                                        <div className='col col-12'>
                                            {self.createTitle(controlId)}
                                            {self.createDescription(controlId)}
                                            {self.createInputControl(controlId)}
                                        </div>
                                    </div>
                                }
                            </div>
                        );
                    })}
                    <button className='btn btn-primary btn-lg' onClick={this.onSaveClick} disabled={!this.state.isValid}>Save</button>
                    <button className='btn btn-secondary-outline btn-lg pull-right' onClick={this.onCancelClick}>Cancel</button>
                </div>
            </Card>
        );
    }
}

import React from 'react';

export class ExpandableSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: this.props.expanded
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick(override) {
        if (override) {
            this.toggle();
        }
        else {
            if (this.props.disabled !== true && this.props.preventDefaultBehavior !== true) {
                this.toggle();
            }
        }
    }

    toggle() {
        var expanded = this.state.expanded;
        this.setState({
            expanded: !expanded
        });
    }

    expand() {
        this.setState({
            expanded: true
        });
    }

    collapse() {
        this.setState({
            expanded: false
        });
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    render() {
        return (
            <div className='expandable-area'>

                <div className='header d-flex flex-row bd-highlight' onClick={() => { this.onClick(false) }}>
                    <div className={this.props.disabled === true ? 'p-2 disabled' : 'p-2'} onClick={() => { this.onClick(true) }}>
                        {this.state.expanded === true && this.props.disabled !== true &&
                            <span className='glyphicon glyphicon-menu-up'></span>
                        }

                        {(this.state.expanded !== true || this.props.disabled === true) &&
                            <span className='glyphicon glyphicon-menu-down'></span>
                        }
                    </div>
                    <div className={this.props.disabled === true ? 'p-2 flex-grow-1 bd-highlight disabled' : 'p-2 flex-grow-1 bd-highlight'}>

                        {this.props.onProvideTitle &&
                            <div>
                                {this.props.onProvideTitle()}
                            </div>
                        }

                        {!this.props.onProvideTitle &&
                            <div>
                                {this.props.title}
                            </div>
                        }

                    </div>
                </div>

                <div className={this.state.expanded === true && this.props.onProvideContent && this.props.disabled !== true ? 'content show' : 'collapsed'}>
                    {this.props.onProvideContent()}
                </div>

            </div>
        );
    }
} 
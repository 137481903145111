export const DotNetType = {
    Byte: 'System.Byte',
    SByte: 'System.SByte',
    UInt16: 'System.UInt16',
    UInt32: 'System.UInt32',
    UInt64: 'System.UInt64',
    Int16: 'System.Int16',
    Int32: 'System.Int32',
    Int64: 'System.Int64',
    Decimal: 'System.Decimal',
    Double: 'System.Double',
    Single: 'System.Single',
    DateTime: 'System.DateTime',
    Char: 'System.Char',
    Boolean: 'System.Boolean'
}

export class DotNet {

    static GetRegexForType(dotNetType) {
        switch (dotNetType) {
            case DotNetType.Byte:
            case DotNetType.SByte:
            case DotNetType.UInt16:
            case DotNetType.UInt32:
            case DotNetType.UInt64:
            case DotNetType.Int16:
            case DotNetType.Int32:
            case DotNetType.Int64:
                return "[0-9]*";
            case DotNetType.Decimal:
            case DotNetType.Double:
            case DotNetType.Single:
                return "[0-9]*(\.[0-9]*)?";
            case DotNetType.DateTime:
                return "(\d{4})-(\d{2})-(\d{2})(T\d{2}:\d{2}:\d{2})?";
            case DotNetType.Char:
                return "\w";
            case DotNetType.Boolean:
                return "(true|false)";
            default:
                return null;
        }
    }

    static IsValueOfType(value, dotNetType) {
        var regex = DotNet.GetRegexForType(dotNetType);
        return regex
            ? Utils.MatchesRegex(regex, value)
            : true;
    }

}

export class Utils {

    static IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    static Clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static MatchesRegex(regex, value) {        
        if (regex) {
            var r = new RegExp(regex);
            var match = r.exec(value);
            if (match == null || match[0] != value) {
                return false;
            }
        }
        return true;
    }

}


export class String {

    static PrettyPrintFromCamelCase(str) {
        var result = '';
        if (str && str.length > 0) {
            result = str.replace(/([A-Z0-9])/g, " $1").replace(/(^\w|\s\w)/g, m => m.toUpperCase());
        }
        return result;
    }


    static IsJson(item) {
        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === "object" && item !== null) {
            return true;
        }

        return false;
    }
}


export class Timer {

    constructor() {
        this.timer = null;
    }

    waitAsync(ms) {
        clearTimeout(this.timer);
        return new Promise(resolve => {
            this.timer = setTimeout(() => {
                resolve();
            }, ms);
        })
    }
    
}


export class Url {
    static getQueryString(url) {
        var result = null;
        var idx = url.indexOf('?');
        if (idx > 0) {
            result = url.substring(idx);
        }
        return result;
    }

    static getPathName(url) {
        var result = url;
        var idx = url.indexOf('?');
        if (idx > 0) {
            result = url.substring(0, idx);
        }
        return result;
    }

    static getQueryStringParameterNames(url) {
        var result = [];
        var queryString = Url.getQueryString(url);
        if (queryString) {
            var urlParams = new URLSearchParams(queryString);
            var keys = urlParams.keys();
            for (var key of keys) {
                result.push(key)
            }
        }
        return result;
    }

    static getQueryStringParameterValue(url, name) {
        var queryString = Url.getQueryString(url);
        var urlParams = new URLSearchParams(queryString);
        return urlParams.get(name);
    }
}

export class Guid {
    static New() {
        return Math.random().toString(36).substr(2, 16);
    }
}
import React from 'react';
import { Card, Row, Column } from '../../../components/Containers';
import { RuleEditorTitle } from './RuleEditorTitle';

export class RuleTargetCard extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleTarget) {
            throw new Error("ruleTarget Property is required");
        }

        this.target = this.props.ruleTarget;
        this.datasourceDefinitions = this.props.datasourceDefinitions;
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    onDeleteClick() {
        if (this.props.onDeleteClick) {
            this.props.onDeleteClick(this.target);
        }
    }

    getAffinityText() {
        var result = null;
        switch (this.target.affinity) {
            case 1:
                result = 'Exclusive';
                break;
            case 2:
                result = 'Include';
                break;
            case 3:
                result = 'Exclude';
                break;
            default:
                throw new Error(`Affinity ${this.target.affinity} is not supported`);
        }
        return result;
    }

    getLocationText() {
        var result = 'Independend';
        switch (this.target.location) {
            case 2:
                result = `Centered To ${this.getRelatedFeature()}`;
                break;
            case 3:
                result = `Fix Location from Pouch Top`;
                break;
            default:
                break;
        }
        return result;
    }

    getRelatedFeature() {        
        var feature = this.datasourceDefinitions.items.find(e => e.id == this.target.relatedFeature);
        return feature.name;
    }

    render() {
        var self = this;
        return (
            <Card title={<RuleEditorTitle title={this.target.definition.name} button={<span className='glyphicon glyphicon-remove'></span>} disabled={this.props.preventEdit} onClick={() => this.onDeleteClick()} />} noIcon={true} subtitle={`The ${this.target.definition.name} options will be limited to:`}>

                <div className="card-body">
                    <Row>
                        <Column>
                            <label>Items</label>
                            {this.target.items.map(function (item, idx) {
                                return (
                                    <p key={`tar_${idx}`} className="card-text">{item.name}</p>
                                );
                            })}
                        </Column>

                        <Column>
                            <label>Affinity</label>
                            <p className="card-text">{this.getAffinityText()}</p>
                        </Column>
                    </Row>

                    {this.target.location > 1 &&
                        <Row>
                            <Column>
                                <label>Location</label>
                                <p className="card-text">{this.getLocationText()}</p>
                            </Column>

                            <Column>
                                <label>Offset</label>
                                <p className="card-text">{this.target.offset.toFixed(4)} mm/in</p>
                            </Column>
                        </Row>
                    }
                </div>

            </Card>


        );
    }
}
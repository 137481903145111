import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { Tile } from '../../../core/Tile';
import { GenericComponent } from '../../../dataForms/GenericComponent';


export class DielineConfigurationHomeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Dieline & 3D Model Configuration' />
                <div className='tile-container'>
                    <Tile title='Bottom Fills' onClick={() => window.Router.setRoute('/configuration/dieline/bottomfills')} />
                    <Tile title='Converting Methods' onClick={() => window.Router.setRoute('/configuration/dieline/convertingmethods')} />
                    <Tile title='Double Cuts' onClick={() => window.Router.setRoute('/configuration/dieline/doublecut')} />
                    <Tile title='Eyemarks' onClick={() => window.Router.setRoute('/configuration/dieline/eyemarks')} />
                    <Tile title='Finish Types' onClick={() => window.Router.setRoute('/configuration/dieline/finishType')} />
                    <Tile title='Gusset Types' onClick={() => window.Router.setRoute('/configuration/dieline/gussetType')} />
                    <Tile title='Hang Holes' onClick={() => window.Router.setRoute('/configuration/dieline/hangholes')} />
                    <Tile title='ePac Connect' onClick={() => window.Router.setRoute('/configuration/dieline/qrcodetype')} />
                    <Tile title='Rounded Corners' onClick={() => window.Router.setRoute('/configuration/dieline/roundedcorners')} />
                    <Tile title='Seal Width' onClick={() => window.Router.setRoute('/configuration/dieline/sealwidth')} />
                    <Tile title='Tear Notches' onClick={() => window.Router.setRoute('/configuration/dieline/tearnotches')} />
                    <Tile title='Valves' onClick={() => window.Router.setRoute('/configuration/dieline/valves')} />
                    <Tile title='Zippers' onClick={() => window.Router.setRoute('/configuration/dieline/zippers')} />
                </div>
            </div>
        );
    }
}


export class DielineConfigurationFinishTypesView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Finish Types'/>
                <GenericComponent dataObject='dielineFinishTypeMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}

export class DielineConfigurationConvertingMethodsView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Converting Methods'/>
                <GenericComponent dataObject='dielineConvertingMethodMappings' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class DielineConfigurationHangHolesView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Hang Holes' />
                <GenericComponent dataObject='dielineHangHoleMappings' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class DielineConfigurationTearNotchesView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Tear Notches' />
                <GenericComponent dataObject='dielineTearNotchMappings' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class DielineConfigurationZippersView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Zippers' />
                <GenericComponent dataObject='dielineZipperMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class DielineConfigurationEyemarksView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Eyemarks'/>
                <GenericComponent dataObject='dielineEyemarkMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class DielineConfigurationBottomFillsView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Bottom Fills'/>
                <GenericComponent dataObject='dielineBottomFillMappings' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}


export class DielineConfigurationValvesView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Valves'/>
                <GenericComponent dataObject='dielineValveMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class DielineConfigurationSealWidthView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Seal Width'/>
                <GenericComponent dataObject='dielineSealWidthMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class DielineConfigurationRoundedCornersView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Rounded Corners'/>
                <GenericComponent dataObject='dielineRoundedCornerMappings' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}


export class DielineConfigurationDoubleCutView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Double Cut' />
                <GenericComponent dataObject='dielineDoubleCutMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class DielineConfigurationQrCodeTypeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='ePac Connect'/>
                <GenericComponent dataObject='dielineQrCodeTypeMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class DielineConfigurationGussetTypeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Gusset Type'/>
                <GenericComponent dataObject='dielineGussetTypeMappings' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}




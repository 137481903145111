import React from 'react';

export class RoundIndicator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showHint: false
        }

        this.indicate = this.props.indicate ? this.props.indicate : null;
        this.text = this.props.text ? this.props.text : null;
    }

    getClass() {
        switch (this.indicate) {
            case 'success':
                return 'success';
            case 'error':
                return 'error';
            default:
                return '';
        }
    }

    render() {
        return (
            <div className={'round-indicator ' + this.getClass()} onMouseEnter={() => { this.setState({ showHint: true }) }} onMouseLeave={() => { this.setState({ showHint: false }) }}>
                {this.state.showHint &&
                    <div className='popover'>{this.text}</div>
                }
            </div>
        )
    }


}

import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { ImageTitle } from '../../../core/ImageTitle';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { ProductGroup } from './ProductGroup';
import { ExpandableCard } from '../../../components/Containers';
import { ImageTileCircle } from '../../../components/Containers';	
import { Logger } from "../../../infrastructure/Logger";


const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
}


export class ProductGroupListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: null,
            downloadInProgress: false
        }
        this.dataAccess = new ApiDataAccess('/data/productGroups');

    }


    componentDidMount() {

        window.Loader.show();
        this.dataAccess.get('/overview')
            .then(items => {
                var groups = Object.groupBy(items, ({ productGroupName }) => productGroupName);                
                this.setState({
                    groups: groups
                });                
                window.Loader.hide();
            });
    }

    export() {
        this.setState({ downloadInProgress: true });
        window.Alerts.showInfo('It will take a while until your export is completed. Please continue using the application and you will be notified when your export is ready for download.', 'Scheduled Export');

        this.dataAccess.post('/export', {})
            .then(data => {
                Logger.writeDebug('Enqueued Export of data', data);
            });
    }


    render() {
        return (
            <div>
                <ImageTitle text='Packaging Groups' />

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/packaginggroups/new')} />
                            <ToolbarButton disabled={this.state.downloadInProgress} icon='glyphicon-export' text='Export' onClick={() => { this.export(); }} />
                        </div>
                    )
                }} />

                {this.state.groups &&
                    <div>
                        {Object.keys(this.state.groups).map((key, idx) => {
                            return (
                                <ProductGroupRow  group={this.state.groups[key]} name={key} key={`group_${idx}`} />
                            )
                        })}
                    </div>
                }
            </div>
        )
    }

}


export class ProductGroupRow extends React.Component {

    constructor(props) {
        super(props);

        this.name = this.props.name;
        this.specs = Object.groupBy(this.props.group, ({ structureSpecId }) => structureSpecId);
        this.info = this.props.group[0];



        this.products = Object.groupBy(this.props.group, ({ productName }) => productName);
        this.panel = null;
        this.productGroup = {
            id: this.props.group[0].productGroupId,
            isPublished: this.props.group[0].productGroupPublished
        }

        var value1 = Object.keys(this.specs)[0];
        var value2 = Object.keys(this.products)[0];

        this.specsCount = value1 !== 'null' && value1 !== 'undefined' ? Object.keys(this.specs).length : 0;
        this.productCount = value2 !== 'null' && value2 !== 'undefined' ? Object.keys(this.products).length : 0;


        this.state = {
            isPublished: this.productGroup.isPublished === 'True'
        }
    }

    getSpecs() {
        var result = [];
        Object.keys(this.specs).forEach(specId => {
            var item = {
                id: this.specs[specId][0].structureSpecId,
                name: `${this.specs[specId][0].structureSpecName} (SPEC-${specId})`,
                imageId: null
            };
            result.push(item);
        }); 

        result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        return result;
    }


    getProducts() {
        var result = [];
        Object.keys(this.products).forEach(productId => {

            
            var item = {
                id: this.products[productId][0].productId,
                name: this.products[productId][0].productName,
                imageId: this.products[productId][0].productImageId
            };
            result.push(item);
        });

        result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        return result;
    }

    onPublishToggle(item, value, toggle) {
        var self = this;
        var isPublished = this.state.isPublished;
        toggle.setValue(isPublished);

        var productGroup = new ProductGroup(item.id);
        if (isPublished) {
            productGroup.publish(false)
                .then(data => {
                    window.Alerts.showSuccess('Packaging Group has been unpublished');
                    self.productGroup.isPublished = false;
                    self.setState({
                        isPublished: false
                    });
                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                    else {
                        window.Alerts.showInfo('Product Group cannot be unpublished');
                    }
                });
        }
        else {
            productGroup.publish(true)
                .then(data => {
                    window.Alerts.showSuccess('Packaging Group has been published');
                    self.productGroup.isPublished = true;
                    self.setState({
                        isPublished: true
                    });

                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                    else {
                        window.Alerts.showInfo('Product Group cannot be published');
                    }
                });
        }
    }


    render() {
        return (
            <div className='productGroupRow'>
                <ExpandableCard title={<ProductGroupCardTitle name={this.name} info={this.info} productGroup={this.productGroup} group={this.props.group} products={this.products} specsCount={this.specsCount} productCount={this.productCount} isPublished={this.state.isPublished} onPublishToggle={(gr, v, ctrl) => { this.onPublishToggle(gr, v, ctrl) } } />}>
                    <div className='panelContainer'>
                        <div className='item'>
                            <h3>Structure Specs</h3>
                            {this.specsCount > 0
                                ?
                                <div className='table'>
                                    <div className='body'>
                                        {this.getSpecs().map((item, idx) => {
                                            return (
                                                <div key={`spec_${idx}`} className='row clickable' onClick={() => { window.Router.setRoute(`/structurespecs/${item.id}`) }}>
                                                    <span>{item.name}</span>
                                                </div>                                                
                                            )
                                        })}
                                    </div>
                                </div>
                                :
                                <p>No published Structure Specs</p>
                            }
                        </div>

                        <div className='item'>
                            <h3>Products</h3>
                            {this.productCount > 0
                                ?
                                <div>
                                    {this.getProducts().map((item, idx) => {                                        
                                        return (
                                            <ImageTileCircle key={`img_${idx}`} src={`/data/images/${item.imageId ? item.imageId : 0}/thumbnail`} value={item.name} onClick={() => { window.Router.setRoute(`/products/${item.id}`) }} />
                                        )
                                    })}
                                </div>
                                :
                                <p>No published Products</p>
                            }

                        </div>
                    </div>
                </ExpandableCard>                
            </div>
        )
    }
}

export class ProductGroupCardTitle extends React.Component {


    getProductPreview() {
        var result = [];
        Object.keys(this.props.products).forEach(productId => {
            if (result.length < 6) {
                var item = {
                    id: productId,
                    name: this.props.products[productId][0].productName,
                    imageId: this.props.products[productId][0].productImageId
                };
                result.push(item);
            }
        });

        result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        return result;
    }

    render() {
        return (
            <div className='productGroupCardTitle'>
                <div className='titleRow'>
                    <div className='caption' onClick={() => { window.Router.setRoute('/packaginggroups/' + encodeURIComponent(this.props.productGroup.id)) }}>
                        <span>{this.props.name}</span>
                        <p>{this.props.info.productGroupDescription}</p>
                    </div>
                    <div className='controls'>
                        <button className='btn btn-primary btn-sm' onClick={() => { window.Router.setRoute('/packaginggroups/' + encodeURIComponent(this.props.productGroup.id)) }}><span className='glyphicon glyphicon-pencil' /></button>
                        <ToggleSwitchControl on={this.props.isPublished} onChange={(value, cntrl) => this.props.onPublishToggle(this.props.productGroup, value, cntrl)} />
                    </div>
                </div>

                <h3>Product Attributes</h3>
                <div className='properties'>
                    <PropertyItem title={'Agressiveness'} property={'productAgressivnessName'} group={this.props.group} />
                    <PropertyItem title={'Aroma'} property={'productAromaName'} group={this.props.group} />
                    <PropertyItem title={'Child Resistance'} property={'productChildResistanceName'} group={this.props.group} />
                    <PropertyItem title={'Consistency'} property={'productConsistencyName'} group={this.props.group} />
                    <PropertyItem title={'Emission'} property={'productEmissionName'} group={this.props.group} />
                    <PropertyItem title={'Light Sensitivity'} property={'productLightSensitivtyName'} group={this.props.group} />
                    <PropertyItem title={'Moisture'} property={'productMoistureName'} group={this.props.group} />
                    <PropertyItem title={'Oxygen Sensitivity'} property={'productOxygenSensitivtyName'} group={this.props.group} />
                    <PropertyItem title={'Shelf Lifetime'} property={'productShelfLifetimeName'} group={this.props.group} />
                    <PropertyItem title={'Fill Temperature'} property={'productTemperatureName'} group={this.props.group} />
                </div>

                {this.props.productCount > 0 &&
                    <div>
                        <h3>Sample Products</h3>
                        
                        <div className='imagePreview'>
                            {this.getProductPreview().map((item, idx) => {
                                return (
                                    <TinyImageLineItem key={`itm1_${idx}`} item={item} />
                                )
                            })}
                        </div>
                        
                    </div>
                }

                <div className='stats'>
                    <p>{`${this.props.specsCount} Published Structure Specs`}</p>
                    <p>{`${this.props.productCount} Published Products`}</p>
                </div>

            </div>
        )
    }
}


export class PropertyItem extends React.Component {
    constructor(props) {
        super(props);

        this.title = this.props.title;
        this.group = this.props.group;
        this.property = this.props.property;

        this.items = this.props.group.map(a => a[this.property]).filter(distinct);
    }

    render() {
        return (
            <div className='item'>
                <div className='caption'>{this.title}</div>
                <div className='content'>
                    {this.items.map((item, idx) => {
                        return (
                            <p key={`itm_${idx}`}>{item}</p>
                        )
                    })}
                </div>
            </div>        
        )
    }

}

export class TinyImageLineItem extends React.Component {

    render() {
        return (
            <div className='tinyImageLineItem'>
                <ImageTileCircle src={`/data/images/${this.props.item.imageId ? this.props.item.imageId : 0}/thumbnail`} value={this.props.item.name} />
            </div>
        )
    }
}

export class PublishableNameDisplay extends React.Component {

    render() {
        return (
            <div className="flex-center">
                {this.props.item.isPublished === true
                    ? <span className='glyphicon glyphicon-play color-green' title='Published' />
                    : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />
                }
                <div>{this.props.item.name}</div>
            </div>
        )
    }
}


import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';

export class HelpControl extends React.Component {

    constructor(props) {
        super(props);
       
        this.dataAccess = new ApiDataAccess(window.HELP_URL, { mode: 'cors' });

        this.state = {
            showPopup: false,
            data: null
        }

        this.onClick = this.onClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onClick() {
        var self = this;        
        this.dataAccess.get('/api/docs/' + this.props.help.url)
            .then(data => {
                self.setState({
                    data: data,
                    showPopup: true
                });
            })
    }

    onCloseClick() {
        this.setState({
            data: null,
            showPopup: false
        });
    }


    render() {
        return (
            <div className='help-control'>
                {this.props.help &&
                    <div>
                        <span className='glyphicon glyphicon-question-sign' onClick={this.onClick}></span>
                    </div>
                }         

                {this.state.showPopup &&
                    <div className='popup'>
                        <div className='background' onClick={this.onCloseClick}>
                        </div>

                    <div className='dialog'>
                            <div className='title'><span>{this.state.data.name}</span><span className='close-button glyphicon glyphicon-remove-circle' onClick={this.onCloseClick}></span></div>
                            <div className='content' dangerouslySetInnerHTML={{ __html: this.state.data.html }}>                                
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

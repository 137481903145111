import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { DropdownControl } from '../../../controls/DropdownControl';
import { TextboxControl } from '../../../controls/TextboxControl';

export class SettingsView extends React.Component {

    constructor(props) {
        super(props);

        this.controls = {
        };

        this.state = {
            settings: [],
            settingTypes: null,
            dataSourceItems: null,
            selectedSettingType: null,
            selectedSettingValue: null
        }

        this.getSettingsTypesAsync()
            .then(items => {
                this.setState({
                    settingTypes: items
                })
            });

        this.refreshList();


        this.onSettingsTypeChanged = this.onSettingsTypeChanged.bind(this);
        this.onDataSourceItemChanged = this.onDataSourceItemChanged.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    refreshListAsync() {
        return new Promise((resolve, reject) => {
            this.getSettingsTypesAsync()
                .then(settingTypes => {
                    let dataSourceRegistry = {};
                    let promisses = [];
                    let dataSourceNames = [];

                    settingTypes.forEach(settingType => {
                        if (settingType.dataSource && !dataSourceNames.find(e => e === settingType.dataSource)) {
                            dataSourceNames.push(settingType.dataSource);
                            promisses.push(this.getDataSourceAsync(settingType.dataSource));
                        }
                    });

                    Promise.all(promisses)
                        .then(results => {
                            for (let i = 0; i < results.length; i++) {
                                dataSourceRegistry[dataSourceNames[i]] = results[i];
                            }

                            this.getSettingsAsync()
                                .then(items => {

                                    let listItems = [];

                                    items.forEach(item => {
                                        let settingsType = settingTypes.find(e => e.id === item.settingTypeId);
                                        let value = settingsType.dataSource ? dataSourceRegistry[settingsType.dataSource].find(e => e.id == item.value).name : item.value;
                                        listItems.push({
                                            id: item.id,
                                            settingsTypeId: item.settingTypeId,
                                            settingsType: settingsType ? settingsType.name : 'N/A',
                                            value: value ? value : 'N/A'
                                        })
                                    });
                                    resolve(listItems);
                                });
                        })
                });
        });
    }

    refreshList() {
        this.refreshListAsync()
            .then(items => {
                this.setState({
                    settings: items
                });
            });
    }

    getSettingsAsync() {
        return new Promise((resolve, reject) => {
            let dataAccess = new ApiDataAccess('/data/settings');
            dataAccess.get('/')
                .then(data => {
                    resolve(data);
                })
                .catch(ex => {
                    reject(ex);
                })
        });
    }


    getSettingsTypesAsync() {
        return new Promise((resolve, reject) => {
            this.getDataSourceAsync('SettingTypes')
                .then(settingTypes => {
                    resolve(settingTypes);
                });
        })
    }

    getDataSourceAsync(name) {
        return new Promise((resolve, reject) => {
            let dataAccess = new ApiDataAccess(`/data/datasources/${encodeURI(name)}`);
            dataAccess.get('/')
                .then(data => {
                    resolve(data);
                })
                .catch(ex => {
                    reject(ex);
                });
        });
    }

    onSettingsTypeChanged(value, item) {
        if (item) {
            if (item.dataSource) {
                this.getDataSourceAsync(item.dataSource)
                    .then(data => {
                        this.setState({
                            selectedSettingType: value,
                            dataSourceItems: data
                        });
                    });
            }
        }
        else {
            this.resetSelection();
        }
    }

    resetSelection() {
        this.controls.settingTypes.setValue(null);
        this.setState({
            selectedSettingType: null,
            selectedSettingValue: null,
            dataSourceItems: null
        });
    }

    onDataSourceItemChanged(value) {
        this.setState({
            selectedSettingValue: value
        });
    }


    saveAsync() {
        return new Promise((resolve, reject) => {
            let data = {
                settingTypeId: this.state.selectedSettingType,
                value: this.state.selectedSettingValue
            };

            let dataAccess = new ApiDataAccess('/data/settings');
            dataAccess.post('/', data)
                .then(data => { resolve(data); })
                .catch(ex => { reject(ex); });
        });
    }

    onSaveClick() {
        this.saveAsync()
            .then(data => {
                this.resetSelection();
                this.refreshList();
            })
            .catch(data => {
                this.resetSelection();
                this.refreshList();
            });
    }


    render() {
        return (
            <div className="view settings">
                <h1>Settings</h1>

                <div className='table'>
                    <div className='table-header'>
                        <div className = 'row'>
                        <div className='col col-6'>Type</div>
                            <div className='col col-6'>Value</div>
                        </div>
                    </div>

                    <div className='body'>
                    {this.state.settings.map((item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row border-bottom"'>
                                <div className='col col-6'>{item.settingsType}</div>
                                <div className='col col-6'>{item.value}</div>
                            </div>
                        )
                    })}
                    </div>
                </div>

                <div className='table'>
                    <div className='body'>
                        <div className='row'>
                            <div className='col col-6'>
                                <label htmlFor='type_dll'>Setting</label>
                                {this.state.settingTypes &&
                                    <DropdownControl id='type_dll' items={this.state.settingTypes} valueMemberName='id' displayMemberName='name' onChange={this.onSettingsTypeChanged} onInit={ctrl => { this.controls.settingTypes = ctrl }} />
                                }
                            </div>

                            <div className='col col-6'>
                                <label htmlFor='value_dll'>Value</label>
                                {this.state.dataSourceItems
                                    ? <DropdownControl id='value_dll' items={this.state.dataSourceItems} valueMemberName='id' displayMemberName='name' onChange={this.onDataSourceItemChanged} />
                                    : <TextboxControl onChnage={this.onDataSourceItemChanged} disabled={true} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <button className='btn btn-primary btn-lg pull-right' disabled={!(this.state.selectedSettingType && this.state.selectedSettingValue)} onClick={this.onSaveClick}>Save</button>

            </div>
        );
    }
}



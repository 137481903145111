import React from 'react';

export class SimpleListControl extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveClick = this.onRemoveClick.bind(this);
    }

    onRemoveClick(value) {
        if (this.props.onRemoveClick) {
            this.props.onRemoveClick(value);
        }
    }

    render() {
        var self = this;
        return (
            <div className='control simple-list'>
                {this.props.items && this.props.items.length > 0 &&
                    <div>
                        {this.props.items.map(function (item, id) {
                            return (
                                <div className='row border-bottom list-item'>
                                    <div className='content'>{item[self.props.displayMemberName]}</div>
                                    <div>
                                        <button className='btn btn-danger btn-sm pull-right' onClick={() => self.onRemoveClick(item[self.props.valueMemberName])}><span className='glyphicon glyphicon-remove'></span></button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }

                {!this.props.items || this.props.items.length == 0 &&
                    <div className='alert alert-info'>The list does not contain any items.</div>
                }

            </div>
        );
    }
}




export class GenericListControl extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.onRender) {
            throw new Error("onRender Property is required");
        }

        
    }

    render() {
        var self = this;
        var items = this.props.items ? this.props.items : [];

        if (this.props.onSort) {
            items = this.props.onSort(items);
        }


        return (
            <div className='table'>
                {self.props.onRenderTitle &&
                    <div className='table-header'>
                        {self.props.onRenderTitle()}
                    </div>
                }
                {items &&
                    <div className='body'>
                    {items.map((item, idx) => {
                        return (
                            <div key={`glci_${idx}`} className='list-row border-bottom'>
                                    {self.props.onRender(item, idx)}
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        );
    }
}




import React from 'react';
import { ComboBoxControl } from '../../../controls/ComboBoxControl';

const ItemType = {
    PrintFilm: 'PFILM',
    Film: 'FILM',
    Adhesive: 'ADHESIVE'
}

const ItemCategory = {
    Film: 'FILM',
    Adhesive: 'ADHESIVE'
}

export class MaterialEditorComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.filmWidth = this.props.filmWidth ? this.props.filmWidth : null;
        this.isPrelam = this.props.isPrelam ? this.props.isPrelam : false;


        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);
        this.metaData = null;

        this.printFilmSourceItems = null;
        this.filmSourceItems = null;
        this.adhesiveSourceItems = null;
        this.allSourceItems = null;


        this.state = {
            loaded: false,
            selectedItem: null,
            layers: []
        };

        this.onAddFilmClick = this.onAddFilmClick.bind(this);
        this.onAddAdhesiveClick = this.onAddAdhesiveClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    setValue(values) {
        var items = values && values.length > 0 ? values : [];

        this.setState({
            loaded: false
        });

        let layers = [];
        let sort = 0;
        items.forEach(item => {
            let layer = {
                type: this.getItemCategory(item.dataObject2.typeCode),
                dataObject2: item.dataObject2,
                dataObject2Id: item.dataObject2.id,
                sort: sort
            }
            sort++;
            layers.push(layer);
        });

        this.forceUpdate(() => {
            this.setState({
                layers: layers,
                selectedItem: null,
                loaded: true
            });
        });

        this.dataManager.reportChange(values, this);
    }

    getItemCategory(type) {
        switch (type) {
            case ItemType.Film:
            case ItemType.PrintFilm:
                return ItemCategory.Film;
            case ItemType.Adhesive:
                return ItemCategory.Adhesive;
        }
        return null;
    }


    getValue() {
        return this.state.layers;
    }

    isInputValid() {
        let layers = this.state.layers;
        return layers && layers.length > 0 && layers[0].type === ItemCategory.Film && layers[layers.length - 1].type === ItemCategory.Film;
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;

            self.loadDataSourcesAsync()
                .then(results => {
                    self.filmSourceItems = results.films;
                    self.printFilmSourceItems = results.printFilms;
                    self.adhesiveSourceItems = results.adhesives;
                    self.allSourceItems = results.all;

                    self.setState({
                        loaded: true
                    });

                    resolve(self);
                });
        });
    }


    loadDataSourcesAsync() {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.dataManager.getDataSourceItems('items')
            ])
                .then((results) => {
                    let printFilms = results[0].filter(obj => {
                        return obj.typeCode === ItemType.PrintFilm
                    });

                    let films = results[0].filter(obj => {
                        return obj.typeCode === ItemType.Film || obj.typeCode === ItemType.PrintFilm
                    });

                    let adhesives = results[0].filter(obj => {
                        return obj.typeCode === ItemType.Adhesive
                    });

                    let all = results[0].filter(obj => {
                        return obj.typeCode === ItemType.Adhesive || obj.typeCode === ItemType.PrintFilm || obj.typeCode === ItemType.Film
                    });



                    let items = {
                        printFilms: printFilms,
                        films: films,
                        adhesives: adhesives,
                        all: all
                    }
                    resolve(items);
                })
                .catch(ex => reject(ex));
        });
    }


    onAddFilmClick() {
        let film = this.state.selectedItem;

        let item = {
            type: ItemCategory.Film,
            dataObject2: film
        }


        let layers = this.state.layers;
        layers.push(item);
        this.setValue(layers);
    }

    onAddAdhesiveClick() {
        let adhesive = this.state.selectedItem;

        let item = {
            type: ItemCategory.Adhesive,
            dataObject2: adhesive
        }

        let layers = this.state.layers;
        layers.push(item);
        this.setValue(layers);
    }

    onDeleteClick(idx) {
        let layers = this.state.layers;
        layers.splice(idx, 1);
        this.setLayers(layers);
        this.setValue(layers);
    }

    setLayers(layers) {
        this.setState({
            loaded: false
        });

        this.forceUpdate(() => {
            this.setState({
                layers: layers,
                selectedItem: null,
                loaded: true
            });
        });
    }


    isLatestLayerFilm() {
        let layers = this.state.layers;
        if (layers && layers.length > 0) {
            return layers[layers.length - 1].type === ItemCategory.Film;
        }
        return false;
    }

    getLayerName(layer, idx) {
        if (idx > 0) {
            switch (layer.type) {
                case ItemCategory.Adhesive:
                    return 'Adhesive'
                case ItemCategory.Film:
                    return 'Film'
                default:
                    return '';
            }
        }
        else {
            return this.isPrelam ? 'Film' : 'Print Web';
        }
    }


    getFilms() {
        if (this.isPrelam && this.filmWidth) {
            let films = this.filmSourceItems.filter(obj => {
                return obj.filmWidth === this.filmWidth
            });
            return films;
        }
        else {
            if (this.state.layers.length == 0) {
                return this.printFilmSourceItems;
            }
            else {
                let printWeb = this.state.layers[0];
                let filmWidth = printWeb.dataObject2.filmWidth;

                let films = this.filmSourceItems.filter(obj => {
                    return obj.filmWidth === filmWidth
                });
                return films;
            }
        }
    }



    render() {
        return (
            <div className='materialEditor'>
                {this.state.loaded &&
                    <div>
                        <div className='table'>
                            <div className='table-header'>
                                <div className='row'>
                                    <div className='col col-2'>Structure</div>
                                    <div className='col col-2'>Type</div>
                                    <div className='col col-2'>Code</div>
                                    <div className='col col-5'>Name</div>
                                    <div className='col col-1'></div>
                                </div>
                            </div>

                            <div className='body'>
                            {this.state.layers.map((layer, idx) => {
                                return (
                                    <div className='row' key={'layer' + idx}>
                                        <div className='col col-2'>{this.getLayerName(layer, idx)}</div>
                                        <div className='col col-2 color-orange'>{layer.dataObject2.typeCode}</div>
                                        <div className='col col-2 color-orange'>{layer.dataObject2.code ? layer.dataObject2.code : 'TBD'}</div>
                                        <div className='col col-5'>{layer.dataObject2.description}</div>
                                        <div className='col col-1'>
                                            <button className='btn btn-secondary-outline btn-sm pull-right' disabled={idx < this.state.layers.length - 1} onClick={() => { this.onDeleteClick(idx) }}><span className='glyphicon glyphicon-trash'></span></button>
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>

                        <div>
                            {!this.isLatestLayerFilm()
                                ? <ComboBoxControl id='filmddl' items={this.getFilms()} alternativeItems={this.allSourceItems} displayPropertyName={'description'} onAddClick={this.onAddFilmClick} valuePropertyName={'id'} onSelected={item => { this.setState({ selectedItem: item }) }} />
                                : <ComboBoxControl id='adhesiveddl' items={this.adhesiveSourceItems} alternativeItems={this.allSourceItems} displayPropertyName={'description'} onAddClick={this.onAddFilmClick} valuePropertyName={'id'} onSelected={item => { this.setState({ selectedItem: item }) }} />
                            }
                        </div>

                    </div>
                }
            </div>
        )
    }
}

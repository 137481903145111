import React, { Component, useEffect, useState } from 'react';
import { ChevronDown, PlayCircle, Search } from 'react-feather';
import { BackSplash } from './Dialogs';



export class Textbox extends Component {
    static displayName = Textbox.name;

    constructor(props) {
        super(props);

        this.state = {            
            value: this.props.value ? this.props.value : '',
            valid: this.isValueValid(this.props.value),
            focused: false
        }

        this.notifyListeners(this.props.value);
        this.onChange = this.onChange.bind(this);

        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onChange(evt) {
        let value = evt.target.value;
        let valid = this.isValueValid(value);

        if (this.props.requireValidInput === true) {
            if (valid || value.length===0) {
                this.setState({
                    value: value,
                    valid: valid
                });
            }
        }
        else {
            this.setState({
                value: value,
                valid: valid
            });
        }

        this.notifyListeners(value);
        evt.preventDefault();
    }

    notifyListeners(value) {
        let result = this.isValueValid(value);
        if (this.props.onChange) {
            this.props.onChange(value, result.valid);
        }
    }

    isValueValid(value) {
        let result = { valid: true, type: ValidationType.Success, id: this.props.id };

        if (this.props.required === true) {
            if (!value || value.length === 0) {
                result = { valid: false, type: ValidationType.Required, id: this.props.id };
            }
        }

        if (this.props.regex) {
            let valid = Regex.Matches(this.props.regex, value);            
            if (!valid) {
                result = { valid: false, type: ValidationType.Invalid, id: this.props.id };
            }
        }       

        if (this.props.onValidate) {
            this.props.onValidate(result, this);
        }

        return result;
    }


    getStyle(focused) {
        let result = 'control textbox';

        if (!focused) {
            let validation = this.isValueValid(this.state.value);
            if (!validation.valid) {
                result += ' error';
            }
            else {
                if (this.state.value) {
                    result += ' valid';
                }
            }
        }

        return result;
    }

    render() {
        return (
            <input id={this.props.id} className={this.getStyle(this.state.focused)} type={this.props.type ? this.props.type : 'text'} autoComplete='off' value={this.state.value} readOnly={this.props.readOnly} onChange={this.onChange} maxLength={parseInt(this.props.maxLength) > 0 ? parseInt(this.props.maxLength) : 255} placeholder={this.props.placeholder ? this.props.placeholder : 'Input text here'} required={this.props.required === true ? true : false} onFocus={() => this.setState({ focused: true })} onBlur={() => this.setState({ focused: false })} />
        );
    }
}




export class Dropdown extends Component {
    static displayName = Dropdown.name;

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : '-1',
            valid: this.isValueValid(this.props.value),
            focused: false
        }

        this.notifyListeners(this.props.value);
        this.onChange = this.onChange.bind(this);
    }

    onChange(evt) {
        let value = evt.target.value;
        let valid = this.isValueValid(value);

        this.setState({
            value: value,
            valid: valid
        });

        this.notifyListeners(value);
        evt.preventDefault();
    }

    notifyListeners(value) {
        let validation = this.isValueValid(value);
        if (this.props.onChange) {
            this.props.onChange(value, validation.valid)
        }
    }

    isValueValid(value) {
        let result = { valid: true, type: ValidationType.Success, id: this.props.id };

        if (this.props.required === true) {
            if (!value || value === "-1") {
                result = { valid: false, type: ValidationType.Required, id: this.props.id };
            }
        }

        if (this.props.onValidate) {
            this.props.onValidate(result, this);
        }

        return result;
    }

    getStyle(focused) {
        let result = 'control dropdown';
        if (!focused) {
            let validation = this.isValueValid(this.state.value);
            if (!validation.valid) {
                result += ' error';
            }
            else {
                if (this.state.value) {
                    result += ' valid';
                }
            }
        }
        return result;
    }

    render() {
        return (
            <select id={this.props.id} className={this.getStyle(this.state.focused)} value={this.state.value} onChange={this.onChange} onFocus={() => this.setState({ focused: true })} onBlur={() => this.setState({ focused: false })}>
                { this.props.children}
            </select>
            )
    }
}


export class CustomDropdown extends Component {
    static displayName = Dropdown.name;

    constructor(props) {
        super(props);

        this.state = {
            selectionVisible: false,
            value: this.props.value ? this.props.value : -1
        }

        this.notifyListeners(this.props.value );

        this.onToggle = this.onToggle.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    getSelectedItem() {
        let result = this.props.children[0];
        this.props.children.forEach(item => {
            if (item.props && item.props.value == this.state.value) {
                result = item;
            }
        });
        return result;
    }


    onToggle() {
        this.setState({ selectionVisible: !this.state.selectionVisible });
    }

    onItemClick(idx, item) {
        if (item.props && item.props.disabled !== true) {
            this.setState({
                value: item.props.value,
                selectionVisible: false
            });

            this.notifyListeners(item.props.value);
        }
    }

    notifyListeners(value) {
        let validation = this.isValueValid(value);
        if (this.props.onChange) {
            this.props.onChange(value, validation.valid);
        }
    }

    isValueValid(value) {
        let result = { valid: true, type: ValidationType.Success, id: this.props.id };

        if (this.props.required === true) {
            if (!value || value == "-1") {
                result = { valid: false, type: ValidationType.Required, id: this.props.id };
            }
        }

        if (this.props.onValidate) {
            this.props.onValidate(result, this);
        }

        return result;
    }

    getStyle() {
        let result = 'control dropdown selector';
        var validation = this.isValueValid(this.state.value);
        if (!validation.valid) {
            result += ' error';
        }
        else {
            if (this.state.value) {
                result += ' valid';
            }
        }
        return result;
    }


    render() {

        return (
            <div className='customDropdown'>
                <div className={!this.state.selectionVisible ? this.getStyle() : 'control dropdown selector focus'} onClick={this.onToggle}>
                    <div className='listItem'>{this.getSelectedItem()}</div>
                    <div className='chevron'><ChevronDown className='icon' /></div>
                </div>    

                {this.state.selectionVisible &&
                    <div>
                        <BackSplash clear={true} onClick={() => { this.setState({ selectionVisible: false }) }} />
                        <div className='optionsContainer'>
                            {this.props.children.map((item, idx) => {
                                return (
                                    <div key={'list_item' + idx} className={item.props && item.props.disabled === true ? 'listItem disabled' : 'listItem'} onClick={() => { this.onItemClick(idx, item)}}>
                                        {item}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }

            </div>
            )
    }

}



export class Checkbox extends Component {
    static displayName = Checkbox.name;

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : false,
            valid: this.isValueValid(this.props.value)

        }

        this.notifyListeners(this.props.value);
        this.onChange = this.onChange.bind(this);
    }

    onChange(evt) {
        let value = evt.target.checked;

        let valid = this.isValueValid(value);

        this.setState({
            value: value,
            valid: valid
        });

        this.notifyListeners(value);
    }

    notifyListeners(value) {
        let validation = this.isValueValid(value);
        if (this.props.onChange) {
            this.props.onChange(value, validation.valid)
        }
    }

    isValueValid(value) {
        let result = { valid: true, type: ValidationType.Success, id: this.props.id };
        if (this.props.required === true) {
            if (!value || value === false) {
                result = { valid: false, type: ValidationType.Required, id: this.props.id };
            }
        }

        if (this.props.onValidate) {
            this.props.onValidate(result, this);
        }

        return result;
    }

    getStyle() {
        let result = 'controlContainer control checkbox';
        let validation = this.isValueValid(this.state.value);

        if (!validation.valid) {
            result += ' error';
        }
        else {
            if (this.state.value) {
                result += ' valid';
            }
        }
        return result;
    }

    render() {
        return (
            <div className={this.getStyle()}>
                <label><input id={this.props.id} type="checkbox" className={'checkbox'} checked={this.state.value} onChange={this.onChange} /><div className='payload'>{this.props.children}</div></label>
            </div>
        )
    }
}


export class RadioButton extends Component {
    static displayName = RadioButton.name;

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : false,
            valid: this.isValueValid(this.props.value)

        }

        this.notifyListeners(this.props.value);
        this.onChange = this.onChange.bind(this);
    }

    onChange(evt) {
        let value = evt.target.checked;
        let valid = this.isValueValid(value);

        this.setState({
            value: value,
            valid: valid
        });

        this.notifyListeners(value);
    }

    notifyListeners(value) {
        let validation = this.isValueValid(value);
        if (this.props.onChange) {
            this.props.onChange(value, validation.valid)
        }
    }

    isValueValid(value) {
        let result = { valid: true, type: ValidationType.Success, id: this.props.id };
        if (this.props.required === true) {
            if (!value || value === "-1") {
                result = { valid: false, type: ValidationType.Required, id: this.props.id };
            }
        }

        if (this.props.onValidate) {
            this.props.onValidate(result, this);
        }

        return result;
    }

    getStyle() {
        let result = 'control radiobutton';
        let validation = this.isValueValid(this.state.value);
        if (!validation.valid) {
            result += ' error';
        }
        else {
            if (this.state.value) {
                result += ' valid';
            }
        }
        return result;
    }

    render() {
        return (
            <label className='controlContainer radiobutton'><input type="radio" className={this.getStyle()} checked={this.state.value} name={this.props.name} onChange={this.onChange} /> <div className='payload'>{this.props.children}</div></label>
        )
    }
}


export const ValidationType = {
    Success: 0,
    Required: 1,
    Invalid: 2
}


export class ValidationOrchestrator {

    constructor(onValidatingFunc) {
        this.inputValidations = [];

        this.invalidField = null;
        this.onValidatingFunc = onValidatingFunc;
        this.hasValidated = false;
    }

    register(inputValidation) {
        this.inputValidations.push(inputValidation);
    }

    validate() {
        let result = true;
        this.inputValidations.reverse().forEach(i => {
            if (!i.validate()) {
                this.invalidField = i;
                result = false;
            }
        });
        this.hasValidated = true;
        return result;
    }

    getInvalidFieldName() {
        return this.invalidField
            ? this.invalidField.getInvalidFieldName()
            : null;
    }

    onValidating() {
        if (this.onValidatingFunc && this.hasValidated) {
            let isValid = true;
            let items = this.inputValidations.reverse();
            items.forEach(i => {
                if (!i.isValid()) {
                    isValid = false;
                }
            });
            
            this.onValidatingFunc(isValid);
        }
    }
}

export class InputValidation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: ValidationType.Success,
            show: false
        };

        this.validationResults = {};
        this.items = [];

        if (this.props.validationOrchestrator) {
            this.props.validationOrchestrator.register(this);
        }

        this.onValidate = this.onValidate.bind(this);

        this.children = React.Children.map(this.props.children,
            child => React.cloneElement(child, {
                onValidate: this.onValidate
            })
        );

    }

    getInvalidFieldName() {
        var item = this.getFirstInvalidField();
        return item ? item.id : null;
    }

    isValid() {
        var item = this.getFirstInvalidField();
        return item == null;
    }

    validate() {        
        this.setState({
            show: true
        });
        return this.isValid();
    }

    onValidate(result, ctrl) {

        this.validationResults[ctrl.props.id] = result;
        let item = this.getFirstInvalidField();
        let validationType = item ? item.type : ValidationType.Success;

        this.setState({
            type: validationType
        });

        if (this.props.validationOrchestrator) {
            this.props.validationOrchestrator.onValidating();
        }

    }

    convertToArray() {
        var result = [];
        for (var key of Object.keys(this.validationResults)) {
            result.push(this.validationResults[key]);
        }
        return result.reverse();
    }

    getFirstInvalidField() {
        var result = null;
        var items = this.convertToArray(this.validationResults);

        if (items && items.length > 0) {
            items.forEach(i => {
                if (i.type !== ValidationType.Success && result == null) {
                    result = i;
                }
            });
        }
        return result;
    }

    getErrorMessage(show) {
        if (show) {
            switch (this.state.type) {
                case 1:
                    return (<span className='error-message'>{this.props.requiredText}</span>)
                    break;
                case 2:
                    return (<span className='error-message'>{this.props.invalidText}</span>)
                    break;
            }
        }
        return null;
    }

    render() {
        return (
            <div className='input-validation'>
                { this.children}
                { this.getErrorMessage(this.state.show || !this.props.validationOrchestrator)}
            </div>
        );
    }
};








export class Regex {

    static Matches(exp, value) {
        var result = true;
        var regex = exp ? exp : null;
        if (regex) {
            var r = new RegExp(regex, 'g');
            var match = r.exec(value);            
            if (match == null || match[0] !== value) {
                result = false;
            }
        }
        return result;
    }
}



export const Searchbox = ({ id, value, className, maxLength, delay, placeholder, onSubmit }) => {

    const [val, setVal] = React.useState(value ? value : '');
    const [timeoutRef, setTimeoutRef] = React.useState(null);
    const timeout = null;


    const submit = () => {
        if (onSubmit) {
            onSubmit(val);
        }
    }

    const onKeyUp = evt => {
        clearTimeout(timeoutRef);
        if (evt.keyCode === 13) {
            submit();
        }
        else {            
            var timeout = setTimeout(() => {
                submit();
            }, delay ? parseInt(delay) : 1000);
            setTimeoutRef(timeout);
        }
    }

    const onChange = evt => {
        setVal(evt.target.value);
    }

    return (
        <div className={`control search ${className ? className : ''}`}>
            <input id={id} type='text' className={'control textbox'} value={val} placeholder={placeholder ? placeholder : 'Search'} maxLength={maxLength} onKeyUp={onKeyUp} onChange={onChange} />
            <Search className='icon' onClick={() => { submit() }} />
        </div>
    )

}
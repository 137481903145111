import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';

var LifeCycleState = {
    Undefined: 0,
    Loading: 1,
    Completed: 2,
    Valid: 3,
    Invalid: 4
}

export class DistinctTextboxDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);

        this.settings = {
            loader: false,
            mode: "cors",
            cache: 'default',
            credentials: "include",
            redirect: "follow",
            referrer: "no-referrer",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        };

        this.dataAccess = new ApiDataAccess('/data', this.settings);
        this.timeout = null;
        this.metaData = null;
        this.currentValue = null;        
        this.isValidPhrase = true;
        this.state = {
            value: '',
            maxlength: 0,
            lifeCycleState: LifeCycleState.Invalid,            
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var validationData = metaData.validationData;
            self.setState({
                maxlength: validationData && validationData.maximumLength ? validationData.maximumLength : 0
            });
            resolve(self);
        });

    }

    setValue(value) {
        var newValue = value ? value : null;
        this.setState({
            value: newValue
        });

        this.currentValue = newValue;
        this.dataManager.reportChange(newValue, this);
    }

    getValue() {
        return this.state.value;
    }

    isInputValid() {
        var self = this;
        var result = true;
        var value = this.currentValue;
        var validationData = this.metaData ? this.metaData.validationData : null;        
        if (validationData) {

            if (validationData.required === true) {               
                if (!value || value == null || value.length == 0) {
                    result = false;
                }
            }

            if (result == true) {
                result = this.matchesRegex(value);
            }
        }
        
        this.setState({
            lifeCycleState: result == true && self.isValidPhrase==true ? LifeCycleState.Valid : LifeCycleState.Invalid
        });

        if (result == true) {
            result = self.isValidPhrase == true;
        }

        return result;
    }

    onChange(event) {
        var self = this;
        var value = event.target.value;
        var previousValue = this.state.value;
        var match = this.matchesRegex(value);
        var newValue = match ? value : previousValue;

        self.setValue(newValue);
        self.dataManager.reportChange(newValue, this);

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }

        if (self.timeout) {
            clearTimeout(self.timeout);
            self.timeout = null;
        }

        self.timeout = setTimeout(() => {
            self.setState({
                lifeCycleState: LifeCycleState.Loading
            });

            var request = {
                'text': newValue
            };

            self.dataAccess.post(`/lookup/${self.props.lookupDataSource}`, request)
                .then(data => {
                    self.isValidPhrase = !data || data.length == 0;
                    self.setState({
                        lifeCycleState: self.isValidPhrase ? LifeCycleState.Invalid : LifeCycleState.Valid
                    });
                    self.setValue(newValue);
                    self.dataManager.reportChange(newValue, self);
                })
                .catch(error => {
                    self.isValidPhrase = false;
                    self.setState({
                        lifeCycleState: LifeCycleState.Invalid
                    });
                    self.setValue(newValue);
                    self.dataManager.reportChange(newValue, self);
                })
        }, 500);         
    }

    matchesRegex(value) {
        var validationData = this.metaData ? this.metaData.validationData : null;
        if (validationData && validationData.regex) {
            var r = new RegExp(validationData.regex);
            var match = r.exec(value);
            if (match == null || match[0] != value) {
                return false;
            }
        }
        return true;
    }

    render() {        
        return (
            <div className='input-control'>
                <div className="input-group">

                    {/* 
                    <div className='input-group-prepend'>
                        {(this.state.lifeCycleState == LifeCycleState.Undefined || this.state.lifeCycleState == LifeCycleState.Loading) &&
                            <span className={'input-group-text background-green'}><img src='/Images/ajax-loader.gif' /></span>
                        }

                        {this.state.lifeCycleState == LifeCycleState.Valid &&
                            <span className={'input-group-text glyphicon glyphicon-ok background-green'}></span>
                        }

                        {this.state.lifeCycleState == LifeCycleState.Invalid &&
                            <span className={'input-group-text glyphicon glyphicon-remove background-red'}></span>
                        }
                    </div>
                    */}

                    <input id={this.props.propertyName} className={this.state.lifeCycleState == LifeCycleState.Valid ? 'control textbox valid' : 'control textbox error'} value={this.state.value} onChange={this.onChange} maxLength={this.state.maxlength} disabled={this.props.disabled == true}></input>
                </div>

                {this.state.lifeCycleState == LifeCycleState.Invalid && this.state.value && this.state.value.length > 0 &&
                    <p className='error-message'>{this.props.error ? this.props.error : 'Your input is invalid since it exists already!'}</p>
                }                
            </div>
        );
    }
}

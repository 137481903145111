import React from 'react';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { LookupDataSourceDataAccess } from '../../../infrastructure/DataSourceDataAccess';
import { DataManager } from '../../../dataControls/DataManager';
import { GenericListControl } from '../../../controls/SimpleListControl';
import { ImageLabel } from '../../../core/ImageTitle';
import { LabelDataControl } from '../../../dataControls/LabelDataControl';
import { RelationshipItemsDisplayDataControl } from '../../../dataControls/RelationshipItemsDisplayDataControl';
import { StructureSpec } from './StructureSpec';
import { ProductGroupsByStructureSpecListControl, ProductsByStructureSpecListControl } from './ProductGroupsByStructureSpecListControl';
import { StructureSpecRulesListComponent } from './StructureSpecRulesListComponent';
import { Card, Row, Column } from '../../../components/Containers';
import { Alert } from '../../../components/Components';
import { PublishableNameDisplay } from './ProductGroupsByStructureSpecListControl';
import { DateTime } from '../../../core/DateDisplay';
import { CertificateList } from './CertificateList';

export class StructureSpecViewComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataId) {
            throw new Error("Data Id Property is required");
        }

        this.productGroupList = null;
        this.productList = null;
        this.structureSpec = new StructureSpec(this.props.dataId);

        this.state = {
            isValid: false,
            jobTemplate: null,
            structureSpec: null,
            replacementSpec: null,
            isPublished: false
        };

        this.dataAccess = new ApiDataAccess('/data');
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);

        this.dataManager = new DataManager('StructureSpecs', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        this.loadContext();
    }

    loadContext() {
        var self = this;
        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(data => {
                            //2) bind Controls            
                            if (!data.isValid) {
                                window.Router.setRoute('/structurespecs/' + this.props.dataId + '/edit');
                            }
                            else {
                                self.setState({
                                    structureSpec: data,
                                    jobTemplate: data.navJobTemplate,
                                    isPublished: data.isPublished
                                });

                                if (data.discontinueSpecReplacementId > 0) {
                                    self.loadStructureSpecAsync(data.discontinueSpecReplacementId)
                                        .then(s => {
                                            self.setState({
                                                replacementSpec: s
                                            });
                                        });
                                }


                                dataManager.bindControls();
                            }
                        })
                        .catch(() => {
                            window.Router.setRoute('/structurespecs/' + encodeURIComponent(this.props.dataId) + '/edit');
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                    self.forceUpdate();
                }

                //3) onControlBound --> Set Value
            });
    }

    loadStructureSpecAsync(id) {
        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('/data/StructureSpecs/');
            dataAccess.get(
                `/${id}`,
                data => { resolve(data) },
                error => { reject(error) }
            );
        });
    }


    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onPublishClick() {
        this.publish(!this.state.isPublished);
    }

    publish(value) {
        var self = this;
        var isPublished = !value;
        if (isPublished) {
            this.structureSpec.publish(false)
                .then(data => {
                    self.loadContext();
                    self.productGroupList.refresh();
                    self.productList.refresh();
                    window.Alerts.showSuccess('Structure Spec ' + data.name + ' has been unpublished');
                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                });
        }
        else {
            this.structureSpec.publish(true)
                .then(data => {
                    self.loadContext();
                    self.productGroupList.refresh();
                    self.productList.refresh();
                    window.Alerts.showSuccess('Structure Spec ' + data.name + ' has been published');
                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                });
        }
    }

    onDeleteClick() {
        var self = this;
        window.Confirmation.show('Delete', 'Structure Specs should only be deleted when they will not be used anymore. Please unpublish the structure spec instead if a material is temporarily unavailable. Deleting a structure spec is not reversable. Are you sure to delete this structure spec?',
            function () {
                self.structureSpec.delete()
                    .then(data => {
                        window.Alerts.showInfo('The Structure Spec has been deleted');
                        window.Router.setRoute('/structurespecs');
                    })
                    .catch(ex => {
                        window.Alerts.showError('An error occurred while deleting the Structure Spec');
                    })
            }
        );
    }

    onControlUnbound(control, propertyName) {
    }

    getFormattedDate(dateStr) {
        if (dateStr) {
            var date = new Date(dateStr);
            return date.toLocaleDateString();
        }
        else {
            return null;
        }
    }

    render() {
        var self = this;
        return (
            <div className='product product-view-component'>

                <h2>{this.props.dataId ? 'SPEC-' + this.props.dataId : ''}</h2>

                {this.state.structureSpec && this.state.structureSpec.discontinueSpecReplacementId &&
                    <Alert type="success" className="clickable" onClick={() => { window.Router.setRoute(`/structurespecs/${this.state.structureSpec.discontinueSpecReplacementId}`) }}>This Spec will be discontinued on <DateTime showTimeZone={false} dateTime={this.state.structureSpec.discontinueDate} /> and replaced by SPEC-{this.state.structureSpec.discontinueSpecReplacementId}</Alert>
                }

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/structurespecs')} />
                            {window.CONFIG.JOB_TEMPLATES_DISABLED
                                ? <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/structurespecs/new')} />
                                : <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/structurespecs/import')} />
                            }
                            <ToolbarButton icon='glyphicon-pencil' text='Edit' onClick={() => window.Router.setRoute('/structurespecs/' + encodeURIComponent(this.props.dataId) + '/edit')} />
                            <ToolbarButton icon='glyphicon-book' text='Spec' onClick={() => document.location = '/data/structurespecs/' + encodeURIComponent(this.props.dataId) + '/pdf?attachment=1'} />
                            <ToolbarButton icon={this.state.isPublished ? 'glyphicon-stop' : 'glyphicon-play'} text={this.state.isPublished ? 'unpublish' : 'publish'} onClick={this.onPublishClick} />
                            <ToolbarButton icon='glyphicon-trash' className='btn-danger' text='Delete' onClick={this.onDeleteClick} />
                        </div>
                    )
                }} />

                <Card title={this.state.jobTemplate ? this.state.jobTemplate.name : ''} subtitle='General information for this structure spec:'>
                    {this.state.replacementSpec && this.state.structureSpec &&
                        <Alert type='info' onClick={() => { window.Router.setRoute(`/structurespecs/${this.state.replacementSpec.id}`) }}>This Structure Spec has been scheduled for discontinuation on <b>{this.getFormattedDate(this.state.structureSpec.discontinueDate)}</b> and will be replaced by <b>{this.state.replacementSpec.name} (SPEC-{this.state.replacementSpec.id})</b>.</Alert>
                    }

                    <Row>
                        <Column>
                            <label htmlFor='description'>Secondary Name</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='secondaryName' onRender={data => <span>{data ? data : 'No Secondary Name set yet.'}</span>} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='description'>Description</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='description' onRender={data => <span>{data ? data : 'No Description set yet.'}</span>} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='description'>Default Area Price</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='defaultAreaPrice' onRender={data => <span>{data ? data : 'No Price retreived yet.'}</span>} />
                        </Column>
                    </Row>
                </Card>


                {this.state.structureSpec &&
                    <Card title='Films and Structure' subtitle='The following films are used to manufacture this structure:'>
                        <StructureSpecSheetComponent id='structure' structureSpecId={this.props.dataId} structureSpec={this.state.structureSpec} films={this.state.structureSpec ? this.state.structureSpec.defaultFilmNames : null} />
                    </Card>
                }
                {this.state.structureSpec &&
                    <Card title='Supply Chain Info' subtitle='The Supply Chain Team provided the following information:'>
                        <StructureSpecItemCommentViewComponent id='comments' structureSpecId={this.props.dataId} structureSpec={this.state.structureSpec} films={this.state.structureSpec ? this.state.structureSpec.defaultFilmNames : null} />
                    </Card>
                }


                <Card title='Features' subtitle='The structure provide the following features:'>
                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='features' display={['name']} noItemsText='No Features defined yet.' />
                </Card>


                <Card title='Properties' subtitle='The structure has the following physical poperties:'>
                    <StructureSpecPropertiesViewComponent id='properties' structureSpecId={this.props.dataId} />
                </Card>


                <Card title='Appearance & Sustainability' subtitle='The following describes the look and feel of this structure:'>

                    <Row>
                        <Column>
                            <ImageLabel text='Finish Type' for='finishTypeId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='finishType' onRender={data => <span>{data.name}</span>} />
                        </Column>

                        <Column>
                            <ImageLabel text='Transparency' for='transparencyId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='transparency' onRender={data => <span>{data.name}</span>} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Sustainability' for='sustainabilityId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='sustainability' onRender={data => <span>{data.name}</span>} />
                        </Column>

                        <Column>
                            <ImageLabel text='Film Width' for='filmWidthId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='filmWidth' onRender={data => <span>{data.formattedValue}</span>} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Barrier Type' for='barrierTypeId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='barrierType' onRender={data => <span>{data.name}</span>} />
                        </Column>

                        <Column />

                    </Row>

                </Card>


                <Card title='Recommended Color Specs' subtitle='The following color specs are recommended for this structure:'>
                    <Row>
                        <Column>
                            <ImageLabel text='Default' for='colorSpecId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='colorSpec' onRender={data => <span>{data.name}</span>} />
                        </Column>


                        <Column>
                            <ImageLabel text='Using extended Gamut Scheme' for='colorSpecGamutId' />
                            <LabelDataControl dataManager={this.dataManager} propertyName='colorSpecGamut' onRender={data => <span>{data.name}</span>} />
                        </Column>
                    </Row>
                </Card>

                <Card title='Manufacturing and Application' subtitle='The following restrictions apply for manufacturing:'>
                    <Row>
                        <Column>
                            <ImageLabel text='Supported Converting Methods' for='convertingMethods' />
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='convertingMethods' display={['name']} />
                        </Column>

                        <Column>
                            <ImageLabel text='Supported Seal Types' for='sealTypes' />
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='sealTypes' display={['name']} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Supported Fill Types' for='fillTypes' />
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='fillTypes' display={['name']} />
                        </Column>

                        <Column>
                            <ImageLabel text='Supported Seal Wrap Types' for='sealWrapTypes' />
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='sealWrapTypes' display={['name']} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Applications' for='applications' />
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='applications' display={['name']} />
                        </Column>

                        <Column>
                            <ImageLabel text='Manufacturing Options' for='manufacturingOptions' />
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='manufacturingOptions' display={['name']} />
                        </Column>
                    </Row>
                </Card>

                <Card title='Limitations' subtitle='The following weight and size restrictions are applied:'>
                    <Row>
                        <Column>
                            <ImageLabel text='Max. Pouch Fill Weight' for='fillWeightMaxValue' />
                            {this.state.structureSpec && this.state.structureSpec.fillWeightMaxValue &&
                                <div className='flex'>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='fillWeightMaxValue' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='fillWeightUomId' />
                                </div>
                            }
                            {(!this.state.structureSpec || !this.state.structureSpec.fillWeightMaxValue) &&
                                <div className='flex'>
                                    <div className='label-control'>N/A</div>
                                </div>
                            }
                        </Column>

                        <Column>
                            <ImageLabel text='Max. Pouch Dimensions' for='fillWeightMaxValue' />
                            {this.state.structureSpec && this.state.structureSpec.pouchAreaMaxValue &&
                                <div className='flex'>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='pouchAreaMaxValue' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='pouchAreaUomId' />
                                </div>
                            }
                            {(!this.state.structureSpec || !this.state.structureSpec.pouchAreaMaxValue) &&
                                <div className='flex'>
                                    <div className='label-control'>N/A</div>
                                </div>
                            }

                        </Column>
                    </Row>
                </Card>


                <Card title='Certifications' subtitle='The structure is certified for the following products:'>
                    <CertificateList structureSpecId={this.props.dataId} />
                </Card>
                


                <Card title='Sales Channels' subtitle='The structure is available for the following sales channels'>
                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='salesChannels' display={['name']} />
                </Card>


                <Card title='Packaging Groups' subtitle='The spec is associated with the following packaging groups:'>
                    <ProductGroupsByStructureSpecListControl structureSpecId={this.props.dataId} onInit={cntrl => self.productGroupList = cntrl} />
                </Card>

                <DiscontinueReferencesControl structureSpecId={this.props.dataId} />


                <Card title='Feature Restrictions' subtitle='The following feature restrictions potentially apply to this structure:'>
                    <StructureSpecRulesListComponent structureSpecId={this.props.dataId} />
                </Card>


                <Card title='Sample Poducts' subtitle='The following products could be packaged based on the packaging group assignments of this structure:'>
                    <ProductsByStructureSpecListControl structureSpecId={this.props.dataId} onInit={cntrl => self.productList = cntrl} />
                </Card>

            </div>
        );
    }
}

export class DiscontinueReferencesControl extends React.Component {

    constructor(props) {
        super(props);
        var self = this;

        this.state = {
            items: []
        };

        this.dataAccess = new ApiDataAccess('/data/StructureSpecs');
        this.dataAccess.get(`/replacement/${this.props.structureSpecId}/references`)
            .then(items => {
                self.setState({
                    items: items
                });
            });
    }

    render() {
        return (
            <div className='discontinue-References-control'>

                {this.state.items && this.state.items.length > 0 &&
                    <Card title='Replacement for Discontinued Structure Specs' subtitle='This material is used as replacement for the following structures:'>
                        <div className='table'>
                            <div className='body'>
                                {this.state.items.map(function (item, idx) {
                                    return (
                                        <div key={`row_${idx}`} className="row clickable" onClick={() => { window.Router.setRoute(`/structurespecs/${item.id}`) }}>
                                            <PublishableNameDisplay item={item} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
}



class StructureSpecSheetComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.structureSpecId) {
            throw new Error("StructureSpecId Property is required");
        }

        this.films = [];

        try {
            this.films = [];
            var films = this.props.structureSpec.items.filter(e => e.dataObject2.typeCode !== "ADHESIVE");
            films.forEach(item => {
                var film = {
                    name: item.dataObject2.name,
                    type: item.dataObject2.typeCode
                };
                this.films.push(film);
            });
        }
        catch
        {
            this.films = [];
            this.props.films.forEach(name => {
                var item = this.props.structureSpec.items.find(e => e.dataObject2.name == name);
                var type = item && item.dataObject2 ? item.dataObject2.typeCode : 'N/A';
                var film = {
                    name: name,
                    type: type
                };
                this.films.push(film);
            });
        }


        this.state = {
            prelam: null,
            alternativeFilms: null
        }

        this.getAlternativesAsync()
            .then(data => {
                this.setState({
                    prelam: data.prelamOption,
                    alternativeFilms: data.alternativeMaterial
                })
            })
    }


    getAlternativesAsync() {
        return new Promise((resolve, reject) => {
            let dataAccess = new ApiDataAccess('/data/structurespecs');
            dataAccess.get(`/${this.props.structureSpecId}/alternative`)
                .then(data => { resolve(data); })
                .catch(ex => { reject(ex); });
        })
    }



    render() {
        return (
            <div className='structure-display'>
                {this.props.films && this.props.films.length > 0 &&
                    <Row>
                        <Column>
                            <div className='filtered-product-list'>
                                {this.films.map(function (item, id) {
                                    return (
                                        <div key={`fpl_${id}`} className="d-flex flex-row bd-highlight mb-3 border-bottom">
                                            <div className="p-2 flex-grow-1 bd-highlight">
                                                <div className='content'><span className='bold color-red'>{'(' + String.fromCharCode(65 + id) + ') '}</span> <span>{item.name}</span> <span className='bold color-red'>({item.type})</span> </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Column>

                        <Column>
                            <img src={'/data/structurespecs/' + this.props.structureSpecId + '/image?' + Date.now()} />
                        </Column>
                    </Row>
                }
                {(!this.props.films || this.props.films.length == 0) &&
                    <div className='row'>
                        <div className='attribute col-sm-12 col-lg-12'>
                            <p>No films have been assigned.</p>
                        </div>
                    </div>
                }

                {this.state.prelam &&
                    <Alert type='info'>A Prelam Option for this material exists: <b>{this.state.prelam.name}</b></Alert>
                }

                {this.state.alternativeFilms && this.state.alternativeFilms.length > 0 &&
                    <Alert type='info'>This structure can also be manufactured in the following Width: <b>{this.state.alternativeFilms[0].filmWidth}</b></Alert>
                }

            </div>
        );
    }
}


export class StructureSpecPropertiesViewComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.structureSpecId) {
            throw new Error("StructureSpecId Property is required");
        }

        this.dataSource = new LookupDataSourceDataAccess('propertiesByStructureSpec');

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        var self = this;
        var request = { number: this.props.structureSpecId };
        this.dataSource.getDataSource(request)
            .then(dataSource => {
                var items = dataSource.getItems();
                self.setState({
                    items: items
                });
            })
            .catch(error => {
                window.Alerts.showError('Could not retrieve Structure Spec Properties')
            })
    }

    render() {
        return (
            <div>
                {this.state.items && this.state.items.length > 0 &&
                    <GenericListControl items={this.state.items}
                        onRenderTitle={
                            () => {
                                return (
                                    <div className='row'>
                                        <div className='col col-4'>Name</div>
                                        <div className='col col-4'>Typical Value</div>
                                        <div className='col col-4'>Test Method</div>
                                    </div>
                                )
                            }
                        }
                        onRender={
                            (item, idx) => {
                                return (
                                    <div className='row'>
                                        <div className='col col-4'>{item.name}</div>
                                        <div className='col col-4'>{item.formattedValue}</div>
                                        <div className='col col-4'>{item.testMethod}</div>
                                    </div>
                                )
                            }
                        } />
                }
                {(!this.state.items || this.state.items.length == 0) &&
                    <div>
                        <Alert type='info'>No Physical Properties have been defined yet</Alert>
                    </div>
                }
            </div>
        );
    }
}



export class StructureSpecItemCommentViewComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.structureSpecId) {
            throw new Error("StructureSpecId Property is required");
        }

        this.dataSource = new LookupDataSourceDataAccess('propertiesByStructureSpec');

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        var self = this;
        self.getItemCommentsAsync().then(result => {
            self.setState({
                items: result
            })
        });
    }


    getItemCommentsAsync = async () => {
        if (this.props.structureSpec.items.length > 0) {
            var results = await this.fetchCommentData(this.props.structureSpec.items);
            var filtered = results.filter(function (itemComment) {
                return itemComment != null;
            });
            return filtered;
        }
    }


    fetchCommentData = async (data) => {
        const promises = data.map(async (spec) => {
            var item = spec.dataObject2;
            let dataAccess = new ApiDataAccess('/data/items');
            var promise = await new Promise((resolve, reject) => {
                dataAccess.get(`/${item.id}/comment`)
                    .then(data => {
                        if (data.comment) {
                            data.filmName = item.name;
                        }
                        if (data.expirationDate) {
                            data.expirationDate = new Date(data.expirationDate).toLocaleDateString();
                        }
                        if (!data.comment) {
                            data = null;
                        }
                        resolve(data);
                    })
                    .catch(ex => { reject(ex) });
            });
            return promise;
        });
        const results = await Promise.all(promises);
        return results;
    };


    render() {
        return (
            <div>
                {this.state.items && this.state.items.length > 0 &&
                    <GenericListControl items={this.state.items}
                        onRenderTitle={
                            () => {
                                return (
                                    <div className='row'>
                                        <div className='col col-4'>Material</div>
                                        <div className='col col-6'>Comment</div>
                                        <div className='col col-2'>Expiration Date</div>
                                    </div>
                                )
                            }
                        }
                        onRender={
                            (item, idx) => {
                                return (
                                    <div className='row'>
                                        <div className='col col-4'>{item?.filmName}</div>
                                        <div className='col col-6'>{item?.comment}</div>
                                        <div className='col col-2'>{item?.expirationDate}</div>
                                    </div>
                                )
                            }
                        } />
                }
                {(!this.state.items || this.state.items.length == 0) &&
                    <div>
                        <Alert type='info'>No Supply Chain Comments</Alert>
                    </div>
                }
            </div>
        );
    }
}
import React from 'react';
import { TextboxControl } from '../../../controls/TextboxControl';
import { DataSourceDropDownControl } from '../../../controls/DataSourceDropDownControl';
import { DataSourceChecklistControl } from '../../../controls/DataSourceChecklistControl';
import { RuleTriggerDefinitionSelector } from './RuleDefinitionSelector';
import { InputControlType } from './Rule';
import { Spacer } from '../../../components/Containers';
import { Logger } from "../../../infrastructure/Logger";

export class RuleTriggerPopupContent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.modal) {
            throw new Error("Modal Property is required");
        }

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }

        this.ruleDataManager = this.props.ruleDataManager;
        this.control = null;
        this.definition = null;
        this.values = this.props.values ? this.props.values : [];
        this.state = {
            definition: this.definition,
            values: this.values,
            items: []
        }

        this.onDefinitionSelected = this.onDefinitionSelected.bind(this);
        this.onTupleChange = this.onTupleChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onCollectionChange = this.onCollectionChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
    }

    onTestClick() {
        alert('weird');
    }

    reset() {
        this.values = this.props.values ? this.props.values : [];
        this.setState({
            values: this.values,
            items: []
        });
    }

    onDefinitionSelected(value, definition) {
        this.reset();
        this.definition = definition;
        this.setState({
            definition: this.definition,
        });

        if (this.control && this.definition) {
            this.control.setDataSource(this.definition.dataSourceName);
        }
    }

    onTupleChange(definition, tuple, physicalProperty) {        
        if (tuple) {
            var item = {
                from: tuple.item1,
                to: tuple.item2,
                name: definition.name,
                uom: physicalProperty.uom
            };            
            this.pushItem(0, item);
        }
        else {
            this.setState({
                values: []
            });
        }
    }

    onItemChange(value, item) {
        this.pushItem(value, item);
    }

    pushItem(value, item) {
        var values = [];
        values.push(value);

        var items = [];
        items.push(item);

        this.setState({
            values: values,
            items: items
        });
    }


    onCollectionChange(values, items) {
        this.setState({
            values: values,
            items: items
        });
    }

    getControl() {
        if (this.definition) {
            switch (this.definition.inputControlType) {
                case InputControlType.FreeTextItem:
                    return (<p>Free Text is not supported!</p>);
                case InputControlType.FreeTextTuple:
                    return (<RuleTriggerPhysicalPropertyControl ruleDataManager={this.ruleDataManager} definition={this.definition} onChange={this.onTupleChange} />);                    
                case InputControlType.SingleChoice:
                    return (<DataSourceDropDownControl onInit={ctrl => this.control = ctrl} dataSourceName={this.definition.dataSourceName} onChange={this.onItemChange} />);                    
                case InputControlType.MultipleChoice:
                    return (<DataSourceChecklistControl onInit={ctrl => this.control = ctrl} dataSourceName={this.definition.dataSourceName} onChange={this.onCollectionChange} />);                    
                default:
                    throw new Error(`Input Control Type ${this.definition.inputControlType} is no supported`);
            }
        }
    }

    onAddClick() {
        this.props.modal.close();
        if (this.props.onAddClick) {

            var trigger = {
                definition: this.definition,                
                items: this.state.items
            };

            this.props.onAddClick(trigger);
        }
    }

    isValid() {
        return this.state.values && this.state.values.length > 0;
    }

    render() {
        var self = this;
        return (
            <div className='ruleTriggerPopupContent rulePopup'>
                <RuleTriggerDefinitionSelector ruleDataManager={this.ruleDataManager} onChange={this.onDefinitionSelected} />

                <div className='inner-content'>
                    {self.getControl()}
                </div>

                <Spacer />

                <div className='modal-footer'>
                    <button className='btn btn-lg btn-primary' disabled={!this.isValid()} onClick={this.onAddClick}>Add</button>
                    <button className='btn btn-lg btn-secondary pull-right' onClick={this.props.modal.close}>Cancel</button>
                </div>
            </div>
        );
    }
}

class RuleTriggerPhysicalPropertyControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }

        if (!this.props.definition) {
            throw new Error("Definition Property is required");
        }

        this.ruleDataManager = this.props.ruleDataManager;
        this.definition = this.props.definition;

        this.fromTextbox = null;
        this.toTextbox = null;
        this.dataSource = null;

        this.state = {
            physicalProperty: null
        };

        this.from = null;
        this.to = null;

        this.onFromChange = this.onFromChange.bind(this);
        this.onToChange = this.onToChange.bind(this);
    }

    onFromChange(txt) {
        this.from = parseFloat(txt);
        if (this.props.onChange) {
            var tuple = {
                item1: this.from,
                item2: this.to
            };
            if (this.isValid()) {
                this.props.onChange(this.definition, tuple, this.state.physicalProperty);
            } else {
                this.props.onChange(this.definition, null, this.state.physicalProperty);
            }
        }        
    }

    onToChange(txt) {
        this.to = parseFloat(txt);
        if (this.props.onChange) {
            var tuple = {
                item1: this.from,
                item2: this.to
            };

            if (this.isValid()) {
                this.props.onChange(this.definition, tuple, this.state.physicalProperty);
            } else {
                this.props.onChange(this.definition, null, this.state.physicalProperty);
            }
        }        
    }

    isValid() {
        return !isNaN(this.from) && !isNaN(this.to) && parseFloat(this.to) > parseFloat(this.from);        
    }

    componentDidMount() {
        var self = this;
        this.ruleDataManager.getPhysicalPropertiesDataSource()
            .then(dataSource => {
                self.dataSource = dataSource;
                self.setState({
                    physicalProperty: dataSource.getItem(self.definition.dataSourceItemId)
                });
            })
            .catch(ex => {
                Logger.writeError('Could not retrieve physical properties', ex);
            });
    }

    getRegex() {        
        var integerLength = this.state.physicalProperty.integerLength;
        var fragmentLength = this.state.physicalProperty.fragmentLength;
        return '[0-9]{0,' + integerLength + '}(\\.[0-9]{0,' + fragmentLength + '})?';                
    }


    render() {
        var self = this;
        return (
            <div>
                {this.state.physicalProperty &&
                    <div className='view'>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6'>
                                <label htmlFor='txtFrom'>From: ({this.state.physicalProperty.uom})</label>
                                <TextboxControl id='txtFrom' required={true} regex={self.getRegex()} onChange={this.onFromChange} onInit={ctrl => self.fromTextbox = ctrl} />
                            </div>

                            <div className='col-sm-12 col-lg-6'>
                                <label htmlFor='txtTo'>To: ({this.state.physicalProperty.uom})</label>
                                <TextboxControl id='txtTo' required={true} regex={self.getRegex()} onChange={this.onToChange} onInit={ctrl => self.toTextbox = ctrl} />
                            </div>
                        </div>
                    </div>
                    }
            </div>
        );
    }
}
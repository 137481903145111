import React from 'react';
import { GenericDataAccess } from '../dataControls/GenericDataAccess';
import { HelpControl } from './HelpControl';

export class SingleChoiceControl extends React.Component {
    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;
        this.dataAccess = new GenericDataAccess();
        this.validation = this.props.validation;

        this.datasource = this.props.datasource;
        this.value = this.getDefaultValue();

        this.state = {
            items: [],
            value: this.value
        }

        this.onChange = this.onChange.bind(this);
    }

    getDefaultValue() {
        var result = -1;
        if (this.props.value || this.props.value == 0) {            
            result = parseInt(this.props.value);
        } else {
            if (this.datasource && this.datasource.defaultSelectedId >= 0) {                
                result = this.datasource.defaultSelectedId
            }
        }
        return result;
    }

    setValue(value) {
        this.setState({
            value: value
        });
    }

    getItem(id) {
        var result = null
        this.state.items.map((item) => {
            if (item && item.id == id) {
                result = item;
            }
        });
        return result;
    }

    componentDidMount() {       
        //this.value = -1;
        this.changeDatasource(this.datasource);
    }

    changeDatasource(datasource) {        
        this.datasource = datasource;        
        this.getData(this.datasource)
    }

    getData(datasource) {
        var self = this;
        this.dataAccess.getDataSourceData(datasource.name, function (result) {            
            if (result && result && result.length == 1) {
                self.value = result[0].id;
            }

            self.setState({
                items: result,
                value: self.value
            });

            if (self.props.onInit) {
                self.props.onInit(self);
            }

            self.raiseChangeNotification();
        });
    }

    isInputValid() {
        var isValid = true;
        if (this.props.validation && this.props.validation.required == true) {
            if (this.value && (this.value == null || this.value == -1)) {
                isValid = false;
            }
        }
        return isValid;
    }


    onChange(event) {
        this.value = parseInt(event.target.value);
        this.setState({
            value: this.value
        });

        this.raiseChangeNotification();
    }

    raiseChangeNotification() {
        if (this.props.onChange) {
            if (this.value !== -1) {
                this.props.onChange(this.value, this.props.id)
            } else {
                this.props.onChange(null, this.props.id)
            }
        }
    }

    render() {
        var self = this;
        return (
            <div className='input-control single-choice-control'>
                <label htmlFor={this.props.id}>{this.props.display.caption}</label>
                <HelpControl help={this.props.help} />
                {this.state.items && this.state.items.length > 1 &&
                    <select id={this.props.id} className={self.isInputValid() ? 'control dropdown valid' : 'control dropdown error'} value={this.state.value} onChange={this.onChange} disabled={!this.state.items || this.state.items.length === 0 || this.props.disabled === true}>

                        {this.datasource.defaultSelectedId < 1 &&
                            <option value={-1}>Please select...</option>
                        }

                        {this.state.items.map(function (item, index) {
                            return (<option value={item[self.datasource.valueMemberName]} key={self.props.id + '_' + index + '_option'}>{item[self.datasource.displayMemberName]}</option>);
                        })}
                    </select>
                }

                {this.state.items && this.state.items.length == 1 &&
                    <input className='control textbox' value={self.state.items[0][self.datasource.displayMemberName]} disabled={true}></input>
                }


            </div>
        );
    }
}

import React from "react";

window.Confirmation = (function (conf) {
    conf.control = {};

    conf.register = function (cntrl) {
        conf.control = cntrl;
    }

    conf.show = function (title, content, onYesClick, onNoClick) {
        conf.control.show(title, content, onYesClick, onNoClick);
    }

    return conf;
}(window.Confirmation = window.Confirmation || {}));



export class LegacyConfirmationComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onYesClick = this.onYesClick.bind(this);
        this.onNoClick = this.onNoClick.bind(this);
    }

    componentDidMount() {
        window.Confirmation.register(this);
    }

    onYesClick(popup, func) {
        popup.close();
        if (func) {
            func();
        }
    }

    onNoClick(popup, func) {
        popup.close();
        if (func) {
            func();
        }
    }

    show(title, content, onYesClick, onNoClick) {
        var self = this;
        window.Modal.show(
            modal => {
                return (
                    <div>
                        <div>
                            <p>{content}</p>
                        </div>
                    </div>
                )
            },
            title,
            modal => {
                return (
                    <div>
                        <button className='btn btn-primary' onClick={() => self.onYesClick(modal, onYesClick)}>Yes</button>
                        <button className='btn btn-secondary pull-right' onClick={() => self.onNoClick(modal, onNoClick)}>No</button>
                    </div>
                )
            }
        )
    }

    render() {
        return (
            <div></div>
        );
    }
}


import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { TextboxControl } from '../../../controls/TextboxControl';
import { DropdownControl } from '../../../controls/DropdownControl';
import { Logger } from "../../../infrastructure/Logger";

export class SortableCaption extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sortAsc: true
        }
    }

    onClick() {
        var sort = !this.state.sortAsc;
        this.setState({
            sortAsc: sort
        });

        if (this.props.onClick) {
            this.props.onClick(sort);
        }
    }


    render() {
        return (
            <span className='clickable' onClick={() => { this.onClick() }}><span>{this.props.text}</span> <span className={this.state.sortAsc ? 'glyphicon glyphicon-chevron-up' : 'glyphicon glyphicon-chevron-down'}></span></span>
        )
    }
}

export class FeatureDefaultLocationListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: null,
            convertingMethodSortAsc: true,
            featureSortAsc: true
        }

    }

    componentDidMount() {
        this.refreshList();
    }


    async refreshList() {
        this.setState({
            items: null
        });

        var dataAccess = new ApiDataAccess('/data');

        var tasks = [
            dataAccess.get('/datasources/PackagingConvertingMethods'),
            dataAccess.get('/datasources/LocationBasedFeatures'),
            dataAccess.get('/featureDefaultLocations')
        ]
        Promise.all(tasks)
            .then(result => {
                var convertingMethods = result[0];
                var dataSourceItems = result[1];
                var items = result[2];

                var list = [];
                items.forEach(item => {
                    var record = {
                        id: item.id,
                        convertingMethod: this.getConvertingMethod(convertingMethods, item.convertingMethodId),
                        feature: this.getFeature(dataSourceItems, item.featureId),
                        featureItem: this.getFeatureItem(dataSourceItems, item.featureId, item.itemId),
                        offset: item.offset
                    }
                    list.push(record);
                });


                this.setState({
                    items: list
                });

            });
    }

   
    getConvertingMethod(convertingMethods, convertingMethodId) {
        var convertingMethod = convertingMethods.find(e => e.id == convertingMethodId);
        return convertingMethod.name;
    }

    getFeature(dataSourceItems, featureId) {
        var feature = dataSourceItems.find(e => e.id == featureId);
        return feature.name;
    }

    getFeatureItem(dataSourceItems, featureId, itemId) {
        var feature = dataSourceItems.find(e => e.id == featureId);
        var item = feature.items.find(e => e.id == itemId);
        return item.name;
    }

    onDeleteClick(id) {
        window.Confirmation.show('DELETE', 'Are you sure to delete this record?',
            () => {
                var dataAccess = new ApiDataAccess('/data');
                dataAccess.delete(`/featureDefaultLocations/${id}`)
                    .then(() => { this.refreshList(); });                
            }
        );
    }

    onConvertingMethodSortClick(asc) {

        var items = this.state.items;

        this.setState({
            items: null
        });

        if (asc) {
            items.sort((a, b) => (a.convertingMethod > b.convertingMethod) ? 1 : -1)
        }
        else {
            items.sort((a, b) => (a.convertingMethod < b.convertingMethod) ? 1 : -1)
        }

        this.setState({
            convertingMethodSortAsc: asc,
            items: items
        });

    }

    onFeatureSortClick(asc) {
        var items = this.state.items;

        this.setState({
            items: null
        });

        if (asc) {
            items.sort((a, b) => (a.feature > b.feature) ? 1 : -1)
        }
        else {
            items.sort((a, b) => (a.feature < b.feature) ? 1 : -1)
        }

        this.setState({
            featureSortAsc: asc,
            items: items
        });
    }

    render() {
        return (
            <div className="view">
                <ImageTitle text='Packaging Feature Default Locations' image='/images/attributes/seal.png' />

                <div className='generic-component'>

                    <div className='toolbar'>
                        <button className="btn btn-primary" onClick={() => { window.Router.setRoute(`/configuration/features/locations/0/edit`) }}>
                            <span className="glyphicon glyphicon-plus"></span>
                            <span className="d-none d-sm-block"> New</span>
                        </button>
                    </div>

                    {this.state.items &&
                        <div className='table'>
                            <div className='table-header'>
                                <div className='row'>
                                    <div className='col col-3'><SortableCaption text='Converting Method' onClick={asc => { this.onConvertingMethodSortClick(asc) }} /></div>
                                    <div className='col col-3'><SortableCaption text='Feature' onClick={asc => { this.onFeatureSortClick(asc) }} /></div>
                                    <div className='col col-3'>Item</div>
                                    <div className='col col-2'>Offset</div>
                                    <div className='col col-1'></div>
                                </div>
                            </div>

                            <div className='body'>
                                {this.state.items.map((item, idx) => {
                                    return (
                                        <div key={`row${idx}`} className='row clickable'>
                                            <div className='col col-3' onClick={() => { window.Router.setRoute(`/configuration/features/locations/${item.id}/edit`) }}>{item.convertingMethod}</div>
                                            <div className='col col-3' onClick={() => { window.Router.setRoute(`/configuration/features/locations/${item.id}/edit`) }}>{item.feature}</div>
                                            <div className='col col-3' onClick={() => { window.Router.setRoute(`/configuration/features/locations/${item.id}/edit`) }}>{item.featureItem}</div>
                                            <div className='col col-2' onClick={() => { window.Router.setRoute(`/configuration/features/locations/${item.id}/edit`) }}>{item.offset.toFixed(4)}</div>
                                            <div className='col col-1'><button className='btn btn-secondary pull-right' onClick={() => { this.onDeleteClick(item.id) }}><span className='glyphicon glyphicon-trash' /></button></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}


export class FeatureDefaultLocationListEditView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            convertingMethods: null,
            items: null,
            convertingMethodId: null,
            featureId: null,
            itemId: null,
            offset: null
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        var dataAccess = new ApiDataAccess('/data');

        var items = await dataAccess.get('/datasources/LocationBasedFeatures');
        var convertingMethods = await dataAccess.get('/datasources/PackagingConvertingMethods');
        if (this.props.param_0 > 0) {
            var data = await dataAccess.get(`/featureDefaultLocations/${this.props.param_0}`);
            this.setState({
                items: items,
                convertingMethods: convertingMethods,
                convertingMethodId: data.convertingMethodId,
                featureId: data.featureId,
                itemId: data.itemId,
                offset: data.offset
            });
        }
        else {
            this.setState({
                items: items,
                convertingMethods: convertingMethods
            });
        }
    }


    getFeatures() {
        var result = []
        this.state.items.forEach(i => {
            result.push({ id: i.id, name: i.name })
        });
        return result;
    }

    getFeatureItems(featureId) {
        var item = this.state.items.find(e => e.id == featureId);
        return item.items;
    }

    onConvertingMethodSelected(value, item) {
        Logger.writeDebug('SELECTED CONVERTING METHOD', value, item);
        this.setState({
            convertingMethodId: value
        });
    }

    onFeatureSelected(value, item) {
        Logger.writeDebug('SELECTED FEATURE', value, item);
        this.setState({
            featureId: value,
            itemId: null,
            offset: null
        });
    }

    onFeatureItemSelected(value, item) {
        Logger.writeDebug('SELECTED ITEM', value, item);
        this.setState({
            itemId: value,
        });
    }

    onOffsetChanged(value) {
        this.setState({
            offset: value
        });
    }

    onSaveClick() {
        var dataAccess = new ApiDataAccess('/data');

        var request = {
            id: this.props.param_0,
            convertingMethodId: this.state.convertingMethodId,
            featureId: this.state.featureId,
            itemId: this.state.itemId,
            offset: this.state.offset,
            status: 'ACT'
        };

        dataAccess.post(`/featureDefaultLocations`, request)
            .then(data => { window.Router.setRoute('/configuration/features/locations') });

    }

    render() {
        return (
            <div className="view">
                <ImageTitle text='Packaging Feature Default Locations' image='/images/attributes/seal.png' />

                <div className='generic-component'>

                    <div className='toolbar'>
                        <button className="btn btn-primary" onClick={() => { window.Router.setRoute(`/configuration/features/locations`) }}>
                            <span className="glyphicon glyphicon-th-list"></span>
                            <span className="d-none d-sm-block"> List</span>
                        </button>
                    </div>

                    <div className='generic-input-component'>

                        {this.state.convertingMethods &&
                            <div className='row'>
                                <label>Converting Method</label>
                                <DropdownControl items={this.state.convertingMethods} valueMemberName='id' displayMemberName='name' onChange={(value, item) => { this.onConvertingMethodSelected(value, item) }} value={this.state.convertingMethodId} />
                            </div>
                        }

                        {this.state.items &&
                            <div className='row'>
                                <label>Feature</label>
                                <DropdownControl items={this.getFeatures()} valueMemberName='id' displayMemberName='name' onChange={(value, item) => { this.onFeatureSelected(value, item) }} value={this.state.featureId} />
                            </div>
                        }

                        {this.state.featureId &&
                            <div className='row'>
                                <label>Feature Item</label>
                                <DropdownControl items={this.getFeatureItems(this.state.featureId)} valueMemberName='id' displayMemberName='name' onChange={(value, item) => { this.onFeatureItemSelected(value, item) }} value={this.state.itemId} />
                            </div>
                        }

                        {this.state.itemId &&
                            <div className='row'>
                                <label>Default Offset</label>
                                <TextboxControl maxlength={7} regex={'[0-9]*(.[0-9]{0,4})?'} onChange={txt => { this.onOffsetChanged(txt) }} value={this.state.offset} />
                            </div>
                        }

                        <div>
                            <button disabled={!this.state.featureId || !this.state.itemId || !this.state.offset} className='btn btn-primary btn-lg' onClick={() => { this.onSaveClick() }}>Save</button>
                            <button className='btn btn-secondary-outline btn-lg pull-right' onClick={() => { window.Router.setRoute(`/configuration/features/locations`) }}>Cancel</button>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
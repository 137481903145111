import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { DataManager } from '../../../dataControls/DataManager';
import { ImageTitle, ImageLabel } from '../../../core/ImageTitle';
import { DistinctTextboxDataControl } from '../../../dataControls/DistinctTextboxDataControl';
import { TextboxDataControl } from '../../../dataControls/TextboxDataControl';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { ExpandableSection } from '../../../controls/ExpandableSection';
import { ChecklistDataControl } from '../../../dataControls/ChecklistDataControls';

import { Card } from '../../../components/Containers';
import { ImageTileCircle } from '../../../components/Containers';
import { Alert } from '../../../components/Components';

export class ProductGroupEditorComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            structureSpecs: [],
            conflictingGroups: [],
            isValid: false
        };

        this.dataAccess = new ApiDataAccess('/data');
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onAttributeSelectionChanged = this.onAttributeSelectionChanged.bind(this);
        this.onStructureSpecSelectionChanged = this.onStructureSpecSelectionChanged.bind(this);
        this.onProductsRetrieved = this.onProductsRetrieved.bind(this);
        this.onConflictsRetrieved = this.onConflictsRetrieved.bind(this);

        this.attributeFilter = new ProductAttributeFilter(this.props.dataId, this.onProductsRetrieved, this.onConflictsRetrieved);

        this.dataManager = new DataManager('productGroups', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(() => {
                            //2) bind Controls                            
                            dataManager.bindControls();
                        })
                        .catch(error => {
                            window.Logger.writeError("Could not load Product Group", error);
                            window.Router.setNotFound();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                }

                //3) onControlBound --> Set Value
            });
    }

    

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    onAttributeSelectionChanged(values, cntrl) {
        this.attributeFilter.setAttributeValues(cntrl.props.propertyName, values);
    }

    onStructureSpecSelectionChanged(values, cntrl) {
        this.setState({
            structureSpecs: values
        });
    }

    onProductsRetrieved(products) {
        this.setState({
            products: products
        });

        if (products && products.length > 0) {
            window.Alerts.showInfo(products.length + ' compatible Products found.')
        }
    }

    onConflictsRetrieved(conflictingGroups) {
        this.setState({
            conflictingGroups: conflictingGroups
        });

        if (conflictingGroups && conflictingGroups.length > 0) {
            window.Alerts.showWarn(conflictingGroups.length + ' conflicting Groups found.')
        }
    }


    onSaveClick() {
        var self = this;
        
        self.dataManager.saveContext()
            .then(data => {
                window.Alerts.showSuccess('Product Group' + data.result.name + ' has been saved');
                window.Router.setRoute('/packaginggroups/' + encodeURIComponent(data.result.id));                
            })
            .catch(error => {                
                window.Alerts.showError('The Packaging Group was not saved.');
            });
        
    }

    render() {
        return (
            <div className='product product-group-editor-component'>

                <ImageTitle text='Packaging Group'  />

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/packaginggroups')} />
                        </div>
                    )
                }} />


                <Card title='General' subtitle='Find below the general Packaging Group information:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='name'>Name</label>
                            <DistinctTextboxDataControl dataManager={this.dataManager} propertyName='name' lookupDataSource='ProductGroupLookup' error='A product group with this name exists already' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='description'>Description</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='description' multiline={true} />
                        </div>
                    </div>
                </Card>


                <Card title='Group Attributes' subtitle='Select the product attributes that define the products that should be associated with this packaging group:'>
                    <div className='table group-attributes'>
                        <div className='body'>
                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Consistency' for='consistencies' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='consistencies' onChange={this.onAttributeSelectionChanged} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Moisture' for='moistures' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='moistures' onChange={this.onAttributeSelectionChanged} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Aroma' for='aromas' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='aromas' onChange={this.onAttributeSelectionChanged} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Light Sensitivity' for='lightSensitivities' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='lightSensitivities' onChange={this.onAttributeSelectionChanged} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Product Shelf Life' for='shelfLifeTimes' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='shelfLifeTimes' onChange={this.onAttributeSelectionChanged} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Child Resistance' for='childResistances' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='childResistances' onChange={this.onAttributeSelectionChanged} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Aggressiveness' for='aggressivnesses' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='aggressivnesses' onChange={this.onAttributeSelectionChanged} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Emission' for='emissions' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='emissions' onChange={this.onAttributeSelectionChanged} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Packaging Temperature' for='temperatures' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='temperatures' onChange={this.onAttributeSelectionChanged} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Oxygen Sensitivity' for='oxygenSensitivities' />
                                    <ChecklistDataControl dataManager={this.dataManager} propertyName='oxygenSensitivities' onChange={this.onAttributeSelectionChanged} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Card>


                <Card title='Structure Specs' subtitle='Select the Structure Specs that should be availaible for this packaging group:'>
                    <div className='structure-specs'>
                        <ChecklistDataControl
                            dataManager={this.dataManager}
                            propertyName='structureSpecs'
                            onChange={this.onStructureSpecSelectionChanged}
                            onRenderItem={(item) => {
                                return (
                                    <div className='col col-12'><span className='color-black'>{item.name}</span> <span className='color-orange'>(SPEC-{item.id})</span></div>)
                            }}
                            onFiltering={(filter, items) => {
                                var result = [];
                                items.forEach(item => {
                                    if (item.name.toLowerCase().includes(filter) || ('spec-' + item.id).includes(filter)) {
                                        result.push(item);
                                    }
                                });
                                return result;
                            }}
                            onSorting={(items) => {
                                if (items) {
                                    items.sort((a, b) => (a.name > b.name) ? 1 : -1)
                                }
                                return items;
                            }}
                        />
                    </div>
                </Card>


                <Card title='Compatible Products' subtitle='The following products will be associated with this group based on the selected Product Attributes:'>
                    {this.state.products && this.state.products.length > 0
                        ? <FilteredProductList products={this.state.products} />
                        : <Alert type='info'>No Products match this filter</Alert>
                    }                    
                </Card>


                <Card title='Conflicting Groups' subtitle='This groups conflicts with existing product groups based on the selected product attributes:'>
                    {this.state.conflictingGroups && this.state.conflictingGroups.length > 0 
                        ? <FilteredProductGroupList groups={this.state.conflictingGroups} />
                        : <Alert type='info'>No conflicts with other groups</Alert>
                    }                    
                </Card>
                        
                <button className='btn btn-lg btn-primary' disabled={!this.state.isValid || (this.state.conflictingGroups && this.state.conflictingGroups.length>0) } onClick={this.onSaveClick}>Save</button>
            </div>

        );
    }
}

export class FilteredProductList extends React.Component {
    render() {        
        return (
            <div className='filtered-product-list product-list'>
                {this.props.products.map(function (item, idx) {
                    return (
                        <ImageTileCircle key={`row_${idx}`} src={'/data/images/' + encodeURIComponent(item.imageId ? item.imageId : 0) + '/thumbnail'} value={<PublishableNameDisplay item={item} />} onClick={() => window.Router.setRoute('/products/' + encodeURIComponent(item.id))} />
                                               
                    )
                })}
            </div>
        );
    }
}

export class PublishableNameDisplay extends React.Component {

    render() {
        return (
            <div className="flex-center">
                {this.props.item.isPublished === true
                    ? <span className='glyphicon glyphicon-play color-green' title='Published' />
                    : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />
                }
                <div>{this.props.item.name}</div>
            </div>
        )
    }
}

export class FilteredProductGroupList extends React.Component {

    render() {
        return (
            <div className='table'>
                <div className='body'>
                {this.props.groups.map(function (item, idx) {
                    return (
                        <div key={`row_${idx}`} className="row clickable" onClick={() => { window.Router.setRoute(`/packaginggroups/${item.id}`) }}>
                            <div className='col col-12'>{item.name}</div>
                        </div>
                    )
                })}
                </div>
            </div>
        );
    }
}

export class ProductAttributeFilter {
    constructor(groupId, onProductsRetrieved, onConflictsRetrieved, onRulesRetrieved) {

        this.dataAccess = new ApiDataAccess('/data/lookup');

        this.groupId = groupId && groupId > 0 ? groupId : null;
        this.onProductsRetrieved = onProductsRetrieved;
        this.onConflictsRetrieved = onConflictsRetrieved;
        this.onRulesRetrieved = onRulesRetrieved;

        this.filter = {
            productGroupId: this.groupId,
            consistencies: [],
            moistures: [],
            aromas: [],
            lightSensitivities: [],
            shelfLifeTimes: [],
            childResistances: [],
            aggressivnesses: [],
            emissions: [],
            temperatures: [],
            oxygenSensitivities: []
        }
    }

    setAttributeValues(attributeName, values) {
        this.filter[attributeName] = this.extractIdentifiers(values);
        this.getProducts();
        this.getProductGroupConflicts();
    }

    getProducts() {
        var self = this;
        if (this.isValid()) {            
            if (this.onProductsRetrieved) {

                this.dataAccess.post('/productsByProductAttributesLookup', self.filter)
                    .then(data => { self.onProductsRetrieved(data); })
                    .catch(error => { window.Alerts.showError('Could not retrieve products for this packaging group.'); });
            }
        }
        else {
            if (self.onProductsRetrieved) {
                self.onProductsRetrieved([]);
            }
        }
    }



    getProductGroupConflicts() {
        var self = this;
        if (this.isValid()) {
            if (this.onConflictsRetrieved) {

                this.dataAccess.post('/productGroupConflictsLookup/', self.filter)
                    .then(data => { self.onConflictsRetrieved(data); })
                    .catch(error => { window.Alerts.showError('Could not retrieve product group conflicts.'); });

            }
        }
        else {
            if (self.onConflictsRetrieved) {
                self.onConflictsRetrieved([]);
            }
        }
    }



    getRelatedRules() {
        var self = this;
        if (this.isValid()) {
            if (this.onConflictsRetrieved) {

                this.dataAccess.post('/RulesByProductAttributes/', self.filter)
                    .then(data => { self.onRulesRetrieved(data); })
                    .catch(error => { window.Alerts.showError('Could not retrieve rules.'); });
            }
        }
        else {
            if (self.onRulesRetrieved) {
                self.onRulesRetrieved([]);
            }
        }
    }


    extractIdentifiers(items) {
        var result = [];
        if (items && items.length > 0) {
            items.forEach(function (item) {
                result.push(item.id);
            });
        }
        return result;
    }

    isValid() {
        
        if (this.filter.consistencies.length == 0) {
            return false;
        }

        if (this.filter.moistures.length == 0) {
            return false;
        }

        if (this.filter.aromas.length == 0) {
            return false;
        }

        if (this.filter.lightSensitivities.length == 0) {
            return false;
        }

        if (this.filter.shelfLifeTimes.length == 0) {
            return false;
        }

        if (this.filter.childResistances.length == 0) {
            return false;
        }

        if (this.filter.aggressivnesses.length == 0) {
            return false;
        }

        if (this.filter.emissions.length == 0) {
            return false;
        }

        if (this.filter.temperatures.length == 0) {
            return false;
        }
        
        if (this.filter.oxygenSensitivities.length == 0) {
            return false;
        }

        return true;
    }

}
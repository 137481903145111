import React from 'react';
import { TextboxControl } from '../../../controls/TextboxControl';
import { DataSourceDropDownControl } from '../../../controls/DataSourceDropDownControl';
import { DataSourceChecklistControl } from '../../../controls/DataSourceChecklistControl';
import { RuleTargetDefinitionSelector } from './RuleDefinitionSelector';
import { InputControlType } from './Rule';
import { Spacer } from '../../../components/Containers';

export class RuleTargetPopupContent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }
        this.ruleDataManager = this.props.ruleDataManager;
        this.control = null;
        this.definition = null;
        this.values = this.props.values ? this.props.values : [];
        this.state = {
            affinity: 0,
            location: 1,
            offset: 0,
            relatedFeature: null,
            definition: this.definition,
            values: this.values,                        
            items: []
        }

        this.onDefinitionSelected = this.onDefinitionSelected.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onCollectionChange = this.onCollectionChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onAffinityChange = this.onAffinityChange.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onOffsetChange = this.onOffsetChange.bind(this);
        this.onRelatedFeatureChange = this.onRelatedFeatureChange.bind(this);
    }

    reset() {
        this.values = this.props.values ? this.props.values : [];
        this.state = {
            affinity: 0,
            values: this.values,
            items: [],
            location: 1,
            offset: 0,
            relatedFeature: null
        }
    }

    onDefinitionSelected(value, definition) {
        this.reset();
        this.definition = definition;
        this.setState({
            definition: this.definition,
            location: 1
        });

        if (this.control && this.definition) {
            this.control.setDataSource(this.definition.dataSourceName);
        }
    }

    onItemChange(value, item) {
        var values = [];
        values.push(value);
        var items = [];
        items.push(item);

        this.setState({
            values: values,
            items: items
        });
    }

    onAffinityChange(event) {
        this.setState({
            affinity: event.target.value
        });
    }

    onCollectionChange(values, items) {
        this.setState({
            values: values,
            items: items
        });
    }

    onLocationChange(event) {
        this.setState({
            location: event.target.value
        });
    }

    onRelatedFeatureChange(value) {
        this.setState({
            relatedFeature: value
        });
    }

    onOffsetChange(value) {
        this.setState({
            offset: value
        });
    }

    getControl() {
        if (this.definition) {
            switch (this.definition.inputControlType) {
                case InputControlType.FreeTextItem:
                    return (<p>Text Controls are not supported in this context</p>);
                    break;
                case InputControlType.FreeTextTuple:
                    return (<p>Text Controls are not supported in this context</p>);
                    break;
                case InputControlType.SingleChoice:
                    return (<DataSourceDropDownControl onInit={ctrl => this.control = ctrl} dataSourceName={this.definition.dataSourceName} onChange={this.onItemChange} />);
                    break;
                case InputControlType.MultipleChoice:
                    return (<DataSourceChecklistControl onInit={ctrl => this.control = ctrl} dataSourceName={this.definition.dataSourceName} onChange={this.onCollectionChange} />);
                    break;
                default:
                    throw new Error(`Input Control Type ${this.definition.inputControlType} is not supported`);
            }
        }
    }

    onAddClick() {
        this.props.modal.close();
        if (this.props.onAddClick) {

            var target = {
                definition: this.definition,
                items: this.state.items,
                affinity: parseInt(this.state.affinity),
                location: parseInt(this.state.location),
                relatedFeature: this.state.location == 2 ? parseInt(this.state.relatedFeature) : 0,
                offset: (this.state.location == 2 || this.state.location == 3) ? parseFloat(this.state.offset) : 0
            };

            this.props.onAddClick(target);
        }
    }

    isValid() {

        if (parseInt(this.state.location) == 2) {
            return this.state.values && this.state.values.length > 0 && this.state.affinity > 0 && parseInt(this.state.relatedFeature) > 0;
        }
        else {
            return this.state.values && this.state.values.length > 0 && this.state.affinity > 0;
        }        
    }

    render() {
        var self = this;
        return (
            <div className='ruleTargetPopupContent rulePopup'>
                <RuleTargetDefinitionSelector id='feature' ruleDataManager={this.ruleDataManager} onChange={this.onDefinitionSelected} />
                
                {this.state.definition &&
                    <div className='inner-content'>                        
                        <h3>Items</h3>
                        {self.getControl()}

                        <h3>Affinity</h3>

                        <div className="form-radio">
                            <input className="form-radio-input" type="radio" id='affinity_1' value={1} checked={this.state.affinity == 1} onChange={self.onAffinityChange} />
                            <label className="form-radio-label" htmlFor='affinity_1'>Exclusive</label>
                        </div>

                        <div className="form-radio">
                            <input className="form-radio-input" type="radio" id='affinity_2' value={2} checked={this.state.affinity == 2} onChange={self.onAffinityChange} />
                            <label className="form-radio-label" htmlFor='affinity_2'>Include</label>
                        </div>

                        <div className="form-radio">
                            <input className="form-radio-input" type="radio" id='affinity_3' value={3} checked={this.state.affinity == 3} onChange={self.onAffinityChange} />
                            <label className="form-radio-label" htmlFor='affinity_3'>Exclude</label>
                        </div>


                        <h3>Location</h3>
                        <table>
                        <tr>
                            <td>
                                <div className="form-radio">
                                    <input className="form-radio-input" type="radio" id='location_1' value={1} checked={this.state.location == 1} onChange={self.onLocationChange} />
                                    <label className="form-radio-label" htmlFor='location_1'>Independent</label>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                                <td>
                                    <div className="form-radio">
                                        <input className="form-radio-input" type="radio" id='location_2' value={2} checked={this.state.location == 2} onChange={self.onLocationChange} />
                                        <label className="form-radio-label" htmlFor='location_2'>Centered To (if applicable)</label>
                                    </div>
                                </td>
                                <td>
                                    {this.state.location == 2 &&
                                        <DataSourceDropDownControl id='related_feature' onInit={ctrl => this.control = ctrl} disabled={this.state.location != 2} required={this.state.location == 2} dataSourceName={'relatedFeatures'} onChange={this.onRelatedFeatureChange} />
                                    }
                                </td>
                                <td>
                                    {this.state.location == 2 &&
                                        <span>With Offset (in/mm)</span>
                                    }
                                </td>
                                <td>
                                    {this.state.location == 2 &&
                                        <TextboxControl required={this.state.location == 2} disabled={this.state.location != 2} regex={'(-)?[0-9]*(\.[0-9]{0,4})?'} onChange={this.onOffsetChange} value={'0.00'} />
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div className="form-radio">
                                        <input className="form-radio-input" type="radio" id='location_3' value={3} checked={this.state.location == 3} onChange={self.onLocationChange} />
                                        <label className="form-radio-label" htmlFor='location_3'>Fix Offset from Pouch Top</label>
                                    </div>
                                </td>
                                <td>
                                    {this.state.location == 3 &&
                                        <TextboxControl required={this.state.location == 3} disabled={this.state.location != 3} regex={'(-)?[0-9]*(\.[0-9]{0,4})?'} onChange={this.onOffsetChange} value={'0.00'} />
                                    }
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                            </tr>


                        </table>
                    </div>
                }

                <Spacer />

                <div className='modal-footer'>
                    <button className='btn btn-lg btn-primary' disabled={!this.isValid()} onClick={this.onAddClick}>Add</button>
                    <button className='btn btn-lg btn-secondary pull-right' onClick={this.props.modal.close}>Cancel</button>
                </div>
            </div>
        );
    }
}
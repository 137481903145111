import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PolicyConfirmation } from '../../../dataControls/PolicyConfirmation';

export class Vent {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Vent', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/ventFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/ventFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/ventFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/ventFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}


export class HangHole {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Hang Hole', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/hangholeFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/hangholeFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/hangholeFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/hangholeFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}


export class TearNotch {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Tear Notch', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/tearNotchFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/tearNotchFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/tearNotchFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/tearNotchFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}


export class Zipper {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Zipper', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/zipperFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/zipperFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/zipperFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/zipperFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}



export class BottomFill {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Bottom Fill', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/bottomFillFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/bottomFillFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/bottomFillFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/bottomFillFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}

export class Core {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Core', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/coreFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/coreFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/coreFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/coreFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}




export class DoubleCut {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Double Cut', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/doubleCutFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/doubleCutFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/doubleCutFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/doubleCutFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}

export class ColorSpec {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Color Spec', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/colorSpecFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/colorSpecFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/colorSpecFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/colorSpecFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}


export class Eyemark {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Eyemark', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/eyemarkFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/eyemarkFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/eyemarkFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/eyemarkFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });;
    }
}



export class SealWidth {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('SealWidth', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/sealwidthFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/sealwidthFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/sealwidthFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/sealwidthFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}


export class QrCodeType {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('ePac Connect', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/qrCodeTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/qrCodeTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/qrCodeTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/qrCodeTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}



export class RoundedCorner {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('RoundedCorner', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/roundedCornerFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/roundedCornerFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/roundedCornerFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/roundedCornerFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}



export class UnwindDirection {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('UnwindDirection', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/unwindDirections/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/unwindDirections/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/unwindDirections/' + encodeURIComponent(self.id) + '/publish'
            : '/unwindDirections/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}



export class ManufacturingOption {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('ManufacturingOption', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/manufacturingOptions/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/manufacturingOptions/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/manufacturingOptions/' + encodeURIComponent(self.id) + '/publish'
            : '/manufacturingOptions/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}


export class OutsourcedFeature {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('OutsourcedFeature', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/outsourcedFeatures/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/outsourcedFeatures/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/outsourcedFeatures/' + encodeURIComponent(self.id) + '/publish'
            : '/outsourcedFeatures/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}



export class GussetType {

    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('GussetType', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/gussetTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/gussetTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/gussetTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/gussetTypeFeatureAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}
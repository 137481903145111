import React, { Component, useEffect, useState } from 'react';
import { Info } from 'react-feather';
import { BackSplash, Modal } from './Dialogs';
import { Edit, Upload, ArrowDownCircle, MessageSquare } from 'react-feather';
import { Textbox, Dropdown } from './Controls';

export class SectionTitle extends Component {
    static displayName = SectionTitle.name;

    render() {
        return (
            <div className='sectionTitle'>
                {this.props.description
                    ? <ContextHelp title={this.props.children} description={this.props.description} image={this.props.image}>
                        <h3>{this.props.children}</h3>
                      </ContextHelp>
                    : <h3>{this.props.children}</h3>
                    }                
            </div>
        );
    }
}

export class SectionLabel extends Component {
    static displayName = SectionLabel.name;

    render() {
        return (
            <div className='sectionLabel'>
                <div className='description'>{this.props.description}</div>
                <div className='action'>{this.props.children}</div>
            </div>
        );
    }
}

export class ContextHelp extends Component {
    static displayName = Info.name;

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({ showModal: true });
    }

    getImageClass() {
        return `background-${this.props.image}`;        
    }

    render() {
        return (
            <div className={this.props.fill === true ? 'contextHelp fill' : 'contextHelp'}>
                
                {this.props.children &&
                    <div className={this.props.fill === true ? 'payload fill' : 'payload'}>
                        {this.props.children}
                    </div>
                }
                <Info className='icon' onClick={this.onClick} />


                {this.state.showModal &&
                    <Modal onCloseClick={() => { this.setState({ showModal: false }) }}>
                    <div className='sectionModalContent'>
                        {this.props.image &&
                            <div className={`image ${this.getImageClass()}`}></div>
                            }
                            <h3>{this.props.title}</h3>
                            <div className='content'>{this.props.description}</div>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}


export class Money extends Component {
    static displayName = Money.name;

    render() {
        return (
            <div className='money'>
                <span className='currency color-light-grey'>{this.props.currency}</span><span className='amount color-grey'>{this.props.amount}</span>
            </div>
        );
    }
}

export class EditLink extends Component {
    static displayName = EditLink.name;

    render() {
        return (
            <div className='iconLink'>
                <span onClick={this.props.onClick}><Edit className='icon' />{this.props.children}</span>
            </div>
        );
    }
}


export class DownloadLink extends Component {
    static displayName = DownloadLink.name;

    render() {
        return (
            <div className='iconLink'>
                <a href={this.props.href}><ArrowDownCircle className='icon' />{this.props.children}</a>
            </div>
        );
    }
}

export class UploadLink extends Component {
    static displayName = UploadLink.name;

    render() {
        return (
            <div className='iconLink'>
                <a href={this.props.href}><Upload className='icon' />{this.props.children}</a>
            </div>
        );
    }
}

export class MessageLink extends Component {
    static displayName = DownloadLink.name;

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }

        this.onIconClick = this.onIconClick.bind(this);
    }

    onIconClick() {
        this.setState({ visible: !this.state.visible });
    }

    getClassName() {
        let result = 'iconLink';

        switch (this.props.type) {
            case 'warn':
                result += ' warn';
                break;
            case 'error':
                result += ' error';
                break;
        }
        return result;
    }

    render() {
        return (
            <div className={this.getClassName()}>

                <span><MessageSquare className='icon' onClick={this.onIconClick} /><span onClick={this.props.onClick}>{this.props.children}</span></span>

                {this.state.visible &&
                    <div className='safety'>
                        <BackSplash clear={true} onClick={this.onIconClick} />
                        <div className='popover' onClick={this.onIconClick}>This is a test</div>
                    </div>
                }

            </div>
        );
    }
}



export class Alert extends Component {
    static displayName = UploadLink.name;

    getClassName() {
        let result = this.props.className
            ? `alert ${this.props.className}`
            : 'alert';

        switch (this.props.type) {
            case 'warn':
                result += ' warn';
                break;
            case 'error':
                result += ' error';
                break;
            case 'success':
                result += ' success';
                break;
            default:
                result += ' info';
                break;
        }
        return result;
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={this.props.onClick}>
                <div className='alert-content'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}



export class SkuEditor extends Component {
    static displayName = SkuEditor.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='skus editor'>
                <ContextHelp title={'test'} description={'test'} fill={true}>
                    <Dropdown>
                        <option value={-1}>Select Quantity Range</option>
                        <option value={1000}>1000...1999</option>
                        <option value={2000}>2000...2999</option>
                        <option value={3000}>3000...3999</option>
                    </Dropdown>
                </ContextHelp>

                <span className='text success'>Select higher quantity to receive lower unit price</span>

                <table>
                    <thead>
                        <tr>
                            <th className='description'><span>SKU Description <ContextHelp title='SKU Description' description='content' /></span></th>
                            <th className='unitPrice'>Unit Price <ContextHelp title='Unit Price' description='content' /></th>
                            <th className='quantity'>Quantity <ContextHelp title='Quantity' description='content' /></th>
                            <th className='totalPrice'>Total Price <ContextHelp title='Total Price' description='content' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='description'><Textbox required={true} /></td>
                            <td className='unitPrice'><Money currency={'GBP'} amount={'0.1234'} /></td>
                            <td className='quantity'><Textbox type='number' required={true} regex={'[0-9]*'} requireValidInput={true} maxLength={6} /></td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.12'} /></td>
                        </tr>

                        <tr>
                            <td className='description'><Textbox required={true} /></td>
                            <td className='unitPrice'><Money currency={'GBP'} amount={'0.1234'} /></td>
                            <td className='quantity'><Textbox type='number' required={true} regex={'[0-9]*'} requireValidInput={true} maxLength={6} /></td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.12'} /></td>
                        </tr>

                        <tr className='totals'>
                            <td className='description'></td>
                            <td className='unitPrice'>Total</td>
                            <td className='quantity'>8000</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1160'} /></td>
                        </tr>

                    </tbody>

                </table>
            </div>
        )
    }
}


export class SkuDisplay extends Component {
    static displayName = SkuDisplay.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='skus display'>
                <table>
                    <thead>
                        <tr>
                            <th className='description'></th>
                            <th className='unitPrice'>Unit Price</th>
                            <th className='quantity'>Quantity</th>
                            <th className='totalPrice'>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td className='description'>Chocolate</td>
                            <td className='unitPrice'><Money currency={'GBP'} amount={'1.2346'} /></td>
                            <td className='quantity'>10000</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>

                        <tr>
                            <td className='description'>Vanilla</td>
                            <td className='unitPrice'><Money currency={'GBP'} amount={'1.2346'} /></td>
                            <td className='quantity'>10000</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>

                        <tr>
                            <td className='description'>Strawberry</td>
                            <td className='unitPrice'><Money currency={'GBP'} amount={'1.2346'} /></td>
                            <td className='quantity'>10000</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>

                    </tbody>

                </table>
            </div>
        )
    }
}



export class GrandTotal extends Component {
    static displayName = GrandTotal.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='skus display grandTotal'>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td className='name'>Subtotal</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='name'>Overage</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='name'>Shipping</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='name'>Total Amount</td>
                            <td className='totalPrice'><Money currency={'GBP'} amount={'1234.56'} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export class SideLabel extends Component {
    static displayName = GrandTotal.name;

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        }

    }

    render() {
        return (
            <div>
                {this.state.visible &&
                    <div className='sidelabel' onClick={() => { this.setState({visible: false})}}>
                        {this.props.children}
                    </div>
                }
            </div>
        )
    }
}

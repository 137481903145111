import React from 'react';
import { ImageTileCircle } from '../../../components/Containers';
import { LookupDataSourceListControl } from '../../../dataControls/LookupDataSourceListControl';



export class ProductGroupsByStructureSpecListControl extends React.Component {

    constructor(props) {
        super(props);

        this.list = null;

        this.state = {
            items: null
        }
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }        
    }

    refresh() {
        if (this.list) {
            this.list.refresh();
        }
    }

    render() {
        var self = this;
        return (
            <LookupDataSourceListControl dataSourceName='ProductGroupsByStructureSpec' filter={{ number: this.props.structureSpecId }}
                onInit={cntr => self.list = cntr}
                onRenderRow={item => {
                    return (
                        <div className='flex clickable' onClick={() => window.Router.setRoute('/packaginggroups/' + encodeURIComponent(item.id))}>
                            {item.isPublished === true
                                ? <span className='glyphicon glyphicon-play color-green' title='Published' />
                                : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />
                            }
                            <div>{item.name}</div>
                        </div>
                    )
                }}
                onDataReceived={items => {
                    self.setState({
                        items: items
                    });
                }}
            />
        );
    }
}


export class ProductsByStructureSpecListControl extends React.Component {

    constructor(props) {
        super(props);

        this.list = null;

        this.state = {
            items: null
        }
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    refresh() {
        if (this.list) {
            this.list.refresh();
        }
    }

    render() {
        var self = this;
        return (
            <div className='products-by-structureSpecList-control'>
                <LookupDataSourceListControl dataSourceName='ProductsByStructureSpec' filter={{ number: this.props.structureSpecId }}
                    onInit={cntr => self.list = cntr}
                    onRenderRow={item => {
                        return (
                            <ImageTileCircle src={'/data/images/' + encodeURIComponent(item.imageId ? item.imageId : 0) + '/thumbnail'} value={<PublishableNameDisplay item={item} />} onClick={() => window.Router.setRoute('/products/' + encodeURIComponent(item.id))} />
                        )
                    }}
                    onDataReceived={items => {
                        self.setState({
                            items: items
                        });
                    }}
                />
            </div>
        );
    }
}

export class PublishableNameDisplay extends React.Component {

    render() {
        return (
            <div className="flex-center">
                {this.props.item.isPublished === true
                    ? <span className='glyphicon glyphicon-play color-green' title='Published' />
                    : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />
                }
                <div>{this.props.item.name}</div>
            </div>        
        )
    }
}



                        
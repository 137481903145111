import React from 'react';
import { Logger } from "../infrastructure/Logger";

export class RelationshipItemsDisplayDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.metaData = null;
        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);
        this.display = this.props.display && Array.isArray(this.props.display) && this.props.display.length > 0 ? this.props.display : ['name'];
    
        this.state = {
            items: {}
        };
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {        
        var self = this;
        this.metaData = metaData;
        return new Promise(function (resolve) {            
            resolve(self);
        });
    }

    setValue(items) {
        if (Number.isInteger(items)) {
            var value = items;
            this.dataManager.getDataSource(this.metaData.dataSourceData.name)
                .then(dataSource => {
                    var item = dataSource.getItem(value);
                    
                    var items = [{
                        dataObject2: item
                    }];
                    this.setState({
                        items: items
                    });                    
                })
                .catch(error => {
                    Logger.writeError('Could not set value.', error);
                })         
        } else {
            this.setState({
                items: items
            });
        }        
    }

    getValue() {
        return this.state.items;
    }

    isInputValid() {
        var result = true;
        return result;
    }

    getRowItems(relationship) {
        var self = this;
        var result = [];
        var item = relationship.dataObject2;
        Object.keys(item).forEach(key => {
            if (self.display.includes(key)) {
                let value = item[key];
                var propertyInfo = {
                    name: key,
                    value: value
                };
                result.push(propertyInfo);
            }
        });
        return result;
    }

    getColumnsWidth(rowItems) {
        var cols = Math.floor(12 / rowItems.length);
        return 'col-sm-12 col-lg-' + cols;
    }

    getNoItemsText() {
        return this.props.noItemsText ? this.props.noItemsText : "No Items available";
    }

    render() {
        var self = this;

        var items = this.props.onSorting ? this.props.onSorting(this.state.items) : this.state.items;

        return (
            <div className='relationship-items-display-data-control'>
                {items && items.length > 0 &&
                    <div>
                    {items.map(function (relationship, idx) {

                        if (self.props.onRender) {
                            return (
                                <div key={`item1_${idx}`} className='item'>{self.props.onRender(relationship.dataObject2, relationship)}</div>
                            ) 
                        }
                        else {
                            var rowItems = self.getRowItems(relationship);
                            return (
                                <div className='row' key={`item2_${idx}`}>
                                    {rowItems.map(function (item, idx) {
                                        return (
                                            <div key={`item3_${idx}`} className={`item ${self.getColumnsWidth(rowItems) }`}>{item.value}</div>
                                        );
                                    })}
                                </div>
                            )
                        }

                        })}
                    </div>
                }

                {(!items || items.length == 0) &&
                    <div className='no-items'>
                        {self.getNoItemsText()}
                    </div>
                }
            </div>
        );
    }
}
import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';


var LifeCycleState = {
    Undefined: 0,
    Loading: 1,
    Completed: 2,
    Valid: 3,
    Invalid: 4
}

export class AutoCompleteControl extends React.Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.currentValue = this.props.value ? this.props.value : null;
        this.currentState = (this.props.required == true && this.currentValue && this.currentValue.length > 0) || this.props.required != true ? LifeCycleState.Valid : LifeCycleState.Invalid;

        this.settings = {
            loader: false,
            mode: "cors",
            cache: 'default',
            credentials: "include",
            redirect: "follow",
            referrer: "no-referrer",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        };

        this.dataAccess = new ApiDataAccess('/data', this.settings);
        this.state = {
            value: this.currentValue,
            maxlength: null,
            lifeCycleState: this.currentState,
            items: [],
            showList: false,
            selectedListId : -1
        }

        this.onKeyPress = this.onKeyPress.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onListItemConfirmed = this.onListItemConfirmed.bind(this);
        this.onListItemChange = this.onListItemChange.bind(this);
        this.onTextboxFocus = this.onTextboxFocus.bind(this);
        this.onItemContainerFocus = this.onItemContainerFocus.bind(this);
    }

    componentDidMount() {
        if (this.props.onStateChange) {
            this.props.onStateChange(this.currentState);
        }
    }

    setValue(value) {
        var newValue = value ? value : null;
        this.setState({
            value: newValue
        });

        this.currentValue = newValue;

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }

    }

    getValue() {
        return this.state.value;
    }

    setLifeCycleState(state) {
        this.currentState = state;

        this.setState({
            lifeCycleState: this.currentState
        });

        if (this.props.onStateChange) {
            this.props.onStateChange(this.currentState);
        }
    }

    isInputValid() {
        var result = true;
        var value = this.currentValue;

        if (this.props.required === true) {
            if (!value || value == null || value.length == 0) {
                result = false;
            }
        }

        if (result == true) {
                this.setLifeCycleState(LifeCycleState.Valid);
        }
        else {
            this.setLifeCycleState(LifeCycleState.Invalid)
        }

        return result;
    }

    onChange(event) {
        var self = this;
        var value = event.target.value;        
        var newValue = value;

        self.setValue(newValue);

        if (self.timeout) {
            clearTimeout(self.timeout);
            self.timeout = null;
        }

        if (newValue && newValue.length > 0) {
            self.timeout = setTimeout(() => {

                self.setLifeCycleState(LifeCycleState.Loading);

                var request = {
                    'text': newValue
                };

                self.dataAccess.post(`/lookup/${self.props.lookupDataSource}`, request)
                    .then(data => {
                        self.setLifeCycleState(LifeCycleState.Valid);
                        self.setState({
                            items: data,
                            selectedListId: data && data.length > 0 ? data[0].id : null,
                            showList: true
                        });
                    })
                    .catch(error => {
                        self.setLifeCycleState(LifeCycleState.Invalid);
                        self.setState({
                            items: [],
                            selectedListId: null,
                            showList: false
                        });
                    })

            }, 500);
        }
        else {
            self.setState({
                items: [],
                selectedListId: null,
                showList: false
            });
        }
    }

    onKeyPress(event) {        
        var keyCode = event.keyCode;
        if (keyCode === 40 && this.state.items && this.state.items.length > 0) {
            this.setState({
                selectedListId: this.state.items[0].id
            });

            var firstRadioButton = document.getElementById(this.props.propertyName + '_rb_0');
            if (firstRadioButton) {
                firstRadioButton.focus();
            }
        }
    }

    onListItemChange(item) {
        this.setState({
            selectedListId : item.id
        });
    }

    onListItemConfirmed(event) {
        var attribute = JSON.parse(event.target.attributes.getNamedItem('data-itemvalue').value);
        this.setState({
            showList: false
        });
        this.setValue(attribute.name);
    }

    onTextboxFocus() {
        this.setState({
            showList: false
        });
    }

    onItemContainerFocus() {
        this.setState({
            showList: true
        });
    }

    render() {
        var self = this;
        return (
            <div className='customDropdown'>
                <input id={this.props.propertyName} className={this.state.lifeCycleState == LifeCycleState.Valid ? 'control textbox valid' : 'control textbox error'} value={this.state.value ? this.state.value : ''} onKeyDown={this.onKeyPress} onChange={this.onChange} maxLength={this.state.maxlength} disabled={this.props.disabled == true} onFocus={this.onTextboxFocus}></input>
                {this.state.items && this.state.items.length > 0 && this.state.showList === true &&
                    <div>
                        <div class="backSplash clear" onClick={() => { this.setState({showList: false}) }} />
                        <div className='optionsContainer' onFocus={this.onItemContainerFocus}>
                            {this.state.items.map(function (item, id) {
                                return (
                                    <div key={`itm_${id}`} className='listItem' >
                                        <input class='radio' type='radio' name={self.props.propertyName + '_rb'} id={self.props.propertyName + '_rb_' + id} value={item.id} checked={item.id == self.state.selectedListId} onChange={() => self.onListItemChange(item)} data-itemvalue={JSON.stringify(item)} onKeyPress={self.onListItemConfirmed} /><label className={item.id == self.state.selectedListId ? 'selected' : ''} htmlFor={self.props.propertyName + '_rb_' + id} data-itemvalue={JSON.stringify(item)} onClick={self.onListItemConfirmed}>{item.name}</label><br />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export class AutoCompleteImageControl extends React.Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.currentValue = this.props.value ? this.props.value : null;
        this.currentState = (this.props.required == true && this.currentValue && this.currentValue.length > 0) || this.props.required != true ? LifeCycleState.Valid : LifeCycleState.Invalid;

        this.settings = {
            loader: false,
            mode: "cors",
            cache: 'default',
            credentials: "include",
            redirect: "follow",
            referrer: "no-referrer",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        };

        this.dataAccess = new ApiDataAccess('/data', this.settings);
        this.state = {
            value: this.currentValue,
            maxlength: null,
            lifeCycleState: this.currentState,
            items: [],
            showList: false,
            selectedListId: -1
        }

        this.onKeyPress = this.onKeyPress.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onListItemConfirmed = this.onListItemConfirmed.bind(this);
        this.onListItemChange = this.onListItemChange.bind(this);
        this.onTextboxFocus = this.onTextboxFocus.bind(this);
        this.onItemContainerFocus = this.onItemContainerFocus.bind(this);
    }

    componentDidMount() {
        if (this.props.onStateChange) {
            this.props.onStateChange(this.currentState);
        }
    }

    setValue(value) {
        var newValue = value ? value : null;
        this.setState({
            value: newValue
        });

        this.currentValue = newValue;

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }

    }

    getValue() {
        return this.state.value;
    }

    setLifeCycleState(state) {
        this.currentState = state;

        this.setState({
            lifeCycleState: this.currentState
        });

        if (this.props.onStateChange) {
            this.props.onStateChange(this.currentState);
        }
    }

    isInputValid() {
        var result = true;
        var value = this.currentValue;

        if (this.props.required === true) {
            if (!value || value == null || value.length == 0) {
                result = false;
            }
        }

        if (result == true) {
            this.setLifeCycleState(LifeCycleState.Valid);
        }
        else {
            this.setLifeCycleState(LifeCycleState.Invalid)
        }

        return result;
    }

    onChange(event) {
        var self = this;
        var value = event.target.value;
        var newValue = value;

        self.setValue(newValue);

        if (self.timeout) {
            clearTimeout(self.timeout);
            self.timeout = null;
        }

        if (newValue && newValue.length > 0) {
            self.timeout = setTimeout(() => {

                self.setLifeCycleState(LifeCycleState.Loading);

                var request = {
                    'text': newValue
                };

                self.dataAccess.post(`/lookup/${self.props.lookupDataSource}`, request)
                    .then(data => {
                        self.setLifeCycleState(LifeCycleState.Valid);
                        self.setState({
                            items: data,
                            selectedListId: data && data.length > 0 ? data[0].id : null,
                            showList: true
                        });
                    })
                    .catch(error => {
                        self.setLifeCycleState(LifeCycleState.Invalid);
                        self.setState({
                            items: [],
                            selectedListId: null,
                            showList: false
                        });
                    })
            }, 500);
        }
        else {
            self.setState({
                items: [],
                selectedListId: null,
                showList: false
            });
        }
    }

    onKeyPress(event) {
        var keyCode = event.keyCode;
        if (keyCode === 40 && this.state.items && this.state.items.length > 0) {
            this.setState({
                selectedListId: this.state.items[0].id
            });

            var firstRadioButton = document.getElementById(this.props.propertyName + '_rb_0');
            if (firstRadioButton) {
                firstRadioButton.focus();
            }
        }
        else if (keyCode === 13 && this.state.items && this.state.items.length == 1) {
            var attribute = this.state.items[0];

            this.setState({
                selectedListId: attribute.id
            });

            if (this.props.onSelected) {
                this.props.onSelected(attribute);
            }
        }
    }

    onListItemChange(item) {
        this.setState({
            selectedListId: item.id
        });
    }

    onListItemConfirmed(event) {
        var attribute = JSON.parse(event.target.attributes.getNamedItem('data-itemvalue').value);
        this.setState({
            showList: false
        });
        this.setValue(attribute.name);

        if (this.props.onSelected) {
            this.props.onSelected(attribute);
        }
    }

    onTextboxFocus() {
        this.setState({
            showList: false
        });
    }

    onItemContainerFocus() {
        this.setState({
            showList: true
        });
    }

    render() {
        var self = this;
        return (
            <div className='autocomplete-control image-autocomplete-control'>
                <input id={this.props.propertyName} className={this.state.lifeCycleState == LifeCycleState.Valid ? 'form-control is-valid' : 'form-control is-invalid'} value={this.state.value} onKeyDown={this.onKeyPress} onChange={this.onChange} maxLength={this.state.maxlength} disabled={this.props.disabled == true} onFocus={this.onTextboxFocus} placeholder={this.props.placeholder}></input>
                {this.state.items && this.state.items.length > 0 && this.state.showList === true &&
                    <div className='item-container' onFocus={this.onItemContainerFocus}>
                        <div className='container-border'>
                            {this.state.items.map(function (item, id) {
                                return (
                                    <div className={item.id == self.state.selectedListId ? 'list-item selected' : 'list-item unselected'} >
                                        <input type='radio' name={self.props.propertyName + '_rb'} id={self.props.propertyName + '_rb_' + id} value={item.id} checked={item.id == self.state.selectedListId} onChange={() => self.onListItemChange(item)} data-itemvalue={JSON.stringify(item)} onKeyPress={self.onListItemConfirmed} />
                                        <label htmlFor={self.props.propertyName + '_rb_' + id} data-itemvalue={JSON.stringify(item)} onClick={self.onListItemConfirmed}>
                                            <img src={'/data/images/' + encodeURIComponent(item.imageId) + '/thumbnail'} data-itemvalue={JSON.stringify(item)} /> 
                                            <span data-itemvalue={JSON.stringify(item)}>{item.name}</span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
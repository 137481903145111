import React from 'react';
import { GenericDataAccess } from '../dataControls/GenericDataAccess';
import { HelpControl } from './HelpControl';

export class CheckItem extends React.Component {

    constructor(props) {
        super(props);
        this.value = this.props.value;
        this.state = {
            selected: this.props.selected ? this.props.selected : false
        }

        this.onClick = this.onClick.bind(this)
    }

    onClick() {
        var selected = !this.state.selected;
        this.setState({
            selected: selected
        });

        if (this.props.onClick) {
            this.props.onClick(this.value, selected);
        }

    }

    render() {
        var self = this;
        return (
            <div className='checkItem' onClick={this.onClick}>
                <div className={this.props.selected ? 'selected' : 'default'} >
                    <div className='checkmark'>
                        {this.props.selected &&
                            <div className='glyphicon glyphicon-ok' />
                        }
                    </div>
                    <div className='text'>
                        <span>{this.props.title}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export class MultipleChoiceControl extends React.Component {
    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;
        this.dataAccess = new GenericDataAccess();

        this.datasource = this.props.datasource;
        this.values = this.getDefaultValues();
        this.state = {
            items: new Array(),
            values: this.values
        }

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        var self = this;
        this.dataAccess.getDataSourceData(this.datasource.name, function (result) {

            self.setState({
                items: result,
                value: self.values
            });

            if (self.props.onInit) {
                self.props.onInit(self);
            }

            self.raiseChangeNotification();
        });
    }

    getDefaultValues() {
        var result = new Array();
        if (this.props.value) {            
            this.props.value.forEach(function (item) {
                result.push(item.dataObject2);
            });            
        }
        
        return result;
    }

    setValue(value) {        
        this.setState({
            value: value
        });
    }


    isInputValid() {
        var isValid = true;
        if (this.props.validation && this.props.validation.required == true) {
            if (this.values && (this.values == null || this.values.length == 0)) {
                isValid = false;
            }
        }
        return isValid;
    }

    raiseChangeNotification() {
        if (this.props.onChange) {

            var relationships = new Array();

            this.values.forEach(dataObject2 => {
                var relationship = {
                    dataObject2: dataObject2,
                    dataObject2Id: dataObject2.id
                };
                relationships.push(relationship);
            });


            this.props.onChange(relationships, this.props.id)
        }
    }

    isSelected(item) {
        var isSelected = false;

        this.values.map(function (value) {            
            if (parseInt(item.id) == parseInt(value.id)) {
                isSelected = true;
            }
        });      
        return isSelected;
    }

    onClick(value, selected) {
        var values = this.state.values;
        if (selected) {           
            values.push(value);
        }
        else {
            var index = values.findIndex(item => {
                return item.id === value.id;
            });

            if (index !== -1) {
                values.splice(index, 1);
            }
        }
        
        this.values = values;
        this.setState({
            values: this.values
        });

        this.raiseChangeNotification();
    }


    render() {
        var self = this;
        return (
            <div className='input-control multiple-choice-control'>
                <label htmlFor={this.props.id}>{this.props.display.caption}</label>
                <HelpControl help={this.props.help} />

                <div className="input-group">
                    <div className='input-group-prepend'>
                        <span className={this.isInputValid() ? 'input-group-text glyphicon glyphicon-ok background-green' : 'input-group-text glyphicon glyphicon-remove background-red'}></span>
                    </div>

                    <div className={this.isInputValid() ? 'row item-container is-valid' : 'row item-container is-invalid'}>
                        {this.state.items.map(function (item, index) {
                            return (
                                <div key={self.props.id + '_' + index + '_option'} className="col col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <CheckItem title={item.name} value={item} selected={self.isSelected(item)} onClick={self.onClick} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

import React from 'react';
import { GenericListComponent } from '../dataForms/GenericListComponent';


export class AuditView extends React.Component {


    getFormat(idx) {        
        switch (idx) {            
            case 0:
            case 1:
            case 5:
            case 6:
                return 'mobile-hidden';                
            default:
                return '';
        }        
        return '';
    }


    render() {
        return (
            <div className="view">
                <GenericListComponent dataObject='audit' enableDelete={false} applyFormat={idx => this.getFormat(idx)} />
            </div>
        );
    }
}
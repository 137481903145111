import React from 'react';
import { Tile } from '../../../core/Tile';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { TextboxControl } from '../../../controls/TextboxControl';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { Logger } from "../../../infrastructure/Logger";


export class RegionalSupportConfigurationView extends React.Component {

    render() {
        return (
            <div className="view">
                <div className='tile-container'>
                <Tile title='Core Vocabulary' onClick={() => window.Router.setRoute('/configuration/regions/vocabulary')} />
                    <Tile title='Languages' onClick={() => window.Router.setRoute('/configuration/regions/languages')} />
                </div>
            </div>
        );
    }
}


export class LanguageListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cultures: []
        };
        this.dataAccess = new ApiDataAccess('/data/translations');
        this.onPublishToggle = this.onPublishToggle.bind(this);
        this.loadCultures();
    }


    loadCultures() {
        var self = this;

        this.dataAccess.get('/cultures')
            .then(data => {
                self.setState({
                    cultures: data
                });
            })
            .catch(error => { });
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var url = value === false
            ? '/' + item.id + '/unpublish'
            : '/' + item.id + '/publish'

        this.dataAccess.post(url, null)
            .then(data => {
                self.loadCultures();
                window.Alerts.showInfo(value === false ? 'The language set has been unpublished' : 'The language set has been published', 'Language Set');
                window.Router.setRoute('/configuration/regions/languages');

            })
            .catch(error => {
                self.loadCultures();
                if (error && error.Messages && error.Messages.Length > 0) {
                    window.Alerts.showError(error.Messages[0], 'Language Set');
                }
                else {
                    window.Alerts.showError('Could not publish or unpublish Language Set', 'Language Set');
                }
            });        
    }


    render() {
        var self = this;
        return (
            <div className="view ">
                <h1>Languages</h1>

                <div className="generic-component">
                    <div className="table">
                        <div className='table-header'>
                            <div className='header row background-black color-white'>
                                <div className='col col-10'>Language</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        </div>

                        <div className='body'>
                            {this.state.cultures.map((item, idx) => {
                                return (
                                    <div key={`row_${idx}`} className='row border-bottom'>
                                        <div className='col col-10 clickable' onClick={() => { window.Router.setRoute('/configuration/regions/translations?language=' + encodeURIComponent(item.id)) }}>{item.value}</div>
                                        <div className='col col-2'>
                                            <div className='pull-right'>
                                                <ToggleSwitchControl on={item.isPublished === true} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}



export class TranslationView extends React.Component {

    constructor(props) {
        super(props);

        this.culture = null;
        this.state = {
            cultures: [],
            translations: []
        };

        this.textboxes = [];
        this.dataAccess = new ApiDataAccess('/data/translations');
        this.onBlur = this.onBlur.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.copy = this.copy.bind(this);

    }

    componentDidMount() {
        var self = this;
        var language = self.props.query_language ? self.props.query_language : null;
        if (language) {
            this.loadCultures()
                .then(cultures => {

                    self.culture = cultures.find(culture => {
                        return culture.id == language;
                    });

                    if (!self.culture) {
                        window.Router.setRoute('/configuration/regions/languages');
                    }

                    self.loadTranslations(language)
                        .then(translations => {
                            self.setState({
                                translations: translations,
                                cultures: cultures
                            });
                        });
                });
        }
        else {
            self.loadCoreVocabulary()
                .then(translations => {
                    self.setState({
                        translations: translations,
                        cultures: null
                    });
                });
        }

    }


    loadCultures() {
        var self = this;
        return new Promise((resolve, reject) => {
            self.dataAccess.get('/cultures')
                .then(data => { resolve(data); })
                .catch(error => { reject(error); })
        });
    }


    loadTranslations(cultureString) {
        var self = this;

        self.textboxes = [];
        self.setState({
            translations: null
        });

        return new Promise((resolve, reject) => {

            self.dataAccess.get('/' + cultureString)
                .then(data => { resolve(data); })
                .catch(error => { reject(error); });
        });
    }

    loadCoreVocabulary() {
        var self = this;

        self.textboxes = [];
        self.setState({
            translations: null
        });

        return new Promise((resolve, reject) => {
            self.dataAccess.get('/vocabulary/core')
                .then(data => { resolve(data); })
                .catch(error => { reject(error); });
        });
    }

    onBlur(item, value) {        
        item.name = value;
        this.dataAccess.post('/', item)
            .then(data => {
                Logger.writeDebug('Saved translation'. data);
            })
            .catch(error => {
                window.Alerts.showError('Could not save translation');
            });

    }

    copy(item, idx) {
        var self = this;
        item.culture = { cultureString: this.culture ? this.culture.id : 0 };

        this.dataAccess.post('/translate', item)
            .then(data => {
                self.textboxes[idx].setValue(data.name);
                self.onBlur(item, item.sourceName);
            })
            .catch(error => {
                window.Alerts.showError('Could not save translation');
            })
    }

    onPublishClick() {
        var url = this.culture.isPublished
            ? '/' + this.culture.id + '/unpublish'
            : '/' + this.culture.id + '/publish'

        this.dataAccess.post(url, null)
            .then(data => {
                window.Alerts.showInfo(this.culture.isPublished ? 'The language set has been unpublished' : 'The language set has been published', 'Language Set');
                window.Router.setRoute('/configuration/regions/translations');
            })
            .catch(error => {
                if (error && error.Messages && error.Messages.Length > 0) {
                    window.Alerts.showError(error.Messages[0], 'Language Set');
                }
                else {
                    window.Alerts.showError('Could not publish or unpublish Language Set', 'Language Set');
                }
            });
    }

    render() {
        var self = this;
        return (
            <div className="view view-translation">

                {this.state.translations && this.state.translations.length > 0 &&
                    <div className='translations'>
                        {self.culture && self.culture.value
                            ? <h1>Translations to {self.culture.value}</h1>
                            : <h1>Core Vocabulary</h1>
                        }


                        {self.culture && self.culture.value &&
                            <ToolbarControl renderButtons={() => {
                                return (
                                    <div>
                                        <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/configuration/regions/languages')} />
                                        <ToolbarButton icon={self.culture.isPublished ? 'glyphicon-stop' : 'glyphicon-play'} text={self.culture.isPublished ? 'unpublish' : 'publish'} onClick={self.onPublishClick} />
                                    </div>
                                )
                            }} />
                        }


                        <div className='table'>
                            <div className='table-header'>
                                <div className='row'>
                                    <div className='col col-3'>Type</div>
                                    <div className='col col-4'>Word</div>
                                    <div className='col col-1'></div>
                                    <div className='col col-4'>Translation</div>
                                </div>
                            </div>

                            <div className='body'>
                                {this.state.translations.map((item, idx) => {
                                    return (
                                        <div key={'row' + idx} className='row'>
                                            <div className='col col-3'>{item.sourceType}</div>
                                            <div className='col col-4'>{item.sourceName}</div>
                                            <div className='col col-1'>
                                                {self.culture && self.culture.value &&
                                                    <button className='btn btn-primary btn-sm' onClick={() => { self.copy(item, idx) }}><span className='glyphicon glyphicon-arrow-right'></span></button>
                                                }
                                            </div>
                                            <div className='col col-4'>
                                                <TextboxControl required={true} onBlur={value => { self.onBlur(item, value) }} value={item.name} onInit={item => { self.textboxes.push(item) }} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';


export class ReleaseView extends React.Component {


    

    render() {
        return (
            <div className="view">
                <h1>Releases</h1>
                <ReleaseListView notificationSubscribers={this.props.notificationSubscribers} />
            </div>
        );
    }
}


export class ReleaseListView extends React.Component {

    constructor(props) {
        super(props);

        this.list = null;
        this.dataAccess = new ApiDataAccess('/data/releases');
        this.onPublishToggle = this.onPublishToggle.bind(this);
        this.onNewClick = this.onNewClick.bind(this);

        if (this.props.notificationSubscribers) {
            this.props.notificationSubscribers.registerHandler('ReleaseListView.onCommandReceived', command => { this.onCommandReceived(command, this) });
        }
        
    }

    onCommandReceived(command, self) {       
        if (command.Action == "release-created") {            
            self.list.refresh(null, true);
        }
    }


    onNewClick() {
        var self = this;
        window.Confirmation.show('New Release', 'The release will not be published automatically. You will need to publish the release manually after it becomes available. Are you sure to create a new release?',
            () => {

                self.dataAccess.post('/new', null)
                    .then(data => {
                        window.Alerts.showSuccess('The Release has been scheduled and should become available in a few moments.');
                    })
                    .catch(error => {
                        window.Alerts.showError('Could not schedule the release');
                    });
            });
    }

    onPublishToggle(item, value, cntrl) {

        var self = this;
        window.Confirmation.show('Publish Release', 'Are you sure to publish this release? Configurations will be pushed to all Systems.',
            () => {

                self.dataAccess.put(`/publish/${encodeURIComponent(item.id)}`, null)
                    .then(data => {
                        self.list.refresh(null, true);
                        window.Alerts.showSuccess('Release has been published!');
                    })
                    .catch(error => {
                        self.list.refresh(null, true);
                        window.Alerts.showError('Could not publish release!');
                    });
            },
            () => {
                self.list.refresh(null, true);
            }
        );
    }

    render() {
        var self = this;
        return (
            <div className="release-list-view">
                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-plus' text='New' onClick={self.onNewClick} />
                        </div>
                    )
                }} />

                <PageableDataObjectList dataObject='releases'
                    onInit={cntrl => this.list = cntrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-1'>Build #</div>
                                <div className='col col-3'>Date</div>
                                <div className='col col-6'>User</div>
                                <div className='col col-2'><div className='pull-right'>Published</div></div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row border-bottom'>
                                <div className='col col-1'>{item.version}</div>
                                <div className='col col-3'>{item.createDate}</div>
                                <div className='col col-6'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}

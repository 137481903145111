
export class ValidationData {
    constructor(data) {

        if (!data) {
            throw new Error("ValidationData: data is required");
        }
              
        this.allowProceedOnInvalidInput = data.allowProceedOnInvalidInput ? data.allowProceedOnInvalidInput : false;
        this.maximumLength = data.maximumLength ? data.maximumLength : 0;
        this.regex = data.regex ? data.regex : null;
        this.required = data.required ? data.required : false;
    }
}

export class DataSourceData {
    constructor(data) {

        if (!data) {
            throw new Error("DataSourceData: data is required");
        }

        this.name = data.name;
        this.valueMemberName = data.valueMemberName;
        this.displayMemberName = data.displayMemberName;
        this.defaultSelectedId = data.defaultSelectedId;
    }
}

export class DisplayData {
    constructor(data) {

        if (!data) {
            throw new Error("DisplayData: data is required");
        }

        this.caption = data.caption ? data.caption : null;
        this.title = data.title ? data.title : null;
        this.description = data.description ? data.description : null;
        this.inputType = data.inputType ? data.inputType : null;
        this.columnSize = data.columnSizeId ? data.columnSizeId : 0;
    }
}

export class MetaData {
    constructor(data) {        
        this.validationData = data.validation ? new ValidationData(data.validation) : null;
        this.dataSourceData = data.datasource ? new DataSourceData(data.datasource) : null;
        this.displayData = data.display ? new DisplayData(data.display) : null;
    }

}

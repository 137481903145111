import React from 'react';
import { Capability } from '../infrastructure/Capabilities';

export class LoaderComponent extends Capability {


    show() {
        window.Loader.show();
    }

    hide() {
        window.Loader.hide();
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}
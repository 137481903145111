import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import React from 'react';

export class LookupDataSourceListControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.filter) {
            throw new Error("Filter Property is required");
        }

        if (!this.props.dataSourceName) {
            throw new Error("Data Source Name Property is required");
        }

        if (!this.props.onRenderRow) {
            throw new Error("On Render Row Property is required");
        }


        this.state = {
            items: null
        };

        this.dataAccess = new ApiDataAccess('/data/lookup');
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
        this.refresh();
    }

    refresh() {
        var self = this;

        this.dataAccess.post(`/${self.props.dataSourceName}`, self.props.filter)
            .then(items => {
                self.setState({
                    items: items
                });

                if (self.props.onDataReceived) {
                    self.props.onDataReceived(items)
                }
            })
            .catch(error => {
                window.Alerts.showError('Could not retrieve List');
            })
    }

    render() {
        var self = this;
        return (
            <div className='lookup-data-source-list-control'>
                {this.state.items &&
                    <div className='item-container'>
                        {this.state.items.map(function (item, id) {
                            return (
                                <div key={`lci_${id}`} className='row'>
                                    {self.props.onRenderRow(item)}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        );
    }
}
import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { DataManager } from '../../../dataControls/DataManager';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { ImageLabel } from '../../../core/ImageTitle';
import { DropdownDataControl } from '../../../dataControls/DropdownDataControl';
import { TextboxDataControl } from '../../../dataControls/TextboxDataControl';
import { ChecklistDataControl } from '../../../dataControls/ChecklistDataControls';
import { MaterialEditorComponent } from './MaterialEditorComponent';
import { StructureSpecPropertyEditor } from './StructureSpecPropertyEditor';
import { EditableSortableListDataControl } from '../../../dataControls/EditableSortableListDataControl';
import { TextboxControl } from '../../../controls/TextboxControl';
import { Card, Row, Column } from '../../../components/Containers';
import { Logger } from "../../../infrastructure/Logger";

export class StructureSpecEditorComponent extends React.Component {

    constructor(props) {
        super(props);

        /*
        if (!this.props.dataId) {
            throw new Error("Data Id Property is required");
        }
        */

        this.state = {
            isValid: false,
            jobTemplate: null,
            structureSpec: null
        };

        this.dataAccess = new ApiDataAccess('/data');
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onShowPouchCalculatorClick = this.onShowPouchCalculatorClick.bind(this);

        this.dataManager = new DataManager('StructureSpecs', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        var self = this;
        window.Loader.show();
        this.dataManager.init()
            .then(dataManager => {
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(data => {
                            //2) bind Controls            

                            self.setState({
                                structureSpec: data,
                                jobTemplate: data.navJobTemplate
                            });
                            dataManager.bindControls();
                            window.Loader.hide();
                        })
                        .catch(() => {
                            self.loadJobTemplate();
                            dataManager.bindControls();
                            window.Loader.hide();
                        });
                }
                else {
                    dataManager.bindControls();
                    window.Loader.hide();
                }

            });
    }

    loadJobTemplate() {
        var self = this;

        this.dataAccess.get(`/navJobTemplates/${encodeURIComponent(self.props.dataId)}`)
            .then(data => {
                self.setState({
                    jobTemplate: data
                });
            })
            .catch(error => {
                Logger.writeError("Could not load Job Template", error);
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    onShowPouchCalculatorClick() {
        var self = this;
        window.Modal.show(function (popup) {
            return (
                <div>
                    <PouchAreaCalculatorPopupContent popup={popup} onCalculated={area => { self.dataManager.setValue('pouchAreaMaxValue', area) }} />                    
                </div>
            )
        }, 'Pouch Area Calculation')     
    }


    onSaveClick() {
        var self = this;

        if (this.props.dataId) {
            self.dataManager.dataObject.id = this.props.dataId;
            self.dataManager.dataObject.name = this.state.jobTemplate.name;
        }

        self.dataManager.saveContext()
            .then(data => {

                if (this.props.dataId) {
                    window.Alerts.showSuccess('Structure Spec has been saved');
                    window.Router.setRoute('/structurespecs/' + encodeURIComponent(self.dataManager.dataObject.id));
                }
                else {
                    window.Alerts.showSuccess('Structure Spec ' + data.navJobTemplate.name + ' has been saved');
                    window.Router.setRoute('/structurespecs/' + encodeURIComponent(data.id));
                }

            })
            .catch(error => {
                Logger.writeError(`StructureSpecEditorComponent.onSaveClick`, error);
                window.Alerts.showError('An error occurred while saving the Structure Spec');
            });

    }


    render() {
        var self = this;
        return (
            <div className='product product-editor-component'>

                {this.props.dataId && 
                    <h2>{'SPEC-' + this.props.dataId}</h2>
                }
                

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/structurespecs')} />
                        </div>
                    )
                }} />

                <Card title={this.state.jobTemplate ? this.state.jobTemplate.name : ''} subtitle='General information for this structure spec:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='description'>Secondary Name</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='secondaryName' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='description'>Description</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='description' multiline={true} />
                        </div>
                    </div>


                </Card>


                <Card title='Films and Structure' subtitle='Select the films and adhesives that are used to manufacture this structure:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='structure'>Films & Structure</label>
                            {window.CONFIG.JOB_TEMPLATES_DISABLED
                                ? <MaterialEditorComponent dataManager={this.dataManager} propertyName='items' />
                                : <EditableSortableListDataControl dataManager={this.dataManager} propertyName='films' maxItems={4} />
                            }

                        </div>
                    </div>
                </Card>


                <Card title='Features' subtitle='Select the features that are associated with this structure:'>
                    <Row>
                        <Column>
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='features' />
                        </Column>
                        <Column>
                        </Column>
                    </Row>
                </Card>


                <Card title='Properties' subtitle='Select the physical properties that describe this structure:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <StructureSpecPropertyEditor dataManager={this.dataManager} propertyName='properties' structureSpecId={this.props.dataId} />
                        </div>
                    </div>
                </Card>


                <Card title='Appearance & Sustainability' subtitle='Select the attributes to describe the look and feel of this structure:'>
                    <Row>
                        <Column>
                            <ImageLabel text='Finish Type' for='finishTypeId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='finishTypeId' />
                        </Column>

                        <Column>
                            <ImageLabel text='Transparency' for='transparencyId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='transparencyId' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Sustainability' for='sustainabilityId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='sustainabilityId' />
                        </Column>

                        <Column>
                            <ImageLabel text='Film Width' for='filmWidthId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='filmWidthId' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Barrier Type' for='barrierTypeId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='barrierTypeId' />
                        </Column>

                        <Column>
                        </Column>
                    </Row>
                </Card>


                <Card title='Recommended Color Specs' subtitle='Select the colors specs that should be used by default for this structure:'>
                    <Row>
                        <Column>
                            <ImageLabel text='Default' for='colorSpecId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='colorSpecId' />
                        </Column>


                        <Column>
                            <ImageLabel text='Using extended Gamut Scheme' for='colorSpecGamutId' />
                            <DropdownDataControl dataManager={this.dataManager} propertyName='colorSpecGamutId' />
                        </Column>
                    </Row>
                </Card>


                <Card title='Manufacturing and Application' subtitle='Provide manufacturing related restrictions:'>

                    <Row>
                        <Column>
                            <ImageLabel text='Supported Converting Methods' for='convertingMethods' />
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='convertingMethods' />
                        </Column>

                        <Column>
                            <ImageLabel text='Supported Seal Types' for='sealTypes' />
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='sealTypes' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Supported Fill Types' for='fillTypes' />
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='fillTypes' />
                        </Column>

                        <Column>
                            <ImageLabel text='Supported Seal Wrap Types' for='sealTypes' />
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='sealWrapTypes' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <ImageLabel text='Applications' for='applications' />
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='applications' />
                        </Column>

                        <Column>
                            <ImageLabel text='Manufacturing Options' for='manufacturingOptions' />
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='manufacturingOptions' />
                        </Column>
                    </Row>
                </Card>


                <Card title='Limitations' subtitle='Enter the weight and size restrictions:'>

                    <Row>
                        <Column>
                            <ImageLabel text='Max. Pouch Fill Weight' for='fillWeightMaxValue' />
                            <div className='flex'>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='fillWeightMaxValue' />
                                <DropdownDataControl dataManager={this.dataManager} propertyName='fillWeightUomId' />
                            </div>
                        </Column>


                        <Column>
                            <ImageLabel text='Max. Pouch Dimensions' for='fillWeightMaxValue' />
                            <div className='flex'>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='pouchAreaMaxValue' />
                                <DropdownDataControl dataManager={this.dataManager} propertyName='pouchAreaUomId' />
                            </div>
                            <button className="btn btn-primary" onClick={self.onShowPouchCalculatorClick}><span className='glyphicon glyphicon-option-horizontal'></span></button>

                        </Column>
                    </Row>

                </Card>



                <Card title='Sales Channels' subtitle='Define the sales channels for which this material should be available:'>
                    <ChecklistDataControl dataManager={this.dataManager} propertyName='salesChannels' />
                </Card>


                <button className='btn btn-lg btn-primary' disabled={!this.state.isValid} onClick={this.onSaveClick}>Save</button>

            </div>
        );
    }
}

export class PouchAreaCalculatorPopupContent extends React.Component {

    constructor(props) {
        super(props);

        this.cntrLength = null;
        this.cntrWidth = null;
        this.cntrGusset = null;

        this.onCalculateClick = this.onCalculateClick.bind(this);
    }

    onCalculateClick() {

        var length = isNaN(parseFloat(this.cntrLength.getValue())) ? 0 : parseFloat(this.cntrLength.getValue());
        var width = isNaN(parseFloat(this.cntrWidth.getValue())) ? 0 : parseFloat(this.cntrWidth.getValue());
        var gusset = isNaN(parseFloat(this.cntrGusset.getValue())) ? 0 : parseFloat(this.cntrGusset.getValue());
        var area = (length * width) * 2 + (gusset * width);
        this.props.popup.close();

        if (this.props.onCalculated) {
            this.props.onCalculated(area);
        }

    }
    

    render() {
        return (
            <div className="view">
                <div className='row'>
                    <div className='col-sm-12 col-lg-2'><label>Length</label></div>
                    <div className='col-sm-12 col-lg-10'><TextboxControl id='pouchLength' onInit={ctrl => { this.cntrLength = ctrl }} regex='[0-9]{0,8}(\.[0-9]{0,4})?' required={true}/></div>
                </div>                
                <div className='row'>
                    <div className='col-sm-12 col-lg-2'><label>Width</label></div>
                    <div className='col-sm-12 col-lg-10'><TextboxControl id='pouchWidth' onInit={ctrl => { this.cntrWidth = ctrl }} regex='[0-9]{0,8}(\.[0-9]{0,4})?' required={true}/></div>
                </div>                
                <div className='row'>
                    <div className='col-sm-12 col-lg-2'><label>Gusset</label></div>
                    <div className='col-sm-12 col-lg-10'><TextboxControl id='pouchGusset' onInit={ctrl => { this.cntrGusset = ctrl }} regex='[0-9]{0,8}(\.[0-9]{0,4})?'/></div>
                </div>                


                <button className='btn btn-primary' onClick={this.onCalculateClick}>Calculate</button>
                <button className='btn btn-danger pull-right' onClick={this.props.popup.close}>Cancel</button>
                
            </div>
        );
    }
}
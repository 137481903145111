import React from 'react';
import { LookupDataSourceDataAccess } from '../../../infrastructure/DataSourceDataAccess';
import { Card } from '../../../components/Containers';
import { Logger } from "../../../infrastructure/Logger";

export class ProductRulesListComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.productId) {
            throw new Error("ProductId Property is required");
        }
        this.productId = this.props.productId;
        this.dataSourceDataAccess = new LookupDataSourceDataAccess("rulesByProduct");

        this.state = {
            rules: []
        }

    }

    componentDidMount() {
        var self = this;

        var request = {
            number: this.productId
        }

        this.dataSourceDataAccess.getDataSource(request)
            .then(dataSource => {
                self.setState({
                    rules: dataSource.getItems()
                });
            })
            .catch(ex => {
                Logger.writeError('Could not retrieve rules', ex);
            });
    }

    render() {
        var self = this;
        return (
            <Card title='Feature Restrictions' subtitle='The following feature restrictions are potentially assoicated with this product:'>
                {self.state.rules && self.state.rules.length > 0
                    ? <div className='filtered-product-list'>
                        {self.state.rules.map(function (item, id) {
                            return (
                                <div key={`itm_${id}`} className="flex border-bottom" onClick={() => window.Router.setRoute('/rules/' + encodeURIComponent(item.id))}>
                                    {item.isPublished
                                        ? <span className='glyphicon glyphicon-play color-green clickable' title='Published'> </span>
                                        : <span className='glyphicon glyphicon-stop color-red clickable' title='Unpublished'> </span>
                                    }
                                    <div className={item.isPublished ? 'content clickable' : 'content clickable color-gray'}>{item.name}</div>
                                </div>
                            )
                        })}
                    </div>
                    : <div>There are no Feature Restrictions for Packaging this Product</div>
                }

                        
            </Card>
        );
    }
}
import React from 'react';
import { Guid } from './Utils';
import { Logger } from "../infrastructure/Logger";

var ALERT_TYPE = {
    Info: 0,
    Success: 1,
    Warn: 2,
    Error: 3
}


window.Alerts = (function (alerts) {
    alerts.controls = [];

    alerts.register = function (cntrl) {
        alerts.controls.push(cntrl);
    }

    alerts.showInfo = function (content, title, autoHide, url) {
        alerts.controls.forEach(function (cntr) {
            var options = {
                title: title ? title : null,
                content: content ? content : null,
                type: ALERT_TYPE.Info,
                autoHide: autoHide ? autoHide === true : true,
                url: url ? url : null
            };
            cntr.show(options);
        });
    }

    alerts.showSuccess = function (content, title, autoHide, url) {
        alerts.controls.forEach(function (cntr) {
            var options = {
                title: title ? title : null,
                content: content ? content : null,
                type: ALERT_TYPE.Success,
                autoHide: autoHide ? autoHide === true : true,
                url: url ? url : null
            };
            cntr.show(options);
        });
    }

    alerts.showWarn = function (content, title, autoHide, url) {
        alerts.controls.forEach(function (cntr) {
            var options = {
                title: title ? title : null,
                content: content ? content : null,
                type: ALERT_TYPE.Warn,
                autoHide: autoHide ? autoHide === true : true,
                url: url ? url : null
            };
            cntr.show(options);
        });
    }

    alerts.showError = function (content, title, autoHide, url) {
        alerts.controls.forEach(function (cntr) {
            var options = {
                title: title ? title : null,
                content: content ? content : null,
                type: ALERT_TYPE.Error,
                autoHide: autoHide ? autoHide === true : false,
                url: url ? url : null
            };
            cntr.show(options);
        });
    }


    return alerts;
}(window.Alerts = window.Alerts || {}));


export class LegacyAlertsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: []
        };

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        Logger.writeDebug('AlertsComponent: componentDidMount');
        window.Alerts.register(this);
    }

    show(options) {
        Logger.writeDebug('AlertsComponent: show', options);
        var self = this;

        options.guid = Guid.New();

        if (options.autoHide) {
            options.onExpired = setTimeout(function () {
                self.hide(options)
            }, 8000)
        }

        var alerts = self.state.alerts;
        alerts.push(options);
        this.setState(alerts);
    }

    hide(options) {
        Logger.writeDebug('AlertsComponent: hide', options);
        var self = this;
        var alerts = this.state.alerts;
        this.findAlert(options.guid, function (index) {
            alerts.splice(index, 1);
            self.setState(alerts);
        });
    }

    onClick(options) {
        this.hide(options);
        if (options.url) {
            document.location = options.url;
        }
    }


    findAlert(guid, onFound) {
        var alerts = this.state.alerts;
        alerts.find((options, index) => {
            if (options && options.guid === guid) {
                onFound(index);
                return true;
            }
        });
    }

    getStyle(options) {
        var result = 'alert overlapped bounce-in-top';

        if (!options.autoHide) {
            result += ' pointer'
        }

        switch (options.type) {
            case ALERT_TYPE.Info:
                result += ' background-blue color-white clickable';
                break;
            case ALERT_TYPE.Success:
                result += ' background-green color-white clickable';
                break;
            case ALERT_TYPE.Warn:
                result += ' background-orange color-black clickable';
                break;
            case ALERT_TYPE.Error:
                result += ' background-orange color-white clickable';
                break;
            default:
                result += ' background-blue color-white clickable';
        }
        return result;
    }

    render() {
        var self = this;
        return (
            <div className='alerts-component col-xs-12 col-sm-12 col-md-4 col-lg-3'>
                {this.state.alerts.map(function (options, idx) {
                    return (
                        <div key={`alt_${idx}`} className={self.getStyle(options)} onClick={() => self.onClick(options)}>
                            {options.title && options.title.length > 0 &&
                                <div className='alert-title'>
                                    <span>{options.title}</span>
                                    <img src="/images/logo_tiny.png" className='pull-right' />
                                </div>
                            }
                            <div className='alert-content'>
                                <span>{options.content}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

import React, { useState, useContext, useEffect } from 'react';
import { User, Camera, MessageSquare, Twitter, Info, LogOut, XSquare, Zap } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ToggleContainer, CodeBlock } from '../infrastructure/Containers';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext, SettingsContext, UserContext } from '../infrastructure/Contexts';
import Cookies from 'universal-cookie';
import { Logger } from "../infrastructure/Logger";
import { TextboxControl } from "../controls/TextboxControl";


export const Layout = props => {

    

    return (
        <div id='layout'>
            <Header />
            <div className='container'>
                {props.children}
                {/*<ReleaseNotesModal notificationSubscribers={props.notificationSubscribers} />*/}
            </div>
            <ExportCompletedModal notificationSubscribers={props.notificationSubscribers} />
        </div>
    );
}

export const ExportCompletedModal = props => {

    const [productGroupExportCompleted, setProductGroupExportCompleted] = useState(null);

    useEffect(() => {        
        props.notificationSubscribers.registerHandler("ExportCompletedModal.onCommandReceived", onCommandReceived);
    }, []);

    const onCommandReceived = command => {
        if (command.Action == "ProductGroupExportCompleted") {            
            setProductGroupExportCompleted(command.Properties);          
        }        
    }

    const onDownloadClick = url => {
        var element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', url);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setProductGroupExportCompleted(null);
    }

    return (
        <div>            
            {productGroupExportCompleted && 
                <div className='notificationPopup'>
                    <div className='title'>Export Completed</div>
                    <div className='content'>
                        <p>Your requested product groups export has been completed.</p>
                        <p>Please press the DOWNLOAD button below to continue.</p>
                        <p>Depending on the file size the download may take some seconds.</p>

                        <button className='btn btn-primary' type='submit' onClick={() => { onDownloadClick(productGroupExportCompleted.url) }}>Download</button>
                        <button className='btn btn-secondary pull-right' onClick={() => { setProductGroupExportCompleted(null) }}>Cancel</button>
                    </div>
                </div>
            }
        </div>
    );
}




export const Header = props => {

    return (
        <div className='header background-white'>
            <Colorbar />
            <img className='logo clickable' src='api/scripts/css/images/png/epac_logo.png' onClick={() => { window.Router.setRoute('/') }} />
            <DropdownMenu />
        </div>
    );
}


export const Colorbar = props => {
    return (
        <div className='colorbar'>
            <div className='rectangle background-navy' />
            <div className='rectangle background-orange' />
            <div className='rectangle background-green' />
            <div className='rectangle background-blue' />
            <div className='rectangle background-purple' />
            <div className='rectangle background-yellow' />
        </div>
    );
}


export const DropdownMenu = props => {
    const cookies = new Cookies();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader)
    const modal = capabilitiesContext.getCapability(CapabilityName.Modal)
    const [visible, setVisible] = useState(false);

    const getUserName = () => {

        return `${userContext.firstName} ${userContext.lastName}`;
    }

    const onMyProfileClick = () => {
        setVisible(false);
        loader.show();
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get('/userDetails')
            .then(profile => {                
                loader.hide();
                modal.show(
                    modal => { return (<MyProfileModal modal={modal} profile={profile} capabilitiesContext={capabilitiesContext} settingsContext={settingsContext} />); },
                    modal => { return (<div className='close' onClick={() => { modal.hide() }}>Close</div>); },
                    modal => { return (<h2>My Profile</h2>); }
                );
            });
    }

    const onFeedbackClick = () => {

        modal.show(
            modal => { return (<FeedbackModal modal={modal} />); },
            modal => { },
            modal => { return (<h2>Your Feedback</h2>); }
        )
    }


    const onHelpClick = () => {
        const clientId = settingsContext['Security:ClientId'];
        setVisible(false);
        loader.show();
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get(`/cms/help-${clientId}`)
            .then(article => {
                loader.hide();
                modal.show(
                    modal => { return (<div className='article' dangerouslySetInnerHTML={{ __html: article.html }} />); },
                    modal => { return (<div className='close' onClick={() => { modal.hide() }}>Close</div>); },
                    modal => { return (<h2>{article.name}</h2>); }
                );
            });
    }

    const onReleaseNotesClick = () => {
        const version = settingsContext['application:Version'];
        const clientId = settingsContext['security:ClientId'];
        setVisible(false);
        loader.show();
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get(`/cms/release_${clientId}`)
            .then(article => {
                loader.hide();
                modal.show(
                    modal => { return (<div className='article' dangerouslySetInnerHTML={{ __html: article.html }} />); },
                    modal => { return (<div className='close' onClick={() => { modal.hide() }}>Close</div>); },
                    modal => { return (<h2>Release Notes {version}</h2>); }
                );
            });
    }

    return (
        <div className='contact-pop-over'>
            <button className='btn btn-primary' onClick={() => { setVisible(!visible) }}><User className='icon' /><span className='caption'>{getUserName()}</span></button>
            {visible &&
                <div>
                    <div className='backSplash clear' onClick={() => { setVisible(false); }} />
                    <div className='pop-over'>
                        <div className='triangle'></div>
                        <div className='menu'>
                            <div className='menu-item' onClick={() => { onMyProfileClick() }}><Camera className='icon' /><span>My Profile</span></div>
                            <div className='menu-item' onClick={() => { onFeedbackClick() }}><MessageSquare className='icon' /><span>Feedback</span></div>
                            <div className='menu-separator' />                            
                            <div className='menu-item' onClick={() => { window.Router.setRoute('/home/webhooks') }}><Zap className='icon' /><span>Web Hooks</span></div>
                            <div className='menu-separator' />
                            <div className='menu-item' onClick={() => { onReleaseNotesClick() }}><Twitter className='icon' /><span>Release Notes</span></div>
                            <div className='menu-item' onClick={() => { onHelpClick() }} ><Info className='icon' /><span>Help</span></div>
                            <div className='menu-separator' />
                            <div className='menu-item' onClick={() => { document.location = '/account/signout' }}><LogOut className='icon' /><span>Logout</span></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export const FeedbackModal = props => {

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const onSubmitClick = () => {
        if (title && content) {
            props.modal.hide();

            var dataAccess = new ApiDataAccess('api/feedback');
            var request = {
                title: title,
                content: content
            };
            dataAccess.post('/', request)
                .then(result => {
                    if (result == true) {
                        window.Alerts.showSuccess('Thank you for submitting your feedback!', 'Feedback');
                    }
                    else {
                        window.Alerts.ShowError('Your feedback was not sumbitted. Please try again.', 'Feedback');
                    }                    
                })
                .catch(error => {
                    window.Alerts.ShowError('Your feedback was not sumbitted. Please try again.', 'Feedback');
                });
        }
    }

    return (
        <div>

            <label htmlFor='title'>Subject</label>
            <p>What is the subject for your feedback?</p>
            <TextboxControl id='title' required={true} onChange={value => { setTitle(value); } } />

            <label htmlFor='title'>Description</label>
            <p>Please describe with a couple words your request:</p>
            <TextboxControl id='title' required={true} textarea={true} onChange={value => { setContent(value); }} />

            <div className='default-space'></div>
            <div className='close' onClick={() => { onSubmitClick() }}>Submit</div>
        </div>
    )
}


export const MyProfileModal = props => {
   
    const [token, setToken] = useState(null);

    const settings = props.settingsContext;
    const notifications = props.capabilitiesContext.getCapability(CapabilityName.Notification);

    const refreshSecurityToken = () => {
        setToken(null);

        var dataAccess = new ApiDataAccess('token');
        dataAccess.get('/')
            .then(token => {
                notifications.add('info', 'Token', 'Access Token has been retrieved');
                setToken(token);
            })
    }

    return (
        <div className='profile'>
            <div className="row">
                <div className="column w2"><label>First Name</label></div>
                <div className="column w2">{props.profile.user.firstName}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Last Name</label></div>
                <div className="column w2">{props.profile.user.lastName}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>eMail</label></div>
                <div className="column w2">{props.profile.user.email}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Locations</label></div>
                <div className="column w2">
                    {props.profile.location.locationName}
                    {props.profile.locations && props.profile.locations.length > 1 &&
                        <ToggleContainer title={'more...'}>
                            {props.profile.locations.map((location, idx) => {
                                if (location.locationId !== props.profile.location.locationId) {
                                    return (
                                        <p key={'loc' + idx}>{location.locationName}</p>
                                    )
                                }
                                else {
                                    return '';
                                }
                            })}
                        </ToggleContainer>
                    }
                </div>
            </div>
            <div className="row">
                <div className="column w2"><label>Role</label></div>
                <div className="column w2">{props.profile.role}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Type</label></div>
                <div className="column w2">{props.profile.userType}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Health Check</label></div>
                <div className="column w2"><a href={settings["health:Url"]} rel="noreferrer" target='_blank'>click here</a></div>
            </div>
            <div className="row">
                <div className="column w2"><label>Security Token</label></div>
                <div className="column w2">
                    <a onClick={() => { refreshSecurityToken() }}>Refresh...</a>
                    {token &&
                        <div>
                            <CodeBlock onCopyToClipboard={() => { return token.access_token; }}>{token.access_token}</CodeBlock>
                            <CodeBlock>Valid From: {token.valid_from}</CodeBlock>
                            <CodeBlock>Valid To: {token.valid_to}</CodeBlock>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


export const ReleaseNotesModal = props => {
    const settingsContext = useContext(SettingsContext);
    const cookies = new Cookies();
    const currentVersion = cookies.get('version');
    const [visible, setVisible] = useState(false);
    const [article, setArticle] = useState(null);
    const version = settingsContext['Application:Version'];
    const clientId = settingsContext['Security:ClientId'];

   
    if (version !== currentVersion && !visible) {
        cookies.set('version', version);
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get(`/cms/release_${clientId}`)
            .then(data => {
                setArticle(data);
                setVisible(true);
            });
    }
    else {
        Logger.writeDebug('User is aware of current release', currentVersion);
    }
   
    const hide = () => {
        setVisible(false);
    }

    return (
        <div>
            {visible &&
                <div>
                    <div className='backSplash' />
                    <div className='modal'>
                        <div className='header'>
                            <h2>Release Notes</h2>
                            <XSquare className='icon btn-close' onClick={() => { hide(); }} />
                        </div>

                        <div className='content'>
                            <div className='article' dangerouslySetInnerHTML={{ __html: article.html }} />
                        </div>

                        <div className='footer'>
                            <div className='close' onClick={() => { hide() }}>Close</div>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

import React from 'react';
import { Card } from '../../../components/Containers';
import { RuleEditorTitle } from './RuleEditorTitle';

export class RuleTriggerCard extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleTrigger) {
            throw new Error("ruleTrigger Property is required");
        }

        this.trigger = this.props.ruleTrigger;
        this.onDeleteClick = this.onDeleteClick.bind(this);

    }

    onDeleteClick() {
        if (this.props.onDeleteClick) {
            this.props.onDeleteClick(this.trigger);
        }
    }

    isTuple() {
        //console.log(this.trigger);
    }

    render() {
        return (
            <Card title={<RuleEditorTitle title={this.trigger.definition.name} button={<span className='glyphicon glyphicon-remove'></span>} disabled={this.props.preventEdit} onClick={() => this.onDeleteClick()} />} noIcon={true} subtitle={`Any of following ${this.trigger.definition.name} selection(s) will trigger this rule:`}>

                <div className="card-body">

                    {this.trigger.definition.inputControlType !== 2 &&
                        <div>
                            {this.trigger.items.map(function (item, idx) {
                                return (
                                    <p key={`tri_${idx}`} className="card-text">{item.name}</p>
                                );
                            })}
                        </div>
                    }

                    {this.trigger.definition.inputControlType === 2 && this.trigger.items && this.trigger.items.length > 0 &&
                        <div>
                            <p className="card-text">{this.trigger.items[0].from} to {this.trigger.items[0].to} {this.trigger.items[0].uom}</p>
                        </div>
                    }
                </div>                            
            </Card>

        );
    }
}
import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { LookupDataSourceDataAccess } from '../../../infrastructure/DataSourceDataAccess';
import { ImageTitle, ImageLabel } from '../../../core/ImageTitle';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { DropdownControl } from '../../../controls/DropdownControl';
import { ProductGroup } from './ProductGroup';
import { FilteredProductList } from './ProductGroupEditorComponent';
import { DataManager } from '../../../dataControls/DataManager';
import { LabelDataControl } from '../../../dataControls/LabelDataControl';
import { RelationshipItemsDisplayDataControl } from '../../../dataControls/RelationshipItemsDisplayDataControl';
import { Card } from '../../../components/Containers';
import { Spacer } from '../../../components/Containers';
import { Logger } from "../../../infrastructure/Logger";

export class ProductGroupViewComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataId) {
            throw new Error("DataId Property is required");
        }
        this.productGroupId = this.props.dataId;

        this.state = {
            isValid: false,
            products: [],
            data: {},
            isPublished: false
        };
        this.structureSpecList = null;

        this.productGroup = new ProductGroup(this.props.dataId);
        this.dataAccess = new ApiDataAccess('/data');
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.mergeGroups = this.mergeGroups.bind(this);
        this.publishProducts = this.publishProducts.bind(this);
        this.onDiscontinueClick = this.onDiscontinueClick.bind(this);

        this.dataManager = new DataManager('productGroups', this.onControlBound, this.onControlUnbound, this.onValidated);
        this.productLookupDataSource = new LookupDataSourceDataAccess("productsByProductGroup");
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        var self = this;
        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (self.props.dataId) {
                    dataManager.loadContext(self.props.dataId)
                        .then(data => {                                                        
                            self.setState({
                                data: data,
                                isPublished: data.isPublished
                            });

                            //2) bind Controls                            
                            dataManager.bindControls();

                            self.productLookupDataSource.getDataSource({ number: self.props.dataId })
                                .then(dataSource => {
                                    self.setState({
                                        products: dataSource.getItems()
                                    });
                                });
                        })
                        .catch(error => {
                            Logger.writeError("Could not load Product Group", error);
                            window.Router.setNotFound();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                }

                //3) onControlBound --> Set Value
            });

    }

    getRelationshipValues(relationship) {        
        var result = [];
        if (relationship && relationship.length > 0) {
            relationship.forEach(item => {                                
                result.push(item.dataObject2);
            });
        }
        return result;
    }


    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }


    deleteItem() {
        var self = this;
        window.Confirmation.show('Delete', 'Are you sure to delete this item?',
            function () {

                self.dataAccess.delete(`/productGroups/${encodeURIComponent(self.props.dataId)}`)
                    .then(data => {
                        window.Alerts.showSuccess('Packaging Group' + data.name + ' has been deleted');
                        window.Router.setRoute('/packaginggroups');
                    })
                    .catch(ex => {
                        if (ex.Messages && ex.Messages.length > 0) {
                            window.Alerts.showInfo(ex.Messages[0]);
                        }  
                    });
            }
        );
    }

    onSaveStructureSpecs(popup) {
        var self = this;
        popup.close();     

        self.dataManager.saveContext()
            .then(data => {
                window.Alerts.showSuccess('Structure Spec Assignments have been saved');                
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while saving the Structure Spec Assignments');
            });
    }

    onPublishClick() {
        var self = this;
        var isPublished = this.state.isPublished;
        if (isPublished) {
            this.productGroup.publish(false)
                .then(data => {
                    self.refresh();
                    window.Alerts.showSuccess('Packaging Group ' + data.name + ' has been unpublished');
                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }    
                });
        }
        else {
            this.productGroup.publish(true)
                .then(data => {
                    self.refresh();
                    window.Alerts.showSuccess('Packaging Group ' + data.name + ' has been published');
                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }    
                });
        }        
    }

    mergeGroups() {
        var self = this;
        window.Modal.show(function (popup) {
            return (
                <MergePopupContent popup={popup} group={self.state.data} />
            )
        }, 'Merge Packaging Groups');
    }

    publishProducts(published) {
        var self = this;

        var url = published
            ? `/productGroups/${this.productGroupId}/products/publish`
            : `/productGroups/${this.productGroupId}/products/unpublish`;

        this.dataAccess.post(url, null)
            .then(data => {
                if (published) {
                    window.Alerts.showSuccess('All Products have been published');
                }
                else {
                    window.Alerts.showSuccess('All Products have been unpublished');
                }
                self.refresh();
            })
            .catch(error => {
                window.Alerts.showError('An error occurred. Please try again.');
            });

    }

    onDiscontinueClick(item) {
        var self = this;
        window.Modal.show(function (popup) {
            return (
                <DiscontinueAssignmentPopupContent popup={popup} item={item} onScheduled={() => { self.structureSpecList.refresh(); }} />
            )
        }, 'Discontinue Material: ')
    }

    restoreStructureSpecClick(item) {
        var self = this;
        window.Confirmation.show(`Reset Discontiuation for ${item.structureSpecName}`, 'This will cancel the schedule for discontinuation for this structure spec. Do you want to continue?',
            () => {
                var dataAccess = new ApiDataAccess('/data');
                dataAccess.post(`/productGroups/${self.productGroupId}/structureSpec/${item.structureSpecId}/discontinue`, null)
                    .then(data => { self.structureSpecList.refresh(); })
                    .catch(error => { window.Alerts.showError('The schedule was cancelled due to an error!'); })
            }
        );
    }

    render() {
        var self = this;
        return (
            <div className='product product-view-component'>

                <ImageTitle text={this.state.data.name} />

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/packaginggroups')} />
                            <ToolbarButton className='mobile-hidden' icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/packaginggroups/new')} />
                            <ToolbarButton className='mobile-hidden' icon='glyphicon-pencil' text='Edit' onClick={() => window.Router.setRoute('/packaginggroups/' + encodeURIComponent(this.props.dataId) + '/edit')} />
                            <ToolbarButton icon={this.state.isPublished ? 'glyphicon-stop' : 'glyphicon-play'} text={this.state.isPublished ? 'unpublish' : 'publish'} onClick={this.onPublishClick} /> 
                            <ToolbarButton className='mobile-hidden' icon='glyphicon-link' text='Merge' onClick={() => this.mergeGroups()} />
                            <ToolbarButton className='mobile-hidden' icon='glyphicon-trash' text='Delete' onClick={() => this.deleteItem()} />
                        </div>
                    )
                }} />

                <Card title='General' subtitle='Find below the general Packaging Group information:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <label>Description</label>
                            <p className='content'><LabelDataControl dataManager={this.dataManager} propertyName='description' /></p>
                        </div>
                    </div>
                </Card>


                <Card title='Group Attributes' subtitle='The following product attributes define the associated products of this group:'>
                    <div className='table'>
                        <div className='body'>
                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Consistency' for='consistencies' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='consistencies' display={['name']} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Moisture' for='moistures' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='moistures' display={['name']} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Aroma' for='aromas' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='aromas' display={['name']} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Light Sensitivity' for='lightSensitivities' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='lightSensitivities' display={['name']} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Product Shelf Life' for='shelfLifeTimes' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='shelfLifeTimes' display={['name']} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Child Resistance' for='childResistances' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='childResistances' display={['name']} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Aggressiveness' for='aggressivnesses' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='aggressivnesses' display={['name']} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Emission' for='emissions' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='emissions' display={['name']} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <ImageLabel text='Packaging Temperature' for='temperatures' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='temperatures' display={['name']} />
                                </div>

                                <div className='col col-6'>
                                    <ImageLabel text='Oxygen Sensitivity' for='oxygenSensitivities' />
                                    <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='oxygenSensitivities' display={['name']} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Card>

                <Card title='Sales Channels' subtitle='These structure specs are availble per sales channel:'>
                    <ProductGroupSalesChannelsList productGroupId={this.productGroupId} />
                </Card>


                <Card title='Structure Specs' subtitle='The following structure specs are associated with the group:'>
                    <ProductGroupStructureSpecsList onInit={ctrl => self.structureSpecList = ctrl} productGroup={self.productGroupId} onDiscontinueClick={item => { self.onDiscontinueClick(item) }} restoreStructureSpecClick={item => { self.restoreStructureSpecClick(item) }} />
                </Card>



                <Card title='Compatible Products' subtitle='The following products are associated with this group based on the group attributes:'>
                    <FilteredProductList products={this.state.products} />
                    {this.state.isPublished &&
                        <div>                            
                            <button className='btn btn-primary' onClick={() => { self.publishProducts(true) }}><span className='glyphicon glyphicon-play'></span> Publish all</button>
                            <button className='btn btn-secondary-outline pull-right' onClick={() => { self.publishProducts(false) }}><span className='glyphicon glyphicon-stop'></span> Unpublish all</button>
                        </div>
                    }
                </Card>

            </div>

        );
    }
}

export class ProductGroupSalesChannelsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            channels: null
        }
    }

    componentDidMount() {
        var dataAccess = new ApiDataAccess('/data/productGroups');
        dataAccess.get(`/${this.props.productGroupId}/salesChannels`)
            .then(data => {
                this.setState({
                    channels: data
                });
            })
            .catch(ex => {
                Logger.writeError('Could not load product groups', ex);
            });
    }

    render() {
        return (
            <div className='productGroupSalesChannelsList'>
                {this.state.channels &&
                    <div className='table'>
                        <div className='table-header'>
                            <div className='row'>
                                <div className='col col-6'>Sales Channel</div>
                                <div className='col col-6'>Structure Specs</div>
                            </div>
                        </div>
                        <div className='body'>
                            {this.state.channels.map((channel, idx) => {
                                return (
                                    <div className='row' key={`row${idx}`}>
                                        <div className='col col-6'>{channel.id}</div>
                                        <div className='col col-6'>{channel.value}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            )
    }
}


export class ProductGroupStructureSpecsList extends React.Component {
    constructor(props) {
        super(props);
        
        this.productGroup = this.props.productGroup;
        this.state = {
            items: null
        };

        this.refresh();
        this.onDiscontinueClick = this.onDiscontinueClick.bind(this);
        this.restoreStructureSpecClick = this.restoreStructureSpecClick.bind(this);
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    refresh() {
        var self = this;
        this.getStructureSpecs()
            .then(items => {
                self.setState({
                    items: items
                });
            });
    }


    getFormattedDate(dateStr) {
        if (dateStr) {
            var date = new Date(dateStr);
            return date.toLocaleDateString();            
        }
        else {
            return null;
        }
    }

    getStructureSpecs() {
        var self = this;
        return new Promise(function (resolve, reject) {
            var dataAccess = new ApiDataAccess('/data/productGroups');
            dataAccess.get(`/${self.productGroup}/structureSpecs`)
                .then(data => { resolve(data); })
                .catch(error => {
                    Logger.writeError('getStructureSpecs', error);
                    reject(error);
                });
        });
    }

    onDiscontinueClick(item) {
        if (this.props.onDiscontinueClick) {
            this.props.onDiscontinueClick(item);
        }
    }

    restoreStructureSpecClick(item) {
        if (this.props.restoreStructureSpecClick) {
            this.props.restoreStructureSpecClick(item);
        }
    }

    render() {
        var self = this;
        return (
            <div className='productGroupStructureSpecsList'>
                {this.state.items &&
                    <div className='table'>
                        <div className='body'>
                            {this.state.items.map((item, idx) => {
                                return (
                                    <div key={'item_' + idx} className='row'>

                                        <div className='col col-2 clickable' onClick={() => window.Router.setRoute(`/structurespecs/${encodeURIComponent(item.structureSpecId)}`)}>
                                            <div className='flex'>
                                                {item.isStructureSpecPublished === true
                                                    ? <span className='glyphicon glyphicon-play color-green' title='Published'> </span>
                                                    : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished'> </span>
                                                }
                                                <div>SPEC-{item.structureSpecId}</div>
                                            </div>
                                        </div>

                                        <div className='col col-8 clickable' onClick={() => window.Router.setRoute(`/structurespecs/${encodeURIComponent(item.structureSpecId)}`)}>
                                            <div>
                                                <span>{item.structureSpecName}</span>
                                                {item.discontinueSpecReplacementId > 0 &&
                                                    <div className='replacement clickable'>
                                                        <span className='color-orange'>Replaced By: </span>
                                                        <span className='color-orange' onClick={() => window.Router.setRoute('/structurespecs/' + encodeURIComponent(item.discontinueSpecReplacementId))} >{item.structureSpecReplacementName}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='col col-2 mobile-hidden'>
                                            {item.isStructureSpecPublished && !item.discontinueStructureSpec && item.isReferencedAsReplacement == 0 && !this.props.disableContinuation &&
                                                <div className='pull-right'>
                                                    {!item.discontinueDate
                                                        ? <button className='btn btn-primary' onClick={() => { self.onDiscontinueClick(item) }}>Discontinue</button>
                                                        : <button className='btn btn-secondary-outline' onClick={() => { self.restoreStructureSpecClick(item) }}>{self.getFormattedDate(item.discontinueDate)}</button>
                                                    }
                                                </div>
                                            }

                                            {item.isStructureSpecPublished && item.discontinueStructureSpec && !this.props.disableContinuation &&
                                                <div className='pull-right mobile-hidden'>
                                                    <button className='btn btn-secondary-outline' disabled={true}>{self.getFormattedDate(item.discontinueDate)}</button>
                                                </div>
                                            }
                                        </div>


                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            )
    }
}

export class DiscontinueAssignmentPopupContent extends React.Component {

    constructor(props) {
        super(props);
        var self = this;

        this.popup = this.props.popup;
        this.rel = this.props.item;

        this.apiDataAccess = new ApiDataAccess('/data');
        this.onDaysSelectionChanged = this.onDaysSelectionChanged.bind(this);
        this.onSpecSelectionChanged = this.onSpecSelectionChanged.bind(this);
        this.onScheduleClick = this.onScheduleClick.bind(this);

        this.days = [
            { id: 1, text: '30 Days', days: 30 },
            { id: 2, text: '60 Days', days: 60 },
            { id: 3, text: '90 Days', days: 90 },
            { id: 4, text: '120 Days', days: 120 }
        ];

        this.state = {
            days: this.days,
            specs: null,
            selectedDay: 0,
            selectedSpec: 0
        };

        this.day = 0;
        this.replacementSpecId = 0;

        this.getStructureSpecs()
            .then(items => {
                if (items && items.length > 0) {
                    var idx = items.findIndex(item => item.structureSpecId == this.rel.structureSpecId);
                    if (idx >= 0) {
                        items.splice(idx, 1);
                    }

                    var result = [];
                    items.forEach(item => {
                        if (!item.discontinueDate && item.isStructureSpecPublished === true) {
                            result.push(item);
                        }
                    });

                    self.setState({
                        specs: result
                    });

                }
            });

    }

    getStructureSpecs() {
        var self = this;
        return new Promise(function (resolve, reject) {
            var dataAccess = new ApiDataAccess('/data/productGroups')
            dataAccess.get(`/${self.rel.productGroupId}/structureSpecs`)
                .then(data => { resolve(data); })
                .catch(error => {
                    Logger.writeError('getStructureSpecs', error);
                    reject(error);
                });
        });
    }


    onScheduleClick() {
        this.popup.close();
        
        var request = {
            replacementStructureSpecId: this.replacementSpecId,
            daysFromToday: this.days
        };

        this.apiDataAccess.post(`/productGroups/${this.rel.productGroupId}/structureSpec/${this.rel.structureSpecId}/discontinue`, request)
            .then(item => {
                if (this.props.onScheduled) {
                    this.props.onScheduled(item);
                }
            })
            .catch(error => {
                window.Alerts.showError('The material was not scheduled for discontinuation due to an error!');
            });        
    }

    onDaysSelectionChanged(value, item) {
        this.days = item ? item.days : 0;
        this.setState({
            selectedDay: this.days
        });
    }

    onSpecSelectionChanged(value, item) {
        this.replacementSpecId = item ? item.structureSpecId : 0;
        this.setState({
            selectedSpec: this.replacementSpecId
        });
    }


    render() {
        return (
            <div>
                {this.state.days &&
                    <div>
                        <label htmlFor='days'>Expiration in:</label>
                        <DropdownControl id="days" items={this.state.days} valueMemberName='id' displayMemberName='text' onChange={this.onDaysSelectionChanged} />
                    </div>
                }
                
                {this.state.specs &&
                    <Spacer>
                        <label htmlFor='days'>Replaced by:</label>
                        <DropdownControl id="specs" items={this.state.specs} valueMemberName='structureSpecId' displayMemberName='structureSpecName' onChange={this.onSpecSelectionChanged} />
                    </Spacer>
                    }                

                <Spacer />

                <button className='btn btn-primary pull-right' onClick={() => { this.popup.close(); }}>Cancel</button>
                <button className='btn btn-secondary' disabled={!this.state.selectedDay || !this.state.selectedSpec} onClick={this.onScheduleClick}>Schedule</button>
            </div>
        )
    }

}




export class MergePopupContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: null,
            selectedGroup: null
        }

        this.group = this.props.group;
        this.dataAccess = new ApiDataAccess('/data/productGroups');

        this.onSelected = this.onSelected.bind(this);
        this.onMergeClick = this.onMergeClick.bind(this);
    }

    componentDidMount() {
        var self = this;

        this.dataAccess.get('/')
            .then(data => {
                var groups = [];
                data.forEach(item => {
                    if (item.isPublished === self.group.isPublished && item.id !== self.group.id) {
                        groups.push(item);
                    }
                });

                self.setState({
                    items: groups
                });
            });
    }

    onSelected(value, item) {
        this.setState({
            selectedGroup: item
        });
    }

    onMergeClick() {
        var self = this;

        this.dataAccess.post(`/${encodeURIComponent(this.group.id)}/merge/${encodeURIComponent(this.state.selectedGroup.id)}`, {})
            .then(data => {
                self.props.popup.close();
                window.Alerts.showSuccess('The Packaging Groups have been merged!');
                window.Router.setRoute('/packaginggroups/' + this.group.id);
            })
            .catch(error => {
                self.props.popup.close();
                window.Alerts.showError('The Packaging Groups have not been merged due to an error.');
                window.Router.setRoute('/packaginggroups');
            });
    }

    render() {
        return (
            <div>
                {this.state.items &&
                    <div>
                        <p>Please select the Packaging Group to merge with:</p>
                        <DropdownControl items={this.state.items} valueMemberName='id' displayMemberName='name' onChange={this.onSelected} />
                        <Spacer/>
                        <div>
                            <button className='btn btn-lg btn-primary' disabled={!this.state.selectedGroup} onClick={this.onMergeClick}>Merge</button>
                            <button className='btn btn-lg btn-secondary pull-right' onClick={() => { this.props.popup.close() }}>Close</button>
                        </div>
                    </div>
                }
            </div>
            )
    }
}


export class PublishableNameDisplay extends React.Component {

    render() {
        return (
            <div className="flex">
                {this.props.item.isPublished === true
                    ? <span className='glyphicon glyphicon-play color-green' title='Published' />
                    : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />
                }
                <div>{this.props.item.name}</div>
            </div>
        )
    }
}


export class PushCommandClient {

    constructor(url, appId, userId) {
        this.registry = new PushCommandSubscriberRegistry();
        if (window.NotificationClient) {
            var pushSettings = {
                url: url,
                appId: appId,
                userId: userId,
                deviceId: Math.random().toString(36).substr(2, 16)
            };
            window.NotificationClient.start(pushSettings, this.registry.onCommandReceived);
        }
    }

    getSubscriberRegistry() {
        return this.registry;
    }

}

export class PushCommandSubscriberRegistry {

    constructor() {
        this.handlers = {};

        this.onCommandReceived = this.onCommandReceived.bind(this);
    }

    registerHandler(name, handler) {
        if (!this.handlers) {
            this.handlers = {};
        }
        this.handlers[name] = handler;
    }

    onCommandReceived(command) {
        if (this.handlers) {
            Object.keys(this.handlers).forEach(k => {
                var handler = this.handlers[k];
                if (handler && typeof handler === 'function') {
                    handler(command);
                }
            });
        }
    }
}
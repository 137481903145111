import React from 'react';
import { HelpControl } from './HelpControl';

var TextInputState = {
    Undefined: 0,
    Initialized: 1,
    HadValue: 2
};


export class TextLineControl extends React.Component {
    constructor(props) {
        super(props);        
        this.text = this.getDefaultValue();
        this.inputState = TextInputState.Undefined;

        this.state = {
            value: this.text
        }

        this.onChange = this.onChange.bind(this);
    }


    getDefaultValue() {
        var result = this.props.value;
        return result == null ? '' : result;
    }

    setValue(value) {
        this.setState({
            value: value
        });
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }

        this.raiseChangeNotification();
        this.inputState = TextInputState.Initialized;
    }

    onChange(event) {
        var self = this;
        var value = event.target.value;

        var currentValue = this.state.value;
        
        if (this.props.validation && this.props.validation.maximumLength && value && value.length > this.props.validation.maximumLength) {
            this.updateText(value.substring(0, this.props.validation.maximumLength));
        }
        else {
           
            var match = this.matchesRegex(value);
            if (match || (this.props.validation && this.props.validation.allowProceedOnInvalidInput == true)) {
                this.updateText(value);
                this.raiseChangeNotification();
            } else {
                if (currentValue != null) {
                    this.updateText(currentValue);
                }
                else {                    
                    this.updateText('-');
                }
                    
            }
            this.inputState = TextInputState.HadValue;
        }
    }


    updateText(txt) {
        this.text = txt;
        this.setState({ value: txt });
    }

    matchesRegex(value) {
        if (this.props.validation && this.props.validation.regex) {
            var r = new RegExp(this.props.validation.regex);
            var match = r.exec(value);
            if (match == null || match[0] != value) {
                return false;
            }
        }
        return true;
    }


    isInputValid() {
        var result = true;
        var value = this.text;

        if (this.props.validation && this.props.validation.required === true) {

            if ((!value && isNaN(value)) || value == null || value.length == 0) {
                result = false;
            }
        }

        if (result == true) {
            result = this.matchesRegex(value);
        }

        return result;
    }

    raiseChangeNotification() {
        if (this.props.onChange) {
            if (this.text !== '') {
                this.props.onChange(this.text, this.props.id)
            }
            else {
                this.props.onChange(null, this.props.id)
            }
        }
    }


    render() {
        return (
            <div className='input-control'>
                <label htmlFor={this.props.id}>{this.props.display.caption}</label>
                <HelpControl help={this.props.help} />
                <input id={this.props.id} type="text" autoComplete="off" value={this.state.value} onChange={this.onChange} className={this.isInputValid() ? 'control textbox valid' : 'control textbox error'}></input>
            </div>
        );
    }
}

import React from 'react';
import { AutoCompleteControl } from '../../../controls/AutoCompleteControl';
import { AppendButton, Spacer } from '../../../components/Containers';



export class SynonymsListDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);
        this.timeout = null;
        this.metaData = null;
        this.currentItems = null;

        this.state = {
            items: [],
            text: null
        }

        this.onTextboxChange = this.onTextboxChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            resolve(self);
        });
    }

    setValue(items) {
        var newItems = items ? items : null;
        this.setState({
            items: newItems
        });

        this.currentItems = newItems;
        this.dataManager.reportChange(newItems, this);
    }

    getValue() {
        var items = this.state.items ? this.state.items : null;
        return items;
    }

    onTextboxChange(value) {
        this.setState({
            text: value
        });
    }

    onAddClick() {
        var items = this.state.items ? this.state.items : [];
        var text = this.state.text;
        if (!items.includes(text)) {
            items.push(text);
            items.sort();
            this.setValue(items);
        }
    }

    onDeleteClick(idx, item) {
        var self = this;
        window.Confirmation.show('Delete', 'Are you sure to remove Synonym "'+item+'"?',
            function () 
            {  
                var items = self.state.items ? self.state.items :[];
                items.splice(idx, 1);
                self.setValue(items);
            }
        );
    }

    isInputValid() {
        var result = true;
        return result;
    }

    render() {
        var self = this;
        return (
            <div className='synonyms-list-data-control'>

                <AppendButton title='Add' onClick={this.onAddClick}>
                    <AutoCompleteControl id={this.props.propertyName} lookupDataSource='SynonymLookup' onChange={this.onTextboxChange} />
                </AppendButton>

                {/*
                <div className="input-group mb-3">
                    <AutoCompleteControl id={this.props.propertyName} lookupDataSource='SynonymLookup' onChange={this.onTextboxChange} />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" disabled={!this.state.text || this.state.text.length == 0} onClick={this.onAddClick}>Add</button>
                    </div>
                </div>
                */}

                <Spacer />

                <div className='flex-container'>
                    {this.state.items.map(function (item, id) {
                        return (
                            <div key={`syn_${id}`} className='flex-item'>
                                <SynonymItem item={item} id={id} onClick={(id, item) => { self.onDeleteClick(id, item) }} />
                            </div>
                        )
                    })}
                </div>

                {(!this.state.items || this.state.items.length == 0) &&
                    <div className='alert alert-info'>
                        <span>No Synonyms have been assigend.</span>
                    </div>
                }
            </div>
        );
    }
}




export class SynonymItem extends React.Component {   
    render() {
        return (
            <div className='item-card'>
                <div className='synonym'>

                    <div className='text'>{this.props.item}</div>
                    <div className='btn btn-secondary'><span className='glyphicon glyphicon-trash' onClick={() => {this.props.onClick(this.props.id, this.props.item)}}></span></div>
                    
                </div>
            </div>
        );
    }

}
import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { GenericComponent } from '../../../dataForms/GenericComponent';


export class PressConfigurationView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Press Configuration'/>
                <GenericComponent dataObject='pressConfigurationData' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}
import React from 'react';

export class EditableSortableListDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.metaData = null;
        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);


        this.state = {            
            valueMemberName: null,
            displayMemberName: null,
            datasourceItems: [],
            dropDownValue: null,
            items: [],
        }

        this.onDropDownSelected = this.onDropDownSelected.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onUpClick = this.onUpClick.bind(this);
        this.onDownClick = this.onDownClick.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var dataSourceData = metaData.dataSourceData;

            if (!dataSourceData) {
                throw new Error(" EditableSortableListDataControl requires Data Source Data");
            }

            self.dataManager.getDataSourceItems(dataSourceData.name)
                .then(items => {
                    self.setState({                        
                        datasourceItems: items,
                        valueMemberName: dataSourceData.valueMemberName,
                        displayMemberName: dataSourceData.displayMemberName
                    });

                    resolve(self);
                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    setValue(value) {
        this.setState({
            items: value
        });
    }

    getValue() {
        var result = null;
        var items = this.state.items;
        if (items && items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                items[i].sort = i;
            }
            result = items;
        }
        return result;
    }

    isInputValid() {        
        var result = true;        
        var validationData = this.metaData && this.metaData.validationData ? this.metaData.validationData : null;
        if (validationData && validationData.required === true) {
            var items = this.getValue();
            result = (items && items.length > 0) === true;
        }        
        return result;
    }

    onDropDownSelected(event) {
        var v = parseInt(event.target.value);
        var value = null;
        if (v >= 0) {
            var items = this.state.datasourceItems;            
            var idx = items.findIndex(item => {
                return item.id === v;
            });
            value = idx >= 0 ? items[idx] : null;            
        }

        this.setState({
            dropDownValue: value
        });
    }

    onAddClick() {
        var items = this.state.items;       

        var item = {
            dataObject1: null,
            dataObject2: this.state.dropDownValue,
            dataObject2Id: this.state.dropDownValue.id,
            status: 'ACT',
            sort: items.length
        };

        items.push(item);
        this.setState({
            items: items
        });

        this.dataManager.reportChange(items, this);
    }

    onDeleteClick(item, idx) {
        var items = this.state.items;
        items.splice(idx, 1);
        this.setState({
            items: items
        });

        this.dataManager.reportChange(items, this);
    }

    onUpClick(item, idx2) {
        var items = this.state.items;
        var idx = item.sort;

        var saved = items[idx - 1];
        items[idx - 1] = item;
        items[idx] = saved;

        for (var i = 0; i < items.length; i++) {
            items[i].sort = i;
        }

        this.setState({
            items: items
        });
    }

    onDownClick(item, idx2) {
        var items = this.state.items;
        var idx = item.sort;

        var saved = items[idx + 1];
        items[idx + 1] = item;
        items[idx] = saved;

        for (var i = 0; i < items.length; i++) {
            items[i].sort = i;
        }

        this.setState({
            items: items
        });        
    }

    canAddItem() {

        if (!this.state.dropDownValue) {
            return false;
        }

        if (this.props.maxItems && this.state.items.length >= parseInt(this.props.maxItems)) {
            return false;
        }

        return true;
    }


    render() {
        var self = this;
        return (
            <div className='editable-sortable-list'>
                <div className="input-group">
                    <select className='form-control' onChange={this.onDropDownSelected} >
                        <option value={-1}>Please select...</option>
                        {this.state.datasourceItems.map(function (item, index) {
                            return (<option value={item[self.state.valueMemberName]} key={self.props.id + '_' + index + '_option'}>{item[self.state.displayMemberName]}</option>);
                        })}
                    </select>

                    <div className='input-group-append'>
                        <button className="btn btn-outline-secondary" disabled={!this.canAddItem()} onClick={this.onAddClick}>Add</button>
                    </div>
                </div>

                <ul className='list-container'>
                    {this.state.items.map((item, idx) => {
                        return (
                            <li className='list-item' key={'li' + idx}>
                                <div className='content'><span>{item.dataObject2.name}</span></div>
                                <div className='action'>
                                    <button className='btn btn-outline-secondary btn-sm' disabled={idx === 0} onClick={() => this.onUpClick(item, idx)}><span className='glyphicon glyphicon-chevron-up'></span></button>
                                    <button className='btn btn-outline-secondary btn-sm' disabled={this.state.items.length - idx === 1} onClick={() => this.onDownClick(item, idx)}><span className='glyphicon glyphicon-chevron-down'></span></button>
                                    <button className='btn btn-danger btn-sm' onClick={() => this.onDeleteClick(item, idx)}><span className='glyphicon glyphicon-trash'></span></button>
                                </div>                                
                            </li>
                            );
                    })}
                </ul>
            </div>
            );
    }
}
import React, { Component } from 'react';
import { Tile } from '../core/Tile';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Card, Row, Column } from '../components/Containers';
import { Alert } from '../components/Components';
import { GenericComponent } from '../dataForms/GenericComponent';


export class Home extends Component {
    constructor(props) {
        super(props);

        this.controls = {
            productsTile: null,
            structureSpecTile: null,
            packagingGroupsTile: null,
            rulesTile: null
        };

        this.releaseDataReceived = this.releaseDataReceived.bind(this);
        this.pendingProductReviews = false;
    }

    releaseDataReceived(release) {

        if (this.controls.productsTile) {
            var count = parseInt(release.productReviewsCount);
            this.pendingProductReviews = count > 0 ? true : false;
            this.controls.productsTile.setCount(count);
        }

        if (this.controls.structureSpecTile) {
            this.controls.structureSpecTile.setCount(parseInt(release.unpublishedStructureSpecCount));
        }

        if (this.controls.packagingGroupsTile) {
            this.controls.packagingGroupsTile.setCount(parseInt(release.unpublishedProductGroupCount));
        }

        if (this.controls.rulesTile) {
            this.controls.rulesTile.setCount(parseInt(release.unpublishedRuleCount));
        }

        if (this.controls.specRequestsTile) {
            this.controls.specRequestsTile.setCount(parseInt(release.pendingMaterialRequestCount));
        }
    }

    render() {
        return (
            <div className="view">

                <div className='tile-container'>
                    <Tile className='mobile-hidden' title='Configuration' onClick={() => window.Router.setRoute('/configuration')} />
                    <Tile title='Products' onClick={() => { this.pendingProductReviews ? window.Router.setRoute('/products/review') : window.Router.setRoute('/products') }} onInit={ctrl => { this.controls.productsTile = ctrl }} />
                    <Tile title='Items' onClick={() => window.Router.setRoute('/configuration/structurespecs/items')} />
                    <Tile title='Structure Spec Definitions' onClick={() => window.Router.setRoute('/structurespecs')} onInit={ctrl => { this.controls.structureSpecTile = ctrl }} />
                    <Tile className='mobile-hidden' title='Structure Spec Requests' onClick={() => window.Router.setRoute('/structurespecs/requests')} onInit={ctrl => { this.controls.specRequestsTile = ctrl }}  />
                    <Tile className='mobile-hidden' title='Structure Specs Sheet Translations' onClick={() => window.Router.setRoute('/structurespecs/sheets')} />
                    <Tile title='Packaging Groups' onClick={() => window.Router.setRoute('/packaginggroups')} onInit={ctrl => { this.controls.packagingGroupsTile = ctrl }}  />
                    <Tile className='mobile-hidden' title='Packaging Feature Rules' onClick={() => window.Router.setRoute('/rules')} onInit={ctrl => { this.controls.rulesTile = ctrl }} />
                    <Tile className='mobile-hidden' title='Certifications' onClick={() => window.Router.setRoute('/certifications')} />
                    <Tile title='Audit' onClick={() => window.Router.setRoute('/audit')} />
                </div>

                <ReleaseInfo onReceived={this.releaseDataReceived} />

            </div>
        );
    }
}



export class ReleaseInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            release: null,
            loaded: false
        }
    }

    componentDidMount() {

        this.getData()
    }


    async getData() {
        
        var data = await window.Cache.getOrAddAsync(`Home.getData()`, 5, async () => {
            var dataAccess = new ApiDataAccess('/api/info', { loader: false });    
            return await dataAccess.get('/release');
        });     
        
        if (this.props.onReceived) {
            this.props.onReceived(data);
        }

        this.setState({
            release: data,
            loaded: true
        });        
    }


    render() {
        return (
            <div>
                {this.state.loaded && this.state.release &&
                    <div className='releaseInfo'>
                        <Card title={`Release Info - ${this.state.release.version}`} subtitle='The following artifacts have been published with the current release:'>

                            <Row>
                                <Column>Published Products</Column>
                                <Column><a href='/products'>{this.state.release.publishedProductCount}</a></Column>
                            </Row>

                            <Row>
                                <Column>Published Packaging Groups</Column>
                                <Column><a href='/packaginggroups'>{this.state.release.publishedProductGroupCount}</a></Column>
                            </Row>

                            <Row>
                                <Column>Published Structure Specifications</Column>
                                <Column><a href='/structurespecs'>{this.state.release.publishedStructureSpecCount}</a></Column>
                            </Row>

                            <Row>
                                <Column>Published Packaging Feature Rules</Column>
                                <Column><a href='/rules'>{this.state.release.publishedRuleCount}</a></Column>
                            </Row>

                        </Card>

                        <Card title='Pending Tasks' subtitle='The following open items are ready for your review:'>

                            {this.state.release.productReviewsCount > 0 &&
                                <Alert type='info' onClick={() => { window.Router.setRoute('/products/review') }}>There are {this.state.release.productReviewsCount} products with a pending review. Click here to review these products.</Alert>
                            }


                            {this.state.release.productReviewsCount > 0 &&
                                <Row>
                                    <Column>Pending Product Reviews</Column>
                                    <Column><a href='/products/review'>{this.state.release.productReviewsCount}</a></Column>
                                </Row>
                            }


                            {this.state.release.unpublishedProductCount > 0 &&
                                <Row>
                                    <Column>Unpublished Products</Column>
                                    <Column><a href='/products'>{this.state.release.unpublishedProductCount}</a></Column>
                                </Row>
                            }

                            {this.state.release.unpublishedProductGroupCount > 0 &&
                                <Row>
                                    <Column>Unpublished Packaging Groups</Column>
                                    <Column><a href='/packaginggroups'>{this.state.release.unpublishedProductGroupCount}</a></Column>
                                </Row>
                            }

                            {this.state.release.unpublishedStructureSpecCount > 0 &&
                                <Row>
                                    <Column>Unpublished Structure Specifications</Column>
                                    <Column><a href='/structurespecs'>{this.state.release.unpublishedStructureSpecCount}</a></Column>
                                </Row>
                            }

                            {this.state.release.unpublishedRuleCount > 0 &&
                                <Row>
                                    <Column>Unpublished Packaging Feature Rules</Column>
                                    <Column><a href='/rules'>{this.state.release.unpublishedRuleCount}</a></Column>
                                </Row>
                            }

                        </Card>

                    </div>
                }

                {this.state.loaded && !this.state.release &&
                    <Card title='Release Info' subtitle=''>
                        <div className='alert alert-info'>No release information available.</div>
                    </Card>
                }
            </div>
        );
    }

}


export class WebHookView extends React.Component {

    render() {
        return (
            <div className="view">
                <h1>Web Hooks</h1>
                <GenericComponent dataObject='webHooks' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}
import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { DataManager } from '../../../dataControls/DataManager';
import { ImageTitle, ImageLabel } from '../../../core/ImageTitle';
import { DistinctTextboxDataControl } from '../../../dataControls/DistinctTextboxDataControl';
import { DropdownDataControl } from '../../../dataControls/DropdownDataControl';
import { LookupDropdownDataControl } from '../../../dataControls/LookupDropdownDataControl';
import { ImageDataControl } from '../../../dataControls/ImageDataControl';
import { TextboxDataControl } from '../../../dataControls/TextboxDataControl';
import { ToolbarComponent, InterfaceMode } from '../../../dataForms/GenericComponent';
import { SynonymsListDataControl } from './SynonymsListDataControl';
import { Card, Row, Column } from '../../../components/Containers';
import { Logger } from "../../../infrastructure/Logger";

export class ProductEditorComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isValid: false,
            industryId: -1
        };

        this.dataAccess = new ApiDataAccess('/data');
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.name = null;

        this.dataManager = new DataManager('products', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(() => {
                            //2) bind Controls                           
                            dataManager.bindControls();
                        })
                        .catch(ex => {
                            Logger.writeError("Could not load Product", ex);
                            window.Router.setNotFound();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                }

                //3) onControlBound --> Set Value
            });
    }

    onControlBound(control, propertyName) {

        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value, control);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    onNameChange(value) {
        this.name = value;
    }

    onSaveClick() {
        var self = this;

        self.dataManager.saveContext()
            .then(data => {
                window.Alerts.showSuccess('Product ' + data.name + ' has been saved');
                window.Router.setRoute('/products/' + encodeURIComponent(data.id));
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while saving the Product');
            });
    }

    setIndustry(id) {
        this.setState({ industryId: id });
    }


    render() {
        return (
            <div className='product product-editor-component'>

               
                <ImageTitle text='Products' />

                <ToolbarComponent mode={this.props.dataId ? InterfaceMode.Edit : InterfaceMode.New} 
                    onListClick={() => window.Router.setRoute('/products/all')}
                    onNewClick={() => window.Router.setRoute('/products/new')}
                    onEditClick={() => window.Router.setRoute('/products/' + encodeURIComponent(this.props.dataId) + '/edit')}
                    onDisplayClick={() => window.Router.setRoute('/products/' + encodeURIComponent(this.props.dataId))}
                />

                <Card title='General' subtitle='Provide the general information for this product:'>
                    <Row>
                        <Column>
                            <label htmlFor='image'>Photo</label>
                            <ImageDataControl dataManager={this.dataManager} propertyName='imageId' searchTerm={this.name} />
                        </Column>

                        <Column>
                            <label htmlFor='name'>Name</label>
                            <DistinctTextboxDataControl dataManager={this.dataManager} propertyName='name' onChange={this.onNameChange} lookupDataSource='ProductLookup' error='A product with this name exists already' />

                            <label htmlFor='description'>Description</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='description' multiline={true} />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='industryId'>Industry</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='industryId' onChange={id => { this.setIndustry(id); }} />
                        </Column>

                        <Column>
                            <label htmlFor='industryCategoryId'>Industry</label>
                            <LookupDropdownDataControl dataManager={this.dataManager} propertyName='industryCategoryId' dataSourceName='CategoriesByIndustry' referenceProperty='industryId' />
                        </Column>
                    </Row>
                </Card>


                <Card title='Attributes' subtitle='Select the attributes that describe this product:'>
                    <Row>
                        <Column>
                            <label htmlFor='consistencyId'>Consistency</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='consistencyId' />
                        </Column>

                        <Column>
                            <label htmlFor='moistureId'>Moisture</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='moistureId' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='aromaId'>Aroma</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='aromaId' />
                        </Column>

                        <Column>
                            <label htmlFor='lightSensitivityId'>Light Sensitivity</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='lightSensitivityId' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='shelfLifeTimeId'>Shelf Life Time</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='shelfLifeTimeId' />
                        </Column>

                        <Column>
                            <label htmlFor='childResistanceId'>Child Resistance</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='childResistanceId' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='aggressivnessId'>Aggressiveness</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='aggressivnessId' />
                        </Column>

                        <Column>
                            <label htmlFor='emissionId'>Emission</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='emissionId' />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='temperatureId'>Packaging Temperature</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='temperatureId' />
                        </Column>

                        <Column>
                            <label htmlFor='oxygenSensitivityId'>Oxygen Sensitivity</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='oxygenSensitivityId' />
                        </Column>
                    </Row>

                </Card>

                <Card title='Synonyms' subtitle='Add synonyms that also describe this product:'>
                    <SynonymsListDataControl dataManager={this.dataManager} propertyName='synonyms' />
                </Card>


                <button className='btn btn-lg btn-primary' disabled={!this.state.isValid} onClick={this.onSaveClick}>Save</button>                
            </div>
        );
    }
}

import React from 'react';

export class RuleEditorTitle extends React.Component {

    render() {
        return (
            <div className='titleRow'>
                <span>{this.props.title}</span>
                {!this.props.disabled &&
                    <button className='btn btn-primary-outline float-right' onClick={this.props.onClick}>{this.props.button}</button>
                }
            </div>
        )
    }

}
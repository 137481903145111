import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { UploadCloud, Check, X } from 'react-feather';

import "./FileUpload.css";


export const FileUpload = forwardRef((props, ref) => {

    var progressIndicator = useRef(null);

    var uploadResult = null; 
    
    const [fileName, setFileName] = useState(props.existingFileName);    
    const [errorOnUpload, setErrorOnUpload] = useState(false);    
    const [uploadErrorText, setUploadErrorText] = useState(null);
    const MAX_UPLOAD_SIZE = 314572800;


    useImperativeHandle(ref, () => ({
        reset: (value) => resetControl()        
    }));

    useEffect(() => {

        if (props.existingFileName) {
            progressIndicator.current.setValue(100);
        }
        else {
            progressIndicator.current.setValue(0);
        }

    }, []);

    const resetControl = () => {        
        setFileName(null);
        setErrorOnUpload(false);
        setUploadErrorText(null);
        progressIndicator.current.setWait(false);
        progressIndicator.current.setValue(0);
    }

    const onClickUpload = () => {
        let element = document.getElementById("upload_" + props.id);
        element.click();

        if (props.onClick) {
            props.onClick();
        }
    }

    const onHandleUpload = (e) => {
        e.preventDefault();

        var fileToUpload = e.target.files[0];
        let file = fileToUpload;
        let fileName = fileToUpload ? fileToUpload.name : '';
        let ext = fileName.toLowerCase().split(".").slice(-1)[0];

        if ((fileToUpload.size > MAX_UPLOAD_SIZE) || (ext !== 'pdf')) {
            setErrorOnUpload(true);
            notifyUserOfUploadError('File cannot exceed 300MB and must be in PDF format to upload.');
            notifyArtworkResourceUploadFailed(props.Id);
            return;
        }

        setFileName(fileName);

        let formData = new FormData();

        formData.append("formFile", file);
        formData.append("fileName", fileName)

        try {
            setErrorOnUpload(false);
            

            let xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / e.total) * 99;
                    progressIndicator.current.setValue(percentComplete);
                }
            }

            xhr.upload.onerror = (e) => {
                console.log('Upload Error', e);
                notifyUserOfUploadError('Unknown error uploading file. Please try again later');
                notifyArtworkResourceUploadFailed(props.Id);
                setFileName(props.existingFileName);
            }

            //https://stackoverflow.com/questions/15418608/xmlhttprequest-level-2-determinate-if-upload-finished
            xhr.upload.onload = (e) => {
                console.log("upload finished", xhr, e);
                setFileName(fileToUpload.name);                
                progressIndicator.current.setWait(true);
                
            };

            xhr.onreadystatechange = (e) => {

                if (progressIndicator.current) {
                    progressIndicator.current.setValue(99);
                }

                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const status = xhr.status;
                    uploadResult = xhr.responseText;
                    //setUploadResult(xhr.responseText);

                    console.log('Status', status);

                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                            progressIndicator.current.setValue(100);

                            if (props.onUploadComplete) {
                                props.onUploadComplete(props.id, uploadResult, resetControl);
                            }
                        }
                    } else {
                        setErrorOnUpload(true);
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                        }
                        notifyArtworkResourceUploadFailed(props.Id);
                    }
                }
            }

            console.log(`uploading to ${props.url}...`);

            xhr.open("POST", props.url);
            xhr.send(formData);
        }
        catch (ex) {
            console.log('Upload Error', ex);
            notifyUserOfUploadError('Unknown error uploading file. Please try again later');
            setErrorOnUpload(true);
            notifyArtworkResourceUploadFailed(props.Id);
        }
    }

   
    const notifyArtworkResourceUploadFailed = (artworkResourceId) => {
        if (props.onUploadFailed) {
            props.onUploadFailed(artworkResourceId);
        }
    }

    const notifyUserOfUploadError = (message) => {

        if (!message) {
            message = 'Unknown error uploading file. Please try again later';
        }

        setUploadErrorText(message);        
        setErrorOnUpload(true);
    }


    return (
            <div className="uploadTile uploadLink buttonTile">
                <div className="header"></div>
                <div className="content">
                    {errorOnUpload
                        ? <div className='progress indicator failure'>
                            <div className='circle'>
                                <X className='icon' />
                            </div>
                        </div>
                        : <ProgressIndicator ref={progressIndicator} size={80} total={100} />
                    }

                    {fileName &&
                        <h4 className='fileName'>{fileName}</h4>
                    }

                    <input className='upload' type="file" id={"upload_" + props.id} accept=".pdf" onChange={onHandleUpload} disabled={props.disabled} />

                </div>
                <div className="footer">
                    <button
                        data-testid={`order-placement-upload-artwork-file-button-${props.testIndex}`}
                        className="btn btn-secondary" onClick={() => { onClickUpload() }}>
                        {fileName
                            ? <span>Change</span>
                            : <span>Upload</span>
                        }
                    </button>
                    {errorOnUpload && <p className='tile-error'>{uploadErrorText}</p>}
                </div>

            </div>
    );
});


export const ProgressIndicator = forwardRef((props, ref) => {

    const total = props.total;
    const [circumference, setCircumference] = useState(0);
    const [progressOffset, setProgressOffset] = useState(0);
    const [progress, setProgress] = useState(0);
    const [waiting, setWaiting] = useState(false);

    const size = props.size;
    const strokeWidth = 8;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circleOneStroke = '#EEEEEE';
    const circleTwoStroke = '#7CBD42';


    const style = {
        width: size,
        height: size,
        lineHeight: '80px'
    }

    const containerStyle = {
        height: size,
        lineHeight: '80px'
    }

    const setValue = (step) => {
        var p = (100 / total) * step;
        var c = 2 * Math.PI * radius;
        var po = ((100 - p) / 100) * c;

        setProgress(p);
        setCircumference(c)
        setProgressOffset(po);
    }

    const setWait = (value) => {
        setWaiting(value);
    }

    useImperativeHandle(ref, () => ({
        setValue: (value) => setValue(value),
        setWait: (value) => setWait(value)
    }));

    useEffect(() => {
        setValue(props.value ? props.value : 0);
    }, []);

    const getProgressIcon = (progress) => {

        if (waiting) {
            return <span className="spinner"></span>
        }
        else {
            var value = progress.toFixed();
            if (value == 0) {
                return <span><UploadCloud className='icon thin' /></span>
            }
            else if (value < 100) {
                return <span>{progress.toFixed(0)}%</span>
            }
            else {
                return <span><Check className='icon' /></span>
            }
        }
    }

    return (
        <div className='progress indicator' style={containerStyle}>
            <div className="progress-ring" style={style}>
                <svg width={size} height={size}>
                    <circle
                        className="svg-circle-bg"
                        stroke={circleOneStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                    />
                    <circle
                        className="svg-circle"
                        stroke={circleTwoStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={progressOffset}
                        fill='#FFF'
                    />
                </svg>

                <div className='value'>
                    {getProgressIcon(progress)}
                </div>
            </div>

        </div>
    )
});
import React from 'react';

export class TextboxDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);

        this.metaData = null;
        this.currentValue = null;
        this.state = {
            value: '',
            maxlength: 0
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var validationData = metaData.validationData;
            self.setState({
                maxlength: validationData && validationData.maximumLength ? validationData.maximumLength : null
            });
            resolve(self);
        });
    }

    setValue(value) {
        var newValue = value ? value : '';

        this.setState({
            value: newValue
        });

        this.currentValue = newValue;
        this.dataManager.reportChange(newValue, this);
    }

    getValue() {
        return this.state.value;
    }

    isInputValid() {
        var result = true;
        var value = this.currentValue;
        
        var validationData = this.metaData ? this.metaData.validationData : null;
        if (validationData) {
            if (validationData.required !== true && (!value || value == null || value.length === 0)) {
                result = true;
            }
            else {
                if (validationData.required === true) {
                    if (!value || value == null || value.length === 0) {
                        result = false;
                    }
                }

                if (result === true) {
                    result = this.matchesRegex(value);
                }
            }
        }

        if (result === true && this.props.onValidate) {
            result = this.props.onValidate(value);
        }

        return result;
    }

    onChange(event) {                
        var value = event.target.value;        
        var previousValue = this.state.value;
        this.currentValue = value;

        var newValue = null;
        if (value && value.length > 0) {

            if (this.props.proceedOnError === true) {
                newValue = this.currentValue;
            }
            else {
                newValue = this.isInputValid() ? this.currentValue : previousValue;
            }            
        }
        else {
            newValue = null;
        }

        this.setValue(newValue);
        this.dataManager.reportChange(newValue, this);

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }

    }

    matchesRegex(value) {
        var validationData = this.metaData ? this.metaData.validationData : null;
        if (validationData && validationData.regex) {
            var r = new RegExp(validationData.regex);
            var match = r.exec(value);
            if (match == null || match[0] != value) {
                return false;
            }
        }
        return true;
    }

    render() {
        var isValid = this.isInputValid();
        return (
            <div className='input-control'>
                {this.props.multiline &&
                    <textarea rows="5" id={this.props.propertyName} className={isValid ? 'control textbox valid' : 'control textbox error'} value={this.state.value} onChange={this.onChange} maxLength={this.state.maxlength} disabled={this.props.disabled == true}></textarea>
                }

                {!this.props.multiline &&
                    <input id={this.props.propertyName} className={isValid ? 'control textbox valid' : 'control textbox error'} value={this.state.value} onChange={this.onChange} maxLength={this.state.maxlength} disabled={this.props.disabled == true}></input>
                }
            </div>

        );
    }
}

import { ControlDataAccess } from './ControlDataAccess';
import { DataSource } from '../infrastructure/DataSourceDataAccess';
import { Logger } from "../infrastructure/Logger";

var Status = {
    Active: 'ACT',
    Deleted: 'DEL'
}

export class DataManager {
    constructor(dataObjectName, onControlBound, onControlUnBound, onValidated, onModified) {

        if (!dataObjectName) {
            throw new Error("DataManager: Data Object Name is required");
        }

        this.dataObjectName = dataObjectName;
        this.onControlBound = onControlBound;
        this.onControlUnBound = onControlUnBound;
        this.onValidated = onValidated;
        this.onModified = onModified;

        this.dataAccess = new ControlDataAccess(dataObjectName);
        this.metaDataCollection = {};
        this.controls = {};
        this.dataObject = {};
    }

    // register control
    register(cntrl) {
        if (!cntrl.props.propertyName) {
            throw new Error("DataManager: Control has no Property Name assigned but it is required");
        }
        this.controls[cntrl.props.propertyName] = cntrl;
    }
   
    // Initialize
    async init() {
        
        this.metaDataCollection = await window.Cache.getOrAddAsync(`DataManager.init(${this.dataObjectName})`, null, async () => {
            return await this.dataAccess.getMetaData()
        });     

        return this;
    }

    // bind a specific control
    bindControl(propertyName) {
        var self = this;
        return new Promise(function (resolve, reject) {            
            var cntrl = self.controls[propertyName];            
            if (cntrl) {                
                var metaData = self.metaDataCollection[propertyName];
                if (metaData) {
                    cntrl.init(metaData)
                        .then(cntrl => {
                            Logger.writeDebug('DataManager: Control for property ' + cntrl.props.propertyName + ' bound', cntrl)
                            if (self.onControlBound) {
                                self.onControlBound(cntrl, cntrl.props.propertyName, self);
                            }
                            resolve(self);
                        });
                }
            }
        });
    }


    // bind all controls
    bindControls() {
        var self = this;
        return new Promise(function (resolve, reject) {
            Object.keys(self.metaDataCollection).map((propertyName) => {
                self.bindControl(propertyName);
            });
            resolve(self.controls);
        });
    }


    // unbind all controls
    unbindControl(propertyName) {
        var self = this;
        if (self.controls[propertyName]) {
            var cntrl = self.controls[propertyName];
            if (cntrl) {
                Logger.writeDebug('DataManager: Control for property ' + cntrl.props.propertyName + ' unbound', cntrl)
                if (self.onControlUnBound) {
                    self.onControlUnBound(cntrl, cntrl.props.propertyName);
                }
                self.controls[propertyName] = null;
            }
        }
    }

    // Is called whenever a control reports change
    reportChange(value, control, selectedItem) {
        if (control.props.onChange) {
            control.props.onChange(value, control, selectedItem);
        }

        Object.keys(this.controls).map((propertyName) => {           
            if (this.controls[propertyName] && this.controls[propertyName].onChangeBroadcasted) {
                this.controls[propertyName].onChangeBroadcasted(control.props.propertyName, value, control);
            }
        });
                
        if (this.onValidated) {
            var valid = this.isContextValid();
            this.onValidated(valid);
        }
    }

    getDataSource(datasourceName) {
        var self = this;
        return new Promise(function (resolve) {
            self.getDataSourceItems(datasourceName)
                .then(items => {
                    resolve(new DataSource(items));
                });
        });
   }

    // Get data source Items of a specified data source
    getDataSourceItems(datasourceName) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.dataAccess.getDataSourceItems(datasourceName)
                .then(items => {
                    resolve(items);
                })
                .catch((error) => {
                    Logger.writeError("DataManager: Could not retrieve data source data: " + datasourceName, error);
                    window.Alerts.showError('An error occurred while retrieving data from the server. Please try again later!');
                    reject(error);
                });
        });
    }

    // Get control value
    setValue(propertyName, value) {
        var cntrl = this.controls[propertyName];
        if (cntrl) {
            cntrl.setValue(value);
        }
        else {
            throw new Error("DataManager: Control assigned to Property " + propertyName + " was not found");
        }
    }

    // Get current control value
    getValue(propertyName) {
        var cntrl = this.controls[propertyName];
        if (cntrl) {
            return cntrl.getValue();
        }
        else {
            throw new Error("DataManager: Control assigned to Property " + propertyName + " was not found");
        }
    }


    // reset all controls
    reset() {
        var self = this;
        Object.keys(self.controls).map((propertyName) => {
            if (self.controls[propertyName].setValue) {
                self.controls[propertyName].setValue(null);
            }
            else {
                Logger.writeDebug('Missing method setValue on control for property ' + propertyName, self.controls[propertyName]);
            }

        });
    }

    // Create Json based on current values
    getDataObject(resetObject) {
        var self = this;
        var result = this.dataObject;

        Object.keys(self.metaDataCollection).map((propertyName) => {
            var cntrl = self.controls[propertyName];
            if (cntrl) {
                result[propertyName] = cntrl.getValue();
            }
            else {
                if (propertyName !== 'id' && propertyName !== 'status') {
                    if (resetObject === true) {
                        result[propertyName] = null;
                    }
                }
            }
        });
        return result;
    }

    // Validates the context
    isContextValid() {
        var self = this;
        var result = true;
        Object.keys(self.metaDataCollection).map((propertyName) => {
            var cntrl = self.controls[propertyName];
            if (cntrl) {
                if (cntrl.isInputValid() === false) {
                    result = false;
                }
            }
        });
        return result;
    }

    delete() {
        var self = this;
        return new Promise(function (resolve, reject) {
            var obj = self.getDataObject();
            if (obj && obj.id > 0) {
                self.dataAccess.deleteObject(obj.id)
                    .then(data => resolve(data));
            }
            else {
                reject();
            }
        });
    }

    // Saves the context
    saveContext(resetObject) {
        var self = this;
        
        return new Promise(function (resolve, reject) {
            if (self.isContextValid()) {
                var request = self.getDataObject(resetObject);
                Logger.writeDebug(`DataManager: Saving Context for ${self.dataObjectName}`, request);
                self.dataAccess.saveData(request)
                    .then(data => {
                        self.dataObject = data;
                        Logger.writeDebug('DataManager: Saved Data Object', self.dataObject);
                        resolve(data);
                    })
                    .catch(error => {
                        Logger.writeError('DataManager: Could not save item', request, error);
                        window.Alerts.showError(error);
                        reject(error);
                    });
            }
            else {
                reject('Context is not valid and cannot be saved');
            }
        });        
    }


    loadContext(id) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.dataAccess.loadData(id)
                .then(data => {
                    Logger.writeDebug('DataManager.loadContext', data);
                    if (data && data.status === Status.Deleted) {
                        Logger.writeDebug('The item exists but is deleted', data);
                        window.Router.setNotFound();
                    }
                    else {
                        self.dataObject = data;
                        resolve(data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

}

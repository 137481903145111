import React from 'react';
import { GenericComponent } from '../../../dataForms/GenericComponent';
import { ImageTitle } from '../../../core/ImageTitle';
import { ManufacturingOption } from './ManufacturingOption';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { ConvertingMethod } from './ConvertingMethod';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { ExpandableSection } from '../../../controls/ExpandableSection';
import { DataManager } from '../../../dataControls/DataManager';
import { ChecklistDataControl } from '../../../dataControls/ChecklistDataControls';
import { TextboxDataControl } from '../../../dataControls/TextboxDataControl';
import { ToggleSwitchDataListControl } from '../../../dataControls/ToggleSwitchDataListControl';
import { Alert } from '../../../components/Components';
import { Card } from '../../../components/Containers';



export class PackagingFinishTypeAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Package Finish Types' />
                <GenericComponent dataObject='packagingFinishTypeAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class PackagingTransparencyAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Package Transparency' />
                <GenericComponent dataObject='packagingTransparencyAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class PackagingSustainabilityAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Package Sustainability' />
                <GenericComponent dataObject='packagingSustainabilityAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class PackagingFillTypeAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Package Fill Types' />
                <GenericComponent dataObject='packagingFillTypeAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class PackagingSealTypeAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Package Seal Types' />
                <GenericComponent dataObject='packagingSealTypeAttributes' dataId={this.props.param_0} />
            </div>
        );
    }
}


export class PackagingSealWrapTypeAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Package Seal Types' />
                <GenericComponent dataObject='packagingSealWrapTypeAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class PackagingBarrierTypeAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Barrier Types' />
                <GenericComponent dataObject='packagingBarrierTypeAttributes' dataId={this.props.param_0} />
            </div>
        );
    }
}

export class PackagingApplicationAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Applications' />
                <GenericComponent dataObject='packagingApplicationAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}



export class ManufacturingOptionView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new ManufacturingOption(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Manufacturing Options' />
                <Alert type='info'>Manufacturing Options are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='manufacturingOptions'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}




export class PackagingConvertingMethodAttributeListView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new ConvertingMethod(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });
    }


    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Converting Methods' />
                <PageableDataObjectList dataObject='packagingConvertingMethodAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row clickable'>
                                <div className='col col-6' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting/' + item.id)}>{item.name}</div>
                                <div className='col col-2' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting/' + item.id)}>{item.createDate}</div>
                                <div className='col col-2' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting/' + item.id)}>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}


export class PackagingConvertingMethodAttributeView extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.param_0) {
            throw new Error("Data Id Property is required");
        }

        this.dataId = this.props.param_0;
        this.dataAccess = new ApiDataAccess('/data');
        this.state = {
            data: null
        };

        this.onPublishClick = this.onPublishClick.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.dataAccess.get(`/packagingConvertingMethodAttributes/${encodeURIComponent(this.dataId)}/formatted`)
            .then(data => {
                this.setState({
                    data: data
                });
            })
    }

    getCheckMark(data) {
        if (data === 'True' || data === true) {
            return (<span className='color-green'><span className='glyphicon glyphicon-ok'></span> Yes</span>)
        }
        else {
            return (<span className='color-red'><span className='glyphicon glyphicon-remove'></span> No</span>)
        }
    }

    onPublishClick() {
        var self = this;
        var feature = new ConvertingMethod(this.dataId);

        var published = this.state.data.isPublished === 'True';
        feature.publish(!published)
            .then(() => {
                self.loadData();
            });
    }

    render() {
        return (
            <div>
                {this.state.data &&
                    <div className="view">
                        <ImageTitle text={`Converting Methods - ${this.state.data.name}`} />

                        <ToolbarControl renderButtons={() => {
                            return (
                                <div>
                                    <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting')} />
                                    <ToolbarButton icon='glyphicon-pencil' text='Edit' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting/' + encodeURIComponent(this.dataId) + '/edit')} />
                                    <ToolbarButton icon={this.state.data.isPublished === 'True' ? 'glyphicon-stop' : 'glyphicon-play'} text={this.state.data.isPublished === 'True' ? 'unpublish' : 'publish'} onClick={this.onPublishClick} />
                                </div>
                            )
                        }} />

                        <Card title='General' subtitle='Information to this Converting Method'>
                            <div className='row'>
                                <div className='attribute col-sm-12 col-lg-6'>
                                    <label>Name</label>
                                    <p>{this.state.data.name}</p>
                                </div>
                                <div className='attribute col-sm-12 col-lg-6'>
                                    <label>Component Type</label>
                                    <p>{this.state.data.componentTypeName}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='attribute col-sm-12 col-lg-12'>
                                    <label>Description</label>
                                    <p>{this.state.data.description}</p>
                                </div>
                            </div>
                        </Card>

                        <Card title='Dimensions' subtitle='Sizes and Available Input Fields'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Length Required</label>
                                    <p>{this.getCheckMark(this.state.data.lengthRequired)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Width Required</label>
                                    <p>{this.getCheckMark(this.state.data.widthRequired)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Gusset Required</label>
                                    <p>{this.getCheckMark(this.state.data.gussetRequired)}</p>
                                </div>
                            </div>

                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Web Width Required</label>
                                    <p>{this.getCheckMark(this.state.data.webWidthRequired)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Repeat Required</label>
                                    <p>{this.getCheckMark(this.state.data.repeatRequired)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                </div>
                            </div>

                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Length Input Field Disabled</label>
                                    <p>{this.getCheckMark(this.state.data.lengthInputFieldDisabled)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Width Input Field Disabled</label>
                                    <p>{this.getCheckMark(this.state.data.widthInputFieldDisabled)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Gusset Input Field Disabled</label>
                                    <p>{this.getCheckMark(this.state.data.gussetInputFieldDisabled)}</p>
                                </div>
                            </div>

                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Web Width Input Field Disabled</label>
                                    <p>{this.getCheckMark(this.state.data.webWidthInputFieldDisabled)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Repeat Input Field Disabled</label>
                                    <p>{this.getCheckMark(this.state.data.repeatInputFieldDisabled)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                </div>
                            </div>

                            <div className='row flex'>
                                <div className='attribute col col-12'>
                                    <label>Only Standard Dimensions Allowed</label>
                                    <p>{this.getCheckMark(this.state.data.onlyStandardDimensionsAllowed)}</p>
                                </div>
                            </div>
                        </Card>


                        <Card title='Bottom Fill' subtitle='Information for Bottom Fill'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Default Value</label>
                                    <p>{this.state.data.bottomFillDefaultId ? this.state.data.bottomFillDefaultId : 'N/A'}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Allow Change</label>
                                    <p>{this.getCheckMark(this.state.data.bottomFillAllowChange)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                </div>
                            </div>
                        </Card>


                        <Card title='Core' subtitle='Information for Core'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Default Value</label>
                                    <p>{this.state.data.coreDefaultId ? this.state.data.coreDefaultId : 'N/A'}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Allow Change</label>
                                    <p>{this.getCheckMark(this.state.data.coreAllowChange)}</p>
                                </div>
                                <div className='attribute col col-4'>
                                </div>
                            </div>
                        </Card>


                        <Card title='Eyemarks' subtitle='Information for Eyemarks'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Default Value</label>
                                    <p>{this.state.data.eyemarkDefaultId}</p>
                                </div>

                                <div className='attribute col col-4'>
                                    <label>Length</label>
                                    <p>{this.state.data.eyemarkLengthValue} {this.state.data.eyemarkLengthUomId}</p>
                                </div>

                                <div className='attribute col col-4'>
                                    <label>Allow Change</label>
                                    <p>{this.getCheckMark(this.state.data.eyemarkAllowChange)}</p>
                                </div>
                            </div>
                        </Card>

                        <Card title='Bleed' subtitle='Information for Bleed'>
                            <div className='row flex'>
                                <div className='attribute col col-12'>
                                    <label>Length</label>
                                    <p>{this.state.data.bleedLengthValue} {this.state.data.eyemarkLengthUomId}</p>
                                </div>
                            </div>

                        </Card>

                        <Card title='Size Restrictions' subtitle='Information for Size Restrictions'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Width</label>
                                    <p>{this.state.data.minWidthValue} {this.state.data.minWidthUomId} - {this.state.data.maxWidthValue} {this.state.data.maxWidthUomId}</p>
                                </div>

                                <div className='attribute col col-4'>
                                    <label>Length</label>
                                    <p>{this.state.data.minLengthValue} {this.state.data.minLengthUomId} - {this.state.data.maxLengthValue} {this.state.data.maxLengthUomId}</p>
                                </div>

                                <div className='attribute col col-4'>
                                    <label>Gusset</label>
                                    <p>{this.state.data.minGussetValue} {this.state.data.minGussetUomId} - {this.state.data.maxGussetValue} {this.state.data.maxGussetUomId}</p>
                                </div>
                            </div>
                        </Card>


                        <Card title='SKU Restrictions' subtitle='Information for SKU Restrictions'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Min. Count</label>
                                    <p>{this.state.data.minSkuCount ? this.state.data.minSkuCount : 'N/A'}</p>
                                </div>

                                <div className='attribute col col-4'>
                                    <label>Max. Count</label>
                                    <p>{this.state.data.maxSkuCount ? this.state.data.maxSkuCount : 'N/A'}</p>
                                </div>

                                <div className='attribute col col-4' />
                            </div>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Min. Quantity</label>
                                    <p>{this.state.data.minSkuQuantity ? this.state.data.minSkuQuantity : 'N/A'}</p>
                                </div>

                                <div className='attribute col col-4'>
                                    <label>Max. Quantity</label>
                                    <p>{this.state.data.maxSkuQuantity ? this.state.data.maxSkuQuantity : 'N/A'}</p>
                                </div>

                                <div className='attribute col col-4' />
                            </div>
                        </Card>


                        <Card title={`Additional Required Material ${this.state.data.materialAddedName}`} subtitle='Information for Additional Material'>
                            <div className='row flex'>
                                <div className='attribute col col-4'>
                                    <label>Added Length</label>
                                    <p>{this.state.data.materialAddedLengthValue ? this.state.data.materialAddedLengthValue : 'N/A'}</p>
                                </div>
                                <div className='attribute col col-4'>
                                    <label>Added Width</label>
                                    <p>{this.state.data.materialAddedWidthValue ? this.state.data.materialAddedWidthValue : 'N/A'}</p>
                                </div>
                                <div className='attribute col col-4' />
                            </div>

                        </Card>

                        <Card title='Sales Channels' subtitle='Information for Sales Channels'>
                            <div className='row'>
                                <div className='attribute col col-12'>
                                    {this.state.data.salesChannels}
                                </div>
                            </div>

                        </Card>

                        <Card title='Estimator Features' subtitle='Enable Estimator Features'>
                            <div className='row'>
                                <div className='attribute col col-12'>
                                    {this.state.data.estimatorFeatures}
                                </div>
                            </div>

                        </Card>

                    </div>
                }
            </div>
        );
    }
}


export class PackagingConvertingMethodAttributeEditView extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.param_0) {
            throw new Error("Data Id Property is required");
        }

        this.dataId = this.props.param_0;
        this.state = {
            data: null,
            isValid: false
        };

        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.dataManager = new DataManager('packagingConvertingMethodAttributes', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        var self = this;
        this.dataManager.init()
            .then(dataManager => {
                if (this.dataId) {
                    dataManager.loadContext(this.dataId)
                        .then(data => {
                            //2) bind Controls            
                            self.setState({
                                data: data
                            });
                            dataManager.bindControls();
                        })
                        .catch(() => {
                            dataManager.bindControls();
                        });
                }
                else {
                    dataManager.bindControls();
                }
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    onSaveClick() {
        var self = this;
        self.dataManager.saveContext()
            .then(data => {
                window.Alerts.showSuccess('Converting Method has been saved');
                window.Router.setRoute('/configuration/packaging/attributes/converting/' + encodeURIComponent(self.dataManager.dataObject.id));
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while saving the Converting Method');
            });
    }

    render() {
        return (
            <div>
                {this.state.data &&
                    <div className="view">
                        <ImageTitle text={`Converting Methods - ${this.state.data.name}`} />

                        <ToolbarControl renderButtons={() => {
                            return (
                                <div>
                                    <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting')} />
                                </div>
                            )
                        }} />


                        <Card title='General' subtitle='Information to this Converting Method'>
                            <label htmlFor='description'>Description</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='description' multiline={true} />
                        </Card>


                        <Card title='Sales Channels' subtitle='Information to Sales Channels'>
                            <ChecklistDataControl dataManager={this.dataManager} propertyName='salesChannels' />
                        </Card>

                        <Card title='Estimator Features' subtitle='Enable Estimator Features'>
                            <ToggleSwitchDataListControl dataManager={this.dataManager} propertyName='estimatorFeatures' />
                        </Card>

                        <button className='btn btn-lg btn-primary' disabled={!this.state.isValid} onClick={this.onSaveClick}>Save</button>
                    </div>
                }
            </div>
        )
    }
}


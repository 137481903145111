import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PolicyConfirmation } from '../../../dataControls/PolicyConfirmation';
export class StructureSpec {

    constructor(productGroupId) {
        this.productGroupId = productGroupId;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Structure Spec', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                })
                .catch(messages => {
                    reject(messages);
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/structureSpecs/' + encodeURIComponent(self.productGroupId) + '/publish?testPolicy=1'
            : '/structureSpecs/' + encodeURIComponent(self.productGroupId) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => {

                    if (data.isSuccess) {
                        resolve(data.messages);
                    }
                    else {
                        reject(data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }



    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/structureSpecs/' + encodeURIComponent(self.productGroupId) + '/publish'
            : '/structureSpecs/' + encodeURIComponent(self.productGroupId) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    delete() {
        var self = this;
        return new Promise((resolve, reject) => {
            var url = '/structureSpecs/' + encodeURIComponent(self.productGroupId);
            self.dataAccess.delete(
                url,
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

}
import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { DataManager } from '../../../dataControls/DataManager';
import { ControlDataAccess } from '../../../dataControls/ControlDataAccess';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { DropdownDataControl } from '../../../dataControls/DropdownDataControl';
import { TextboxDataControl } from '../../../dataControls/TextboxDataControl';
import { RoundIndicator } from '../../../controls/RoundIndicator';
import { Logger } from "../../../infrastructure/Logger";

export class StandardSizesEditorView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Standard Dimensions' />
                <StandardSizesInputComponent dataId={this.props.param_0} />
            </div>
        )
    }
}

export class StandardSizesListView extends React.Component {
    constructor(props) {
        super(props);

        this.list = null;
        this.dataAccess = new ControlDataAccess('standardDimensions');

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
        this.onCsvClick = this.onCsvClick.bind(this);
        this.onGoogleClick = this.onGoogleClick.bind(this);
    }

    onDeleteClick(item) {
        var self = this;
        window.Confirmation.show('Delete', 'Are you sure to delete this Standard Dimension?',
            () => {
                self.dataAccess.deleteObject(item.id)
                    .then(() => {
                        self.list.refresh();
                    });
            }
        );
    }

    onPdfClick() {
        var url = '/data/standardDimensions?format=pdf';
        window.location = url;
    }

    onCsvClick() {
        var url = '/data/standardDimensions?format=csv';
        window.location = url;
    }

    onGoogleClick() {
        var url = `/export/standardDimensionsExport`;
        var options = "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes";
        window.open(url, 'ePac Data Export', options);
    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Standard Dimensions' />

                <div className="release-list-view">
                    <ToolbarControl renderButtons={() => {
                        return (
                            <div>
                                <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => { window.Router.setRoute('/configuration/dimensions/new') }} />
                            </div>
                        )
                    }} />

                    <PageableDataObjectList dataObject='standardDimensions'
                        onInit={cntrl => this.list = cntrl}
                        onPdfClick={this.onPdfClick}
                        onCsvClick={this.onCsvClick}
                        onGoogleClick={this.onGoogleClick}
                        onRenderTitleRow={() => {
                            return (
                                <div className='row'>
                                    <div className='col col-1 mobile-hidden'>Valid</div>
                                    <div className='col col-2'>Converting Method</div>
                                    <div className='col col-1'>Length</div>
                                    <div className='col col-1'>Width</div>
                                    <div className='col col-1'>Gusset</div>
                                    <div className='col col-1 mobile-hidden'>UOM</div>
                                    <div className='col col-2 mobile-hidden'>Date</div>
                                    <div className='col col-2 mobile-hidden'>User</div>
                                    <div className='col col-1 mobile-hidden'></div>
                                </div>
                            );
                        }}
                        onRenderRow={(item, idx) => {
                            return (
                                <div key={`row_${idx}`} className='row border-bottom'>
                                    <div className='col col-1 clickable mobile-hidden' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}><RoundIndicator indicate={item.valid == 'True' ? 'success' : 'error'} /></div>
                                    <div className='col col-2 clickable' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.convertingMethodId}</div>
                                    <div className='col col-1 clickable' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.length}</div>
                                    <div className='col col-1 clickable' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.width}</div>
                                    <div className='col col-1 clickable' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.gusset}</div>
                                    <div className='col col-1 clickable mobile-hidden' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.lengthUomId}</div>
                                    <div className='col col-2 clickable mobile-hidden' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.createDate}</div>
                                    <div className='col col-2 clickable mobile-hidden' onClick={() => { window.Router.setRoute(`/configuration/dimensions/${encodeURIComponent(item.id)}`) }}>{item.createUser}</div>
                                    <div className='col col-1 mobile-hidden'><button className='btn btn-secondary-outline btn-sm pull-right' onClick={() => self.onDeleteClick(item)}><span className='glyphicon glyphicon-trash'></span></button></div>
                                </div>
                            );
                        }} />
                </div>
            </div>
        );
    }
}


var ComponentTypes = {
    ThreeSideSeal: 1,
    RollStock: 2,
    StandupPouch: 3,
    SideGussetPouch: 4,
    FlatBottomPouch: 5
}


export class StandardSizesInputComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isValid: false,
            componentType: null
        }

        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);

        this.dataManager = new DataManager('standardDimensions', this.onControlBound, this.onControlUnbound, this.onValidated);
        this.onConvertingMethodChange = this.onConvertingMethodChange.bind(this);
    }

    // Initialize and Bind Data Controls 
    componentDidMount() {
        var self = this;

        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(data => {

                            self.setState({
                                componentType: data.convertingMethod.componentTypeId
                            });

                            //2) bind Controls                            
                            dataManager.bindControls();
                        })
                        .catch(error => {
                            Logger.writeError("Could not load Feedback", error);
                            window.Router.setNotFound();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                }

                //3) onControlBound --> Set Value
            });
    }

    // Is invoked when a control was bound
    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    // Is invoked when the data context is validated by the data manager
    onValidated(isValid) {

        this.setState({
            isValid: isValid
        });
    }

    // Is invoked when a control was unbound
    onControlUnbound(control, propertyName) {
    }

    onSaveClick() {
        if (this.props.modal) {
            this.props.modal.close();
        }

        this.dataManager.saveContext(true)
            .then(data => {
                window.Alerts.showSuccess('The dimension has been saved.');
                window.Router.setRoute('/configuration/dimensions');
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while saving. Please try again.');
            });
    }

    onConvertingMethodChange(value, ctrl, item) {

        if (item) {
            switch (item.componentTypeId) {
                case ComponentTypes.ThreeSideSeal:
                    this.setState({
                        componentType: ComponentTypes.ThreeSideSeal,
                        isValid: false
                    });
                    break;
                case ComponentTypes.StandupPouch:
                    this.setState({
                        componentType: ComponentTypes.StandupPouch,
                        isValid: false
                    });
                    break;
                case ComponentTypes.RollStock:
                    this.setState({
                        componentType: ComponentTypes.RollStock,
                        isValid: false
                    });
                    break;
                case ComponentTypes.SideGussetPouch:
                    this.setState({
                        componentType: ComponentTypes.SideGussetPouch,
                        isValid: false
                    });
                    break;
                case ComponentTypes.FlatBottomPouch:
                    this.setState({
                        componentType: ComponentTypes.FlatBottomPouch,
                        isValid: false
                    });
                    break;
                default:
                    throw new Error('Component Type ' + item.componentTypeId + ' is not supported');
            }
        }
    }


    render() {
        return (
            <div className='view'>

                <div className='row'>
                    <div className='col-sm-12'>
                        <label htmlFor='convertingMethodId'>Converting Method</label>
                        <DropdownDataControl dataManager={this.dataManager} propertyName='convertingMethodId' onChange={this.onConvertingMethodChange} />
                    </div>
                </div>

                {this.state.componentType === ComponentTypes.ThreeSideSeal &&
                    <div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='convertingMethodId'>UOM</label>
                                <DropdownDataControl dataManager={this.dataManager} propertyName='lengthUomId' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='length'>Length</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='length' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='width'>Width</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='width' />
                            </div>
                        </div>
                    </div>
                }

                {this.state.componentType === ComponentTypes.StandupPouch &&
                    <div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='convertingMethodId'>UOM</label>
                                <DropdownDataControl dataManager={this.dataManager} propertyName='lengthUomId' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='length'>Length</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='length' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='width'>Width</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='width' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='gusset'>Bottom Gusset</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='gusset' />
                            </div>
                        </div>
                    </div>
                }

                {this.state.componentType === ComponentTypes.SideGussetPouch &&
                    <div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='convertingMethodId'>UOM</label>
                                <DropdownDataControl dataManager={this.dataManager} propertyName='lengthUomId' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='length'>Length</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='length' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='width'>Width</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='width' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='gusset'>Side Gusset</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='gusset' />
                            </div>
                        </div>
                    </div>
                }

                {this.state.componentType === ComponentTypes.FlatBottomPouch &&
                    <div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='convertingMethodId'>UOM</label>
                                <DropdownDataControl dataManager={this.dataManager} propertyName='lengthUomId' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='length'>Length</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='length' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='width'>Width</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='width' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='gusset'>Side Gusset</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='gusset' />
                            </div>
                        </div>
                    </div>
                }

                {this.state.componentType === ComponentTypes.RollStock &&
                    <div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='convertingMethodId'>UOM</label>
                                <DropdownDataControl dataManager={this.dataManager} propertyName='lengthUomId' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='width'>Web Width</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='width' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <label htmlFor='length'>Repeat</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='length' />
                            </div>
                        </div>
                    </div>
                }

                <button className='btn btn-lg btn-primary' disabled={!this.state.isValid} onClick={this.onSaveClick}>Submit</button>
                <button className='btn btn-lg btn-secondary-outline pull-right' onClick={() => { window.Router.setRoute('/configuration/dimensions') }}>Cancel</button>

            </div>
        )
    }
}

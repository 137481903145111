import React from 'react';

import './ToggleSwitchControl.css';
export class ToggleSwitchControl extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.on = this.props.on === true;

        this.state = {
            on: this.on
        };

    }

    onClick() {

        if (this.props.disabled !== true) {            
            var newValue = !this.on;
            this.setValue(newValue);

            if (this.props.onChange) {
                this.props.onChange(newValue, this);
            }
        }
    }

    setValue(value) {
        this.on = value == true;

        this.setState({
            on: this.on
        });

    }

    getValue() {
        return this.on;
    }

    getLabel() {

        var on = this.getValue();
        if (on) {
            if (this.props.onLabel) {
                return this.props.onLabel;
            }
        }
        else {
            if (this.props.offLabel) {
                return this.props.offLabel;
            }
        }
    }

    render() {
        return (            
            <div>
                <div className={this.props.disabled ? 'control toggle-switch' : 'control toggle-switch disabled'}>
                    <div className={this.state.on === true ? 'on' : 'off'}>
                        <div className='container' onClick={this.onClick}>
                            <div className='switch'>
                            </div>
                        </div>
                        <div className='turned-on-label'>
                            <span>{this.getLabel()}</span>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

import React from 'react';

export class ToolbarControl extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.renderButtons) {
            throw new Error("Render Buttons Property requires a collection of buttons");
        }
    }

    render() {
        return (
            <div className='toolbar'>
                {this.props.renderButtons()}
            </div>
        );
    }
}


export class ToolbarButton extends React.Component {


    render() {
        return (
            <button className={this.props.className ? 'btn btn-primary btn-tb ' + this.props.className : 'btn btn-primary btn-tb'} disabled = { this.props.disabled === true } onClick = { this.props.onClick } > <span className={this.props.icon ? 'glyphicon ' + this.props.icon : 'glyphicon glyphicon-cog'} /> <span className='text'> {this.props.text}</span></button>
        );
    }

}




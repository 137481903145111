import React from 'react';
import { ImageTitle } from '../../core/ImageTitle';
import { Tile } from '../../core/Tile';


export class ConfigurationHomeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Configuration' />
                <div className='tile-container'>
                    <Tile title='Industries' onClick={() => window.Router.setRoute('/configuration/industries')} />
                    <Tile title='Sales Channels' onClick={() => window.Router.setRoute('/configuration/saleschannels')} />
                    <Tile title='Estimator Features' onClick={() => window.Router.setRoute('/configuration/estimatorfeatures')} />
                    <Tile title='Product Attributes' onClick={() => window.Router.setRoute('/configuration/products/attributes')} />
                    <Tile title='Packaging Attributes' onClick={() => window.Router.setRoute('/configuration/packaging/attributes')} />
                    <Tile title='Packaging Features' onClick={() => window.Router.setRoute('/configuration/feature/attributes')} />
                    <Tile title='Structure Spec Sheets' onClick={() => window.Router.setRoute('/configuration/structurespecs')} />
                    <Tile title='Standard Dimensions' onClick={() => window.Router.setRoute('/configuration/dimensions')} />
                    <Tile title='Regional Support' onClick={() => window.Router.setRoute('/configuration/regions')} />
                    <Tile title='Press Configuration' onClick={() => window.Router.setRoute('/configuration/press')} />
                    <Tile title='Dieline & 3D Model' onClick={() => window.Router.setRoute('/configuration/dieline')} />
                    <Tile title='Settings' onClick={() => window.Router.setRoute('/configuration/settings')} />
                    <Tile title='Releases' onClick={() => window.Router.setRoute('/configuration/releases')} />
                </div>
            </div>
        );
    }
}


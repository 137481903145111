import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PolicyConfirmation } from '../../../dataControls/PolicyConfirmation';


export class InputControlType {
    static FreeTextItem = 1;
    static FreeTextTuple = 2;
    static SingleChoice = 3;
    static MultipleChoice = 4;
}


export class Rule {

    constructor(ruleId) {
        this.ruleId = ruleId;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Hang Hole', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {                                        
                                        if (error.Messages && error.Messages.length > 0) {
                                            var txt = 'The rule can not be published.';
                                            error.Messages.forEach(item => {
                                                txt += ' ' + item;
                                            });
                                            window.Alerts.showError(txt);
                                            resolve();
                                        }
                                        else {
                                            reject(error);
                                        }                                                                               
                                    })
                            }
                            else {
                                resolve();
                            }
                        })
                })
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/rules/' + encodeURIComponent(self.ruleId) + '/publish?testPolicy=1'
            : '/rules/' + encodeURIComponent(self.ruleId) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/rules/' + encodeURIComponent(self.ruleId) + '/publish'
            : '/rules/' + encodeURIComponent(self.ruleId) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }




    executePrioritizeUp() {
        var self = this;
        var url = '/rules/' + encodeURIComponent(self.ruleId) + '/up';
        return new Promise(function (resolve, reject) {
            self.dataAccess.put(url, null)
                .then(data => { resolve(data); })
                .catch(error => { reject(error); });
        });
    }

    executePrioritizeDown() {
        var self = this;
        var url = '/rules/' + encodeURIComponent(self.ruleId) + '/down';
        return new Promise(function (resolve, reject) {
            self.dataAccess.put(url, null)
                .then(data => { resolve(data); })
                .catch(error => { reject(error); });
        });
    }

    executeDelete() {
        var self = this;
        var url = '/rules/' + encodeURIComponent(self.ruleId);
        return new Promise(function (resolve, reject) {
            self.dataAccess.delete(url)
                .then(data => { resolve(data); })
                .catch(error => { reject(error); });
        });        
    }
}
import React from 'react';
import { Url } from './Utils';
import { Logger } from "../infrastructure/Logger";

var RegexPattern = {
    Number: '[0-9]+',
    Text: '[-0-9a-zA-Z]+'
}

export class RouteRequest {
    constructor(location) {
        this.pathName = location.pathname;
        this.search = location.search;
        this.fullUrl = this.search ? this.pathName + this.search : this.pathName;
    }

    getQuery() {
        var result = {};
        var self = this;
        var names = Url.getQueryStringParameterNames(self.fullUrl);
        if (names) {
            names.forEach(function (name) {
                var value = Url.getQueryStringParameterValue(self.fullUrl, name);
                result[name] = value;
            });
        }
        return result;
    }

    getPathComponents() {
        return RouteRequest.getPathComponents(this.pathName);
    }

    static getPathComponents(pathName) {
        var result = [];
        var comps = pathName.split('/');
        comps.forEach(function (comp) {
            if (comp) {
                result.push(comp);
            }
        });
        return result;
    }

    static GetCurrent() {
        return new RouteRequest(window.location);
    }
}

export class Route {
    constructor(urlTemplate) {
        this.urlTemplate = urlTemplate.toLowerCase();
        this.urlPattern = this.urlTemplate
            .replace(new RegExp('{id}', 'g'), RegexPattern.Number)
            .replace(new RegExp('{txt}', 'g'), RegexPattern.Text)
    }

    matches(pathName) {
        var result = false;
        if (pathName && pathName.length > 0) {
            var regex = new RegExp(this.urlPattern);
            var match = pathName.toLowerCase().match(regex);
            var r1 = RouteRequest.getPathComponents(pathName);
            var r2 = this.getUrlTemplateComponents(true);
            result = match && r1.length == r2.length ? true : false;
        }
        return result;
    }

    getUrlTemplateComponents(includeTokens) {
        var result = [];
        var comps = this.urlTemplate.split('/');
        comps.forEach(function (comp) {
            if (comp) {
                if (includeTokens !== true) {
                    if (comp !== '{id}' && comp !== '{txt}')
                        result.push(comp);
                }
                else {
                    result.push(comp);
                }
            }
        });
        return result;
    }
}

export class Component {
    constructor(control, title, metaData) {
        this.control = control;
        this.title = title;
        this.metaData = metaData;
    }
}

export class RouteComponent {
    constructor(route, component) {
        this.route = route;
        this.component = component;
    }

    static GetNotFound() {
        var route = new Route('/notfound');
        var component = new Component(<NotFoundView />, 'Not Found');
        return new RouteComponent(route, component);
    }

}

export class RouteComponentRegistry {

    constructor() {
        this.routeComponents = {};
    }

    register(url, control, title, metaData) {
        var route = new Route(url);
        var component = new Component(control, title, metaData);
        var routeComponent = new RouteComponent(route, component);
        this.routeComponents[route.urlPattern] = routeComponent;
    }

    getRouteComponent(url) {
        var result = null;
        var self = this;
        if (url && url.length > 0) {
            var currentUrl = url.toLowerCase();
            var routeComponent = this.routeComponents[currentUrl];
            if (routeComponent) {
                result = routeComponent;
            }
            else {
                Object.keys(this.routeComponents).forEach((urlPattern) => {
                        var routeComponent = self.routeComponents[urlPattern];

                        if (routeComponent.route.matches(currentUrl)) {
                            if (urlPattern !== '/') {
                                result = routeComponent;
                            }
                        }
                    }
                );
            }
        }
        return result;
    }
}


window.onpopstate = function (e) {
    var request = RouteRequest.GetCurrent();
    var url = request.pathName;
    var search = request.search;
    window.Router.setRoute(url, search);
}

window.Router = (function (router) {
    router.registry = new RouteComponentRegistry();
    router.control = null;

    router.register = function (cntrl) {
        router.control = cntrl;
    }

    router.registerRoute = function (relativeUrl, component, title, metaData) {
        router.registry.register(relativeUrl, component, title, metaData);
    }


    router.getRouteComponent = function (url) {
        return router.registry.getRouteComponent(url);
    }

    router.setRoute = function (url) {
        window.history.pushState(url, "ePac", url);

        //Application.collapseNavigation();

        if (router.control) {
            url = Url.getPathName(url);
            router.control.setRoute(url);
            document.documentElement.scrollTop = 0;
        }
        else {
            document.location = url;
        }
    }

    router.setNotFound = function () {

        //Application.collapseNavigation();
        router.control.setNotFound();
    }


    return router;
}(window.Router = window.Router || {}));






export class RouteContent extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.onRegister) {
            throw Error('No routes defined');
        }

        this.currentView = null;
        this.props.onRegister(window.Router);

        this.request = RouteRequest.GetCurrent();

        var initialRoute = this.getRouteInfo(this.request.pathName, this.request.search)
        if (this.props.onRouting) {
            this.props.onRouting(this, initialRoute);
        }

        this.state = {
            routeInfo: initialRoute ? initialRoute : RouteComponent.GetNotFound()
        };

    }

    componentDidMount() {
        window.Router.register(this);
    }

    registerCurrentView(view) {
        this.currentView = view;
    }

    getParameters(request, routeComponent) {
        var pathComponents = request.getPathComponents();
        var templateComponents = routeComponent.route.getUrlTemplateComponents();
        templateComponents.forEach(function (templateComponent) {
            var idx = pathComponents.indexOf(templateComponent);
            if (idx > -1) {
                pathComponents.splice(idx, 1)
            }
        });

        return pathComponents;
    }

    getRouteInfo(url) {
        var routeInfo = window.Router.getRouteComponent(url);
        if (routeInfo) {
            Logger.writeDebug("Router: using route template", routeInfo.route);

            var request = RouteRequest.GetCurrent();
            var query = request.getQuery();
            var parameters = this.getParameters(request, routeInfo);

            var props = {};
            if (query) {
                Object.keys(query).forEach((key) => {
                    props['query_' + key] = query[key];
                });
            }

            if (parameters) {
                parameters.forEach((parameter, idx) => {
                    props[`param_${idx}`] = parameter;
                });

                props['router'] = this;
                props['notificationSubscribers'] = this.props.notificationSubscribers;

                var comp = React.cloneElement(routeInfo.component.control, props);
                routeInfo.component.control = comp;
            }
            
            return routeInfo;
        }
        else {
            Logger.writeDebug("Router: No managed route found", url);
            return null;
        }
    }

    reset() {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.setState({
                routeInfo: null
            });
            resolve();
        });
    }

    setNotFound() {
        this.setState({
            routeInfo: RouteComponent.GetNotFound()
        });
    }

    setRoute(url) {
        var self = this;
        Logger.writeDebug('Router: setting route', url);
        if (this.currentView) {
            if (this.currentView.onLeavingView) {
                this.currentView.onLeavingView()
                    .then(result => {
                        var proceed = result == false ? false : true;
                        if (proceed == true) {
                            self.executeRoute(url);
                        }
                    });
            }
            else {
                self.executeRoute(url);
            }
        }
        else {
            self.executeRoute(url);
        }
    }

    executeRoute(url) {
        var self = this;
        this.currentView = null;
        this.reset()
            .then(() => {
                var routeInfo = self.getRouteInfo(url);
                if (self.props.onRouting) {
                    self.props.onRouting(self, routeInfo);
                }
                if (routeInfo) {
                    document.title = routeInfo.component.title ? routeInfo.component.title : 'Products';
                    self.setState({
                        routeInfo: routeInfo
                    });
                } else {
                    document.location = url;
                }
            });
    }

    render() {
        return (
            <div>
                {this.state && this.state.routeInfo &&
                    <div>
                        {this.state.routeInfo.component.title && <h1>{this.state.routeInfo.component.title}</h1>}
                        {this.state.routeInfo.component.control}
                    </div>
                }
            </div>
        );
    }
}


export class NotFoundView extends React.Component {

    render() {
        return (
            <div className="view">
                <p>The page you are looking for does not exist.</p>
            </div>
        );
    }
}

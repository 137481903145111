import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { DropdownControl } from '../../../controls/DropdownControl';
import { RoundIndicator } from '../../../controls/RoundIndicator';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { Spacer } from '../../../components/Containers';
import { Logger } from "../../../infrastructure/Logger";

export class StructureSpecRegistry extends React.Component {

    constructor(props) {
        super(props);

        this.list = null;

        this.filterId = 0;
        this.filter = null;
        this.state = {
            filterName: 'All'
        };

        this.onPublishToggle = this.onPublishToggle.bind(this);
        this.onDefineClick = this.onDefineClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onFilterApplied = this.onFilterApplied.bind(this);
        this.onDiscontinueClick = this.onDiscontinueClick.bind(this);
    }


    onPublishToggle(item, value, toggle) {
        if (this.props.onPublishToggle) {
            this.props.onPublishToggle(item, value, this.list, toggle);
        }
    }

    onDefineClick(item) {
        if (this.props.onItemSelected) {
            this.props.onItemSelected(item);
        }
    }

    onFilterClick() {
        this.filterId++;        
        if (this.filterId > 2) {
            this.filterId = 0;
        }

        switch (this.filterId) {
            case 0:
                this.filter = null;
                break;
            case 1:
                this.filter = 'published=1';
                break;
            case 2:
                this.filter = 'published=0';
                break;
        }
        this.list.refresh(this.filter, true);

        this.setState({
            filterName: this.getFilterName()
        });

    }

    getFilterName() {
        switch (this.filterId) {
            case 0:
                return 'All';
                break;
            case 1:
                return 'Published';
                break;
            case 2:
                return 'Unpublished';
                break;
        }
    }

    onFilterApplied(txt) {
        this.filter = "name like '" + txt + "%'";
        this.filterId = 0;

        this.setState({
            filterName: this.getFilterName()
        });

        this.list.refresh(this.filter, true);
    }

    onDiscontinueClick(item) {
        var self = this;
        window.Modal.show(function (popup) {
            return (
                <DiscontinuePopupContent popup={popup} spec={item} onScheduled={() => { self.list.refresh(self.filter, true);}} />
            )
        }, 'Discontinue Material: '+item.name)
    }

    restoreStructureSpecClick(item) {
        var self = this;
        window.Confirmation.show(`Reset Discontiuation for ${item.name}`, 'This will cancel the schedule for discontinuation for this structure spec. Do you want to continue?',
            () => {
                var dataAccess = new ApiDataAccess('/data');
                dataAccess.post(`/StructureSpecs/${item.id}/discontinue`, {})
                    .then(item => {
                        self.list.refresh(self.filter, true);
                    })
                    .catch(error => {
                        window.Alerts.showError('The schedule was cancelled due to an error!');
                    })
            }            
        );
    }

    getFormattedDate(dateStr) {
        if (dateStr) {
            var date = new Date(dateStr);
            return date.toLocaleDateString();
        }
        else {
            return null;
        }        
    }

    render() {
        var self = this;
        return (
            <div>
                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            {window.CONFIG.JOB_TEMPLATES_DISABLED
                                ? <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/structurespecs/new')} />
                                : <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/structurespecs/import')} />
                            }                            
                            <ToolbarButton icon='glyphicon glyphicon-filter' text={this.state.filterName} onClick={this.onFilterClick} />
                        </div>
                    )
                }} />

                <PageableDataObjectList dataObject='structureSpecsRegistry' onInit={list => { this.list = list }} onFilterApplied={this.onFilterApplied}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-1'>Name</div>
                                <div className='col col-1'>Valid</div>
                                <div className='col col-4'>Spec</div>
                                <div className='col col-1 mobile-hidden'>Width</div>
                                <div className='col col-1 mobile-hidden'>Price Change (%)</div>
                                <div className='col col-1 mobile-hidden'>User</div>
                                <div className='col col-1 mobile-hidden'>Date</div>
                                <div className='col col-1 mobile-hidden'><div className='pull-left'>Expiration</div></div>
                                <div className='col col-1 mobile-hidden'><div className='pull-right'>Published</div></div>
                            </div>
                        )
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row'>
                                <div className='col col-1 clickable' onClick={() => self.onDefineClick(item)}>{'SPEC-' + item.id}</div>
                                <div className='col col-1 clickable' onClick={() => self.onDefineClick(item)}><RoundIndicator indicate={item.isValid == 'True' ? 'success' : 'error'} text={item.reason} /></div>
                                <div className='col col-4 clickable' onClick={() => self.onDefineClick(item)}>{item.name}</div>
                                <div className='col col-1 clickable mobile-hidden' onClick={() => self.onDefineClick(item)}>{item.filmWidth && parseFloat(item.filmWidth) > 0 ? parseFloat(item.filmWidth).toFixed(2) : 'N/A'}</div>
                                <div className='col col-1 clickable mobile-hidden' onClick={() => self.onDefineClick(item)}>{item.defaultAreaPriceChangePercentage ? parseFloat(item.defaultAreaPriceChangePercentage).toFixed(2) : ''}</div>
                                <div className='col col-1 clickable mobile-hidden' onClick={() => self.onDefineClick(item)}>{item.createUser}</div>
                                <div className='col col-1 clickable mobile-hidden' onClick={() => self.onDefineClick(item)}>{item.createDate}</div>

                                <div className='col col-1 mobile-hidden'>
                                    {item.isDefined === 'True' && item.isPublished === 'True' && item.referencedAsReplacementCount === '0' && !item.discountinueSpecReplacementId &&
                                        <div className='pull-left'>
                                            <button className='btn btn-sm btn-secondary-outline' onClick={() => self.onDiscontinueClick(item)}>Discontinue</button>
                                        </div>
                                    }

                                    {parseInt(item.discountinueSpecReplacementId) > 0 && 
                                        <div className='pull-left'>
                                            <button className='btn btn-sm btn-secondary-outline' onClick={() => self.restoreStructureSpecClick(item)}>{self.getFormattedDate(item.discontinueDate)}</button>
                                        </div>
                                    }
                                </div>

                                <div className='col col-1 mobile-hidden'>
                                    {item.isDefined === 'True' && !item.discountinueSpecReplacementId &&
                                        <div className='pull-right'>
                                            <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                        </div>
                                    }

                                    {item.isDefined !== 'True' &&
                                        <div className='pull-right'>
                                            <button className='btn btn-sm btn-primary' onClick={() => self.onDefineClick(item)}>Define</button>
                                        </div>
                                    }

                                </div>
                            </div>
                        )
                    }} />
            </div>
        );
    }
}



class DiscontinuePopupContent extends React.Component {

    constructor(props) {
        super(props);
        var self = this;

        this.popup = this.props.popup;
        this.spec = this.props.spec;

        this.apiDataAccess = new ApiDataAccess('/data');
        this.onDaysSelectionChanged = this.onDaysSelectionChanged.bind(this);
        this.onSpecSelectionChanged = this.onSpecSelectionChanged.bind(this);
        this.onScheduleClick = this.onScheduleClick.bind(this);

        this.days = [
            { id: 1, text: '30 Days', days: 30 },
            { id: 2, text: '60 Days', days: 60 },
            { id: 3, text: '90 Days', days: 90 },
            { id: 4, text: '120 Days', days: 120 }
        ];

        this.state = {
            days: this.days,
            specs: null,
            selectedDay: 0,
            selectedSpec: 0
        };

        this.day = 0;
        this.replacementSpecId = 0;


        this.getDataSourceItems('StructureSpecs')
            .then(items => {
                if (items && items.length > 0) {
                    var idx = items.findIndex(item => item.id == this.spec.id);
                    if (idx >= 0) {
                        items.splice(idx, 1);
                    }

                    var result = [];
                    items.forEach(item => {
                        if (!item.discountinueDate && item.isPublished === true) {
                            result.push(item);
                        }
                    });

                    self.setState({
                        specs: result
                    });

                }
            });
    }



    getDataSourceItems(datasourceName) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.apiDataAccess.get(`/datasources/${encodeURI(datasourceName)}`)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    Logger.writeError('ControlDataAccess: getDataSourceItems ' + datasourceName, error);
                    reject(error);
                });
        });
    }


    onScheduleClick() {
        var self = this;
        this.popup.close();

        var request = {
            replacementStructureSpecId: this.replacementSpecId,
            daysFromToday: this.days
        };

        this.apiDataAccess.post(`/StructureSpecs/${this.spec.id}/discontinue`, request)
            .then(item => {
                if (self.props.onScheduled) {
                    self.props.onScheduled(item);
                }
            })
            .catch(error => {
                window.Alerts.showError('The material was not scheduled for discontinuation due to an error!');
            });
    }

    onDaysSelectionChanged(value, item) {
        this.days = item ? item.days : 0;

        this.setState({
            selectedDay: this.days
        });
    }

    onSpecSelectionChanged(value, item) {        
        this.replacementSpecId = item ? item.id : 0;

        this.setState({
            selectedSpec: this.replacementSpecId
        });
    }


    render() {
        return (
            <div>
                {this.state.days &&
                    <div>
                        <label htmlFor='days'>Expiration in:</label>
                        <DropdownControl id="days" items={this.state.days} valueMemberName='id' displayMemberName='text' onChange={this.onDaysSelectionChanged} />
                    </div>
                }

                {this.state.specs &&
                    <div>
                        <label htmlFor='days'>Replaced by:</label>
                        <DropdownControl id="specs" items={this.state.specs} valueMemberName='id' displayMemberName='name' onChange={this.onSpecSelectionChanged} />
                    </div>
                }

                <Spacer>
                    <button className='btn btn-primary' disabled={!this.state.selectedDay || !this.state.selectedSpec} onClick={this.onScheduleClick}>Schedule</button>
                    <button className='btn btn-secondary pull-right' onClick={() => { this.popup.close(); }}>Cancel</button>
                </Spacer>
            </div>
        )
    }

}
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { DataSourceDataAccess, DataSource } from '../../../infrastructure/DataSourceDataAccess';
import { Logger } from "../../../infrastructure/Logger";

var ActionType = {
    None: 0,
    Create: 1,
    Update: 2,
    Delete: 3
}

export class RuleDataManager {

    constructor() {
        this.rulesDataAccess = new ApiDataAccess('/data/rules');
        this.ruleTriggerDefinitionDataAccess = new DataSourceDataAccess('ruleTriggerDefinitions');
        this.ruleTargetDefinitionDataAccess = new DataSourceDataAccess('ruleTargetDefinitions');
        this.physicalPropertiesDataAccess = new DataSourceDataAccess('physicalProperties');
        this.totalRuleTriggerDefinitionCount = 0;
        this.totalRuleTargetDefinitionCount = 0;

        this.id = 0;
        this.name = null;
        this.triggers = [];
        this.targets = [];
        this.triggerCallbacks = [];
        this.targetCallbacks = [];
    }

    setId(id) {
        this.id = id;
    }

    reset() {
        this.id = 0;
        this.name = null;
        this.triggers = [];
        this.targets = [];
    }

    load(id) {
        var self = this;
        return new Promise((resolve, reject) => {
            self.reset();
            self.getRuleTriggerDefinitionDataSouce()
                .then(() => {
                    self.getRuleTargetDefinitionDataSouce()
                        .then(() => {
                            self.rulesDataAccess.get('/' + encodeURIComponent(id))
                                .then(data => { resolve(data); })
                                .catch(error => { reject(error); });
                        })
                        .catch(ex => {
                            reject(ex);
                        })
                })
                .catch(ex => {
                    reject(ex);
                })
        });
    }

    save() {
        var self = this;
        return new Promise((resolve, reject) => {
            if (self.isValid()) {
                var request = {
                    id: self.id,
                    name: self.name,
                    triggers: self.triggers,
                    targets: self.targets
                };

                self.rulesDataAccess.post('/', request)
                    .then(data => { resolve(data); })
                    .catch(error => { reject(error); });
            }
            else {
                reject('Rule cannot be saved since it is not valid!');
            }
        });
    }

    isValid() {
        return this.name && this.name.length > 0
            && this.triggers && this.triggers.length > 0
            && this.targets && this.targets.length > 0;
    }

    getTotalRuleTargetDefinitionCount() {
        return this.totalRuleTargetDefinitionCount;
    }

    getTotalRuleTriggerDefinitionCount() {
        return this.totalRuleTriggerDefinitionCount;
    }

    registerTriggerCallback(callback) {
        this.triggerCallbacks.push(callback);
    }

    registerTargetCallback(callback) {
        this.targetCallbacks.push(callback);
    }

    invokeTriggerCallbacks(actionType, trigger) {
        this.triggerCallbacks.forEach(callback => {
            try {
                callback(actionType, trigger);
            }
            catch (ex) {
                Logger.writeError('Error while invoking trigger callback', ex)
            }
        })
    }

    invokeTargetCallbacks(actionType, trigger) {
        this.targetCallbacks.forEach(callback => {
            try {
                callback(actionType, trigger);
            }
            catch (ex) {
                Logger.writeError('Error while invoking target callback', ex)
            }
        })
    }

    setName(txt) {
        this.name = txt;
    }

    getName() {
        return this.name;
    }




    getRuleTriggerDefinitionDataSouce() {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.ruleTriggerDefinitionDataAccess.getDataSource()
                .then(dataSource => {
                    var dataSourceItems = dataSource.getItems();
                    self.totalRuleTriggerDefinitionCount = dataSourceItems.length;
                    var items = [];
                    dataSourceItems.forEach(item => {
                        if (!self.definitionExists(self.triggers, item)) {
                            items.push(item);
                        }
                    });
                    resolve(new DataSource(items));
                })
                .catch(errors => {
                    reject(errors);
                });
        });
    }

    getRuleTargetDefinitionDataSouce() {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.ruleTargetDefinitionDataAccess.getDataSource()
                .then(dataSource => {
                    var dataSourceItems = dataSource.getItems();
                    self.totalRuleTargetDefinitionCount = dataSourceItems.length;
                    var items = [];
                    dataSourceItems.forEach(item => {
                        if (!self.definitionExists(self.targets, item)) {
                            items.push(item);
                        }
                    });
                    resolve(new DataSource(items));
                })
                .catch(errors => {
                    reject(errors);
                });
        });
    }

    getPhysicalPropertiesDataSource() {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.physicalPropertiesDataAccess.getDataSource()
                .then(dataSource => {                    
                    resolve(dataSource);
                })
                .catch(errors => {
                    reject(errors);
                });
        });
    }

    addTrigger(trigger) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.triggers.push(trigger);
            self.invokeTriggerCallbacks(ActionType.Create, trigger);
            resolve(self.triggers)
        });
    }

    addTarget(target) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.targets.push(target);
            self.invokeTargetCallbacks(ActionType.Create, target);
            resolve(self.targets)
        });
    }


    removeTrigger(trigger) {
        var self = this;
        return new Promise(function (resolve, reject) {

            var idx = self.triggers.findIndex(item => {
                return item.definition.id == trigger.definition.id;
            });

            if (idx >= 0) {
                self.triggers.splice(idx, 1);
                self.invokeTriggerCallbacks(ActionType.Delete, trigger);
            }

            resolve(self.triggers);
        });
    }

    removeTarget(target) {
        var self = this;
        return new Promise(function (resolve, reject) {

            var idx = self.targets.findIndex(item => {
                return item.definition.id == target.definition.id;
            });

            if (idx >= 0) {
                self.targets.splice(idx, 1);
                self.invokeTargetCallbacks(ActionType.Delete, target);
            }

            resolve(self.targets);
        });
    }

    getTriggers() {
        return this.triggers;
    }

    getTargets() {
        return this.targets;
    }

    definitionExists(pool, definition) {
        return pool.find(item => {
            return item.definition.id == definition.id;
        })
    }
}


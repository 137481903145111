import React from 'react';
import { ToggleSwitchControl } from '../controls/ToggleSwitchControl';



export class ToggleSwitchDataListControl extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        if (this.props.onValue < 0) {
            throw new Error("PropertyName onValue is required");
        }

        if (this.props.offValue < 0) {
            throw new Error("PropertyName offValue is required");
        }


        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);

        this.metaData = null;

        this.state = {
            items: [],
            value: null,
            on: false,
            onLabelText: '',
            offLabelText: '',
            valueMemberName: null,
            displayMemberName: null,
            datasourceItems: []
        }

        this.onChange = this.onChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var dataSourceData = metaData.dataSourceData;

            if (!dataSourceData) {
                throw new Error(" ToggleSwitchDataControl requires Data Source Data");
            }

            self.dataManager.getDataSourceItems(dataSourceData.name)
                .then(items => {

                    self.items = items;
                    self.datasourceItems = items;

                    self.setState({
                        items: items,
                        datasourceItems: items
                    });

                    resolve(self);
                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    getItemByValue(items, value) {
        var result = null;
        var dataSourceData = this.metaData.dataSourceData;
        if (items && items.length > 0) {
            items.forEach(function (item) {
                if (parseInt(item[dataSourceData.valueMemberName]) == parseInt(value)) {
                    result = item;
                }
            });
        }
        return result;
    }

    setValue(value) {
        var items = [];

        if (value) {
            value.forEach(item => {

                var datasourceItem = this.items.find(i => {
                    return i.id === item.dataObject2Id;
                });

                if (datasourceItem) {
                    var data = {
                        id: item.dataObject2Id,
                        name: datasourceItem.name,
                        sort: value.sort,
                        dataObject2Id: item.dataObject2Id
                    };
                    items.push(data);
                }
            });
        }

        this.setState({
            value: items
        });
        this.dataManager.reportChange(items, this);
    }


    getValue() {
        var values = this.state.value;

        var relationships = [];
        values.forEach(value => {
            var relationship = {
                sort: value.sort,
                dataObject2Id: value.id,
                name: value.name,
                id: value.id
            }
            relationships.push(relationship);
        });

        return relationships;
    }

    //onSelectionChange(items) {
    //    console.log("itemsss: ", items)
    //    var self = this;
    //    items.sort((a, b) => a.sort - b.sort);
    //    for (var i = 0; i < items.length; i++) {
    //        items[i].sort = i;
    //    }

    //    var value = [];
    //    items.forEach(item => {
    //        var datasourceItem = self.getDataSourceItem(item.id);
    //        var data = {
    //            id: item.id,
    //            name: datasourceItem.name
    //        };
    //        value.push(data);
    //    });

    //    this.setState({
    //        value: value
    //    });
    //    this.dataManager.reportChange(value, this);
    //}

    getDataSourceItem(id) {
        return this.state.items.find(item => {
            return item.id === id;
        });
    }

    isInputValid() {
        var result = true;
        return result;
    }

    onChange(value) {
        var newValue = value === true ? this.props.onValue : this.props.offValue;
        this.setValue(newValue);
        this.dataManager.reportChange(newValue, this);
    }

    getValueIndex(id) {
        return this.state.value.findIndex(value => {
            return value.id === id;
        });
    }


    onToggle(toggle, item) {
        var values = this.getValue();

        if (toggle === true) {
            var data = {
                id: item.id,
                name: item.name,
                sort: item.sort,
                dataObject2Id: item.dataObject2Id
                //sort: values.length
            };
            values.push(data);
        }
        else {

            var idx = this.getValueIndex(item.id);
            if (idx >= 0) {
                values.splice(idx, 1);
            }
        }

        this.setState({
            value: values
        });

        this.dataManager.reportChange(values, this);


    }

    isChecked(item) {
        // this looks into the relationship list returned
        var idx = this.getValueIndex(item.id);
        return idx >= 0;
    }
    render() {
        var self = this;
        return (
            <div className='control simple-list'>
                {this.state.items && this.state.items.length > 0 &&
                    <div>
                        {this.state.items.map(function (item, id) {
                            return (
                                <div className='row border-bottom list-item' key={"feature_" + id} >
                                    <div className='content'>{item.name}  <ToggleSwitchControl on={self.isChecked(item)} onChange={(value) => self.onToggle(value, item)} /></div>
                                    <div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }

                {!this.state.items || this.state.items.length == 0 &&
                    <div className='alert alert-info'>The list does not contain any items.</div>
                }

            </div>
        );
    }
}

import React from 'react';
import { DataSourceDataAccess } from '../infrastructure/DataSourceDataAccess';
import { DropdownControl } from './DropdownControl';
import { Logger } from "../infrastructure/Logger";

export class DataSourceDropDownControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataSourceName) {
            throw new Error("dataSourceName is required");
        }

        this.dataSourceDataAccess = new DataSourceDataAccess(this.props.dataSourceName);
        this.control = null;
        this.dataSource = null;
        this.state = {
            items: []
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setDataSource(this.props.dataSourceName);
    }

    setDataSource(name) {
        var self = this;
        this.control.reset();
        var dataSourceDataAccess = new DataSourceDataAccess(name);
        dataSourceDataAccess.getDataSource()
            .then(dataSource => {
                self.dataSource = dataSource;
                self.setState({
                    items: dataSource.getItems()
                });
            })
            .catch(errors => {
                Logger.writeError('Could not retrieve data source items', errors);
            });

        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }


    onChange(value) {
        if (this.props.onChange) {
            var item = this.dataSource.getItem(value);
            this.props.onChange(value, item);
        }
    }

    render() {
        return (
            <DropdownControl id={'ddl'+this.props.id} onInit={ctrl => this.control = ctrl} required={this.props.required} disabled={this.props.disabled} items={this.state.items} value={this.props.value} onChange={this.onChange} valueMemberName='id' displayMemberName='name' />
        );
    }
}
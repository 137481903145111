import React from 'react';
import { GenericDataAccess } from '../dataControls/GenericDataAccess';

export class PagingModule extends React.Component {
    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;
        var listSettings = null;
        
        this.state = {
            pageSize: listSettings ? listSettings.pageSize : 200
        }

        this.timer = null;
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
        this.onCsvClick = this.onCsvClick.bind(this);
        this.onGoogleClick = this.onGoogleClick.bind(this); 
        this.onFilterChanged = this.onFilterChanged.bind(this);
    }

    onPageSizeChanged(event) {
        var pageSize = parseInt(event.target.value);

        if (this.props.onPageSizeChanged) {
            this.props.onPageSizeChanged(pageSize);
        }
        
        this.setState({
            pageSize: pageSize
        });
        

    }

    onPdfClick() {
        if (this.props.onPdfClick) {
            this.props.onPdfClick();
        }
    }

    onCsvClick() {
        if (this.props.onCsvClick) {
            this.props.onCsvClick();
        }
    }

    onGoogleClick() {
        if (this.props.onGoogleClick) {
            this.props.onGoogleClick();
        }
    }

    onFilterChanged(event) {
        var value = event.target.value;
        var self = this;
        if (this.props.onFilterApplied) {
            clearTimeout(this.timer);
            self.timer = setTimeout(() => {
                self.props.onFilterApplied(value);
            }, 500);            
        }
    }

    render() {

        return (
            <div className="paging">

                <div className="button-section pull-left">
                    <button className="btn btn-primary btn-sm no-marging-left" disabled={this.props.isFirst == true} onClick={this.props.onFirstClick}><span className="glyphicon glyphicon-fast-backward"></span></button>
                    <button className="btn btn-primary btn-sm no-marging-left" disabled={this.props.isFirst == true} onClick={this.props.onPreviousClick}><span className="glyphicon glyphicon-step-backward"></span></button>
                </div>


                <div className='filter mobile-hidden'>
                    <select id="page_size_selector" className="control dropdown valid" onChange={this.onPageSizeChanged} value={this.state.pageSize}>
                        <option value='20'>20 Items</option>
                        <option value='50'>50 Items</option>
                        <option value='100'>100 Items</option>
                        <option value='200'>200 Items</option>
                    </select>

                    {this.props.onFilterApplied &&
                        <input className='control textbox' type="text" autoComplete="off" onChange={this.onFilterChanged} placeholder='Filter...' ></input>
                    }
                </div>


                <div className="col">
                    {/*
                        {this.props.enableExport !== false &&
                            <div>                                
                                <img className='img-btn' src="/images/excel.png" onClick={this.onCsvClick} title='Export to Microsoft Excel' alt='Export to Microsoft Excel' />
                                <img className='img-btn' src="/images/sheets.png" onClick={this.onGoogleClick} title='Export to Google Sheets' alt='Export to Google Sheets' />
                            </div>
                        }               
                        */}
                </div>

                <div className="filter mobile-hidden">
                    <div className="center">
                        {(this.props.pageIndex + 1) + ' of ' + this.props.totalPageCount}
                    </div>
                </div>

                <div className="button-section">
                    <button className="btn btn-primary btn-sm no-marging-right pull-right" disabled={this.props.isLast == true} onClick={this.props.onLastClick}><span className="glyphicon glyphicon-fast-forward"></span></button>
                    <button className="btn btn-primary btn-sm no-marging-right pull-right" disabled={this.props.isLast == true} onClick={this.props.onNextClick}><span className="glyphicon glyphicon-step-forward"></span></button>
                </div>              

            </div>
        );
    }
}


class TableModule extends React.Component {
    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    getColumnTitle(id) {
        var metaData = this.props.metaData[id];
        var display = metaData.display;
        if (display) {

            if (!display.columnSizeId || display.columnSizeId == 0) {
                return null;
            }

            if (display.caption) {
                return display.caption;
            }
        }
        return null;
    }

    getColumnWidth(id) {
        var metaData = this.props.metaData[id];
        var display = metaData.display;
        if (display) {
            if (!display.columnSizeId || display.columnSizeId == 0) {
                return 0;
            }
            return display.columnSizeId;
        }
        return 0;
    }

    onDeleteClick(item) {        
        if (this.props.onDeleting) {
            this.props.onDeleting(item);
        }
    }

    onRowSelected(item) {        

        if (this.props.onRowSelected) {
            this.props.onRowSelected(item);
        }
    }


    render() {
        var self = this;
        return (
            <div className='table'>
                <div className="table-header">
                    <div className='row'>
                        <div className='col col-2'/ >
                    {Object.keys(self.props.metaData).map((controlId, index) => {
                        var title = self.getColumnTitle(controlId);
                        if (title) {
                            return (
                                <div className={self.props.applyFormat ? `col col-${self.getColumnWidth(controlId)} ${self.props.applyFormat(index)}` : `col col-${self.getColumnWidth(controlId)}`} key={'header_' + index}>{title}</div>
                            );
                        }
                    })}
                    </div>
                </div>

                <div className='body'>
                {self.props.items.map((item, index1) => {
                    return (
                        <div className="row selectable border-bottom" key={'row_' + index1}>
                            <div className='col col-2'>
                                <button className='btn btn-primary-outline' onClick={() => { self.onDeleteClick(item)}} disabled={self.props.enableDelete == false}><span className='glyphicon glyphicon-trash'></span></button>
                                {!self.props.disableView &&
                                    <button className='btn btn-secondary-outline' onClick={() => { self.onRowSelected(item) }} ><span className='glyphicon glyphicon-eye-open'></span></button>
                                }
                            </div>

                            {Object.keys(self.props.metaData).map((controlId, index2) => {
                                var title = self.getColumnTitle(controlId);
                                if (title) {
                                    return (
                                        <div className={self.props.applyFormat ? `col col-${self.getColumnWidth(controlId)} ${self.props.applyFormat(index2)}` : `col col-${self.getColumnWidth(controlId)}`} key={'row_' + index1 + '_' + index2} onClick={() => { self.onRowSelected(item) }}>
                                            <span>{item[controlId]}</span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    );
                })}
                </div>
            </div>
        );
    }
}


export class GenericListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;
        //this.dataAccess = window.GenericDataAccess;
        this.dataAccess = new GenericDataAccess();

        var listSettings = null;

        this.metaData = null;
        this.pageIndex = 0;
        this.pageSize = listSettings ? listSettings.pageSize: 200;
        this.totalPageCount = 0;

        this.state = {
            items: [],
            isFirst: true,
            isLast: true
        }

        this.onDeleting = this.onDeleting.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onFirstClick = this.onFirstClick.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onLastClick = this.onLastClick.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
        this.onCsvClick = this.onCsvClick.bind(this);
        this.onGoogleClick = this.onGoogleClick.bind(this);
        this.loadList();
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onPageSizeChanged(pageSize) {
        this.pageSize = pageSize;
        this.pageIndex = 0;
        this.loadList();
    }

    onPdfClick() {
        var url = this.props.filter
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=pdf&filter=' + encodeURI(this.props.filter)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=pdf';

        window.location = url;
    }

    onCsvClick() {
        var url = this.props.filter
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=csv&filter=' + encodeURI(this.props.filter)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=csv';

        window.location = url;
    }

    onGoogleClick() {
        var url = this.props.filter
            ? `/export/${encodeURI(this.props.dataObject)}Export?filter=${encodeURI(this.props.filter)}`
            : `/export/${encodeURI(this.props.dataObject)}Export`;

        var options = "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes";
        window.open(url, 'ePac Data Export', options);
    }

    async refresh() {
        await this.loadList();
    }

    async loadList() {
        var result = await this.dataAccess.getMetaData(this.props.dataObject);
        this.metaData = result;
        this.loadItems();
    }

    loadItems() {
        var self = this;
        var filter = this.props.filter ? this.props.filter : null;

        this.dataAccess.getList(this.props.dataObject, this.pageSize, this.pageIndex, filter, function (result) {
            var page = result;
            self.totalPageCount = page.totalPageCount;
            self.setState({
                items: page.items,
                isFirst: page.isFirst,
                isLast: page.isLast
            });
        });
    }

    onFirstClick() {
        this.pageIndex = 0;
        this.loadList();
    }

    onPreviousClick() {
        if (this.pageIndex > 0) {
            this.pageIndex--;
        }
        this.loadList();
    }

    onNextClick() {
        if (this.pageIndex < this.totalPageCount - 1) {
            this.pageIndex++;
        }
        this.loadList();
    }

    onLastClick() {
        this.pageIndex = this.totalPageCount - 1;
        this.loadList();
    }

    onRowSelected(item) {
        if (this.props.onRowSelected) {
            this.props.onRowSelected(item, this.props.dataObject);
        }
    }

    onDeleting(item) {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?',
            () => 
            {                
                self.dataAccess.deleteItem(self.props.dataObject, item.id, function () {
                    self.loadList();
                    if (self.props.onDeleted) {
                        self.props.onDeleted(item, self.props.dataObject);
                    }
                });
            }            
        );
    }

    render() {        
        return (
            <div className='generic-list-component'>
                <PagingModule pageIndex={this.pageIndex} totalPageCount={this.totalPageCount} isFirst={this.state.isFirst} isLast={this.state.isLast} onFirstClick={this.onFirstClick} onPreviousClick={this.onPreviousClick} onNextClick={this.onNextClick} onLastClick={this.onLastClick} onPageSizeChanged={this.onPageSizeChanged} onPdfClick={this.onPdfClick} onCsvClick={this.onCsvClick} onGoogleClick={this.onGoogleClick}/>
                {this.state.items && this.state.items.length > 0 &&
                    <div>
                        <TableModule disableView={!this.props.onRowSelected} metaData={this.metaData} items={this.state.items} onRowSelected={this.onRowSelected} enableDelete={this.props.enableDelete} onDeleting={this.onDeleting} applyFormat={this.props.applyFormat}  />
                    </div>
                }
                {(!this.state.items || this.state.items.length == 0) &&
                    <div className="alert alert-info">
                        No results
                    </div>
                }

            </div>
        );
    }
}

import React, { Component, useState } from 'react';
import { CheckCircle, ChevronRight, ChevronLeft, ChevronDown, Info } from 'react-feather';

import './Containers.css';

export class Row extends Component {
    static displayName = Row.name;

    render() {
        return (
            <div className='row'>
                {this.props.children}
            </div>
        );
    }
}

export class Column extends Component {
    static displayName = Column.name;

    render() {
        return (
            <div className={this.props.width ? 'column w' + this.props.width : 'column w2'}>
                <div className='spacer'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


export class Card extends Component {
    static displayName = Card.name;

    render() {
        return (
            <div className={this.props.transparent === true ? 'card transparent' : 'card'}>
                {this.props.title &&
                    <div className='title'>
                        {this.props.noIcon !== true &&
                            <div className='imageContainer'>
                                <CheckCircle className='icon' />
                            </div>
                        }

                        <div className='textContainer'>
                            <div className='titleText'>{this.props.title}</div>
                            {this.props.subtitle &&
                                <div className='subText'>{this.props.subtitle}</div>
                            }
                        </div>
                    </div>
                }

                <div className='container'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


export class ExpandableCard extends Component {
    static displayName = ExpandableCard.name;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.expanded ? !this.props.expanded : true
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    toggle() {
        let collapsed = !this.state.collapsed;
        this.setState({ collapsed: collapsed });
        if (this.props.onChange && collapsed === false) {
            this.props.onChange(collapsed);
        }
    }

    collapse() {
        this.setState({ collapsed: true });
    }

    expand() {
        this.setState({ collapsed: false });
    }

    render() {
        return (
            <div className={'card expandable'}>
                {this.props.title &&
                    <div className='title' onClick={this.toggle}>
                        <div className='imageContainer'>
                            {this.state.collapsed
                                ? <ChevronRight className='icon' />
                                : <ChevronDown className='icon' />
                            }
                        </div>

                        <div className='textContainer'>
                            <div className='titleText'>{this.props.title}</div>
                            {this.props.subtitle &&
                                <div className='subText'>{this.props.subtitle}</div>
                            }
                        </div>
                    </div>
                }

                {!this.state.collapsed &&
                    <div className='container'>
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }
}


export class ToggleContainer extends Component {
    static displayName = ToggleContainer.name;

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    render() {
        return (
            <div className={this.state.visible ? 'toggleContainer' : 'toggleContainer'}>

                {this.state.visible
                    ? <div>{this.props.children}</div>
                    : <div className='toggle'><span onClick={() => { this.setState({ visible: true }) }}>{this.props.toggleText}</span></div>
                }
            </div>
        );
    }
}


export const TileContainer = ({ children }) => {

    return (
        <div className='tileContainer'>
            {children}
        </div>
    )
}


export const ImageTile = ({ id, src, value, className, onClick }) => {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <div id={id} className={`imageTile ${className ? className : ''}`} onClick={handleClick}>
            <div className='image' style={{ backgroundImage: `url(${src}), url('/images/round_logo.png')` }} />
            <div className='caption'><span>{value}</span></div>
        </div>
    )
}


export const ImageTileCircle = ({ id, src, value, className, onClick }) => {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <div id={id} className={`imageTileCircle ${className ? className : ''}`} onClick={handleClick}>
            <div className='image' style={{ backgroundImage: `url(${src}), url('/images/round_logo.png')` }} />
            {value &&
                <div className='caption'><span>{value}</span></div>
            }
        </div>
    )
}



export const Carousel = ({ children }) => {

    const [index, setIndex] = useState(0);
    const [orientation, setOrientation] = useState(null);

    const onPreviousClick = () => {
        var idx = index;
        if (idx > 0) {
            idx = idx - 1;
        }
        else {
            idx = children.length - 1;
        }
        setOrientation('previous');
        setIndex(idx);
    }

    const onNextClick = () => {
        var idx = index;
        if (idx < children.length - 1) {
            idx = idx + 1;
        }
        else {
            idx = 0;
        }
        setOrientation('next');
        setIndex(idx);
    }

    const getAnimationStyle = () => {
        var result = '';

        if (orientation == 'next') {
            result = 'slide-in-left';
        }

        if (orientation == 'previous') {
            result = 'slide-in-right';
        }

        return result;
    }

    return (
        <div className='carousel'>
            <div className='nav' onClick={onPreviousClick}>
                <ChevronLeft className='icon' />
            </div>
            <div className='window'>
                <div className='item-container' onClick={onNextClick}>
                    {children.map((child, i) => {
                        return (
                            <div key={`child${i}`} className={index == i ? `carousel-item visibility-visible ${getAnimationStyle()}` : 'carousel-item visibility-hidden'}>{child}</div>
                        )
                    })}
                </div>
            </div>
            <div className='nav' onClick={onNextClick}>
                <ChevronRight className='icon' />
            </div>
        </div>
    )
}

export const Page = ({ children }) => {

    return (
        <div className='page'>
            {children}
        </div>
    )
}



export const ButtonTile = ({ id, className, width, height, children, onClick, text }) => {

    return (
        <div id={id} className={className ? `buttonTile ${className}` : 'buttonTile'} style={{ width: width ? width : 280, height: height ? height : 400 }}>
            <div className='header'>
                <Info className="icon" />
            </div>

            <div className='content'>
                {children}
            </div>

            {text && onClick &&
                <div className='footer'>
                    <button className='btn btn-secondary' onClick={onClick}>{text}</button>
                </div>
            }
        </div>
        )
}


export const ExpandableTab = ({ children, className, title }) => {

    const [collapsed, setCollapsed] = useState(true);

    return (
        <div>
            <div className={className ? `expandableTab ${className}` : 'expandableTab'}>
                {!collapsed &&
                    <div className='backsplash' onClick={() => { setCollapsed(!collapsed) }} />
                }
                <div className='component'>
                    <div className={collapsed ? 'content hidden scale-up-ver-top' : 'content visible scale-up-ver-top'}>
                        <div className='panel'>
                            {children}
                        </div>
                    </div>
                    <div className='tab' onClick={() => { setCollapsed(!collapsed) }}>{title}</div>
                </div>
            </div>
        </div>
    )
}


export class AppendButton extends React.Component {

    render() {
        return (
            <div className='append-button'>
                <div className='payload'>{this.props.children}</div>
                <button className='btn btn-secondary-outline' disabled={!this.props.onClick} onClick={this.props.onClick}>{this.props.title}</button>
            </div>
        )
    }
}


export class Spacer extends React.Component {

    render() {
        return (
            <div className='default-spacer'>
                {this.props.children}
            </div>
        )
    }

}
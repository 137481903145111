import React from 'react';
import { LookupDataSourceDataAccess } from '../../../infrastructure/DataSourceDataAccess';
import { Logger } from "../../../infrastructure/Logger";

export class StructureSpecRulesListComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.structureSpecId) {
            throw new Error("StructureSpecId Property is required");
        }
        this.structureSpecId = this.props.structureSpecId;
        this.dataSourceDataAccess = new LookupDataSourceDataAccess("rulesByStructureSpec");

        this.state = {
            rules: []
        }

    }

    componentDidMount() {
        var self = this;

        var request = {
            number: this.structureSpecId
        }

        this.dataSourceDataAccess.getDataSource(request)
            .then(dataSource => {
                self.setState({
                    rules: dataSource.getItems()
                });
            })
            .catch(ex => {
                Logger.writeError('Could not retrieve rules', ex);
            });
    }

    render() {
        var self = this;
        return (
            <div className='structure-spec-rules-list'>
                {self.state.rules.map(function (item, id) {
                    return (
                        <div key={`fpl_${id}`} className='row clickable'>
                        <div className="flex" onClick={() => window.Router.setRoute('/rules/' + encodeURIComponent(item.id))}>
                            {item.isPublished === true
                                ? <span className='glyphicon glyphicon-play color-green' title='Published' />
                                : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />
                            }
                            <div>{item.name}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}
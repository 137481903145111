import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Logger } from "../infrastructure/Logger";


export class PageableDataObjectList extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.dataObject) {
            throw new Error("DataObject Property is required");
        }

        if (!this.props.onRenderRow) {
            throw new Error("onRenderRow Property is required");
        }

        var listSettings = null;

        this.timer = null;
        this.filter = this.props.filter ? this.props.filter : null;

        this.pageSize = listSettings ? listSettings.pageSize : 200;
        this.apiDataAccess = new ApiDataAccess('/data');

        this.state = {
            pageSize: this.pageSize,
            isFirst: true,
            isLast: true,
            items: null,
            pageIndex: 0,
            totalPageCount: 0
        }

        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onFirstClick = this.onFirstClick.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onLastClick = this.onLastClick.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
    }

    componentDidMount() {
        this.refresh(this.filter);
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    refresh(filter, forceRefresh) {
        this.filter = filter;
        this.refreshList(this.state.pageIndex, this.state.pageSize, forceRefresh);
    }

    refreshList(pageIndex, pageSize, forceRefresh) {
        var self = this;

        if (forceRefresh === true) {
            self.setState({ items: null });
        }

        this.getList(pageIndex, pageSize)
            .then(data => {
                self.setState({
                    isFirst: data.isFirst,
                    isLast: data.isLast,
                    items: data.items,
                    pageIndex: data.pageIndex,
                    totalPageCount: data.totalPageCount
                });
            })
            .catch((error) => {
                Logger.writeError('Could not retrieve list', error);
                window.Alerts.showError('Could not retrieve list');
            });
    }

    getList(pageIndex, pageSize) {
        var self = this;

        return new Promise(function (resolve, reject) {
            var url = '';
            if (self.props.overrideBaseUrl) {
                
                url = '/' + encodeURI(self.props.overrideBaseUrl) + '/pagable?pagesize=' + encodeURI(pageSize) + '&pageIndex=' + encodeURI(pageIndex);
                console.log("we are overriding: ", url)
            } else {
                url = '/' + encodeURI(self.props.dataObject) + '/pagable?pagesize=' + encodeURI(pageSize) + '&pageIndex=' + encodeURI(pageIndex);
            }

            if (self.filter) {
                url += '&filter=' + encodeURI(self.filter);
            }

            self.apiDataAccess.get(url)
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    Logger.writeError('Could not retrieve list', error);
                    reject(error)
                });
        });
    }

    onPageSizeChanged(event) {
        var pageSize = event.target.value;
        this.setState({
            pageSize: pageSize
        });
        this.refreshList(this.state.pageIndex, pageSize);
    }

    onFirstClick() {
        var pageIndex = 0;
        this.setState({
            pageIndex: pageIndex
        });
        this.refreshList(pageIndex, this.state.pageSize);
    }

    onPreviousClick() {
        var pageIndex = this.state.pageIndex;
        if (pageIndex > 0) {
            pageIndex = pageIndex - 1;
        }
        else {
            pageIndex = 0;
        }

        this.setState({
            pageIndex: pageIndex
        });
        this.refreshList(pageIndex, this.state.pageSize);
    }

    onNextClick() {
        var pageIndex = this.state.pageIndex;
        if (pageIndex < this.state.totalPageCount - 1) {
            pageIndex = pageIndex + 1;
        }
        else {
            pageIndex = this.state.totalPageCount - 1;
        }

        this.setState({
            pageIndex: pageIndex
        });
        this.refreshList(pageIndex, this.state.pageSize);
    }

    onLastClick() {
        var pageIndex = this.state.totalPageCount - 1;
        this.setState({
            pageIndex: pageIndex
        });
        this.refreshList(pageIndex, this.state.pageSize);
    }

    onFilterChanged(event) {
        var value = event.target.value;
        var self = this;
        if (this.props.onFilterApplied) {
            clearTimeout(this.timer);
            self.timer = setTimeout(() => {
                self.props.onFilterApplied(value);
            }, 500);
        }
    }


    render() {
        var self = this;
        return (
            <div className="generic-component">

                <div className="generic-list-component">

                    <div className="paging">

                        <div className="button-section pull-left">
                            <button className="btn btn-primary btn-sm no-marging-left" disabled={this.state.isFirst == true} onClick={this.onFirstClick}><span className="glyphicon glyphicon-fast-backward"></span></button>
                            <button className="btn btn-primary btn-sm no-marging-left" disabled={this.state.isFirst == true} onClick={this.onPreviousClick}><span className="glyphicon glyphicon-step-backward"></span></button>
                        </div>

                        <div className='filter mobile-hidden'>
                            <select id="page_size_selector" className="control dropdown valid" onChange={this.onPageSizeChanged} value={this.state.pageSize}>
                                <option value='20'>20 Items</option>
                                <option value='50'>50 Items</option>
                                <option value='100'>100 Items</option>
                                <option value='200'>200 Items</option>
                            </select>
                            {this.props.onFilterApplied &&
                                <input type="text" autoComplete="off" className='control textbox' onChange={this.onFilterChanged} placeholder='Filter...' ></input>
                            }
                        </div>

                        <div className="col">
                            {/* 
                                {self.props.onPdfClick &&
                                    <img className='img-btn' src="/images/pdf.png" onClick={self.props.onPdfClick} title='Export to Adobe PDF' alt='Export to Adobe PDF' />
                                }

                                {this.props.onCsvClick &&
                                    <img className='img-btn' src="/images/excel.png" onClick={this.props.onCsvClick} title='Export to Microsoft Excel' alt='Export to Microsoft Excel' />
                                }

                                {this.props.onGoogleClick &&
                                    <img className='img-btn' src="/images/sheets.png" onClick={this.props.onGoogleClick} title='Export to Google Sheets' alt='Export to Google Sheets' />
                                }
                                */}
                        </div>

                        <div className="filter mobile-hidden">
                            <div className="center">
                                {(this.state.pageIndex + 1) + ' of ' + this.state.totalPageCount}
                            </div>
                        </div>

                        <div className="button-section">
                            <button className="btn btn-primary btn-sm no-marging-right pull-right" disabled={this.state.isLast == true} onClick={this.onNextClick}><span className="glyphicon glyphicon-step-forward"></span></button>
                            <button className="btn btn-primary btn-sm no-marging-right pull-right" disabled={this.state.isLast == true} onClick={this.onLastClick}><span className="glyphicon glyphicon-fast-forward"></span></button>
                        </div>
                    </div>


                    <div className='table'>
                        {self.props.onRenderTitleRow &&
                            <div className='table-header'>
                                {self.props.onRenderTitleRow()}
                            </div>
                        }

                        {this.state.items &&
                            <div className='body'>
                                {this.state.items.map((item, idx) => {
                                    return (
                                        self.props.onRenderRow(item, idx, self.state.isFirst && idx == 0, self.state.isLast && idx == self.state.items.length - 1)
                                    )
                                })}
                            </div>
                        }
                    </div>


                </div>
            </div>
        );
    }
}
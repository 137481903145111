import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { Alert } from '../../../core/Alert';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { Vent, HangHole, TearNotch, Zipper, BottomFill, Core, DoubleCut, ColorSpec, Eyemark, SealWidth, QrCodeType, RoundedCorner, UnwindDirection, OutsourcedFeature, GussetType } from './Features';


export class VentAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new Vent(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });
    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Zippers' image='/images/attributes/finish.png' />
                <Alert type='info'>Vent Options are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='ventFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}

export class FeatureZipperAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;        
        var feature = new Zipper(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });        
    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Zippers' image='/images/attributes/finish.png' />
                <Alert type='info'>Zippers are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='zipperFeatureAttributes'
                    onInit={ctrl => this.list = ctrl }
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}

export  class FeatureHangholeAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new HangHole(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });        
    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Hang Holes' image='/images/attributes/finish.png' />
                <Alert type='info'>Hang Holes are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='hangholeFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}

export  class FeatureTearnotchAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new TearNotch(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });        

    }


    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Tear Notches' image='/images/attributes/finish.png' />
                <Alert type='info'>Tear Notches are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='tearNotchFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}



export class FeatureBottomFillAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new BottomFill(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Bottom Fill' image='/images/attributes/finish.png' />
                <Alert type='info'>Bottom Fills are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='bottomFillFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export  class FeatureCoreAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new Core(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Core' image='/images/attributes/finish.png' />
                <Alert type='info'>Cores are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='coreFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class FeatureColorSpecAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new ColorSpec(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Color Spec' image='/images/attributes/finish.png' />
                <Alert type='info'>Color Specs are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='colorSpecFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class FeatureEyemarkAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new Eyemark(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Eyemarks' image='/images/attributes/finish.png' />
                <Alert type='info'>Eyemarks are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='eyemarkFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-4'>Name</div>
                                <div className='col col-2'>Ouside Artwork</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-4'>{item.name}</div>
                                <div className='col col-2'>{item.count}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}

export class FeatureDoubleCutAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new DoubleCut(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Double Cut' image='/images/attributes/finish.png' />
                <PageableDataObjectList dataObject='doubleCutFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}

export class FeatureRoundedCornerAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new RoundedCorner(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='RoundedCorner' image='/images/attributes/finish.png' />
                <Alert type='info'>Rounded Corners are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='roundedCornerFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class FeatureSealWidthAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new SealWidth(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Seal Width' image='/images/attributes/finish.png' />
                <Alert type='info'>Seal Width are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='sealWidthFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class QrCodeTypeAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new QrCodeType(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='ePac Connect' image='/images/attributes/finish.png' />
                <Alert type='info'>Epac Connect Options are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='qrCodeTypeFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}




export class UnwindDirectionView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new UnwindDirection(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Unwind Direction' image='/images/attributes/finish.png' />
                <Alert type='info'>Unwind Directions are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='unwindDirections'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class OutsourcedFeatureView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new OutsourcedFeature(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Outsourced Features' image='/images/attributes/finish.png' />
                
                <PageableDataObjectList dataObject='outsourcedFeatures'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class FeatureGussetTypeAttributeView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new GussetType(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh();
            });

    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Gusset Types' image='/images/attributes/finish.png' />
                <PageableDataObjectList dataObject='gussetTypeFeatureAttributes'
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, isFirstItem, isLastItem) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-2'>{item.createDate}</div>
                                <div className='col col-2'>{item.createUser}</div>
                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}
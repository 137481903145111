import React from 'react';
import { GenericDataAccess } from '../dataControls/GenericDataAccess';
import { GenericListComponent } from './GenericListComponent';
import { GenericDisplayComponent } from './GenericDisplayComponent';
import { GenericInputComponent } from './GenericInputComponent';


export class InterfaceMode {
    static List = 0;
    static Display = 1;
    static Edit = 2;
    static New = 3;
}


export class ToolbarComponent extends React.Component {

    constructor(props) {
        super(props);

        this.logger = window.BaseLogger;

        this.state = {
        }

    }


    render() {
        var self = this;
        return (
            <div className='toolbar'>
                {this.props.mode == InterfaceMode.List &&
                    // List View
                    <div>
                    <button className='btn btn-primary' disabled={this.props.enableEdit == false}  onClick={this.props.onNewClick}><span className='glyphicon glyphicon-plus' /><span className='text'> New</span></button>
                    </div>
                }

                {this.props.mode == InterfaceMode.Display &&
                    // Item Display
                    <div>
                        <button className='btn btn-primary' onClick={this.props.onListClick}><span className='glyphicon glyphicon-th-list' /><span className='text'> List</span></button>
                        <button className='btn btn-primary' disabled={this.props.enableEdit == false} onClick={this.props.onNewClick}><span className='glyphicon glyphicon-plus' /><span className='text'> New</span></button>
                        <button className='btn btn-primary' disabled={this.props.enableEdit == false} onClick={this.props.onEditClick}><span className='glyphicon glyphicon-pencil' /><span className='text'> Edit</span></button>
                        <button className='btn btn-primary' disabled={this.props.enableClone == false} onClick={this.props.onCloneClick}><span className='glyphicon glyphicon-duplicate' /><span className='text'> Clone</span></button>
                        <button className='btn btn-primary-outline' disabled={this.props.enableDelete == false} onClick={this.props.onDeleteClick}><span className='glyphicon glyphicon-trash' /><span className='text'> Delete</span></button>
                    </div>
                }

                {this.props.mode == InterfaceMode.Edit &&
                    // Item Edit
                    <div>
                        <button className='btn btn-primary' onClick={this.props.onListClick}><span className='glyphicon glyphicon-th-list' /><span className='text'> List</span></button>
                        <button className='btn btn-primary' onClick={this.props.onDisplayClick}><span className='glyphicon glyphicon-eye-open' /><span className='text'> Display</span></button>
                    </div>
                }

                {this.props.mode == InterfaceMode.New &&
                    // New Item
                    <div>
                        <button className='btn btn-primary' onClick={this.props.onListClick}><span className='glyphicon glyphicon-th-list' /><span className='text'> List</span></button>
                    </div>
                }
            </div>
        );
    }
}



export class GenericComponent extends React.Component {

    constructor(props) {
        super(props);

        this.logger = window.BaseLogger;
        this.dataAccess = new GenericDataAccess();

        this.state = {
            mode: this.props.dataId && this.props.dataId > 0 ? InterfaceMode.Display : InterfaceMode.List,
            itemId: this.props.dataId ? this.props.dataId : null
        }

        this.itemId = null;
        this.onListClick = this.onListClick.bind(this);
        this.onDisplayClick = this.onDisplayClick.bind(this);
        this.onNewClick = this.onNewClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onInputCompleted = this.onInputCompleted.bind(this);
        this.onCloneClick = this.onCloneClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
    }

    onListClick() {
        this.setState({
            mode: InterfaceMode.List,
            itemId: null
        });
        this.notifiyOnModeChange(InterfaceMode.List);
    }

    onDisplayClick() {
        this.setState({
            mode: InterfaceMode.Display
        });
        this.notifiyOnModeChange(InterfaceMode.Display);
    }

    onNewClick() {
        this.setState({
            mode: InterfaceMode.New,
            itemId: null
        });
        this.notifiyOnModeChange(InterfaceMode.New);
    }

    onEditClick() {
        this.setState({
            mode: InterfaceMode.Edit
        });
        this.notifiyOnModeChange(InterfaceMode.Edit);
    }

    onRowSelected(item) {
        this.itemId = item.id;
        this.setState({
            mode: InterfaceMode.Display,
            itemId: item.id
        });
        this.notifiyOnModeChange(InterfaceMode.Display);
    }

    onCloneClick() {
        var self = this;
        self.dataAccess.getItem(self.props.dataObject, self.state.itemId, function (result) {
            if (result) {
                var item = result;
                item.id = 0;
                self.dataAccess.saveItem(self.props.dataObject, item, function (result) {
                    window.Alerts.showSuccess('The item has been cloned', 'Clone');
                    var item = result;
                    self.setState({
                        mode: InterfaceMode.Edit,
                        itemId: item.id
                    });
                    self.notifiyOnModeChange(InterfaceMode.Edit);
                });
            }
        });
    }

    onDeleteClick() {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?',
            function () {
                self.dataAccess.deleteItem(self.props.dataObject, self.state.itemId, function () {
                    window.Alerts.showSuccess('The item has been deleted.', 'Delete');
                    self.onListClick();
                });
            }
        );
    }

    onInputCompleted() {
        this.setState({
            mode: InterfaceMode.List,
            itemId: null
        });
        this.notifiyOnModeChange(InterfaceMode.List);
    }


    notifiyOnModeChange(mode) {
        if (this.props.onModeChange) {
            this.props.onModeChange(mode, this.props.dataObject, this.itemId);
        }
    }

    onPdfClick() {
        //?format={format}&filter={filter}&title={title}&recordId={recordId}
        var url = this.props.title
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=pdf&filter=null&title=' + encodeURI(this.props.title) + '&recordId=' + encodeURI(this.state.itemId)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=pdf&filter=null&recordId=' + encodeURI(this.state.itemId)

        window.location = url;
    }

    render() {
        var self = this;
        return (
            <div className="generic-component">
                <ToolbarComponent mode={this.state.mode} enableClone={this.props.enableClone} enableEdit={this.props.enableEdit} enableDelete={this.props.enableDelete} onNewClick={this.onNewClick} onEditClick={this.onEditClick} onListClick={this.onListClick} onDisplayClick={this.onDisplayClick} onCloneClick={this.onCloneClick} onDeleteClick={this.onDeleteClick} onPdfClick={this.onPdfClick} />

                {this.state.mode == InterfaceMode.List &&
                    <GenericListComponent id="list_module" dataObject={this.props.dataObject} onRowSelected={this.onRowSelected} filter={this.props.filter} enableDelete={this.props.enableDelete} />
                }

                {this.state.mode == InterfaceMode.Display &&
                    <GenericDisplayComponent dataObject={this.props.dataObject} dataId={this.state.itemId} />
                }

                {this.state.mode == InterfaceMode.Edit &&
                    <GenericInputComponent dataObject={this.props.dataObject} dataId={this.state.itemId} onCompleted={this.onInputCompleted} />
                }

                {this.state.mode == InterfaceMode.New &&
                    <GenericInputComponent dataObject={this.props.dataObject} dataId={null} onCompleted={this.onInputCompleted} />
                }

            </div>
        );
    }
}

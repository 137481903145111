import React from 'react';
import { RuleTargetPopupContent } from './RuleTargetPopupContent';
import { RuleTargetCard } from './RuleTargetCard';
import { Logger } from "../../../infrastructure/Logger";

export class RuleTargetEditor extends React.Component {

    constructor(props) {
        super(props);
        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }
        this.ruleDataManager = this.props.ruleDataManager;


        this.state = {
            targets: [],
            datasourceDefinitions: []
        }

        this.onAddClick = this.onAddClick.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onTargetAdd = this.onTargetAdd.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {

        var self = this;
        this.ruleDataManager.getRuleTargetDefinitionDataSouce()
            .then(data => {
                self.setState({ datasourceDefinitions: data });
            });


        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    setTargets(targets) {
        var self = this;
        targets.forEach(target => {
            self.onTargetAdd(target);
        });
    }

    onTargetAdd(target) {
        var self = this;
        this.ruleDataManager.addTarget(target)
            .then(targets => {
                self.setState({
                    targets: targets
                });
            })
            .catch(errors => {
                Logger.writeError('Could not add Target', errors);
            });
    }


    onAddClick(target) {
        Logger.writeDebug("TARGET", target);
        this.onTargetAdd(target);
       
        if (this.props.onChanged) {
            this.props.onChanged(target);
        }
    }

    onDeleteClick(target) {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?', () => {
            self.setState({
                targets: []
            });

            self.ruleDataManager.removeTarget(target)
                .then(targets => {
                    self.setState({
                        targets: targets
                    });
                })
                .catch(errors => {
                    Logger.writeError('Could not remove target', errors);
                });

            if (self.props.onChanged) {
                self.props.onChanged(target);
            }

        });
    }

    showModal() {
        var self = this;
        window.Modal.show(
            modal => <RuleTargetPopupContent modal={modal} ruleDataManager={self.ruleDataManager} onAddClick={self.onAddClick} />,
            'Packaging Feature',
            null,
            'rule-popup'
        )
    }

    onClick() {
        this.showModal();
    }

    render() {
        var self = this;
        return (
            <div>
                {this.state.datasourceDefinitions &&
                    <div className="target-editor">
                        <div className="rule-trigger-editor">
                            {this.state.targets.map(function (target, idx) {
                                return (
                                    <div key={`targ_${idx}`}>
                                        {idx > 0 &&
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className='connector'></div>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button className='btn btn-primary-outline btn-round' disabled={true}>+</button>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className='connector'></div>
                                                </div>
                                            </div>
                                        }
                                        <RuleTargetCard preventEdit={self.props.preventEdit} ruleTarget={target} datasourceDefinitions={self.state.datasourceDefinitions} onDeleteClick={self.onDeleteClick} onEditClick={self.onEditClick}  />
                                    </div>
                                );
                            })}

                            {this.props.preventEdit !== true && this.state.targets && this.state.targets.length > 0 && this.state.targets.length < this.ruleDataManager.getTotalRuleTargetDefinitionCount() &&
                                <div>
                                    <div className="d-flex justify-content-center">
                                        <div className='connector'></div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn btn-primary-outline btn-round' onClick={this.onClick}>+</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                </div>
        );
    }
}
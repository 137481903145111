import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PolicyConfirmation } from '../../../dataControls/PolicyConfirmation';
import { Logger } from "../../../infrastructure/Logger";

export class Product {

    constructor(productGroupId) {
        this.productGroupId = productGroupId;
        this.dataAccess = new ApiDataAccess('/data');
    }


    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Product', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        Logger.writeError('Error', error);
                                        reject(error);
                                    });
                            }
                            else {
                                resolve();
                            }
                        });
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/products/' + encodeURIComponent(self.productGroupId) + '/publish?testPolicy=1'
            : '/products/' + encodeURIComponent(self.productGroupId) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => {
                    if (!data.isSuccess) {
                        reject(data);
                    }
                    resolve(data.messages);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/products/' + encodeURIComponent(self.productGroupId) + '/publish'
            : '/products/' + encodeURIComponent(self.productGroupId) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => {
                    if (!data.isSuccess) {
                        reject(data);
                    }
                    resolve(data.result);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
import React from 'react';
import { Tile } from '../../../core/Tile';
import { ImageTitle } from '../../../core/ImageTitle';

export class ConfigurationPackagingAttributesHomeScreen extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Packaging Attributes' image='/images/attributes/material.png' />
                <div className='tile-container'>
                    <Tile title='Applications' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/application')} />
                    <Tile title='Barrier Types' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/barrier')} />
                    <Tile title='Converting Method' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/converting')} />
                    <Tile title='Fill Type' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/fill')} />
                    <Tile title='Finish Type' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/finish')} />
                    <Tile title='Seal Type' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/seal')} />
                    <Tile title='Seal Wrap' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/wrap')} />
                    <Tile title='Sustainability' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/sustainability')} />
                    <Tile title='Transparency' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/transparency')} />
                    <Tile title='Manufacturing Option' onClick={() => window.Router.setRoute('/configuration/packaging/attributes/manufacturingoption')} />
                </div>
            </div>
        );
    }
}
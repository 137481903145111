import React from 'react';
import { Tile } from '../../../core/Tile';
import { ImageTitle } from '../../../core/ImageTitle';

export class ConfigurationProductAttributesHomeView extends React.Component {


    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Attributes'/>
                <div className='tile-container'>
                    <Tile title='Aggressivness' onClick={() => window.Router.setRoute('/configuration/products/attributes/aggressivness')} />
                    <Tile title='Aroma' onClick={() => window.Router.setRoute('/configuration/products/attributes/aroma')} />
                    <Tile title='Child Resistance' onClick={() => window.Router.setRoute('/configuration/products/attributes/child')} />
                    <Tile title='Consistency' onClick={() => window.Router.setRoute('/configuration/products/attributes/consistency')} />
                    <Tile title='Emission' onClick={() => window.Router.setRoute('/configuration/products/attributes/emission')} />
                    <Tile title='Light Sensitivity' onClick={() => window.Router.setRoute('/configuration/products/attributes/light')} />
                    <Tile title='Moisture' onClick={() => window.Router.setRoute('/configuration/products/attributes/moisture')} />
                    <Tile title='Oxygen Sensitivity' onClick={() => window.Router.setRoute('/configuration/products/attributes/oxygensensitivity')} />
                    <Tile title='Shelf Life Time' onClick={() => window.Router.setRoute('/configuration/products/attributes/lifetime')} />
                    <Tile title='Temperature' onClick={() => window.Router.setRoute('/configuration/products/attributes/temperature')} />
                </div>
            </div>
        );
    }
}
import React from 'react';
import { GenericComponent } from '../../../dataForms/GenericComponent';
import { ImageTitle } from '../../../core/ImageTitle';

export class ProductConsistencyAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Consistency' />
                <GenericComponent dataObject='productConsistencyAttributes' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class ProductMoistureAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Moisture'/>
                <GenericComponent dataObject='productMoistureAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductAromaAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Aroma' />
                <GenericComponent dataObject='productAromaAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductLightSensitivityAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Light Sensitivity' />
                <GenericComponent dataObject='productLightSensitivityAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductShelfLifeTimeAttributeView extends React.Component {


    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Shelf Life' />
                <GenericComponent dataObject='productShelfLifeTimeAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductChildResistanceAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Child Resistancy' />
                <GenericComponent dataObject='productChildResistanceAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductAgressivnessAttributeView extends React.Component {


    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Aggressiveness' />
                <GenericComponent dataObject='productAgressivnessAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductEmissionAttributeView extends React.Component {


    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Emission' />
                <GenericComponent dataObject='productEmissionAttributes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ProductTemperatureAttributeView extends React.Component {


    render() {
        return (
            <div className="view">
                <ImageTitle text='Packaging Temperature' />
                <GenericComponent dataObject='productTemperatureAttributes' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}

export class ProductOxygenSensitivityAttributeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Product Oxygen Sensitivity' />
                <GenericComponent dataObject='productOxygenSensitivityAttributes' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}
import React, { Component } from 'react';


export class Modal extends Component {
    static displayName = Modal.name;

    constructor(props) {
        super(props);

        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onCloseClick() {
        if (this.props.onCloseClick) {
            this.props.onCloseClick(this);
        }
    }

    render() {
        return (
            <div>
                <BackSplash onClick={this.onCloseClick} />
                <div className='modal'>
                    <div className='modalContent'>
                        {this.props.children}
                    </div>
                    <div className='close' onClick={this.onCloseClick}>Close</div>
                </div>
            </div>
        );
    }
}

export class Confirmation extends Component {
    static displayName = Confirmation.name;

    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onCancelClick() {
        if (this.props.onCancelClick) {
            this.props.onCancelClick(this);
        }
    }

    onSaveClick() {
        if (this.props.onSaveClick) {
            this.props.onSaveClick(this);
        }
    }

    render() {
        return (
            <div>
                <BackSplash onClick={this.onCancelClick} />
                <div className='confirmation'>
                    <div className='modalContent'>
                        {this.props.children}
                    </div>
                    <div className='button left' onClick={this.onCancelClick}>Cancel</div>
                    <div className='button right' onClick={this.onSaveClick}>Save</div>
                </div>
            </div>
        );
    }
}



export class BackSplash extends Component {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    /*
    componentDidMount() {
        document.body.classList.add('noscroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noscroll');
    }
    */

    onClick() {
        if (this.props.onClick) {
            this.props.onClick(this);
        }
    }

    onMouseMove() {
        if (this.props.onMouseMove) {
            this.props.onMouseMove(this);
        }
    }

    render() {
        return (
            <div className={this.props.clear === true ? 'backSplash clear' : 'backSplash'} onClick={this.onClick} onMouseMove={this.props.onMouseMove}>
                {this.props.children}
            </div>
        );
    }
}


import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ImageTileCircle, Spacer } from '../components/Containers';
import { Logger } from "../infrastructure/Logger";

export class PhotoSelectComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            isFirst: false,
            isLast: false,
            items: null
        }

        this.dataAccess = new ApiDataAccess('/data/images');
        this.onPhotoSelected = this.onPhotoSelected.bind(this);
    }

    getPhotos(searchTerm, pageSize, pageIndex) {
        var self = this;
        return new Promise((resolve, reject) => {

            var request = {
                pageSize: pageSize,
                pageIndex: pageIndex,
                searchTerm: searchTerm
            }

            self.dataAccess.post('/external/search', request)
                .then(data => { resolve(data); })
                .catch(error => {
                    Logger.writeError('Could not get photos', error);
                    reject(error);
                });
        });
    }

    setPhotos(pageIndex) {
        var self = this;
        this.getPhotos(this.props.search, 50, pageIndex)
            .then(page => {
                self.setState({
                    pageIndex: page.pageIndex,
                    isFirst: page.isFirst,
                    isLast: page.isLast,
                    items: page.items
                });
            });
    }

    componentDidMount() {
        this.setPhotos(this.state.pageIndex);
    }


    onPhotoSelected(photo) {
        if (this.props.onSelected) {
            this.props.onSelected(photo);
        }
    }

    render() {
        var self = this;
        return (
            <div className="photo-select-component">
                {this.state.items && this.state.items.length > 0 &&
                    <div className='pageable-container'>
                        <div className='photo-container'>
                            {this.state.items.map(function (photo, idx) {
                                return (
                                    <ImageTileCircle key={`img_${idx}`} src={photo.urls.small} onClick={() => self.onPhotoSelected(photo)}/>
                                );
                            })}
                        </div>
                    </div>
                }
            </div>
        );
    }
}



export class ImageDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);
        this.currentValue = null;

        this.state = {
            size: 0,
            value: this.currentValue,
            showUploader: false
        }

        this.onPhotoSelected = this.onPhotoSelected.bind(this);
        this.onImageClick = this.onImageClick.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);

    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        self.metaData = metaData;
        return new Promise(function (resolve, reject) {
            resolve(self);            
        });
    }

    setValue(value) {

        this.currentValue = value;
        var newValue = this.currentValue && this.currentValue >= 0 ? this.currentValue : -1
        this.setState({
            value: newValue
        });
        this.dataManager.reportChange(newValue, this);
    }

    getValue() {
        return this.state.value >= 0 ? this.state.value : null;
    }

    getImage(value) {
        var result = null;
        if (parseInt(value) > 0) {
            var timestamp = new Date().getTime();
            result = '/data/images/' + encodeURIComponent(value) + '?ts=' + encodeURIComponent(timestamp);            
        }
        return result;
    }

    isInputValid() {
        var result = true;
        var validationData = this.metaData && this.metaData.validationData ? this.metaData.validationData : null;
        if (validationData) {
            if (validationData.required === true && this.currentValue < 0) {
                result = false;
            }
        }
        return result;
    }

    showUploadModal() {
        var self = this;

        window.Modal.show(function (popup) {
            return (
                <div className='image-upload-modal'>
                    <p>Please select the image and press the upload button or select a photo from below:</p>
                    <div >
                        
                        <input id={self.props.id + '_image_upload_cntrl'} className={'control textbox valid'} type="file" name="pic" accept="image/*"></input>

                        <Spacer />

                        <div className='p-2 bd-highlight'>
                            <button className='btn btn-primary' onClick={() => { self.onUploadClick(popup); }}>Upload</button>
                            <button className='btn btn-secondary pull-right' onClick={() => { popup.close() }}>Cancel</button>
                        </div>
                    </div>
                    
                    {self.props.searchTerm &&
                        <div>                            
                            <PhotoSelectComponent search={self.props.searchTerm} onSelected={photo => { self.onPhotoSelected(photo, popup); }}/>
                        </div>
                    }
                </div>
            )
        }, 'Select an Image', null, 'image-upload')
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        })
    };



    onUploadClick(popup) {

        var self = this;
        var uploadControl = document.getElementById(this.props.id + '_image_upload_cntrl');
        var file = uploadControl && uploadControl.files && uploadControl.files.length > 0 ?  uploadControl.files[0] : null;

        if (file && file.size > 0) {
            self.setState({
                showUploader: true
            });

            this.getBase64(file)
                .then(base64 => {
                    popup.close();
                    var dataAccess = new ApiDataAccess('/data/images');
                    var url = '/upload/' + encodeURI(this.currentValue ? this.currentValue : 0);

                    dataAccess.post(url, { text: base64 })
                        .then(imageInfo => {
                            Logger.writeDebug('Image uploaded sucessfully', imageInfo);
                            self.setValue(null);
                            self.forceUpdate(() => {
                                self.imageId = imageInfo.id;
                                self.setState({
                                    size: file.size,
                                    value: imageInfo.id,
                                    showUploader: false
                                });
                                self.setValue(imageInfo.id);
                                self.forceUpdate();
                            });
                        })
                        .catch(error => {
                            Logger.writeError('Could not upload image', error);
                            self.setState({
                                size: 0,
                                value: null,
                                showUploader: false
                            });
                            window.Alerts.showError('Could not upload image');
                        })
                });                    
        }        
    }

    onImageClick() {
        this.showUploadModal();
    }

    onDeleteClick() {
        this.setState({
            size: 0,
            value: null,
            showUploader: false
        });
        this.setValue(null);
        this.forceUpdate();    
    }

    onPhotoSelected(photo, popup) {
        var self = this;
        popup.close();

        console.log(photo);
        var dataAccess = new ApiDataAccess('/data/images');
        var url = '/import/' + encodeURI(this.state.value ? this.state.value : 0);
        var request = {
            imageUrl: photo.urls.large
        };

        dataAccess.post(url, request)
            .then(imageInfo => {
                Logger.writeDebug('Image uploaded sucessfully', imageInfo);
                self.setValue(null);
                self.forceUpdate(() => {
                    self.imageId = imageInfo.id;
                    self.setState({
                        size: imageInfo.imageBytes,
                        value: imageInfo.id,
                        showUploader: false
                    });
                    self.setValue(imageInfo.id);
                    self.forceUpdate();
                });
            })
            .catch(error => {
                Logger.writeError('Could not upload image', error);
                self.setState({
                    size: 0,
                    value: null,
                    showUploader: false
                });
                window.Alerts.showError('Could not upload image');
            });
    }


    render() {        
        var imageUrl = this.getImage(this.state.value);
        var isInputValid = this.isInputValid();
        return (
            <div className={isInputValid ? 'control image valid' : 'control image error' }>
                {this.state.showUploader
                    ?
                    <div className='loader'>
                        <img src="/images/ajax-loader.gif" />
                    </div>
                    :
                    <div>
                        {imageUrl &&
                            <div className='block'>
                                <ImageTileCircle src={this.getImage(this.state.value)} onClick={this.onImageClick} />   
                            </div>
                        }

                        {!imageUrl &&
                            <p onClick={this.onImageClick}>Click to upload a photo....</p>
                        }
                    </div>
                }
                <button className='btn btn-sm btn-secondary pull-right' onClick={this.onDeleteClick}><span className='glyphicon glyphicon-trash'></span></button>
            </div>
        );
    }
}

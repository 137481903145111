import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';

export class IndustryView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
    }

    render() {
        return (
            <div className="view">
                <ImageTitle text='Industry' />

                <PageableDataObjectList dataObject='industries'
                    onInit={cntrl => this.list = cntrl}
                    onPdfClick={this.onPdfClick}
                    onCsvClick={this.onCsvClick}
                    onGoogleClick={this.onGoogleClick}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-8'>Name</div>
                                <div className='col col-2 mobile-hidden'>Date</div>
                                <div className='col col-2 mobile-hidden'>User</div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row clickable' onClick={() => { window.Router.setRoute(`/configuration/industries/${item.id}/categories`) }}>
                                <div className='col col-8'>{item.name}</div>
                                <div className='col col-2 mobile-hidden'>{item.createDate}</div>
                                <div className='col col-2 mobile-hidden'>{item.createUser}</div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}


export class IndustryCategoryView extends React.Component {
    constructor(props) {
        super(props);

        this.dataId = this.props.param_0;

        if (!this.dataId) {
            throw new Error("Data Id Property is required");
        }
    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Industry Categories' />


                <PageableDataObjectList dataObject='industrycategories'
                    filter={`industry_id=${this.dataId}`}
                    onInit={cntrl => this.list = cntrl}
                    onPdfClick={this.onPdfClick}
                    onCsvClick={this.onCsvClick}
                    onGoogleClick={this.onGoogleClick}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-8'>Name</div>
                                <div className='col col-2 mobile-hidden'>Date</div>
                                <div className='col col-2 mobile-hidden'>User</div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row'>
                                <div className='col col-8'>{item.name}</div>
                                <div className='col col-2 mobile-hidden'>{item.createDate}</div>
                                <div className='col col-2 mobile-hidden'>{item.createUser}</div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}


import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { DataManager } from '../../../dataControls/DataManager';
import { LabelDataControl } from '../../../dataControls/LabelDataControl';
import { GenericComponent } from '../../../dataForms/GenericComponent';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';

export class PhysicalPropertiesListView extends React.Component {

    constructor(props) {
        super(props);
        this.list = null;
        this.rowSelected = this.rowSelected.bind(this);
    }

    rowSelected(item) {
        window.Router.setRoute('/configuration/structurespecs/uom/' + encodeURIComponent(item.id));
    }

    render() {
        return (
            <div className="view">
                <ImageTitle text='Measure Units'/>
                <GenericComponent dataObject='physicalProperties' dataId={this.props.param_0} enableClone={false}  />
            </div>
        );
    }
}


export class PhysicalPropertiesDetailsView extends React.Component {

    constructor(props) {
        super(props);

        
        if (!this.props.param_0) {
            throw new Error("Data Id Property is required");
        }

        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.dataManager = new DataManager('physicalProperties', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        this.loadContext();
    }

    loadContext() {
        var self = this;

        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.param_0) {
                    dataManager.loadContext(this.props.param_0)
                        .then(data => {
                            dataManager.bindControls();
                        })
                        .catch(() => {
                            window.Router.setRoute('/configuration/structurespecs/uom');
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                    self.forceUpdate();
                }

                //3) onControlBound --> Set Value
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }
    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }


    onControlUnbound(control, propertyName) {
    }


    render() {
        return (
            <div className="view">
                <ImageTitle text='Measure Units' />

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/configuration/structurespecs/uom')} />
                            <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/configuration/structurespecs/uom/new')} />                            
                            <ToolbarButton icon='glyphicon-trash' className='btn-danger' text='Delete' onClick={() => this.deleteItem()} />
                        </div>
                    )
                }} />


                <div className='row'>
                    <div className='attribute col-sm-12 col-lg-3'>
                        <label htmlFor='id'>Id</label>
                    </div>

                    <div className='attribute col-sm-12 col-lg-9'>
                        <LabelDataControl dataManager={this.dataManager} propertyName='id' onRender={data => <span>{data}</span>} />
                    </div>
                </div>

                <div className='row'>
                    <div className='attribute col-sm-12 col-lg-3'>
                        <label htmlFor='id'>Name</label>
                    </div>

                    <div className='attribute col-sm-12 col-lg-9'>
                        <LabelDataControl dataManager={this.dataManager} propertyName='name' onRender={data => <span>{data}</span>} />
                    </div>
                </div>

                <div className='row'>
                    <div className='attribute col-sm-12 col-lg-3'>
                        <label htmlFor='id'>Uom</label>
                    </div>

                    <div className='attribute col-sm-12 col-lg-9'>
                        <LabelDataControl dataManager={this.dataManager} propertyName='uom' onRender={data => <span>{data}</span>} />
                    </div>
                </div>

                <div className='row'>
                    <div className='attribute col-sm-12 col-lg-3'>
                        <label htmlFor='id'>Integer (Length)</label>
                    </div>

                    <div className='attribute col-sm-12 col-lg-9'>
                        <LabelDataControl dataManager={this.dataManager} propertyName='integerLength' onRender={data => <span>{data}</span>} />
                    </div>
                </div>

                <div className='row'>
                    <div className='attribute col-sm-12 col-lg-3'>
                        <label htmlFor='id'>Fragment (Length)</label>
                    </div>

                    <div className='attribute col-sm-12 col-lg-9'>
                        <LabelDataControl dataManager={this.dataManager} propertyName='fragmentLength' onRender={data => <span>{data}</span>} />
                    </div>
                </div>
                
            </div>
        );
    }
}

export class PhysicalPropertiesEditView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Measure Units' />

            </div>
        );
    }
}
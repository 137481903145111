import React from 'react';

export class TextboxControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : '',
            valid: false
        }

        this.currentValue = this.props.value ? this.props.value : null;

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);        
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }        
        this.isInputValid();
    }

    setValue(value) {
        var newValue = value ? value : null;
        this.setState({
            value: newValue
        });
        this.currentValue = newValue;
        this.isInputValid();
    }

    getValue() {
        return this.state.value;
    }

    isInputValid() {
        var result = true;
        var value = this.currentValue;

        if (this.props.required === true) {
            if (!value || value == null || value.length == 0) {
                result = false;
            }
        }

        if (result == true) {
            result = this.matchesRegex(value);            
        }

        this.setState({
            valid: result
        });
        return result;
    }

    onChange(event) {
        var value = event.target.value;
        var previousValue = this.state.value ? this.state.value : '';
        var match = this.matchesRegex(value);

        var newValue = match ? value : previousValue;
        this.setValue(newValue);

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    }

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(this.currentValue);
        }
    }

    onFocus(event) {
        if (this.props.onFocus) {
            this.props.onFocus(this.currentValue);
        }
    }

    matchesRegex(value) {
        var result = true;
        var regex = this.props.regex ? this.props.regex : null;
        if (regex) {
            var r = new RegExp(`^(${regex})$`, 'g');
            var match = r.test(value);            
            return match;
        }        
        return result;
    }

    render() {
        return (
            <div>
                {this.props.textarea
                    ? <textarea id={this.props.id} type="text" autoComplete="off" className={this.state.valid ? 'control textbox valid' : 'control textbox error'} value={this.state.value} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.onFocus} maxLength={this.props.maxlength} disabled={this.props.disabled == true}></textarea>
                    : <input id={this.props.id} type="text" autoComplete="off" className={this.state.valid ? 'control textbox valid' : 'control textbox error'} value={this.state.value} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.onFocus} maxLength={this.props.maxlength} disabled={this.props.disabled == true}></input>
                }                
            </div>
        );
    }
}

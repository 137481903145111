import React from 'react';
import { ApiDataAccess } from '../../infrastructure/ApiDataAccess';
import { ImageTitle } from '../../core/ImageTitle';
import { Alert } from '../../components/Components';
import { ImageTileCircle, Spacer } from '../../components/Containers';

export class StructureSpecRequestView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items : null
        }
        this.feedbackText = null;
        this.dataAccess = new ApiDataAccess('/data/structureSpecs');

        this.onApproveClick = this.onApproveClick.bind(this);
        this.onRejectClick = this.onRejectClick.bind(this);        
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {
        var self = this;
        this.dataAccess.get('/requests/pending')
            .then(data => {
                self.setState({
                    items: data
                });
            })
            .catch(ex => {
                window.Alerts.showError('Could not retreive list');
            });
    }

    onApproveClick(id) {
        var self = this;
        this.dataAccess.post(`/requests/${id}/approve`, null)
            .then(data => {
                self.refreshList();
                window.Alerts.showSuccess('The Request has been approved. The User has been notified');
            })
            .catch(error => {
                window.Alerts.showError('Could not approve request');
            });
    }

    rejectRequest(id, popup) {
        popup.close();        
        var self = this;
        this.dataAccess.post(`/requests/${id}/reject`, { text: self.feedbackText })
            .then(data => {
                self.refreshList();
                window.Alerts.showSuccess('The Request has been rejected. The User has been notified');
            })
            .catch(error => {
                window.Alerts.showError('Could not reject request');
            });        
    }


    onRejectClick(id) {
        var self = this;
        this.feedbackText = null;
        window.Modal.show(function (popup) {
            return (
                <div>
                    <div className='modal-reject-content'>
                        <p>Please enter an explanation for the requestor why you rejected this request:</p>
                        <textarea rows="3" className='control textbox' onChange={evt => { self.feedbackText = evt.target.value }}/>
                    </div>
                    <Spacer/>
                    <button className='btn btn-lg btn-primary' onClick={() => { self.rejectRequest(id, popup) }}>Reject</button>
                    <button className='btn btn-lg btn-secondary pull-right' onClick={popup.close}>Cancel</button>
                </div>
            )
        }, 'Reject Material Request')     
    }

    render() {
        return (
            <div className="view materialRequestView">
                <ImageTitle text='Pending Structure Specs Requests'/>

                {this.state.items && this.state.items.length > 0 &&
                    <div className='table'>
                        <div className='table-header'>
                            <div className='row'>
                                <div className='col col-1'>Product</div>
                                <div className='col col-1 mobile-hidden'>Packaging Group</div>
                                <div className='col col-2'>Structure Spec</div>
                                <div className='col col-2 mobile-hidden'>Converting Method</div>
                                <div className='col col-3 mobile-hidden'>Comment</div>
                                <div className='col col-1 mobile-hidden'>Requester</div>
                                <div className='col col-2 mobile-hidden'></div>
                            </div>
                        </div>

                        <div className='body'>
                        {this.state.items.map((item, idx) => {
                            return (
                                <div key={`row_${idx}`} className='row'>
                                    <div className='col col-1'>
                                        <ImageTileCircle src={`/api/products/thumbnail/${item.productId}`} value={item.productName} onClick={() => window.Router.setRoute(item.productId > 0 ? `/products/${item.productId}` : '/products/review')} />
                                    </div>
                                    <div className='col col-1 mobile-hidden'><a href={`/packaginggroups/${item.productGroupId}`}>{item.productGroupName}</a></div>
                                    <div className='col col-2'><a href={`/structurespecs/${item.structureSpecId}`}>{item.structureSpecName}</a></div>
                                    <div className='col col-2 mobile-hidden'>{item.convertingMethodName}</div>      
                                    <div className='col col-3 mobile-hidden'>{item.comment}</div>      
                                    <div className='col col-1 mobile-hidden'>{item.requesterName}</div>
                                    <div className='col col-2 mobile-hidden'>
                                        <button className='btn btn-primary' onClick={() => { this.onApproveClick(item.id) }}>Approve</button>
                                        <button className='btn btn-secondary-outline' onClick={() => { this.onRejectClick(item.id) }}>Reject</button>
                                    </div>        
                                </div>
                            )
                        })}
                        </div>

                    </div>
                }

                {(!this.state.items || this.state.items.length == 0) &&
                    <Alert type='info'>Currently there are no pending material requests! Lucky you!</Alert>
                }

            </div>
        );
    }
}
import { LookupDataSourceDataAccess } from '../../../infrastructure/DataSourceDataAccess';

export class RuleConflictProvider {

    constructor(ruleDataManager, id) {
        if (!ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }

        this.ruleDataManager = ruleDataManager;
        this.id = id ? id : 0;
        this.dataSourceAccess = new LookupDataSourceDataAccess('ruleConflictsLookup');
    }

    getConflicts() {
        var self = this;
        return new Promise((resolve, reject) => {
            var triggers = self.ruleDataManager.getTriggers();
            var targets = self.ruleDataManager.getTargets();

            var request = {
                ruleId: self.id > 0 ? self.id : null,
                triggers: self.convertTriggersToRequest(triggers),
                targets: self.convertTargetsToRequest(targets)
            };

            if (request.triggers && request.triggers.length > 0 && request.targets && request.targets.length > 0) {
                this.dataSourceAccess.getDataSource(request)
                    .then(dataSource => {
                        resolve(dataSource);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            }
        });
    }

    convertTriggersToRequest(triggers) {
        var result = [];
        triggers.forEach(t => {
            if (t.definition.inputControlType !== 2) {
                var trigger = {
                    valueType: t.definition.id,
                    referenceId: [],
                    fromValue: null,
                    toValue: null
                };

                t.items.forEach(i => {
                    trigger.referenceId.push(i.id);
                });
                result.push(trigger);
            }
            else {
                var trigger = {
                    valueType: t.definition.id,
                    referenceId: null,
                    fromValue: t.items[0].from,
                    toValue: t.items[0].to
                };
                result.push(trigger);
            }
        });
        return result;
    }

    convertTargetsToRequest(targets) {
        var result = [];
        targets.forEach(t => {

            var target = {
                affinitiyId: t.affinity,
                valueType: t.definition.id,
                referenceId: []
            };

            t.items.forEach(i => {
                target.referenceId.push(i.id);
            });
            result.push(target);
        });
        return result;
    }


}
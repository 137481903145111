export class PolicyConfirmation {

    static show(title, messages, isInfo) {
        var self = this;
        return new Promise(function (resolve, reject) {
            if (messages && messages.length > 0) {
                if (isInfo === true) {
                    window.Modal.show(popup => { return self.getPopupContent(messages, () => { popup.close(); resolve(false); }) }, title ? title : 'Policy Information');
                }
                else {
                    window.Confirmation.show(title ? title : 'Policy Information', self.getMessageContent(messages),
                        () => {
                            resolve(true);
                        },
                        () => {
                            resolve(false);
                        }
                    );
                }
            }
            else {
                resolve(true);
            }
        });
    }

    static getPopupContent(messages, onClose) {
        return (
            <div className='policy-confirmation'>
                <p>You can not proceed due to:</p>
                <table>
                    <tbody>
                    <tr>
                        <td><img className='icon' src="/images/caution.png" /></td>
                        <td>
                            <ul className='color-orange'>
                                {messages.map(function (item, idx) {
                                    return (
                                        <li key={`item_${idx}`}>{item.message}</li>
                                    )
                                })}
                            </ul>
                        </td>
                        </tr>
                    </tbody>
                </table>
                <button className='btn btn-primary' onClick={() => { onClose() }}>Close</button>
            </div>
        )
    }

    static getMessageContent(messages) {
        return (
            <div className='policy-confirmation'>
                <p>Proceeding will effect the following:</p>
                <table>
                    <tr>
                        <td><img className='icon' src="/images/caution.png" /></td>
                        <td>
                            <ul className='color-orange'>
                                {messages.map(function (item) {
                                    return (
                                        <li>{item.message}</li>
                                    )
                                })}
                            </ul>
                        </td>
                    </tr>
                </table>
                <p>Do you want to proceed?</p>
            </div>
        );
    }
}
import React from 'react';
import { Popover } from '../components/Pages';
import { Alert } from '../components/Components';

var SortableChecklistMode = {
    Select: 0,
    Sort: 1
};

export class ChecklistDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);

        this.items = [];
        this.datasourceItems = [];

        this.state = {
            items: [],
            datasourceItems: [],
            value: [],

            showFilter: false,
            filterValue: null
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectAllClick = this.onSelectAllClick.bind(this);
        this.onUnselectAllClick = this.onUnselectAllClick.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var dataSourceData = metaData.dataSourceData;

            if (!dataSourceData) {
                throw new Error(" CheckListDataControl requires Data Source Data");
            }

            self.dataManager.getDataSourceItems(dataSourceData.name)
                .then(items => {

                    self.items = items;
                    self.datasourceItems = items;

                    self.setState({
                        items: items,
                        datasourceItems: items
                    });

                    resolve(self);
                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    setValue(value) {        
        var items = [];
        
        if (value) {           
            value.forEach(item => {
               
                var datasourceItem = this.items.find(i => {
                    return i.id === item.dataObject2Id;
                });

                if (datasourceItem) {
                    var data = {
                        id: item.dataObject2Id,
                        name: datasourceItem.name
                    };
                    items.push(data);
                }
            });
        }
        
        this.setState({
            value: items
        });

        this.dataManager.reportChange(items, this);
    }


    getValue() {
        var values = this.state.value;

        var relationships = [];
        values.forEach(value => {
            var relationship = {
                sort: value.sort,
                dataObject2Id: value.id
            }
            relationships.push(relationship);
        });

        return relationships;
    }

    onSelectionChange(items) {
        var self = this;
        items.sort((a, b) => a.sort - b.sort);
        for (var i = 0; i < items.length; i++) {
            items[i].sort = i;
        }

        var value = [];
        items.forEach(item => {
            var datasourceItem = self.getDataSourceItem(item.id);
            var data = {
                id: item.id,
                name: datasourceItem.name
            };
            value.push(data);
        });

        this.setState({
            value: value
        });

        this.dataManager.reportChange(value, this);
    }

    isInputValid() {
        var result = true;
        var validationData = this.metaData && this.metaData.validationData ? this.metaData.validationData : null;
        if (validationData) {
            if (validationData.required === true && this.state.value && this.state.value.length == 0) {
                result = false;
            }
        }
        return result;
    }

    onFilterClick() {
        var self = this;
        var showFilter = !this.state.showFilter;
        var items = this.state.items;


        this.setState({
            showFilter: showFilter,
            filterValue: '',
            datasourceItems: items
        });
    }

    onFilterChange(event) {
        var value = event.target.value.toLowerCase();
        
        if (this.props.onFiltering) {
            this.setState({
                filterValue: value,
                datasourceItems: this.props.onFiltering(value, this.state.items)
            });
        }
        else {
            var items = [];
            this.state.items.forEach(item => {
                if (item.name.toLowerCase().includes(value)) {
                    items.push(item);
                }
            });
            this.setState({
                filterValue: value,
                datasourceItems: items
            });
        }
    }

    getDataSourceItem(id) {
        return this.state.items.find(item => {
            return item.id === id;
        });
    }

    onSelectAllClick() {
        var self = this;
        var values = [];
        this.state.datasourceItems.forEach(item => {
            var datasourceItem = self.getDataSourceItem(item.id);
            var data = {
                id: item.id,
                name: datasourceItem.name,
                sort: values.length
            };
            values.push(data);
        });

        this.setState({
            value: values
        });
        this.dataManager.reportChange(values, this);
    }

    onUnselectAllClick() {
        var values = [];
        this.setState({
            value: values
        });
        this.dataManager.reportChange(values, this);
    }

    render() {
        
        var items = this.props.onSorting ? this.props.onSorting(this.state.datasourceItems) : this.state.datasourceItems;
        
        return (
            <div id={this.props.propertyName} className={this.props.className ? this.props.className : ''}>
                {(items && items.length > 0) || this.state.showFilter ?                    
                    <div className={this.isInputValid() ? 'control checklist valid' : 'control checklist error'}>    
                        <Popover title={<span>Actions</span>}>
                            <div className='dropdown-item' onClick={this.onFilterClick}><div className={this.state.showFilter ? 'glyphicon glyphicon-ok' : 'empty'}/>Use Filter</div>
                            <div className="dropdown-divider"></div>
                            <div className="dropdown-item" onClick={this.onSelectAllClick}><div className={'empty'}/>Select All</div>
                            <div className="dropdown-item" onClick={this.onUnselectAllClick}><div className={'empty'}/>Unselect All</div>
                        </Popover>

                        {this.state.showFilter &&
                            <div className='filter'>
                                <input id={this.props.propertyName + '_filter'} className='control textbox' type='text' autoComplete='off' value={this.state.filterValue} onChange={this.onFilterChange} placeholder="enter a filter criteria..." ></input>
                                <button className="btn btn-secondary-outline" type="button" onClick={this.onFilterClick} title='Remove Filter'><span className="glyphicon glyphicon-remove"></span></button>
                            </div>
                        }

                        <div className='scroll-area'>
                            <ChecklistControlBase propertyName={this.props.propertyName + '_clcb'} onRenderItem={this.props.onRenderItem} items={items} values={this.state.value} onChange={this.onSelectionChange} />
                        </div>
                    </div>
                    :
                    <Alert type='info'>No items are available for selection</Alert>
                }
            </div>
        );
    }
}


export class SortableChecklistDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);


        this.state = {
            mode: SortableChecklistMode.Select,
            items: [],
            datasourceItems: [],
            value: [],

            showFilter: false,
            filterValue: null
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectAllClick = this.onSelectAllClick.bind(this);
        this.onUnselectAllClick = this.onUnselectAllClick.bind(this);
        this.onSortChanged = this.onSortChanged.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var dataSourceData = metaData.dataSourceData;

            if (!dataSourceData) {
                throw new Error(" CheckListDataControl requires Data Source Data");
            }

            self.dataManager.getDataSourceItems(dataSourceData.name)
                .then(items => {
                    self.setState({
                        items: items,
                        datasourceItems: items
                    });

                    resolve(self);
                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    setValue(value) {        
        var self = this;
        var items = []; 
        if (value) {
            value.forEach(item => {
                var datasourceItem = self.getDataSourceItem(item.dataObject2Id);
                var data = {
                    id: item.dataObject2Id,
                    name: datasourceItem.name,
                    sort: item.sort
                };
                items.push(data);
            });
        }

        this.setState({
            value: items            
        });

        if (items && items.length > 1) {
            this.changeMode(SortableChecklistMode.Sort);
        }
        else {
            this.changeMode(SortableChecklistMode.Select);
        }

        this.dataManager.reportChange(items, this);
    }

    getDataSourceItem(id) {
        return this.state.items.find(item => {
            return item.id === id;
        });
    }

    getValue() {
        var values = this.state.value;

        var relationships = [];
        values.forEach(value => {
            var relationship = {
                sort: value.sort,
                dataObject2Id: value.id
            }
            relationships.push(relationship);
        });

        return relationships;
    }

    onSelectionChange(items) {
        var self = this;
        items.sort((a, b) => a.sort - b.sort);
        for (var i = 0; i < items.length; i++) {
            items[i].sort = i;
        }

        var value = [];
        items.forEach(item => {
            var datasourceItem = self.getDataSourceItem(item.id);
            var data = {
                id: item.id,
                name: datasourceItem.name,
                sort: item.sort
            };
            value.push(data);
        });

        this.setState({
            value: value
        });

        this.dataManager.reportChange(value, this);
    }

    isInputValid() {
        var result = true;
        var validationData = this.metaData && this.metaData.validationData ? this.metaData.validationData : null;
        if (validationData) {
            if (validationData.required === true && this.state.value && this.state.value.length == 0) {
                result = false;
            }
        }
        return result;
    }

    onFilterClick() {
        var self = this;
        var showFilter = !this.state.showFilter;
        var items = this.state.items;


        this.setState({
            showFilter: showFilter,
            filterValue: '',
            datasourceItems: items
        });
    }

    onFilterChange(event) {
        var value = event.target.value.toLowerCase();

        var items = [];
        this.state.items.forEach(item => {
            if (item.name.toLowerCase().includes(value)) {
                items.push(item);
            }
        });

        this.setState({
            filterValue: value,
            datasourceItems: items
        });
    }

    onSelectAllClick() {
        var self = this;
        var values = [];
        this.state.datasourceItems.forEach(item => {
            var datasourceItem = self.getDataSourceItem(item.id);
            var data = {
                id: item.id,
                name: datasourceItem.name,
                sort: values.length
            };
            values.push(data);
        });
        this.setState({
            value: values
        });
        this.dataManager.reportChange(values, this);        
    }

    onUnselectAllClick() {
        var values = [];
        this.setState({
            value: values
        });
        this.dataManager.reportChange(values, this);        
    }

    onSortChanged(items) {
        this.setState({
            value: items
        });
        this.dataManager.reportChange(items, this);        
    }

    changeMode(mode) {

        if (mode === SortableChecklistMode.Select) {
            this.setState({
                mode: SortableChecklistMode.Select
            });
        }

        if (mode === SortableChecklistMode.Sort) {
            this.setState({
                mode: SortableChecklistMode.Sort,
                showFilter: false,
                filterValue: '',
                datasourceItems: this.state.items
            });
        }

    }

    render() {
        var self = this;
        return (
            <div className='input-control container-control'>
                <div className="input-group">
                    <div className='input-group-prepend'>
                        <span className={this.isInputValid() ? 'input-group-text glyphicon glyphicon-ok background-green' : 'input-group-text glyphicon glyphicon-remove background-red'}></span>
                    </div>

                    <div className="input-control-container" id={this.props.propertyName}>
                        <div className='action-container d-none d-md-block'>
                            <div className="dropdown">
                                <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {this.state.mode === SortableChecklistMode.Select &&
                                        <div className='dropdown-menu-content'>                                            
                                            <a className="dropdown-item" onClick={() => this.changeMode(SortableChecklistMode.Sort)}>Re-arrange items</a>
                                            <a className='dropdown-item' onClick={this.onFilterClick}><span className={this.state.showFilter ? 'glyphicon glyphicon-ok' : 'hidden'}></span>Use Filter</a>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" onClick={this.onSelectAllClick}>Select All</a>
                                            <a className="dropdown-item" onClick={this.onUnselectAllClick}>Unselect All</a>
                                        </div>
                                    }

                                    {this.state.mode === SortableChecklistMode.Sort &&
                                        <div>
                                            <a className="dropdown-item" onClick={() => this.changeMode(SortableChecklistMode.Select)}>Select Items</a>
                                        </div>
                                    }
                                </div>                               
                            </div>
                        </div>

                        {this.state.showFilter &&
                            <div className='filter'>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button className="btn btn-outline-secondary" type="button" onClick={this.onFilterClick} title='Remove Filter'><span className="glyphicon glyphicon-remove"></span></button>
                                    </div>
                                    <input id={this.props.propertyName + '_filter'} className='form-control' value={this.state.filterValue} onChange={this.onFilterChange} placeholder="enter a filter criteria..." ></input>
                                </div>
                            </div>
                        }

                        {this.state.mode === SortableChecklistMode.Select &&
                            <div>
                                {this.state.value && this.state.value.length > 1 &&
                                    <p>Click <span className='clickable' onClick={() => this.changeMode(SortableChecklistMode.Sort)}>here</span> to re-arrange items below.</p>
                                }
                                <div className='item-container'>
                                    <ChecklistControlBase propertyName={this.props.propertyName + '_clcb'} items={this.state.datasourceItems} values={this.state.value} onChange={this.onSelectionChange} />
                                </div>
                            </div>
                        }

                        {this.state.mode === SortableChecklistMode.Sort &&
                            <div>
                                <p>Click <span className='clickable' onClick={() => this.changeMode(SortableChecklistMode.Select)}>here</span> to select additional items.</p>
                                <SortableListControlBase id={this.props.propertyName + '_slcb'} items={this.state.value} onChange={this.onSortChanged} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}


export class SortableListControlBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            values: this.props.items ? this.props.items : []
        }

        this.onUpClick = this.onUpClick.bind(this);
        this.onDownClick = this.onDownClick.bind(this);
    }

    onUpClick(item) {
        var items = this.props.items;
        var idx = item.sort;

        var saved = items[idx - 1];
        items[idx - 1] = item;
        items[idx] = saved;

        for (var i = 0; i < items.length; i++) {
            items[i].sort = i;
        }

        this.setState({
            values: items
        });

        if (this.props.onChange) {
            this.props.onChange(items);
        }
    }

    onDownClick(item) {
        var items = this.props.items;
        var idx = item.sort;

        var saved = items[idx + 1];
        items[idx + 1] = item;
        items[idx] = saved;

        for (var i = 0; i < items.length; i++) {
            items[i].sort = i;
        }

        this.setState({
            values: items
        });

        if (this.props.onChange) {
            this.props.onChange(items);
        }
    }

    render() {
        var self = this;
        return (
            <div>
                {this.props.items.map(function (item, index) {
                    return (
                        <div className="form-sort">
                            <button onClick={() => self.onUpClick(item)} className='btn btn-outline-secondary btn-sm' disabled={index == 0}><span className='glyphicon glyphicon-chevron-up'></span></button>
                            <button onClick={() => self.onDownClick(item)} className='btn btn-outline-secondary btn-sm' disabled={index == self.props.items.length - 1}><span className='glyphicon glyphicon-chevron-down'></span></button>
                            <span className="form-sort-label">{item.name}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

}



export class ChecklistControlBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            values: this.props.values ? this.props.values : []
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onRenderItem = this.onRenderItem.bind(this);
    }


    getValue() {
        return this.props.values;
    }

    getValueIndex(id) {
        return this.props.values.findIndex(value => {
            return value.id == id;
        });
    }

    getItem(id) {
        return this.props.items.find(item => {
            return item.id === id;
        });
    }

    isChecked(item) {
        var idx = this.getValueIndex(item.id);
        return idx >= 0;
    }


    onSelectionChange(event) {
        var target = event.target;
        var checked = target.checked;
        var id = parseInt(event.target.value);
        var item = this.getItem(id);

        var values = this.getValue();
        if (checked === true) {
            var data = {
                id: item.id,
                sort: values.length
            };
            values.push(data);
        }
        else {
            var idx = this.getValueIndex(id);
            if (idx >= 0) {
                values.splice(idx, 1);
            }
        }

        this.setState({
            value: values
        });

        if (this.props.onChange) {
            this.props.onChange(values);
        }

    }

    onRenderItem(item) {
        if (this.props.onRenderItem) {
            return this.props.onRenderItem(item)
        }
        else {
            return (<span>{item.name}</span>)
        }
    }

    render() {
        var self = this;
        return (
            <div>
                {this.props.items.map(function (item, index) {
                    return (
                        <div key={`cb_${index}`} className="form-check">
                            <input className="form-check-input" type="checkbox" id={self.props.propertyName + '_cb_' + index} value={item.id} checked={self.isChecked(item)} onChange={self.onSelectionChange} />
                            <label className="form-check-label" htmlFor={self.props.propertyName + '_cb_' + index}>{self.onRenderItem(item)}</label>
                        </div>
                    );
                })}
            </div>
        );
    }
}

import React from 'react';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';


export class StructureSpecsImportComponent extends React.Component {

    constructor(props) {
        super(props);

        this.apiDataAccess = new ApiDataAccess('/data');
        this.filter = "status='PND'"
        this.list = null;
        this.onIgnoreClick = this.onIgnoreClick.bind(this);
        this.onImportClick = this.onImportClick.bind(this);
    }

    onIgnoreClick(item) {
        var self = this;

        this.apiDataAccess.post(`/navJobTemplates/${encodeURIComponent(item.id)}/ignore`, null)
            .then(success => { self.list.refresh(self.filter); })
            .catch(error => { window.Alerts.showError('Could not mark the structure spec as ignored'); })

    }

    onImportClick(item) {
        var self = this;

        this.apiDataAccess.post(`/navJobTemplates/${encodeURIComponent(item.id)}/import`, null)
            .then(success => { self.list.refresh(self.filter); })
            .catch(error => { window.Alerts.showError('Could not import the structure spec'); })

    }


    render() {
        return (
            <div className="structure-spec-import-component">
                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/structurespecs')} />
                        </div>
                    )
                }} />

                <PageableDataObjectList dataObject='navJobTemplates' filter={this.filter} onInit={list => { this.list = list }}
                    onRenderTitleRow={() => {
                        return (
                            <div className='header row background-black color-white'>
                                <div className='col col-sm-12 col-lg-10'>Name</div>
                                <div className='col col-sm-12 col-lg-2'></div>
                            </div>
                        )
                    }}
                    onRenderRow={(item) => {
                        return (
                            <div className='row border-bottom'>
                                <div className='col col-sm-12 col-lg-10'>{item.name}</div>
                                <div className='col col-sm-12 col-lg-2'>
                                    <div className='pull-right'>
                                        <button className='btn btn-sm btn-danger' onClick={() => this.onIgnoreClick(item)}>Ignore</button>
                                        <button className='btn btn-sm btn-success' onClick={() => this.onImportClick(item)}>Import</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }} />
            </div>
        );
    }
}
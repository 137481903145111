import React from 'react';
import { ApiDataAccess } from '../../infrastructure/ApiDataAccess';
import { ImageTitle } from '../../core/ImageTitle';
import { ToggleSwitchControl } from '../../controls/ToggleSwitchControl';
import { Card, Row, Column } from '../../components/Containers';
import { Logger } from "../../infrastructure/Logger";

export class StructureSpecSheetRegistryView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: null
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems() {
        this.setState({
            items: null
        })
        var dataAccess = new ApiDataAccess('/data/StructureSpecsRegistry')
        dataAccess.get('/translations')
            .then(registry => {
                var result = [];
                Object.keys(registry).map((key) => {
                    var items = registry[key];
                    var spec = {
                        id: items[0].structureSpecId,
                        name: items[0].structureSpecName,
                        createDate: items[0].createDate,
                        createUser: items[0].createUser,
                        translations: []
                    };

                    items.forEach(item => {
                        var translation = {
                            culture: item.targetCulture,
                            cultureName: item.targetCultureName,
                            exists: item.exists,
                            published: item.published,
                            isLocal: item.isLocal,
                            url: item.url
                        };
                        spec.translations.push(translation);
                    });
                    result.push(spec);

                })                
                this.setState({
                    items: result
                })
            })
            .catch(ex => {
                Logger.writeError('Could not load structure spec items', ex)
            });
            
    }

    onTranslationCompleted() {
        this.loadItems();
    }

    render() {
        return (
            <div className="view structureSpecSheetRegistryView">
                <ImageTitle text='Structure Spec Sheet Translations' />
                {this.state.items &&
                    <div>
                        {this.state.items.map((item, idx) => {
                            return (
                                <StructureSpecItem key={`item_${idx}`} item={item} onTranslated={() => { this.onTranslationCompleted()} } />
                            )
                        })}
                    </div>
                }

            </div>
        )
    }

}


class StructureSpecItem extends React.Component {

    constructor(props) {
        super(props);
        this.item = this.props.item;
    }

    getFormattedDate(item) {
        var date = new Date(item.createDate);
        return date.toLocaleString();
    }

    render() {
        return (
            <Card title={`${this.item.name} (SPEC-${this.item.id})`} subtitle={`The following translation options are available for SPEC-${this.item.id}:`}>
                <div className='table'>
                    <div className='table-header'>
                        <div className='row'>
                            <div className='col col-1'></div>
                            <div className='col col-4'>Name</div>
                            <div className='col col-4'>Culture</div>
                            <div className='col col-1'>Translation</div>
                            <div className='col col-2'>Published</div>
                        </div>
                    </div>

                    <div className='body'>
                        {this.item.translations.map((translation, idx) => {
                            return (
                                <StructureSpecTranslationItem key={`item_${idx}`} item={this.item} translation={translation} />
                            )
                        })}
                    </div>
                </div>                
            </Card>
        )
    }
}


class StructureSpecTranslationItem extends React.Component {

    constructor(props) {
        super(props);

        this.item = this.props.item;

        this.state = {
            translation: this.props.translation
        }
    }

    getFormattedDate(item) {

        var date = new Date(item.createDate);
        return date.toLocaleString();
    }

    onCreateClick(item, translation, showPopup) {
        this.refreshTranslations(item, translation, showPopup);               
    }


    refreshTranslations(item, translation, showPopup) {
        var self = this;
        var dataAccess = new ApiDataAccess('/data/StructureSpecsRegistry');

        dataAccess.post(`/${item.id}/translations/${translation.culture}`, {})
            .then(data => {
                var trans = {
                    culture: data.targetCulture,
                    cultureName: data.targetCultureName,
                    exists: data.exists,
                    published: data.published,
                    isLocal: data.isLocal
                }

                self.setState({
                    translation: trans
                });

                if (showPopup) {
                    self.showPopup(item, translation);
                }
            })
            .catch(ex => {
                window.Alerts.showError('An error occurred while translating the Spec');
            });
    }


    onPopupCloseClick(popup, item, translation) {
        popup.close();
        this.refreshTranslations(item, translation, false);
    }

    showPopup(item, translation) {
        var self = this;
        var dataAccess = new ApiDataAccess('/data/StructureSpecsRegistry');

        dataAccess.get(`/iframe/${translation.culture}`)
            .then(url => {
                window.Modal.show(popup => {
                    return (
                        <div>
                            <iframe src={url} className='translation-iframe' />
                            <button className='btn btn-primary pull-right' onClick={() => { self.onPopupCloseClick(popup, item, translation) }}>Close</button>
                        </div>
                    )
                },
                    "Structure Spec Translations",
                    null,
                    "translations"
                )
            });

    }



    onPublishToggled(item, translation, on) {

        var self = this;
        var dataAccess = new ApiDataAccess('/data/StructureSpecsRegistry');

        var url = on
            ? `/${item.id}/translations/${translation.culture}/publish`
            : `/${item.id}/translations/${translation.culture}/unpublish`;

        dataAccess.post(url, {})
            .then(data => {
                var trans = {
                    culture: data.targetCulture,
                    cultureName: data.targetCultureName,
                    exists: data.exists,
                    published: data.published,
                    isLocal: data.isLocal
                }

                self.setState({
                    translation: trans
                });
            })
            .catch(ex => {
                window.Alerts.showError('An error occurred while translating the Spec');
            })
    }


    render() {
        return (
            <div>
                {this.state.translation.isLocal
                    ?                
                    <div className='row'>
                        <div className='col col-1'>
                            <a href={`/api/structureSpecs/${this.item.id}/pdf?attachment=1&force=1`}><img src='/images/pdf_logo.png' /></a>
                        </div>
                        <div className='col col-4'>{this.state.translation.cultureName}</div>
                        <div className='col col-4'>{this.state.translation.culture}</div>
                        <div className='col col-1'><button className='btn btn-primary-outline btn-sm' disabled={true}>Refresh</button></div>
                        <div className='col col-2'><ToggleSwitchControl disabled={true} on={true} /></div>
                    </div>
                    :
                    <div className='row'>
                        <div className='col col-1'>
                            {this.state.translation.exists
                                ? <a href={`/api/structureSpecs/${this.item.id}/pdf?culture=${this.state.translation.culture}&attachment=1&force=1`}><img src='/images/pdf_logo.png' /></a>
                                : <div><img src='/images/pdf_logo_disabled.png' /></div>
                            }
                        </div>
                        <div className='col col-4'>{this.state.translation.cultureName}</div>
                        <div className='col col-4'>{this.state.translation.culture}</div>
                        {this.state.translation.exists
                            ? <div className='col col-1'><button className='btn btn-primary-outline btn-sm' onClick={() => { this.onCreateClick(this.item, this.state.translation, true) }}>Refresh</button></div>
                            : <div className='col col-1'><button className='btn btn-primary btn-sm' onClick={() => { this.onCreateClick(this.item, this.state.translation, true) }}>Create</button></div>
                        }
                        <div className='col col-2'><ToggleSwitchControl disabled={!this.state.translation.exists} on={this.state.translation.published} onChange={(on) => { this.onPublishToggled(this.item, this.state.translation, on) }} /></div>
                    </div>
                }
            </div>
        )
    }
}


import React from 'react';
import { RuleTriggerPopupContent } from './RuleTriggerPopupContent';
import { RuleTriggerCard } from './RuleTriggerCard';
import { Logger } from "../../../infrastructure/Logger";


export class RuleTriggerEditor extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }
        this.ruleDataManager = this.props.ruleDataManager;

        this.state = {
            triggers: []
        }

        this.onAddClick = this.onAddClick.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onTriggerAdd = this.onTriggerAdd.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this)
        }
    }

    setTriggers(triggers) {
        var self = this;
        triggers.forEach(trigger => {
            self.onTriggerAdd(trigger);
        });
    }

    onTriggerAdd(trigger) {
        var self = this;        
        this.ruleDataManager.addTrigger(trigger)
            .then(triggers => {
                self.setState({
                    triggers: triggers
                });
            })
            .catch(errors => {
                Logger.writeError('Could not add Trigger', errors);
            });
    }

    onAddClick(trigger) {
        this.onTriggerAdd(trigger);
        if (this.props.onChanged) {
            this.props.onChanged(trigger);
        }
    }

    onDeleteClick(trigger) {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?', () => {
            self.setState({
                triggers: []
            });

            self.ruleDataManager.removeTrigger(trigger)
                .then(triggers => {

                    self.setState({
                        triggers: triggers
                    });
                })
                .catch(errors => {
                    Logger.writeError('Could not remove trigger', errors);
                });

            if (self.props.onChanged) {
                self.props.onChanged(trigger);
            }
        });
    }
   
    showModal() {
        var self = this;        
        window.Modal.show(
            modal => <RuleTriggerPopupContent modal={modal} ruleDataManager={self.ruleDataManager} onAddClick={self.onAddClick} />,
            'Trigger',
            null,
            'rule-popup'
        )
    }

    onClick() {
        this.showModal();
    }

    render() {
        var self = this;
        return (
            <div className="rule-trigger-editor">
                {this.state.triggers.map(function (trigger, idx) {
                    return (
                        <div key={`trig_${idx}`}>
                            {idx > 0 &&
                                <div>
                                    <div className="justify-content-center">
                                        <div className='connector'></div>
                                    </div>
                                    <div className="justify-content-center">
                                        <button className='btn btn-primary-outline btn-round' disabled={true}>+</button>
                                    </div>
                                    <div className="justify-content-center">
                                        <div className='connector'></div>
                                    </div>
                                </div>
                            }
                            <RuleTriggerCard preventEdit={self.props.preventEdit} ruleTrigger={trigger} onDeleteClick={self.onDeleteClick} onEditClick={self.onEditClick} />
                        </div>
                    );
                })}

                

                {this.props.preventEdit !== true && this.state.triggers && this.state.triggers.length > 0 && this.state.triggers.length < this.ruleDataManager.getTotalRuleTriggerDefinitionCount() &&
                    <div>
                        <div className="justify-content-center">
                            <div className='connector'></div>
                        </div>
                        <div className="justify-content-center">
                            <button className='btn btn-primary-outline btn-round' onClick={this.onClick}>+</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
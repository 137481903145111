import React from 'react';

export class DropdownControl extends React.Component {
    constructor(props) {
        super(props);

        this.value = this.props.value ? this.props.value : -1;
        this.state = {
            value: this.value
        }

        this.onChange = this.onChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
    }

    componentDidMount() {
        if (this.props.onMounted) {
            this.props.onMounted(this);
        }

        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onChange(event) {
        var self = this;

        var value = event.target.value && event.target.value !== '-1' ? event.target.value : null;

        this.setValue(value);

        var item = null;
        if (value) {
            item = this.props.items.find(function (item) {
                return item[self.props.valueMemberName] == value;
            });
        }

        if (this.props.onChange) {
            this.props.onChange(value, item);
        }
    }

    onAddClick() {
        if (this.props.onAddClick) {
            this.props.onAddClick();
        }
    }

    setValue(value) {
        this.setState({
            value: value && value != null ? value : -1
        });
    }

    getValue() {
        var result = true;
        if (this.props.onValidate) {
            result = this.props.onValidate(this.state.value)
        }
        else {
            result = this.state.value >= 0 ? this.state.value : null;
        }
        return result;
    }

    isInputValid() {

        if (typeof this.props.required === 'undefined') {
            return this.state.value !== -1;
        }
        else if (this.props.required === true) {
            return this.state.value !== -1;
        }
        else {
            return true;
        }
    }

    reset() {
        this.value = this.props.value ? this.props.value : -1;
        this.state = {
            value: this.value
        }
    }

    render() {
        var self = this;
        return (
            <select id={this.props.id} className={this.isInputValid() ? 'control dropdown valid' : 'control dropdown error'} value={this.props.value ? this.props.value : this.state.value} onChange={this.onChange} disabled={!this.props.items || this.props.items.length === 0 || this.props.disabled === true}>
                <option value={-1}>Please select...</option>

                {this.props.items.map(function (item, id) {
                    return (
                        <option value={item[self.props.valueMemberName]} key={self.props.id + '_' + id + '_option'}>{item[self.props.displayMemberName]}</option>
                    );
                })}
            </select>
        );
    }
}

import { ApiDataAccess } from './ApiDataAccess';

export class DataSource {
    constructor(items) {
        this.items = items;
    }

    getItems() {
        return this.items;
    }

    getItem(id) {
        return this.items.find(item => {
            return item.id == id;
        });
    }
}


export class DataSourceDataAccess {
    constructor(name) {
        if (!name) {
            throw new Error("Name is required");
        }

        this.name = name;
        this.dataAccess = new ApiDataAccess('/data/datasources');
    }

    getDataSource() {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.dataAccess.get(`/${encodeURIComponent(self.name)}`)
                .then(data => { resolve(new DataSource(data)) })
                .catch(ex => reject(ex));
        });
    }
}


export class LookupDataSourceDataAccess {
    constructor(name) {
        if (!name) {
            throw new Error("Name is required");
        }

        this.name = name;
        this.dataAccess = new ApiDataAccess('/data/lookup');
    }

    getDataSource(request) {
        var self = this;
        return new Promise(function (resolve, reject) {

            self.dataAccess.post(`/${encodeURIComponent(self.name)}`, request)
                .then(data => { resolve(new DataSource(data)) })
                .catch(ex => reject(ex));

        });
    }
}
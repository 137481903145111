import React from 'react';
import { DataSourceDataAccess } from '../infrastructure/DataSourceDataAccess';
import { ChecklistControl } from './ChecklistControl';
import { Logger } from "../infrastructure/Logger";
export class DataSourceChecklistControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataSourceName) {
            throw new Error("dataSourceName is required");
        }

        this.control = null;
        this.dataSource = null;
        this.state = {
            items: []
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setDataSource(this.props.dataSourceName);
    }

    setDataSource(name) {
        var self = this;
        this.control.reset();
        var dataSourceDataAccess = new DataSourceDataAccess(name);
        dataSourceDataAccess.getDataSource()
            .then(dataSource => {
                self.dataSource = dataSource;
                self.setState({
                    items: dataSource.getItems()
                });
            })
            .catch(errors => {
                Logger.writeError('Could not retrieve data source items', errors);
            });

        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }


    onChange(values) {
        var items = [];
        var ids = [];
        var self = this;
        if (this.props.onChange) {
            if (values && values.length > 0) {
                values.forEach(function (value) {
                    var item = self.dataSource.getItem(value.id);
                    ids.push(value.id);
                    items.push(item);
                });
                this.props.onChange(ids, items);
            }
            else {
                this.props.onChange(null, null);
            }
        }
    }

    render() {
        return (
            <ChecklistControl onInit={ctrl => this.control = ctrl} items={this.state.items} onChange={this.onChange} valueMemberName='id' displayMemberName='name' />
        );
    }
}
import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { HelpControl } from './HelpControl';

export class ImageUploadControl extends React.Component {
    constructor(props) {
        super(props);

        this.imageId = null;
        this.state = {            
            value: this.props.value,
            base64: null,
            size: null
        }
        
        this.onImageClick = this.onImageClick.bind(this);
    }

    setValue(value) {
        this.setState({
            value: value
        });
        this.forceUpdate();

    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }

        this.raiseChangeNotification();
    }

    raiseChangeNotification() {
        if (this.props.onChange) {
            this.props.onChange(this.imageId, this.props.id)
        }
    }

    isInputValid() {
        var result = true;
        return result;
    }

    onUploadClick(popup) {
        var self = this;
        var uploadControl = document.getElementById(this.props.id + '_image_upload_cntrl');
        var file = uploadControl.files[0];

        if (file && file.size > 0) {                        
            var data = new FormData()
            data.append('file', uploadControl.files[0]);

            popup.close();
            var dataAccess = new ApiDataAccess('/data/images');
            var url = '/upload/' + encodeURI(this.state.value ? this.state.value : 0);
            dataAccess.upload(url, data)
                .then(data => {
                    var imageInfo = JSON.parse(data);
                    self.setValue(null);
                    self.forceUpdate(() => {
                        self.imageId = imageInfo.id;
                        self.setState({
                            size: file.size,
                            value: imageInfo.id
                        });
                        self.forceUpdate();

                        self.raiseChangeNotification();
                    });
                })
                .catch(error => {
                    window.Alerts.showError('Could not upload image');
                });       
        }        
    }

    getImage(value) {
        var result = null;
        if (value) {
            var timestamp = new Date().getTime();
            result = '/data/images/' + encodeURIComponent(value) + '?ts='+encodeURIComponent(timestamp);
        }
        return result;
    }


    onImageClick() {
        var self = this;        
        window.Modal.show(function (popup) {
            return (
                <div className='image-upload-modal'>
                    <p>Please select the image and press the upload button</p>
                    <input id={self.props.id+'_image_upload_cntrl'} className='form-control' type="file" name="pic" accept="image/*"></input>
                    <div className='row'>
                        <button className='btn btn-primary' onClick={() => { self.onUploadClick(popup);}}>Upload</button>
                    </div>
                </div>
            )
        }, 'Select an image')    
    }

    render() {
        return (
            <div className='input-control image-upload-control'>
                <label htmlFor={this.props.id}>{this.props.display.caption}</label>
                <HelpControl help={this.props.help} />
                <div className="input-group">
                    <div className='input-group-prepend'>
                        <span className={this.isInputValid() ? 'input-group-text glyphicon glyphicon-ok background-green' : 'input-group-text glyphicon glyphicon-remove background-red'}></span>
                    </div>

                    <div className='image-container' onClick={this.onImageClick}>
                        {this.getImage(this.state.value) &&
                            <img src={this.getImage(this.state.value)} />
                        }
                    </div>


                </div>
            </div>
        );
    }
}

import React from 'react';
import { GenericDataAccess } from '../dataControls/GenericDataAccess';
import { HelpControl } from './HelpControl';
import { Card } from '../components/Containers';

export class GenericDisplayComponent extends React.Component {

    constructor(props) {
        super(props);

        this.logger = window.BaseLogger;
        //this.dataAccess = window.GenericDataAccess;
        this.dataAccess = new GenericDataAccess();


        this.state = {
            item: [],
            metaDataItems: []
        };

    }

    componentDidMount() {
        var self = this;
        this.dataAccess.getFormattedItem(this.props.dataObject, this.props.dataId, function (result) {
            self.setState({
                item: result
            });
            self.retrieveMetaData();
        });
    }

    async retrieveMetaData() {        
        var result = await this.dataAccess.getMetaData(this.props.dataObject);
        this.setState({
            metaDataItems: result
        });
    }

    getLabel(id) {
        var metaData = this.state.metaDataItems[id];
        var display = metaData.display;
        if (display && display.caption) {
            return display.caption;
        }
        return null;
    }

    createTitle(id) {
        var metaData = this.state.metaDataItems[id];
        var display = metaData.display;
        if (display && display.title) {
            return (<h3 className='title'>{display.title}</h3>);
        }
    }



    createDescription(id) {
        var metaData = this.state.metaDataItems[id];
        var display = metaData.display;
        if (display && display.description) {
            return (<p className='description'>{display.description}</p>);
        }
    }


    getHelp(id) {
        var result = null;
        var metaData = this.state.metaDataItems[id];
        var help = metaData.help;
        if (help) {
            result = help;
        }               
        return result;
    }

    getContent(id) {
        return this.state.item[id];
    }

    getControlInputType(id) {
        var result = null;
        var metaData = this.state.metaDataItems[id];
        var display = metaData.display;
        if (display) {
            result = display.inputType;
        }        
        return result;
    }

    getImageUrl(controlId) {
        var timestamp = new Date().getTime();
        var url = '/data/images/' + this.getContent(controlId) + '?ts=' + encodeURIComponent(timestamp);
        return url;
    }

    render() {
        var self = this;
        return (
            <Card title='Details' subtitle='Find below the details for this entity:'>                
                {Object.keys(self.state.metaDataItems).map((controlId, index) => {
                    if (self.getLabel(controlId) && (self.state.item[controlId] || self.props.showPopulated !== true)) {
                        return (
                            <div className='generic-display-component' key={'display_component_' + index}>                                
                                {self.createTitle(controlId)}
                                {self.createDescription(controlId)}
                                <div className='row border-bottom'>
                                    <div className="col col-12">
                                        <span>{self.getLabel(controlId)}</span>
                                    </div>
                                    <div className="col col-12 color-blue">

                                        {self.getControlInputType(controlId) !== 'ImageUpload' && 
                                            <span>{self.getContent(controlId)}</span>
                                        }
                                        
                                        {self.getControlInputType(controlId) === 'ImageUpload' && self.getContent(controlId) &&
                                            <img src={self.getImageUrl(controlId)}/>
                                        }

                                        {self.getControlInputType(controlId) === 'ImageUpload' && !self.getContent(controlId) &&
                                            <span>none</span>
                                        }

                                        <HelpControl help={self.getHelp(controlId)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </Card>
        );
    }
}

import React from 'react';
import { TextboxControl } from './TextboxControl';
import { AppendButton } from '../components/Containers';

export class ComboBoxControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.items) {
            throw new Error("Items Property is required");
        }

        if (!this.props.displayPropertyName) {
            throw new Error("DisplayPropertyName Property is required");
        }

        if (!this.props.valuePropertyName) {
            throw new Error("ValuePropertyName Property is required");
        }

        this.id = 'cbx_' + this.props.id;
        this.items = this.props.items;
        this.alternativeItems = this.props.alternativeItems;
        this.displayPropertyName = this.props.displayPropertyName;
        this.valuePropertyName = this.props.valuePropertyName;

        this.state = {
            items: this.items,
            selectedItem: null,
            expanded: false,
            checked: true
        }


        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
        this.expand = this.expand.bind(this);
        this.onCheckBoxClick = this.onCheckBoxClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
    }

    componentDidMount() {
        var self = this;
        if (this.props.id) {
            window.addEventListener('click', function (e) {
                let ctrl = document.getElementById(self.id);
                if (ctrl && ctrl.contains(e.target)) {
                    // Clicked in box
                } else {
                    self.expand(false);
                }
            });
        }
    }

    onFilterChanged(value) {
        let result = [];
        this.items.forEach(item => {
            if (item[this.displayPropertyName].toLowerCase().includes(value.toLowerCase())) {
                result.push(item);
            }
        });

        this.setState({
            items: result.length > 0 ? result : this.items
        });
    }

    onItemSelected(item) {
        this.setState({
            selectedItem: item
        });

        if (item) {
            this.expand(false);
        }
        else {
            this.setState({
                items: this.items,
                expanded: true
            });
        }

        if (this.props.onSelected) {
            this.props.onSelected(item, this);
        }

    }

    expand(expanded) {
        this.setState({
            expanded: expanded
        });
    }

    onAddClick() {
        if (this.props.onAddClick) {
            this.props.onAddClick();
        }
    }

    onCheckBoxClick() {
        var value = !this.state.checked;
        this.setState({ checked: value });


        if (value) {
            this.setState({ items: this.items, expanded: false });
        } else {
            this.setState({ items: this.alternativeItems, expanded: false });
        }
       
    }

    render() {
        return (
            <div className='customDropdown' id={this.id}>
                {this.state.selectedItem
                    ? <AppendButton title='Add' onClick={this.onAddClick}>
                        <div className='control textbox clickable' onClick={() => { this.onItemSelected(null) }}>
                            <span>{this.state.selectedItem[this.displayPropertyName]}</span>                            
                        </div>
                    </AppendButton>
                    : <div>
                        <AppendButton title={this.state.checked ? <span className='glyphicon glyphicon-lock lock color-green' /> : <span className='glyphicon glyphicon-fire lock color-orange' />} onClick={this.onCheckBoxClick}>
                            <TextboxControl id={'txt' + this.id} onChange={this.onFilterChanged} onFocus={() => { this.expand(true) }} required={true} />
                        </AppendButton>

                        {this.state.expanded &&
                            <div className='optionsContainer'>
                                {this.state.items.map((item, idx) => {
                                    return (
                                        <div key={'item' + idx} className='listItem' onClick={() => { this.onItemSelected(item) }}>
                                            <span>{item[this.displayPropertyName]}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

}
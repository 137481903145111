import React from 'react';
import { Tile } from '../../../core/Tile';
import { ImageTitle } from '../../../core/ImageTitle';

export class ConfigurationFeatureAttributesHomeScreen extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Features' image='/images/attributes/material.png' />
                <div className='tile-container'>
                    <Tile title='Bottom Fills' onClick={() => window.Router.setRoute('/configuration/feature/attributes/bottomfill')} />
                    <Tile title='Cores' onClick={() => window.Router.setRoute('/configuration/feature/attributes/core')} />
                    <Tile title='Color Specs' onClick={() => window.Router.setRoute('/configuration/feature/attributes/colorspecs')} />
                    <Tile title='Double Cuts' onClick={() => window.Router.setRoute('/configuration/feature/attributes/doublecuts')} />
                    <Tile title='Epac Connect' onClick={() => window.Router.setRoute('/configuration/feature/attributes/qrcodetype')} />
                    <Tile title='Eyemarks' onClick={() => window.Router.setRoute('/configuration/feature/attributes/eyemarks')} />
                    <Tile title='Gusset Types' onClick={() => window.Router.setRoute('/configuration/feature/attributes/gussettypes')} />
                    <Tile title='Hang Holes' onClick={() => window.Router.setRoute('/configuration/feature/attributes/hanghole')} />
                    <Tile title='Outsourced Features' onClick={() => window.Router.setRoute('/configuration/feature/attributes/outsourcedfeatures')} />
                    <Tile title='Rounded Corners' onClick={() => window.Router.setRoute('/configuration/feature/attributes/roundedcorners')} />
                    <Tile title='Seal Width' onClick={() => window.Router.setRoute('/configuration/feature/attributes/sealwidth')} />
                    <Tile title='Tear Notches' onClick={() => window.Router.setRoute('/configuration/feature/attributes/tearnotch')} />
                    <Tile title='Unwind Directions' onClick={() => window.Router.setRoute('/configuration/feature/attributes/unwinddirections')} />
                    <Tile title='Vent Options' onClick={() => window.Router.setRoute('/configuration/feature/attributes/vents')} />
                    <Tile title='Zippers' onClick={() => window.Router.setRoute('/configuration/feature/attributes/zipper')} />
                    <Tile title='Feature Default Locations' onClick={() => window.Router.setRoute('/configuration/features/locations')} />
                </div>
            </div>
        );
    }
}


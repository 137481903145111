import React from 'react';

export class DropdownDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataSourceItems = null;
        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);
        this.currentValue = -1;

        this.state = {
            items: [],
            value: this.currentValue,
            defaultSelectedId: -1,
            valueMemberName: null,
            displayMemberName: null
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.metaData = metaData;
            var dataSourceData = metaData.dataSourceData;

            if (!dataSourceData) {
                throw new Error(" DropdownDataControl requires Data Source Data");
            }

            self.dataManager.getDataSourceItems(dataSourceData.name)
                .then(items => {

                    self.dataSourceItems = items;

                    var value = dataSourceData.defaultSelectedId ? dataSourceData.defaultSelectedId : -1;
                    self.currentValue = value;

                    self.setState({
                        items: items,
                        defaultSelectedId: dataSourceData.defaultSelectedId,
                        value: value,
                    });

                    if (items && items.length === 1) {
                        value = items[0][dataSourceData.valueMemberName];
                        self.setValue(value);
                    }

                    resolve(self);
                })
                .catch(error => {
                    reject(error)
                });
        });
    }

    setValue(value) {
        var dataSource = this.metaData.dataSourceData;

        var newValue = value && value >= 0 ? value : -1
        var items = this.dataSourceItems;
        var item = null;
        if (newValue >= 0) {
            item = items.find(e => e[dataSource.valueMemberName] == newValue);
            if (!item) {
                newValue = -1;
            }
        }

        this.setState({
            value: newValue
        });

        this.currentValue = newValue;
        this.reportChange(newValue, item);
    }

    getValue() {
        return this.state.value >= 0 ? this.state.value : null;
    }

    isInputValid() {
        var result = true;
        var validationData = this.metaData && this.metaData.validationData ? this.metaData.validationData : null;
        if (validationData) {
            if (validationData.required === true && this.currentValue < 0) {
                result = false;
            }
        }
        return result;
    }

    onChange(event) {
        var v = parseInt(event.target.value);
        var value = v >= 0 ? v : null;
        this.setValue(value);

        var valueMemberName = this.metaData.dataSourceData.valueMemberName;

        var idx = this.state.items.findIndex(item => {
            return item[valueMemberName] == v;
        });


        var selectedItem = null;
        if (idx >= 0) {
            selectedItem = this.state.items[idx];
        }       

        this.reportChange(value, selectedItem);
    }

    reportChange(value, selectedItem) {
        this.dataManager.reportChange(value, this, selectedItem);
        if (this.props.onChange) {
            this.props.onChange(value, selectedItem);
        }
    }


    render() {
        var self = this;
        return (
            <select id={this.props.id} className={this.isInputValid() ? 'control dropdown valid' : 'control dropdown error'} value={this.state.value} onChange={this.onChange} disabled={!this.state.items || this.state.items.length <= 1 || this.props.disabled === true}>

                {this.state.defaultSelectedId < 1 &&
                    <option value={-1}>Please select...</option>
                }

                {this.state.items.map(function (item, index) {
                    return (<option value={item[self.metaData.dataSourceData.valueMemberName]} key={self.props.id + '_' + index + '_option'}>{item[self.metaData.dataSourceData.displayMemberName]}</option>);
                })}
            </select>
        );
    }
}


import React from 'react';

export class ChecklistControl extends React.Component {

    constructor(props) {
        super(props);

        this.values = this.props.values ? this.props.values : [];
        this.state = {
            values: this.values
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    reset() {
        this.values = this.props.values ? this.props.values : [];
        this.setState({
            values: this.values
        });
    }

    getValue() {
        return this.values;
    }

    getValueIndex(id) {
        return this.values.findIndex(value => {
            return value.id == id;
        });
    }

    getItem(id) {
        return this.props.items.find(item => {
            return item.id === id;
        });
    }

    isChecked(item) {
        var idx = this.getValueIndex(item.id);
        return idx >= 0;
    }


    onSelectionChange(event) {
        var target = event.target;
        var checked = target.checked;
        var id = parseInt(event.target.value);
        var item = this.getItem(id);

        var values = this.getValue();
        if (checked === true) {
            var data = {
                id: item.id,
                sort: values.length
            };
            values.push(data);
        }
        else {
            var idx = this.getValueIndex(id);
            if (idx >= 0) {
                values.splice(idx, 1);
            }
        }

        this.setState({
            value: values
        });

        if (this.props.onChange) {
            this.props.onChange(values);
        }
    }

    render() {
        var self = this;
        return (
            <div className='control checklist input-control'>
                {this.props.items.map(function (item, id) {
                    return (
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id={self.props.id + '_cb_' + id} value={item[self.props.valueMemberName]} checked={self.isChecked(item)} onChange={self.onSelectionChange} />
                            <label className="form-check-label" htmlFor={self.props.id + '_cb_' + id}>{item[self.props.displayMemberName]}</label>
                        </div>
                    );
                })}
            </div>
        );
    }
}

import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Logger } from "../infrastructure/Logger";
import { MetaData } from './MetaData';

export class ControlDataAccess {
    constructor(dataObject) {
        if (!dataObject) {
            throw new Error("ControlDataAccess: DataObject is required!");
        }

        this.apiDataAccess = new ApiDataAccess('/data');
        this.dataObject = dataObject;        
    }

    getDataSourceItems(datasourceName) {
        var self = this;

        window.Loader.show();

        return new Promise(function (resolve, reject) {
            self.apiDataAccess.get(`/datasources/${encodeURI(datasourceName)}`)
                .then(data => {
                    window.Loader.hide();
                    resolve(data);
                })
                .catch(error => {
                    Logger.writeError('ControlDataAccess: getDataSourceItems ' + datasourceName, error);
                    window.Loader.hide();
                    reject(error);
                });
        })
    }

    getMetaData() {
        var self = this;

        window.Loader.show();
        return new Promise(function (resolve, reject) {

            self.apiDataAccess.put(`/${encodeURI(self.dataObject)}`, null)
                .then(data => {
                    var metaData = {};
                    Object.keys(data).map((name) => {
                        metaData[name] = new MetaData(data[name]);
                    });
                    window.Loader.hide();
                    resolve(metaData);
                })
                .catch(error => {
                    Logger.writeError('ControlDataAccess: getMetaData ', error);
                    window.Loader.hide();
                    reject(error);
                })
        });
    }

    saveData(data) {
        var self = this;

        window.Loader.show();
        return new Promise(function (resolve, reject) {
            Logger.writeDebug(`ControlDataAccess: saving data for ${self.dataObject}:`, data)
            self.apiDataAccess.post(`/${encodeURI(self.dataObject)}`, data)
                .then(data => {
                    window.Loader.hide();
                    resolve(data);
                })
                .catch(ex => {
                    Logger.writeError(`Error: ControlDataAccess.saveData:`, ex)
                    window.Loader.hide();

                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                    else {
                        var txt = 'An error occurred while saving the object. Please try again.';
                        reject(txt);
                    }
                });
        });
    }

    deleteObject(id) {
        var self = this;

        window.Loader.show();
        return new Promise(function (resolve, reject) {
            self.apiDataAccess.delete(`/${encodeURI(self.dataObject)}/${id}`)
                .then(data => {
                    window.Loader.hide();
                    resolve(data);
                })
                .catch(ex => {
                    window.Loader.hide();
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                    else {
                        var txt = 'An error occurred while deleting the object. Please try again.';
                        reject(txt);
                    }      
                });
        });
    }

    loadData(id) {
        var self = this;

        window.Loader.show();
        return new Promise(function (resolve, reject) {
            self.apiDataAccess.get(`/${encodeURI(self.dataObject)}/${encodeURI(id)}`)
                .then(data => {
                    window.Loader.hide();
                    resolve(data);
                })
                .catch(error => {
                    Logger.writeError('ControlDataAccess: loadData ' + id, error);
                    window.Loader.hide();
                    reject('Could not get item!');
                })

        });
    }

}

import React from "react";

import './Tile.css';

export class Tile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            count: null
        };

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick(this);
        }
    }

    setCount(count) {

        if (count > 99) {
            count = 99;
        }

        this.setState({
            count: count && count > 0 ? count : null
        })
    }

    render() {
        return (
            <div className={this.props.className ? this.props.className : ''}>
                <div className='tile'>
                    <div className="tile-balloon-dummy">

                        {this.state.count &&
                            <div className='balloon'>
                                <div className='balloon-text'>{this.state.count}</div>                                
                            </div>
                        }

                    </div>
                    <div className="tile-button" onClick={this.onClick}>
                        <span className={this.props.icon ? 'icon glyphicon ' + this.props.icon : 'icon glyphicon glyphicon-cog'}></span>
                        <p className='text'>{this.props.title}</p>
                        {this.props.image &&
                            <div className='image-container'>
                                <img src={this.props.image} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

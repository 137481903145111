import React, { useState, useEffect } from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { TextboxControl } from '../../../controls/TextboxControl';
import DatePicker from "react-datepicker";



import "react-datepicker/dist/react-datepicker.css";





export const ItemCommentModal = props => {
    const [comment, setComment] = useState('');
    const [visible, setVisible] = useState(false);
    const [expirationDate, setExpirationDate] = useState(null);


    useEffect(() => {
        // This code runs once when the component mounts, similar to a constructor
        init();
        onGetData();

    }, []);

    const init = () => {

        // figure out how to set this properly with Settings
        // setDefaultLocale("en-US");
    }


    const onGetData = async () => {
        var dataAccess = new ApiDataAccess('data/items/');
        await dataAccess.get(props.item.id + '/comment')
            .then(result => {
                console.log("result: ", result)
                if (result.comment) {
                    setComment(result.comment)
                }
                if (result.expirationDate) {
                    let yourDate = new Date(result.expirationDate).toLocaleDateString()
                    setExpirationDate(yourDate)
                }

                setVisible(true);
            })
            .catch(error => {
                // window.Alerts.showError('Your comment was not sumbitted. Please try again.', 'Comment');
            });
    }





    const onRemoveClick = () => {
        var dataAccess = new ApiDataAccess('data/items/');

        dataAccess.post(props.item.id + '/comment/remove', null)
            .then(result => {
                if (result != null) {
                    window.Alerts.showSuccess('Your comment is removed!', 'Comment');
                    hide();
                    window.location.reload();
                }
                else {
                    window.Alerts.showError('Your comment could not be removed. Please try again.', 'Comment');
                }
            })
            .catch(error => {
                // window.Alerts.showError('Your comment could not be removed. Please try again.', 'Comment');
            });
    }

    const onSubmitClick = () => {
        if (comment) {
            var dataAccess = new ApiDataAccess('data/items/');
            var request = {
                Comment: comment,
                ExpirationDate: expirationDate
            }
            dataAccess.post(props.item.id + '/comment', request)
                .then(result => {
                    if (result !== null) {
                        window.Alerts.showSuccess('Thank you for submitting your comment!', 'Comment');
                        hide();
                        window.location.reload();
                    }
                    else {
                        window.Alerts.showError('Your comment was not sumbitted. Please try again.', 'Comment');
                    }
                })
                .catch(error => {
                    // window.Alerts.showError('Your comment was not sumbitted. Please try again.', 'Comment');
                });

        }
    }

    const hide = () => {
        props.modal.close();
    }

    return (
        <div>
            {visible &&

                <div>
                    <div className='content'>
                        <label htmlFor='title'>Comment</label>
                        <p>Leave a comment for supply chain</p>
                        <TextboxControl id='title' required={true} textarea={true} value={comment} onChange={value => { setComment(value); }} />

                        <label htmlFor='title'>Expiration Date</label>
                        <p>Please set expiration date for this comment (optional)</p>
                        <div className='datePicker'>
                            <DatePicker selected={expirationDate} locale="es" value={expirationDate} onChange={(date) => {
                                let yourDate = new Date(date).toLocaleDateString()
                                setExpirationDate(yourDate)
                            }} />
                        </div>
                    </div>

                    <div className='default-space'></div>
                    <div className="footer-buttons">
                        {props.item.comment === "" ?
                            <div>
                                <div className='left' onClick={() => { hide() }}>Cancel</div>
                                <div className='right' onClick={() => { onSubmitClick() }}>Submit</div>
                            </div>
                            :
                            <div>
                                <div className='left' onClick={() => { onRemoveClick() }}>Remove Comment</div>
                                <div className='right' onClick={() => { onSubmitClick() }}>Update</div>
                            </div>
                        }

                    </div>
                </div>
            }
        </div>
    )
}
import React from 'react';
import { BackSplash } from '../components/Dialogs';
import { Logger } from "../infrastructure/Logger";

window.Modal = (function (modal) {
    modal.control = {};

    modal.register = function (cntrl) {
        modal.control = cntrl;
    }

    modal.show = function (onCreatingContent, title, onCreatingFooter, classes) {
        modal.control.show(onCreatingContent, title, onCreatingFooter, classes);
    }

    return modal;
}(window.Modal = window.Modal || {}));


export class LegacyModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onCreatingContent = null;
        this.onCreatingFooter = null;
        this.classes = null;
        this.hideClose = this.props.hideClose ? this.props.hideClose : true;

        this.state = {
            visible: false,
            content: null
        };

        this.close = this.close.bind(this);
    }

    componentDidMount() {
        window.Modal.register(this);
    }

    show(onCreatingContent, title, onCreatingFooter, classes) {
        Logger.writeDebug('ModalComponent: show');


        this.classes = classes;
        this.onCreatingContent = onCreatingContent;
        this.onCreatingFooter = onCreatingFooter;

        this.setState({
            visible: true,
            title: title ? title : null
        });
    }

    registerContent(content) {
        this.setState({
            content: content
        });
    }


    close() {
        Logger.writeDebug('ModalComponent: close');
        this.setState({
            visible: false,
        });
    }

    render() {
        return (
            <div>
                {this.state.visible == true &&
                    <div className={'modal-component'}>

                        <BackSplash />

                        <div className={'modal ' + this.classes}>
                            <div className='title background-green color-white'>
                                {this.state.title}
                                {/*{!this.props.hideClose &&*/}
                                {/*    <div className='close-btn color-white' onClick={this.close}><span className='glyphicon glyphicon-remove pull-right'></span> hi</div>*/}
                                {/*}*/}
                            </div>

                            <div className='modalContent'>
                                {this.onCreatingContent(this)}
                            </div>

                            {this.onCreatingFooter &&
                                <div className='modal-footer'>
                                    {this.onCreatingFooter(this)}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

import { Logger } from "./Logger";

window.Cache = (function (cache) {

    cache.entries = {};

    cache.init = () => {
        window.setInterval(cache.removeExpireEntries, 1000);
    } 

    cache.removeExpireEntries = () => {
        var date = new Date();
        Object.keys(cache.entries).forEach(key => {

            try {
                if (cache.entries[key].expirationDate < date) {
                    Logger.writeDebug(`Cache: Removing item with key ${key} from cache.`);
                    cache.entries[key] = undefined;
                }
            }
            catch { }                       
        })              
    }

    cache.getOrAddAsync = async (key, expInMinutes, func) => {

        if (!key) {
            throw new Error('Key is required');
        }

        if (!func || typeof func !== 'function') {
            throw new Error('Function is required');
        }

        var payload = undefined;

        if (cache.entries[key] !== undefined) {
            payload = cache.entries[key].payload;
            Logger.writeDebug(`CACHE: Retrieved cache key ${key} with payload:`, payload);
            return payload;
        }
        else {
            payload = await func();
            var currentdate = new Date();
            var expiration = expInMinutes ?
                new Date(currentdate.getTime() + expInMinutes * 60000)
                : null;
            var item = new CacheItem(key, payload, expiration);
            cache.entries[key] = item;

            Logger.writeDebug(`Cache: Added cache key ${key} with payload:`, payload);
            return payload;
        }
    }

    cache.init();

    return cache;
}(window.Cache = window.Cache || {}));



export class CacheItem {

    constructor(key, payload, expirationDate) {
        this.key = key;
        this.payload = payload;
        this.expirationDate = expirationDate;
    }

}
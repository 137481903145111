import React from 'react';

export class ImageTitle extends React.Component {

    render() {
        return (
            <div className="image-title">
                <h1>
                    <img src={this.props.image} />
                    <span>{this.props.text}</span>
                </h1>
            </div>
        );
    }
}


export class ImageLabel extends React.Component {

    render() {
        return (
            <div className="image-label">
                <img src={this.props.image} />
                <label htmlFor={this.props.for}>{this.props.text}</label>
            </div>
        );
    }
}

import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { ImageTitle } from '../../../core/ImageTitle';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { Card, Row, Column } from '../../../components/Containers';
import { Product } from './Product';
import { ProductRulesListComponent } from './ProductRulesListComponent';
import { ImageTileCircle } from '../../../components/Containers';		
import { Logger } from "../../../infrastructure/Logger";
import { ProductGroupStructureSpecsList } from "../../PackagingGroup/Controls/ProductGroupViewComponent";


export class ProductViewComponent extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataId) {
            throw new Error("DataId Property is required");
        }

        this.dataAccess = new ApiDataAccess('/data');
        this.product = new Product(this.props.dataId);

        this.state = {
            data: {},
            product: null,
            productGroup: null,
            isPublished: false
        }

        this.deleteItem = this.deleteItem.bind(this);
        this.onProductGroupRetrieved = this.onProductGroupRetrieved.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        var self = this;

        this.dataAccess.get(`/products/${encodeURIComponent(self.props.dataId)}/formatted`)
            .then(formattedData => {
                if (formattedData) {
                    self.dataAccess.get(`/products/${encodeURIComponent(self.props.dataId)}`)
                        .then(data => {
                            formattedData.synonyms = data.synonyms;
                            self.setState({
                                product: data,
                                data: formattedData,
                                isPublished: data.isPublished
                            });
                        })
                        .catch(error => {
                            window.Router.setNotFound();
                        });


                    var request = {
                        number: self.props.dataId
                    };

                    self.dataAccess.post('/lookup/ProductGroupByProduct/', request)
                        .then(data => {
                            self.onProductGroupRetrieved(data);
                        });
                }
            })
            .catch(error => {
                Logger.writeError("Could not load Product", error);
                window.Router.setNotFound();
            });            
    }


    onProductGroupRetrieved(groups) {
        if (groups != null && groups.length > 0) {
            this.setState({
                productGroup: groups[0]
            });
        }
    }

    deleteItem() {
        var self = this;
        window.Confirmation.show('Delete', 'Are you sure to delete this item?',
            function ()
            {
                self.dataAccess.delete(`/products/${encodeURIComponent(self.props.dataId)}`)
                    .then(data => {
                        window.Alerts.showSuccess('Product ' + data.name + ' has been deleted');
                        window.Router.setRoute('/products');
                    });
            }            
        );
    }

    onPublishClick() {        
        var self = this;
        var isPublished = this.state.isPublished;
        if (isPublished) {
            this.product.publish(false)
                .then(data => {
                    self.refresh();
                    window.Alerts.showSuccess('Product ' + data.name + ' has been unpublished');
                })
                .catch(ex => {
                    if (ex.exceptions && ex.exceptions.length > 0) {
                        window.Alerts.showInfo(ex.exceptions[0].message);
                    }
                    if (ex.messages && ex.messages.length > 0) {
                        window.Alerts.showInfo(ex.messages[0]);
                    }
                });
        }
        else {
            this.product.publish(true)
                .then(data => {
                    self.refresh();
                    window.Alerts.showSuccess('Product ' + data.name + ' has been published');
                })
                .catch(ex => {
                    if (ex.exceptions && ex.exceptions.length > 0) {
                        window.Alerts.showInfo(ex.exceptions[0].message);
                    }

                    if (ex.messages && ex.messages.length > 0) {
                        window.Alerts.showInfo(ex.messages[0]);
                    }
                });
        }          
    }


    render() {
        return (
            <div className='product product-view-component'>

                <ImageTitle text={this.state.data.name} />

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/products/all')} />
                            <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/products/new')} />
                            <ToolbarButton icon='glyphicon-pencil' text='Edit' onClick={() => window.Router.setRoute('/products/' + encodeURIComponent(this.props.dataId) + '/edit')} />
                            <ToolbarButton icon={this.state.isPublished ? 'glyphicon-stop' : 'glyphicon-play'} text={this.state.isPublished ? 'unpublish' : 'publish'} onClick={this.onPublishClick} /> 
                            <ToolbarButton icon='glyphicon-trash' className='btn-danger' text='Delete' onClick={() => this.deleteItem()} />
                        </div>
                    )
                }} />

                <Card title='General' subtitle='This is the general information to this product:'>
                    <Row>
                        <Column>
                            <div className='photo-container'>
                                <label htmlFor='image'>Photo</label>
                                {this.state.data.imageId &&
                                    <ImageTileCircle src={`/data/images/${encodeURIComponent(this.state.data.imageId)}/thumbnail?ts=${encodeURIComponent(new Date().getTime())}`} />                                
                                }
                                {!this.state.data.imageId &&
                                    <ImageTileCircle src={'/images/round_logo.png'} />                                
                                }
                            </div>
                        </Column>

                        <Column>
                            <label htmlFor='description'>Description</label>
                            <p className='content'>{this.state.data.description}</p>

                            <label htmlFor='description'>Packaging Group</label>
                            {this.state.productGroup &&
                                <div>
                                    <p className='content'><a href={'/packaginggroups/' + encodeURIComponent(this.state.productGroup.id)}>{this.state.productGroup.name}</a></p>
                                </div>
                            }

                            {!this.state.productGroup &&
                                <p className='content'>No Matching Packaging Group exists.</p>
                            }
                        </Column>
                    </Row>



                    <Row>
                        <Column>
                            <label htmlFor='description'>Industry</label>
                            <p className='content'>{this.state.data.industryId}</p>
                        </Column>
                        <Column>
                            <label htmlFor='description'>Category</label>
                            <p className='content'>{this.state.data.industryCategoryId}</p>
                        </Column>
                    </Row>

                </Card>

                <Card title='Attributes' subtitle='The following attributes define this product:'>
                    <Row>
                        <Column>
                            <label htmlFor='consistency'>Consistency</label>
                            <p className='content'>{this.state.data.consistencyId}</p>
                        </Column>

                        <Column>
                            <label htmlFor='moisture'>Moisture</label>
                            <p className='content'>{this.state.data.moistureId}</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='aroma'>Aroma</label>
                            <p className='content'>{this.state.data.aromaId}</p>
                        </Column>

                        <Column>
                            <label htmlFor='lightSensitivity'>Light Sensitivity</label>
                            <p className='content'>{this.state.data.lightSensitivityId}</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='shelfLifeTime'>Shelf Life Time</label>
                            <p className='content'>{this.state.data.shelfLifeTimeId}</p>
                        </Column>

                        <Column>
                            <label htmlFor='childResistance'>Child Resistance</label>
                            <p className='content'>{this.state.data.childResistanceId}</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='aggressivness'>Aggressiveness</label>
                            <p className='content'>{this.state.data.aggressivnessId}</p>
                        </Column>

                        <Column>
                            <label htmlFor='emission'>Emission</label>
                            <p className='content'>{this.state.data.emissionId}</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <label htmlFor='temperature'>Packaging Temperature</label>
                            <p className='content'>{this.state.data.temperatureId}</p>
                        </Column>

                        <Column>
                            <label htmlFor='oxygenSensitivity'>Oxygen Sensitivity</label>
                            <p className='content'>{this.state.data.oxygenSensitivityId}</p>
                        </Column>
                    </Row>

                </Card>


                <Card title='Synonyms' subtitle='The following synonyms also describe this product:'>
                    {this.state.data && this.state.data.synonyms &&
                        <div className='flex-container'>
                            {this.state.data.synonyms.map(function (item, id) {
                                return (
                                    <div key={`row_${id}`} className="flex-item">
                                        <div className='synonym'>{item}</div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </Card>

                {this.state.productGroup &&
                    <Card title='Structure Specs' subtitle={<span>The following structures are available for this Product due to the <a href={'/packaginggroups/' + encodeURIComponent(this.state.productGroup.id)}>{this.state.productGroup.name}</a> assignment:</span>}>
                        <ProductGroupStructureSpecsList productGroup={this.state.productGroup.id} disableContinuation={true} />
                    </Card>
                }
               


                {this.state.product &&
                    <ProductRulesListComponent productId={this.state.product.id} />
                }

            </div>
        );
    }
}


import React from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';

export class LookupDropdownDataControl extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        if (!this.props.dataSourceName) {
            throw new Error("DataSourceName Property is required");
        }

        if (!this.props.dataSourceName) {
            throw new Error("ReferenceProperty Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.propertyName = this.props.propertyName;
        this.dataSourceName = this.props.dataSourceName;
        this.referenceProperty = this.props.referenceProperty;

        this.dataManager.register(this);
        this.currentValue = null;

        this.state = {
            items: [],
            value: this.currentValue,
            defaultSelectedId: -1,
            valueMemberName: null,
            displayMemberName: null
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        this.metaData = metaData;

        var self = this;
        return new Promise(function (resolve, reject) {

            var dataSourceData = self.metaData.dataSourceData;

            if (!dataSourceData) {
                throw new Error(" DropdownDataControl requires Data Source Data");
            }

            resolve(self);
        });
    }


    loadDataSourceData(referenceId) {
        var dataSourceData = this.metaData.dataSourceData;
        var self = this;
        self.setState({ items: null });
        return new Promise((resolve, reject) => {
            var request = { number: referenceId };
            var dataAccess = new ApiDataAccess('/data/lookup');
            dataAccess.post(`/${self.dataSourceName}`, request)
                .then(items => {
                    var value = dataSourceData.defaultSelectedId ? dataSourceData.defaultSelectedId : -1;
                    self.currentValue = value;

                    self.setState({
                        items: items,
                        defaultSelectedId: dataSourceData.defaultSelectedId,
                        value: value,
                        valueMemberName: dataSourceData.valueMemberName,
                        displayMemberName: dataSourceData.displayMemberName
                    });
                    resolve(items);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }


    setValue(value) {

        var newValue = value && value >= 0 ? value : -1
        var items = this.state.items;

        if (newValue >= 0 && items) {
            var item = items.find(e => e[this.state.valueMemberName] == newValue);
            if (!item) {
                newValue = -1;
            }
        }

        this.setState({
            value: newValue
        });

        this.currentValue = newValue;
        this.dataManager.reportChange(newValue, this);
    }

    getValue() {
        return this.state.value >= 0 ? this.state.value : null;
    }

    isInputValid() {
        var result = true;
        var validationData = this.metaData && this.metaData.validationData ? this.metaData.validationData : null;
        if (validationData) {
            if (validationData.required === true && this.currentValue < 0) {
                result = false;
            }
        }
        return result;
    }

    onChange(event) {
        var v = parseInt(event.target.value);
        var value = v >= 0 ? v : null;
        this.setValue(value);

        var idx = this.state.items.findIndex(item => {
            return item[this.state.valueMemberName] === v;
        });

        var selectedItem = null;
        if (idx >= 0) {
            selectedItem = this.state.items[idx];
        }

        this.dataManager.reportChange(value, this, selectedItem);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    onChangeBroadcasted(propertyName, value) {
        var self = this;
        if (propertyName == this.referenceProperty) {
            this.loadDataSourceData(value)
                .then(items => {
                    if (items && items.length == 1) {
                        var dataSourceData = this.metaData.dataSourceData;
                        value = items[0][dataSourceData.valueMemberName];
                        self.setValue(value);
                    }
                    else {
                        self.setValue(self.dataManager.dataObject[this.propertyName]);
                    }
                })
        }
    }


    render() {
        var self = this;
        return (
            <div>
                {this.state.items &&
                    <select id={this.props.id} className={this.isInputValid() ? 'control dropdown valid' : 'control dropdown error'} value={this.state.value ? this.state.value : -1} onChange={this.onChange} disabled={!this.state.items || this.state.items.length <= 1 || this.props.disabled === true}>

                        {this.state.defaultSelectedId < 1 &&
                            <option value={-1}>Please select...</option>
                        }

                        {this.state.items.map(function (item, index) {
                            return (<option value={item[self.state.valueMemberName]} key={self.props.id + '_' + index + '_option'}>{item[self.state.displayMemberName]}</option>);
                        })}
                    </select>
                }
            </div>

        );
    }
}
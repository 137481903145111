import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Logger } from "../infrastructure/Logger";

export class GenericDataAccess {
    constructor() {
        this.apiDataAccess = new ApiDataAccess('/data');
    }


    async getMetaData(entity) {
        return await window.Cache.getOrAddAsync(`GenericDataAccess.getMetaData(${entity})`, null, async () => {
            return await this.apiDataAccess.put(`/${encodeURI(entity)}`, null);
        });                
    }


    saveItem(entity, data, onSuccess) {
        this.apiDataAccess.post(`/${encodeURI(entity)}`, data)
            .then(data => { onSuccess(data) })
            .catch(e => {
                if (e.messages) {
                    var txt = "The item was not saved because of the following reason(s):";
                    for (let m of e.messages) {
                        txt += "<br><b>" + m + "</b>";
                    }
                    window.Alerts.showError(txt);
                }
                else {
                    window.Alerts.showError('An error occurred while saving the data. Please try again.');
                }
            })
    }

    getItem(entity, id, onSuccess) {
        this.apiDataAccess.get(`/${encodeURI(entity)}/${encodeURI(id)}`)
            .then(data => { onSuccess(data) })
            .catch(error => {                
                window.Alerts.showError('Could not get item!');
            });
    }

    getFormattedItem(entity, id, onSuccess) {
        this.apiDataAccess.get(`/${encodeURI(entity)}/${encodeURI(id)}/formatted`)
            .then(data => {
                onSuccess(data);
            })
            .catch(error => {
                window.Alerts.showError('Could not retrieve formatted item!');
            });
    }

    deleteItem(entity, id, onSuccess) {
        this.apiDataAccess.delete(`/${encodeURI(entity)}/${encodeURI(id)}`)
            .then(data => {
                onSuccess(data);
            })
            .catch(error => {
                window.Alerts.showError('Could not delete item!');
            });
    }

    getList(entity, pageSize, pageIndex, filter, onSuccess) {

        var url = filter && filter.length > 0
            ? `/${encodeURI(entity)}/pagable/?pagesize=${encodeURI(pageSize)}&pageIndex=${encodeURI(pageIndex)}&filter=${encodeURI(filter)}`
            : `/${encodeURI(entity)}/pagable/?pagesize=${encodeURI(pageSize)}&pageIndex=${encodeURI(pageIndex)}`;

        Logger.writeDebug(`URL: ${url} - filter: ${filter}`);

        this.apiDataAccess.get(url)
            .then(data => {                
                onSuccess(data);
            })
            .catch(error => {
                window.Alerts.showError('Could not retrieve list!');
            });
    }

    getDataSourceData(datasourceName, onSuccess) {
        this.apiDataAccess.get(`/datasources/${encodeURI(datasourceName)}`)
            .then(data => {
                onSuccess(data);
            })
            .catch(error => {
                window.Alerts.showError('Could not retrieve data source data!');
            });
    }

}

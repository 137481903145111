import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { PolicyConfirmation } from '../../../dataControls/PolicyConfirmation';

export class ConvertingMethod {
    constructor(id) {
        this.id = id;
        this.dataAccess = new ApiDataAccess('/data');
    }

    publish(publish) {
        var self = this;
        return new Promise(function (resolve, reject) {
            self.validatePublishPolicy(publish)
                .then(messages => {
                    PolicyConfirmation.show('Converting Method', messages)
                        .then(confirmed => {
                            if (confirmed === true) {
                                self.executePublish(publish)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            }
                            else {
                                resolve();
                            }
                        });
                });
        });
    }

    validatePublishPolicy(publish) {
        var self = this;

        var url = publish == true
            ? '/packagingConvertingMethodAttributes/' + encodeURIComponent(self.id) + '/publish?testPolicy=1'
            : '/packagingConvertingMethodAttributes/' + encodeURIComponent(self.id) + '/unpublish?testPolicy=1'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }

    executePublish(publish) {
        var self = this;

        var url = publish == true
            ? '/packagingConvertingMethodAttributes/' + encodeURIComponent(self.id) + '/publish'
            : '/packagingConvertingMethodAttributes/' + encodeURIComponent(self.id) + '/unpublish'

        return new Promise(function (resolve, reject) {
            self.dataAccess.post(url, null)
                .then(data => { resolve(data.messages); })
                .catch(error => { reject(error); });
        });
    }
}
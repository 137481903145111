import React from 'react';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';
import { DataManager } from '../../../dataControls/DataManager';
import { ImageTitle } from '../../../core/ImageTitle';
import { DropdownDataControl } from '../../../dataControls/DropdownDataControl';
import { TextboxDataControl } from '../../../dataControls/TextboxDataControl';
import { Product } from './Product';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { GenericDataAccess } from '../../../dataControls/GenericDataAccess';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { PagingModule } from '../../../dataForms/GenericListComponent';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { LabelDataControl } from '../../../dataControls/LabelDataControl';
import { Card } from '../../../components/Containers';
import { Alert } from '../../../components/Components';
import { ImageTileCircle } from '../../../components/Containers';			
import { Logger } from "../../../infrastructure/Logger";

export class ProductTableModule extends React.Component {
    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;
        this.dataAccess = new ApiDataAccess('/data');

        this.state = {
            items: this.props.items
        };

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    getColumnTitle(id) {
        var metaData = this.props.metaData[id];
        var display = metaData.display;
        if (display) {

            if (!display.columnSizeId || display.columnSizeId == 0) {
                return null;
            }

            if (display.caption) {
                return display.caption;
            }
        }
        return null;
    }

    getColumnWidth(id) {
        var metaData = this.props.metaData[id];
        var display = metaData.display;
        if (display) {
            if (!display.columnSizeId || display.columnSizeId == 0) {
                return 0;
            }
            return display.columnSizeId;
        }
        return 0;
    }

    onDeleteClick(event) {
        var item = JSON.parse(event.currentTarget.dataset.item);
        if (this.props.onDeleting) {
            this.props.onDeleting(item);
        }
    }

    onRowSelected(item) {        
        if (this.props.onRowSelected) {
            this.props.onRowSelected(item);
        }
    }

    onPublishToggle(item, value, toggle) {
        var isPublished = item.isPublished == 'True' ? true : false;
                                
        toggle.setValue(!isPublished);

        var self = this;
        var items = this.state.items;

        var product = new Product(item.id);
        if (isPublished) {
            product.publish(false)
                .then(data => {

                    var idx = items.findIndex(function (item) {
                        return parseInt(item.id) === data.id;
                    });

                    items[idx].isPublished = data.isPublished == true ? 'True' : 'False';
                    self.setState({
                        items: items
                    });

                    window.Alerts.showSuccess('Product ' + data.name + ' has been unpublished');
                    self.forceUpdate();
                })
                .catch(ex => {
                    if (ex.exceptions && ex.exceptions.length > 0) {
                        window.Alerts.showInfo(ex.exceptions[0].message);
                    }
                    if (ex.messages && ex.messages.length > 0) {
                        window.Alerts.showInfo(ex.messages[0]);
                    }
                });
        }
        else {
            product.publish(true)
                .then(data => {

                    var idx = items.findIndex(function (item) {
                        return parseInt(item.id) === data.id;
                    });

                    items[idx].isPublished = data.isPublished == true ? 'True' : 'False';
                    self.setState({
                        items: items
                    });

                    window.Alerts.showSuccess('Product ' + data.name + ' has been published');
                    self.forceUpdate();
                })
                .catch(ex => {
                    if (ex.exceptions && ex.exceptions.length > 0) {
                        window.Alerts.showInfo(ex.exceptions[0].message);
                    }

                    if (ex.messages && ex.messages.length > 0) {
                        window.Alerts.showInfo(ex.messages[0]);
                    }
                });
        }
    }

    render() {
        var self = this;
        return (
            <div className='table products'>
                <div className="table-header">
                    <div className='row'>

                        <div className='image'></div>

                        <div className='name'>
                            <span>Product Name</span>
                        </div>

                        <div className='user mobile-hidden'>
                            <span>User</span>
                        </div>

                        <div className='date mobile-hidden'>
                            <span>Date</span>
                        </div>

                        <div className='control mobile-hidden'>
                            <span>Published</span>
                        </div>

                    </div>

                </div>

                <div className='body'>
                {self.state.items.map(function (item, index1) {
                    return (
                        <div className="row border-bottom" key={'row_' + index1}>

                            <div className='image selectable' onClick={() => { self.onRowSelected(item) }}>
                                <ImageTileCircle src={`/data/images/${encodeURIComponent(item.imageId ? item.imageId : 0)}/thumbnail?ts=${encodeURIComponent(new Date().getTime())}`} onClick={() => window.Router.setRoute('/products/' + encodeURIComponent(item.id))} />                                
                            </div>

                            <div className='name selectable' onClick={() => { self.onRowSelected(item) }}>
                                <span>{item.name}</span>
                            </div>

                            <div className='user selectable mobile-hidden' onClick={() => { self.onRowSelected(item) }}>
                                <span>{item.createUser}</span>
                            </div>

                            <div className='date selectable mobile-hidden' onClick={() => { self.onRowSelected(item) }}>
                                <span>{item.createDate}</span>
                            </div>

                            <div className='control mobile-hidden'>                                
                                <ToggleSwitchControl on={item.isPublished == 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                            </div>

                        </div>
                    );
                })}
                </div>
            </div>
        );
    }
}


export class ProductListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;        
        this.dataAccess = new GenericDataAccess();        

        this.metaData = null;
        this.pageIndex = 0;
        this.pageSize = 200;
        this.totalPageCount = 0;
        this.filter = null;
        this.filterId = 0;
        this.filterName = 'All';

        this.state = {
            items: [],
            isFirst: true,
            isLast: true            
        }

        this.onDeleting = this.onDeleting.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onFirstClick = this.onFirstClick.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onLastClick = this.onLastClick.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
        this.onCsvClick = this.onCsvClick.bind(this);
        this.onGoogleClick = this.onGoogleClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onFilterApplied = this.onFilterApplied.bind(this);
        this.loadList();
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onPageSizeChanged(pageSize) {
        this.pageSize = pageSize;
        this.pageIndex = 0;
        this.loadList();
    }

    onPdfClick() {
        var url = this.filter
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=pdf&filter=' + encodeURI(this.filter)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=pdf';

        window.location = url;
    }

    onCsvClick() {
        var url = this.filter
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=csv&filter=' + encodeURI(this.filter)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=csv';

        window.location = url;
    }

    onGoogleClick() {
        var url = this.props.filter
            ? `/export/${encodeURI(this.props.dataObject)}Export?filter=${encodeURI(this.props.filter)}`
            : `/export/${encodeURI(this.props.dataObject)}Export`;

        var options = "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes";
        window.open(url, 'ePac Data Export', options);
    }

    async refresh() {
        await this.loadList();
    }

    async loadList() {        
        this.metaData = await this.dataAccess.getMetaData(this.props.dataObject);
        this.loadItems();
    }

    loadItems() {
        var self = this;

        this.setState({
            items: null
        });

        this.dataAccess.getList(this.props.dataObject, this.pageSize, this.pageIndex, this.filter, (result) => {
            var page = result;

            self.totalPageCount = page.totalPageCount;
            self.setState({
                items: page.items,
                isFirst: page.isFirst,
                isLast: page.isLast
            });
        });
    }

    onFirstClick() {
        this.pageIndex = 0;
        this.loadList();
    }

    onPreviousClick() {
        if (this.pageIndex > 0) {
            this.pageIndex--;
        }
        this.loadList();
    }

    onNextClick() {
        if (this.pageIndex < this.totalPageCount - 1) {
            this.pageIndex++;
        }
        this.loadList();
    }

    onLastClick() {
        this.pageIndex = this.totalPageCount - 1;
        this.loadList();
    }

    onRowSelected(item) {
        if (this.props.onRowSelected) {
            this.props.onRowSelected(item, this.props.dataObject);
        }
    }

    onDeleting(item) {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?',
            function () {

                self.dataAccess.deleteItem(self.props.dataObject, item.id)
                    .then(data => {
                        self.loadList();
                        if (self.props.onDeleted) {
                            self.props.onDeleted(item, self.props.dataObject);
                        }
                    });
            }
        );
    }

    onFilterApplied(txt) {

        this.filter = "name like '" + txt + "%'";
        this.filterId = 0;
        this.filterName = 'All';
        this.loadItems();
    }

    onFilterClick() {        
        this.filterId++;
        if (this.filterId > 2) {
            this.filterId = 0;
        }

        switch (this.filterId) {
            case 0:
                this.filter = null;
                this.filterName = 'All';
                break;
            case 1:
                this.filter = 'published=1';
                this.filterName = 'Published';
                break;
            case 2:
                this.filter = 'published=0';
                this.filterName = 'Unpublished';
                break;
        }        
        this.loadItems();
    }

    render() {
        return (
            <div className='product'>

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon glyphicon-eye-open' text='Review' onClick={() => window.Router.setRoute('/products/review')} />
                            <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/products/new')}  />
                            <ToolbarButton icon='glyphicon glyphicon-filter' text={this.filterName} onClick={this.onFilterClick} />
                        </div>
                    )
                }} />

                <div className='generic-list-component'>
                    <PagingModule pageIndex={this.pageIndex} totalPageCount={this.totalPageCount} isFirst={this.state.isFirst} isLast={this.state.isLast} onFirstClick={this.onFirstClick} onPreviousClick={this.onPreviousClick} onNextClick={this.onNextClick} onLastClick={this.onLastClick} onPageSizeChanged={this.onPageSizeChanged} onPdfClick={this.onPdfClick} onCsvClick={this.onCsvClick} onGoogleClick={this.onGoogleClick} onFilterApplied={this.onFilterApplied} />
                    {this.state.items && this.state.items.length > 0 &&
                        <div>
                            <ProductTableModule metaData={this.metaData} user={this.props.user} items={this.state.items} onRowSelected={this.onRowSelected} enableDelete={this.props.enableDelete} onDeleting={this.onDeleting} />
                        </div>
                    }
                    {(!this.state.items || this.state.items.length == 0) &&
                        <div className="alert alert-info">
                            No results
                    </div>
                    }
                </div>

            </div>
        );
    }
}



export class ProductReviewListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.list = null;
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    onRowSelected(item) {
        window.Router.setRoute('/products/review/' + encodeURIComponent(item.id));
    }

    render() {
        var self = this;
        return (
            <div className='product'>

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon glyphicon-th-list' text='All' onClick={() => window.Router.setRoute('/products')} />
                        </div>
                    )
                }} />


                <PageableDataObjectList dataObject='productReviews' onInit={cntrl => this.list = cntrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-6'>Name</div>
                                <div className='col col-3'>Date</div>
                                <div className='col col-3'>User</div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div key={`row_${idx}`} className='row border-bottom clickable' onClick={() => { self.onRowSelected(item)}}>
                                <div className='col col-6'>{item.name}</div>
                                <div className='col col-3'>{item.createDate}</div>
                                <div className='col col-3'>{item.createUser}</div>
                            </div>
                        );
                    }} />

            </div>
        );
    }
}


export class ProductReviewEditComponent extends React.Component {

    constructor(props) {
        super(props);        


        this.state = {
            isValid: false,
            similarItems: null
        };

        this.dataAccess = new ApiDataAccess('/data');
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);

        this.dataManager = new DataManager('productReviews', this.onControlBound, this.onControlUnbound, this.onValidated);
        this.onApproveClick = this.onApproveClick.bind(this);
        this.onRejectClick = this.onRejectClick.bind(this);
    }

    componentDidMount() {
        var self = this;
        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(data => {
                            //2) bind Controls                            
                            dataManager.bindControls();

                            self.dataAccess.post('/productReviews/similar', { text: data.name })
                                .then(items => {
                                    self.setState({
                                        similarItems: items
                                    });
                                })

                        })
                        .catch(ex => {
                            Logger.writeError("Could not load Product", ex);
                            window.Router.setNotFound();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                }

                //3) onControlBound --> Set Value
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    onApproveClick() {        
        var self = this;
        //this.props.popup.close();        
        self.dataManager.saveContext(true)
            .then(data => {
                window.Alerts.showSuccess('Product ' + data.name + ' has been imported');
                window.Router.setRoute('/products/');
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while approving the Product');
            });
    }

    onRejectClick() {
        var self = this;
        //this.props.popup.close();
        self.dataManager.delete()
            .then(data => {
                window.Alerts.showSuccess('Product ' + data.name + ' has been rejected');                
                if (self.props.onRejected) {
                    self.props.onRejected();
                }
                window.Router.setRoute('/products/review');
            })
    }

    render() {
        var self = this;
        return (
            <div className='product-review-modal-content'>
                <ImageTitle text='Product Review' />

                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon glyphicon-eye-open' text='Review' onClick={() => window.Router.setRoute('/products/review')} />
                        </div>
                    )
                }} />

                <h2>Product Review</h2>
                
                <Card title='General'>
                    <label>Name</label>
                    <TextboxDataControl dataManager={this.dataManager} propertyName='name' />
                    {self.state.similarItems && self.state.similarItems.length > 0 &&
                        <div>
                            <Alert type='info'>We have found exisiting items with a similar name (see below). This may indicate a duplicate for an existing product or that the requested product could be assigned as a synonym to one of the existing products.</Alert>
                            <div className='table similar-items'>
                                <div className='body'>
                                    {self.state.similarItems.map((item, idx) => {
                                        return (
                                            <div key={`row_${idx}`} className='row clickable' onClick={() => { window.Router.setRoute(`/products/${item.item1}`) }}>
                                                <div className='col col-2'><img src={`/api/products/thumbnail/${item.item1}`}/></div>
                                                <div className='col col-10'>{item.item2}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                </Card>


                <Card title='Review & Mapping'>

                    <div className='table'>

                        <div className='table-header'>
                            <div className='row'>
                                <div className='col col-6'>Submitted</div>
                                <div className='col col-6'>Reviewed</div>
                            </div>
                        </div>

                        <div className='body'>
                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Consistency (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='consistencyName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Consistency (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='consistencyId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Moisture (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='moistureName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Moisture (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='moistureId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Aroma (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='aromaName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Aroma (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='aromaId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Light Sensitivity (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='lightSensitivityName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Light Sensitivity (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='lightSensitivityId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Shelf Life Time (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='shelfLifeTimeName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Shelf Life Time (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='shelfLifeTimeId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Child Resistance (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='childResistanceName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Child Resistance (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='childResistanceId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Aggressiveness (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='aggressivenessName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Aggressiveness (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='aggressivenessId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Emission (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='emissionName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Emission (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='emissionId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Temperature (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='temperatureName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Temperature (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='temperatureId' />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-6'>
                                    <label>Oxygen Sensitivity (submitted)</label>
                                    <LabelDataControl dataManager={this.dataManager} propertyName='oxygenSensitivityName' />
                                </div>

                                <div className='col col-6'>
                                    <label>Oxygen Sensitivity (mapped)</label>
                                    <DropdownDataControl dataManager={this.dataManager} propertyName='oxygenSensitivityId' />
                                </div>
                            </div>

                        </div>

                    </div>

                </Card>

                <button className='btn btn-primary' disabled={!this.state.isValid} onClick={this.onApproveClick}>Import</button>
                <button className='btn btn-secondary-outline pull-right' onClick={this.onRejectClick}>Reject</button>

            </div>
        );
    }
}
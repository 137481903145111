import React from 'react';
import { RuleConflictProvider } from './RuleConflictProvider';
import { RuleTriggerEditor } from './RuleTriggerEditor';
import { RuleTargetEditor } from './RuleTargetEditor';
import { Rule } from './Rule';
import { ToolbarControl, ToolbarButton } from '../../../controls/ToolbarControl';
import { TextboxControl } from '../../../controls/TextboxControl';
import { Card, Row, Column } from '../../../components/Containers';
import { RuleEditorTitle } from './RuleEditorTitle';
import { Logger } from "../../../infrastructure/Logger";

export class RuleEditor extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }

        this.ruleDataManager = this.props.ruleDataManager;
        this.ruleConflictProvider = new RuleConflictProvider(this.ruleDataManager, this.props.dataId);
        this.nameTextbox = null;
        this.ruleTriggerEditor = null;
        this.ruleTargetEditor = null;
        this.rule = null;

        this.state = {
            name: null,
            triggerCount: 0,
            targetCount: 0,
            conflicts: [],
            isPublished: false
        }

        this.onTriggerAction = this.onTriggerAction.bind(this);
        this.onTargetAction = this.onTargetAction.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.onChanged = this.onChanged.bind(this);
        
        this.ruleDataManager.registerTriggerCallback(this.onTriggerAction);
        this.ruleDataManager.registerTargetCallback(this.onTargetAction);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        var self = this;
        if (this.props.dataId) {
            this.ruleDataManager.load(this.props.dataId)
                .then(data => {
                    self.rule = new Rule(data.id);
                    self.ruleDataManager.setId(data.id);
                    self.setName(data.name);
                    self.ruleTriggerEditor.setTriggers(data.triggers);
                    self.ruleTargetEditor.setTargets(data.targets);
                    self.setPublish(data.isPublished);

                    if (self.props.onLoaded) {
                        self.props.onLoaded(data);
                    }

                    if (data.isValid === false) {                        
                        if (self.props.onInvalid) {
                            self.props.onInvalid(data);
                        }
                    }
                })
                .catch(ex => {
                    Logger.writeError("Could not load Rule", ex);
                    window.Router.setNotFound();
                });
        }
        else {
            self.ruleDataManager.setId(0);
        }
    }

    onTriggerAction(action, trigger) {
        var triggers = this.ruleDataManager.getTriggers();
        this.setState({
            triggerCount: triggers.length
        });
    }

    onTargetAction(action, target) {
        var targets = this.ruleDataManager.getTargets();
        this.setState({
            targetCount: targets.length
        });
    }

    onChanged() {
        var self = this;

        if (window.CONFIG.RULE_VALIDATION_ENABLED) {
            this.ruleConflictProvider.getConflicts()
                .then(dataSource => {
                    self.setState({
                        conflicts: dataSource.getItems()
                    });
                });
        }
        else {
            self.setState({ conflicts: [] });
        }

    }


    setName(txt) {
        if (this.nameTextbox) {
            this.nameTextbox.setValue(txt);            
        }
        this.onNameChange(txt);
    }

    setPublish(isPublished) {
        this.setState({
            isPublished: isPublished
        });
    }

    onNameChange(txt) {
        this.setState({
            name: txt
        });
        this.ruleDataManager.setName(txt);
    }

    isValid() {
        return this.state.triggerCount > 0 && this.state.targetCount > 0 && this.state.name && this.state.name.length > 0 && (!this.state.conflicts || this.state.conflicts.length == 0);
    }

    onSaveClick() {
        this.ruleDataManager.save()
            .then(policy => {
                window.Alerts.showSuccess('Rule was successfully saved!');                
                window.Router.setRoute('/rules/' + encodeURIComponent(policy.result.id));
            })
            .catch(ex => {
                if (ex.Messages && ex.Messages.length > 0) {
                    window.Alerts.showInfo(ex.Messages[0]);
                }
            });
    }

    onPublishClick() {
        var self = this;
        var publish = !this.state.isPublished;        
        this.rule.publish(publish)
            .then(() => {
                self.refresh();
                if (publish) {
                    window.Alerts.showSuccess("Rule was successfully published.");
                }
                else {
                    window.Alerts.showSuccess("Rule was successfully unpublished.");
                }
            });
    }

    deleteItem() {
        var self = this;
        window.Confirmation.show('Delete', 'Are you sure to delete this item?',
            function () {
                self.rule.executeDelete()
                    .then(data => {
                        window.Router.setRoute('/rules')
                        window.Alerts.showSuccess('Rule ' + data.name + ' has been deleted');
                    })
                    .catch(ex => {
                        if (ex.Messages && ex.Messages.length > 0) {
                            window.Alerts.showInfo(ex.Messages[0]);
                        }
                    });
            }
        );
    }

    render() {
        var self = this;
        return (
            <div className="rule-editor">
                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                        {self.props.preventEdit === true &&
                                <div>
                                    <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/rules')} />
                                    <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/rules/new')} />
                                    <ToolbarButton icon='glyphicon-pencil' text='Edit' onClick={() => window.Router.setRoute('/rules/' + encodeURIComponent(self.props.dataId) + '/edit')} />    
                                    <ToolbarButton icon={this.state.isPublished ? 'glyphicon-stop' : 'glyphicon-play'} text={this.state.isPublished ? 'unpublish' : 'publish'} onClick={this.onPublishClick} /> 
                                    <ToolbarButton icon='glyphicon-trash' className='btn-danger' text='Delete' onClick={() => this.deleteItem()} />
                                </div>
                            }
                            {self.props.preventEdit !== true &&
                                <div>
                                    <ToolbarButton icon='glyphicon-th-list' text='List' onClick={() => window.Router.setRoute('/rules')} />
                                </div>
                            }
                        </div>                            
                    )
                }} />


                {self.props.preventEdit !== true &&
                    <Card title='General'>
                        <label htmlFor='name'>Rule Name</label>
                        <TextboxControl required={true} onChange={this.onNameChange} onInit={ctrl => self.nameTextbox = ctrl} />
                    </Card>
                }
                

                <Row>
                    <Column>
                        <Card title={<RuleEditorTitle title='Conditions' button={<span className='glyphicon glyphicon-plus'></span>} disabled={self.props.preventEdit} onClick={() => self.ruleTriggerEditor.showModal()} />} subtitle='The combination of the following conditions will trigger this rule:'>
                            <RuleTriggerEditor preventEdit={self.props.preventEdit} ruleDataManager={self.ruleDataManager} onChanged={self.onChanged} onInit={ctrl => self.ruleTriggerEditor = ctrl} />
                        </Card>
                    </Column>

                    <Column>
                        <Card title={<RuleEditorTitle title='Feature Restrictions' button={<span className='glyphicon glyphicon-plus'></span>} disabled={self.props.preventEdit} onClick={() => self.ruleTargetEditor.showModal()} />} subtitle='The rule will target the following packaging features when triggered:'>
                            <RuleTargetEditor preventEdit={self.props.preventEdit} ruleDataManager={self.ruleDataManager} onChanged={self.onChanged} onInit={ctrl => self.ruleTargetEditor = ctrl} />
                        </Card>
                    </Column>
                </Row>



                {self.props.preventEdit !== true &&
                    <button className='btn btn-primary btn-lg' disabled={!this.isValid()} onClick={this.onSaveClick}>Save</button>
                }


            </div>
        );
    }
}


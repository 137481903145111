import React from 'react';
import { TextboxControl } from '../../../controls/TextboxControl';
import { DropdownControl } from '../../../controls/DropdownControl';
import { GenericListControl } from '../../../controls/SimpleListControl';
import { Spacer } from '../../../components/Containers';



export class StructureSpecPropertyEditor extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.structureSpecId = this.props.structureSpecId ? this.props.structureSpecId : null;
        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);

        this.state = {
            value: null,
            properties: []
        }
    
        this.onNewPropertyClick = this.onNewPropertyClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onDeletePropertyClick = this.onDeletePropertyClick.bind(this); 
    }



    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve, reject) {

            self.metaData = metaData;
            var dataSourceData = metaData.dataSourceData;

            self.dataManager.getDataSourceItems(dataSourceData.name)
                .then(items => {
                    self.setState({
                        properties: items
                    });
                    resolve(self);
                })
                .catch(error => {
                    reject(error)
                });

        });
    }

    setValue(value) {
        this.setState({
            value: value
        });

        this.dataManager.reportChange(value, this);
    }

    getValue() {
        return this.state.value;
    }

    isInputValid() {
        return true;
    }


    getProperty(id) {
        return this.state.properties.find(function (property) {
            return property.id == id;
        });        
    }

    getFormattedValue(property, item) {        

        if (property.isText == true) {
            return item.text + ' ' + property.uom;
        }
        else if (property.isRange == true) {
            return item.fromValue + ' to ' + item.toValue+' '+ property.uom;
        }
        else {
            return item.fromValue + ' ' + property.uom;
        }
    }

    onNewPropertyClick() {
        var self = this;
        window.Modal.show(
            modal => <PropertyModalContent properties={self.state.properties} modal={modal} structureSpecId={self.props.structureSpecId} onAddClick={self.onAddClick}/>,
            'New Property'
        );
    }

    
    onAddClick(input) {
        var prop = {
            id: 0,
            structureSpecId: parseInt(input.structureSpecId),
            dataObject1Id: parseInt(input.structureSpecId),
            dataObject2Id: parseInt(input.selectedPropertyId),
            dataObject2: { id: input.selectedPropertyId, status: 'ACT' },
            fromValue: input.from,
            toValue: input.to,
            text: input.text,
            status: 'ACT'
        };

        this.removeItem(prop);
        var items = this.state.value ? this.state.value : [];
        
        items.push(prop);
        this.setValue(items);
    }
    
    onDeletePropertyClick(item) {
        this.removeItem(item);
    }

    removeItem(item) {
        var items = this.state.value;
        if (items && items.length > 0) {
            var index = items.findIndex(e => e.dataObject2Id == item.dataObject2Id);
            if (index >= 0) {
                items.splice(index, 1);
                this.setValue(items);
            }
        }
    }

    sortList(items) {
        var result = [];
        if (items && items.length > 0) {
            var tmp = [];
            items.forEach(item => {
                var property = this.getProperty(item.dataObject2Id);
                tmp.push({
                    name: property.name,
                    value: item
                });                
            });
            tmp.sort((a, b) => (a.name > b.name) ? 1 : -1);
            tmp.forEach(item => {
                result.push(item.value);
            });
        }
        return result;       
    }

    render() {
        var self = this;
        return (
            <div className="structure-spec-property-editor">

                <GenericListControl items={this.state.value}
                    onRenderTitle={
                        () => {
                            return (
                                <div className='row'>
                                    <div className='col col-4'>Name</div>
                                    <div className='col col-3'>Typical Value</div>
                                    <div className='col col-3'>Test Method</div>
                                    <div className='col col-2'>
                                        <button className='btn btn-primary btn-sm pull-right' onClick={self.onNewPropertyClick}>Add</button>
                                    </div>
                                </div>
                                )
                        } 
                    }
                    onSort={items => {
                        return self.sortList(items);
                    }}
                    onRender={
                        (item, idx) => {
                        var property = self.getProperty(item.dataObject2Id);
                        return (
                            <div className='row'>
                                <div className='col col-4'>{property.name}</div>
                                <div className='col col-3'>{self.getFormattedValue(property, item)}</div>
                                <div className='col col-3'>{property.testMethod}</div>
                                <div className='col col-2'>
                                    <button className='btn btn-secondary-outline btn-sm pull-right' onClick={() => self.onDeletePropertyClick(item, idx)}><span className='glyphicon glyphicon-trash'></span></button>
                                </div>
                            </div>
                        )
                    }
                }/>               
            </div>
        );
    }
}



class PropertyModalContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedProperty: null,
            isValid: false
        }

        this.input = {
            selectedPropertyId: null,
            structureSpecId: null,
            text: null,
            from: null,
            to: null 
        }

        this.onPropertyTypeChanged = this.onPropertyTypeChanged.bind(this);
        this.onInputTextChange = this.onInputTextChange.bind(this);
        this.onInputRangeFromChange = this.onInputRangeFromChange.bind(this);
        this.onInputRangeToChange = this.onInputRangeToChange.bind(this);
        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
    }

    onPropertyTypeChanged(value, item) {
        this.setState({
            selectedProperty: item
        });
        this.input.selectedPropertyId = item ? item.id : null;
    }

    onInputTextChange(value) {
        this.structureSpecId = this.structureSpecId ;
        this.input.text = value;
        this.input.from = null;
        this.input.to = null;        
        this.setState({
            isValid: this.isValid()
        });
    }

    onInputRangeFromChange(value) {
        this.structureSpecId = this.structureSpecId;
        this.input.text = null;
        this.input.from = value;
        this.setState({
            isValid: this.isValid()
        });
    }

    onInputRangeToChange(value) {
        this.structureSpecId = this.structureSpecId;
        this.input.text = null;
        this.input.to = value;
        this.setState({
            isValid: this.isValid()
        });
    }

    onInputValueChange(value) {
        this.structureSpecId = this.structureSpecId;
        this.input.text = null;
        this.input.from = value;
        this.input.to = null;
        this.setState({
            isValid: this.isValid()
        });
    }

    getValueInputControl() {
        var selectedProperty = this.state.selectedProperty;
        if (selectedProperty) {
            if (selectedProperty.isText == true) {
                return (
                    <div>
                        <div className='row'>
                            <div className='attribute col-sm-12 col-lg-5'>
                                <label htmlFor='rangeFrom'>Text</label>
                                <TextboxControl id='rangeFrom' required={true} onChange={this.onInputTextChange} />
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedProperty.isRange == true) {
                return (
                    <div className='row'>
                        <div className='attribute col-sm-12 col-lg-5'>
                            <label htmlFor='rangeFrom'>Value From</label>
                            <TextboxControl id='rangeFrom' required={true} regex={this.getRegex()} onChange={this.onInputRangeFromChange} />
                        </div>
                        <div className='attribute col-sm-12 col-lg-5'>
                            <label htmlFor='rangeTo'>Value To</label>
                            <TextboxControl id='rangeTo' required={true} regex={this.getRegex()} onChange={this.onInputRangeToChange} />
                        </div>
                    </div>
                );

            }
            else {
                return (
                    <div className='row'>
                        <div className='attribute col-sm-12 col-lg-5'>
                            <label htmlFor='rangeFrom'>Value</label>
                            <TextboxControl id='rangeFrom' required={true} regex={this.getRegex()} onChange={this.onInputValueChange} />
                        </div>
                    </div>
                );
            }
        }
    }

    getRegex() {
        var integerLength = this.state.selectedProperty.integerLength;
        var fragmentLength = this.state.selectedProperty.fragmentLength;
        return '[0-9]{0,' + integerLength + '}(\\.[0-9]{0,' + fragmentLength + '})?';
    }

    isValid() {
        if (this.state.selectedProperty) {
            var property = this.state.selectedProperty;
            if (property.isText == true) {
                return this.input.text && this.input.text.length > 0;
            }
            else if (property.isRange == true) {
                return this.input.from && this.input.from.length > 0 && this.input.to && this.input.to.length > 0;
            }
            else {
                return this.input.from && this.input.from.length > 0;
            }
        }
        return false;
    }

    onAddClick() {
        this.props.onAddClick(this.input);
        this.props.modal.close();
    }

    render() {
        var self = this;
        return (
            <div className='view'>

                <label htmlFor='name'>Name</label>
                <DropdownControl id="name" items={this.props.properties} valueMemberName='id' displayMemberName='name' onChange={self.onPropertyTypeChanged} />

                <Spacer/>

                {self.getValueInputControl()}


                <Spacer>
                    <div className='attribute col-sm-12 col-lg-12'>
                        <button className='btn btn-primary' disabled={!self.state.isValid} onClick={self.onAddClick}>Add</button>
                        <button className='btn btn-secondary pull-right' onClick={() => self.props.modal.close()}>Close</button>
                    </div>
                </Spacer>

            </div>
        );
    }
}
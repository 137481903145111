import React from 'react';
import { ToolbarControl, ToolbarButton } from '../../controls/ToolbarControl';
import { RuleDataManager } from './Controls/RuleDataManager';
import { RulesListControl } from './Controls/RulesListControl';
import { RuleEditor } from './Controls/RuleEditor';


export class RulesHomeView extends React.Component {

    constructor(props) {
        super(props);        
        this.ruleDataManager = new RuleDataManager();
    }

    render() {
        return (
            <div className="view">
                <h1>Packaging Feature Rules</h1>
                <p>Published Rules will be applied depending on the Rule Conditions and in the Sequence of the list below:</p>
                <ToolbarControl renderButtons={() => {
                    return (
                        <div>
                            <ToolbarButton icon='glyphicon-plus' text='New' onClick={() => window.Router.setRoute('/rules/new')} />
                        </div>
                    )
                }} />
                <RulesListControl onRowSelected={item => window.Router.setRoute('/rules/' + encodeURIComponent(item.id))} />
            </div>
        );
    }
}

export class RuleDetailsView extends React.Component {

    constructor(props) {
        super(props);
        this.ruleDataManager = new RuleDataManager();
        this.onLoaded = this.onLoaded.bind(this);

        this.state = {
            name: ''
        };
    }

    onLoaded(rule) {
        this.setState({
            name: rule.name
        });
    }

    render() {
        return (
            <div className="view">          
                <h1>{this.state.name}</h1>
                <RuleEditor ruleDataManager={this.ruleDataManager} dataId={this.props.param_0} preventEdit={true} onLoaded={this.onLoaded} onInvalid={data => { window.Router.setRoute('/rules/'+data.id+'/edit') }} />                                               
            </div>
        );
    }
}

export class RuleEditView extends React.Component {

    constructor(props) {
        super(props);
        this.ruleDataManager = new RuleDataManager();
    }

    render() {
        return (
            <div className="view">
                <h1>Packaging Feature Rule</h1>
                <RuleEditor ruleDataManager={this.ruleDataManager} dataId={this.props.param_0} />
            </div>
        );
    }
}

import React, { useState } from 'react';
import moment from 'moment-timezone';



export const DateTime = ({ dateTime, showTimeZone }) => {

    const tz = moment.tz.guess();

    const [displayUtc, setDisplayUtc] = useState(false);

    return (
        
        <span>
            {showTimeZone
                ? <span className='clickable' onClick={() => { setDisplayUtc(!displayUtc) }}>
                    {displayUtc
                        ? <span>{moment(dateTime).format('L')} {moment(dateTime).format('LTS')} (UTC)</span>
                        : <span>{moment(dateTime + 'Z').format('L')} {moment(dateTime + 'Z').format('LTS')} ({tz})</span>
                    }                                           
                </span>
                : <span>
                    <span>{moment(dateTime + 'Z').format('L')} {moment(dateTime + 'Z').format('LTS')}</span>
                </span>
            }
        </span>

    )
}

import React from 'react';
import { DropdownControl } from '../../../controls/DropdownControl';
import { Logger } from "../../../infrastructure/Logger";

export class RuleTriggerDefinitionSelector extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }
        this.ruleDataManager = this.props.ruleDataManager;

        this.dataSource = null;
        this.state = {
            items: []
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        var self = this;
        this.ruleDataManager.getRuleTriggerDefinitionDataSouce()
            .then(dataSource => {
                self.dataSource = dataSource;
                var items = dataSource.getItems();
                self.setState({
                    items: items
                });
            })
            .catch(errors => {
                Logger.writeError('Could not retreive data source items', errors)
            });
    }

    onChange(value) {
        if (this.props.onChange) {
            var item = this.dataSource.getItem(value);
            this.props.onChange(value, item);
        }
    }

    render() {
        return (
            <div>
                <DropdownControl items={this.state.items} value={this.props.value} onChange={this.onChange} valueMemberName='id' displayMemberName='name' />
            </div>
        );
    }
}




export class RuleTargetDefinitionSelector extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.ruleDataManager) {
            throw new Error("RuleDataManager Property is required");
        }
        this.ruleDataManager = this.props.ruleDataManager;

        this.dataSource = null;
        this.state = {
            items: []
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        var self = this;
        this.ruleDataManager.getRuleTargetDefinitionDataSouce()
            .then(dataSource => {
                self.dataSource = dataSource;
                var items = dataSource.getItems();
                self.setState({
                    items: items
                });
            })
            .catch(errors => {
                Logger.writeError('Could not retreive data source items', errors)
            });
    }

    onChange(value) {
        if (this.props.onChange) {
            var item = this.dataSource.getItem(value);
            this.props.onChange(value, item);
        }
    }

    render() {
        return (
            <div>
                <DropdownControl items={this.state.items} value={this.props.value} onChange={this.onChange} valueMemberName='id' displayMemberName='name' />
            </div>
        );
    }
}
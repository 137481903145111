import React from 'react';

export class LabelDataControl extends React.Component {
    constructor(props) {
        super(props);
        if (!this.props.dataManager) {
            throw new Error("ControlDataManager Property is required");
        }

        if (!this.props.propertyName) {
            throw new Error("PropertyName Property is required");
        }

        this.dataManager = this.props.dataManager;
        this.dataManager.register(this);

        this.state = {
            value: ''
        };
    }

    componentDidMount() {
        this.dataManager.bindControl(this.props.propertyName);
    }

    componentWillUnmount() {
        this.dataManager.unbindControl(this.props.propertyName);
    }

    init(metaData) {
        var self = this;
        return new Promise(function (resolve) {
            resolve(self);
        });
    }

    setValue(value) {
        this.setState({
            value: value
        });
    }

    getValue() {
        return this.state.value >= 0 ? this.state.value : null;
    }

    isInputValid() {
        var result = true;
        return result;
    }

    getRenderData() {        
        if (this.props.onRender) {
            return this.props.onRender(this.state.value)
        }
        else {
            return this.state.value;
        }
    }

    render() {
        return (
            <span className='label-control'>{this.getRenderData(this.state.value)}</span>
        );
    }
}

import React from 'react';
import { ProductReviewListComponent, ProductListComponent, ProductReviewEditComponent } from './Controls/ProductListComponent';
import { ProductEditorComponent } from './Controls/ProductEditorComponent';
import { ProductViewComponent } from './Controls/ProductViewComponent';
import { ImageTitle } from '../../core/ImageTitle';


export class ProductHomeView extends React.Component {

    render() {
        return (

            <div className="view">
                <ImageTitle text='Products' />
                <ProductListComponent dataObject='products' user={this.props.user} onRowSelected={item => window.Router.setRoute('/products/' + encodeURIComponent(item.id))} />
            </div>

        );
    }
}


export class ProductListView extends React.Component {


    render() {
        return (

            <div className="view">
                <ImageTitle text='Products' />
                <ProductListComponent dataObject='products' user={this.props.user} onRowSelected={item => window.Router.setRoute('/products/' + encodeURIComponent(item.id))} />
            </div>

        );
    }
}


export class ProductReviewView extends React.Component {

    render() {
        return (

            <div className="view">
                <ImageTitle text='Pending Product Reviews' />
                <ProductReviewListComponent user={this.props.user}  />
            </div>
        );
    }
}

export class ProductReviewEditView extends React.Component {

    render() {
        return (

            <div className="view">
                <ProductReviewEditComponent user={this.props.user} dataId={this.props.param_0} />
            </div>
        );
    }
}



export class ProductDisplayView extends React.Component {


    render() {
        return (
            <div className="view">                
                <ProductViewComponent user={this.props.user} dataId={this.props.param_0} />
            </div>
        );
    }
}

export class ProductEditView extends React.Component {


    render() {
        return (
            <div className="view">                
                <ProductEditorComponent user={this.props.user} dataId={this.props.param_0} />
            </div>
        );
    }
}





import React from 'react';
import { ImageTitle } from '../../../core/ImageTitle';
import { GenericComponent } from '../../../dataForms/GenericComponent';

export class SalesChannelView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;
    }

    render() {
        var self = this;
        return (
            <div className="view">
                <ImageTitle text='Sales Channels'/>
                <GenericComponent dataObject='saleschannels' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}
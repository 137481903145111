import React from 'react';
import { BackSplash } from '../components/Dialogs';
import { Logger } from "../infrastructure/Logger";

window.Loader = (function (loader) {
    loader.control = {};

    loader.stackIdx = 0;

    loader.register = function (cntrl) {
        loader.control = cntrl;
    }

    loader.show = function () {
        loader.stackIdx++;
      //  console.log('loader', loader.stackIdx);

        loader.control.show();
    }

    loader.hide = function () {
        loader.stackIdx--;

       // console.log('loader', loader.stackIdx);

        if (loader.stackIdx <= 0) {
            loader.stackIdx = 0;
            loader.control.hide();
        }        
    }

    return loader;
}(window.Loader = window.Loader || {}));



export class LegacyLoaderComponent extends React.Component {
    constructor(props) {
        super(props);

        this.timer = null;

        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        window.Loader.register(this);
    }

    
    show() {
        Logger.writeDebug('Loader: show');
        this.setState({
            visible: true
        });
    }

    hide() {
        Logger.writeDebug('Loader: hide');        
        this.setState({
            visible: false,
        });
    }

    render() {
        return (
            <div>
                {this.state.visible &&
                    <div>
                        <BackSplash />
                        <div className='spinner-circle'/>
                    </div>
                }
            </div>
        );
    }
}
